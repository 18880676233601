import React, {FC, ReactElement} from 'react';

import Tippy from '@tippyjs/react';
import {renderToString} from 'react-dom/server';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {Placement} from 'tippy.js';

import styles from './Hint.module.scss';

interface IHintProps{
    children: ReactElement | string | number,
    trigger: ReactElement,
    placement?: Placement,
}

const Hint: FC<IHintProps> = ({children, trigger, placement}) => {

    const rendered = typeof children === 'string' || typeof children === 'number'
        ? String(children)
        : renderToString(children);

    const hintTextLength = rendered.replace(/<[^>]+>/g, '').length;

    return (
        <Tippy
            content={<div className={styles.initial} dangerouslySetInnerHTML={{__html: rendered}} />}
            theme="light"
            interactive
            allowHTML
            className={hintTextLength > 350 ? styles.hint : ''}
            placement={placement ?? 'top'}
        >
            {trigger}
        </Tippy>
    );
};

export default Hint;