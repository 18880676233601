import React, {useCallback, useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import TransparentButton from '../../UI/Button/TransparentButton';
import TariffVariantModal from './TariffVariantModal';

const CreateTariffVariantsButton: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    return (
        <>
            <TransparentButton
                onClick={openModal}
                role="button"
                aria-label="Добавить новый тарифный пакет"
            >
                Добавить пакет
                <PlusCircleIcon
                    width="20px"
                    height="20px"
                    aria-hidden="true"
                />
            </TransparentButton>
            <TariffVariantModal
                open={isModalOpen}
                handleClose={closeModal}
            />
        </>
    );
};

export default React.memo(CreateTariffVariantsButton);