import React from 'react';
import {Link} from 'react-router-dom';

import {Control, FieldErrors, UseFormRegister} from 'react-hook-form';


import {useSelector} from 'react-redux';

import Checkbox from '../../UI/Checkbox/Checkbox';

import IMonitorOutput from '../../../models/Monitor/IMonitor';

import {userSelector} from '../../../redux/authSlice';

import {AppUrls} from '../../../constants/urls';

import {telegramChatAPI} from '../../../api/telegramChatAPI';

import DelaySelector from '../../UI/DelaySelector/DelaySelector';

import DelaySelectorTelegram from '../../UI/DelaySelector/DelaySelectorTelegram';

import styles from './MonitorForm.module.scss';

interface INotifySettingsSubformProps {
    item?: IMonitorOutput,
    errors: FieldErrors<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
}


const NotifySettingsSubform: React.FC<INotifySettingsSubformProps> = ({
    item,
    control,
    register,
}) => {
    const user = useSelector(userSelector);
    //const isNew = !item;
    const {data: chats, isLoading} = telegramChatAPI.useGetActiveTelegramChatsQuery();


    return (
        <>
            <div className="form-group d-flex align-items-center">
                <div className="col">
                    <Checkbox
                        disabled={!user?.email_confirmed}
                        {...register('notify_by_email')}
                    >
                        Уведомлять на подтвержденный Email
                    </Checkbox>
                </div>
                {
                    !user?.email_confirmed
                    && <div className={'col ' + styles.error}>Необходимо подтвердить Email</div>
                }
                <DelaySelector
                    control={control}
                    name="notify_email_confirmed_delay"
                    value={item?.notify_email_confirmed_delay ?? 0}
                />
            </div>

            <div className="form-group d-flex align-items-center">
                Уведомления через Telegram
                {
                    !isLoading && chats?.length === 0
                    && <div className={styles.error}>
                        Необходимо добавить один или несколько чатов
                        в <Link to={AppUrls.SETTINGS}>Настройках</Link> и активировать
                    </div>
                }
            </div>

            {
                !isLoading
                && chats
                && chats.length > 0
                && chats.map((chat, index) => (
                    <div
                        key={chat.id}
                        className="form-group d-flex align-items-center"
                    >
                        <div className="col">
                            <Checkbox
                                value={`${chat.id}`}
                                {...register('telegram_chats')}
                            >
                                {chat.description ?? chat.chat}
                            </Checkbox>
                        </div>

                        <DelaySelectorTelegram
                            index={index}
                            control={control}
                            register={register}
                            values={item?.notify_telegram_chats_settings ?? []}
                            chatId={chat.id}
                        />

                    </div>
                ))
            }
        </>
    );
};

export default NotifySettingsSubform;