import React from 'react';
import Modal from 'rsuite/Modal';

import SecondaryButton from '../../Button/SecondaryButton';

import PrimaryButton from '../../Button/PrimaryButton';

import styles from './ConfirmModal.module.scss';

interface IConfirmModalProps {
    open: boolean,
    handleClose: () => void,
    handleConfirm: () => void,
    description: string,
    confirmActionText: string,
    isLoading?: boolean,
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
    open,
    handleClose,
    handleConfirm,
    description,
    confirmActionText,
    isLoading,
}) => {
    return (
        <Modal
            backdrop="static"
            keyboard={false}
            open={open}
            onClose={handleClose}
            size="md"
        >
            <Modal.Header>
                <Modal.Title>
                    <div className={styles.title}>{description}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <PrimaryButton
                        loading={isLoading ?? false}
                        onClick={handleConfirm}
                        disabled={isLoading ?? false}
                        className="w-25 mx-2"
                    >
                        {confirmActionText}
                    </PrimaryButton>
                    <SecondaryButton onClick={handleClose} className="w-25 mx-2">
                        Отмена
                    </SecondaryButton>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmModal;