import {createApi} from '@reduxjs/toolkit/query/react';

import {AxiosRequestHeaders} from 'axios';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import {providesList} from '../rtk-query/RTKQueryHelper';
import IPayment, {IPaymentCreate, IPaymentUserStats} from '../models/IPayment';
import IPagination from '../models/IPagination';
import IHydraResponse from '../models/HydraResponse';
import {paginationAdapter} from '../utils/paginationHelper';
import ICollectionParams from '../models/ICollectionParams';

export const paymentAPI = createApi({
    reducerPath: 'paymentAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Payment'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addPayment: build.mutation<IPayment, IPaymentCreate>({
            query: (data) => ({
                path: ApiUrls.PAYMENTS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'Payment', id: 'LIST' }],
        }),
        getPayments: build.query<IPagination<IPayment>, ICollectionParams>({
            query: ({page, sort, filters, all = false}) => ({
                path: buildQuery(ApiUrls.PAYMENTS(page, sort, all, filters)),
                headers: {Accept: 'application/ld+json'} as unknown as AxiosRequestHeaders,
            }),
            providesTags: (result) => providesList(result?.items, 'Payment'),
            transformResponse(
                rawResult: IHydraResponse<IPayment>
            ): Promise<IPagination<IPayment>> | IPagination<IPayment> {
                return paginationAdapter(rawResult);
            },
        }),
        getPayment: build.query<IPayment, number>({
            query: (id) => ({
                path: ApiUrls.PAYMENT(id),
            }),
            providesTags: (result, error, id) => [{ type: 'Payment', id }],
        }),
        updatePayment: build.mutation<IPayment, Pick<IPayment, 'id' | 'status'>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.PAYMENT(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    paymentAPI.util.updateQueryData('getPayment', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'Payment', id: 'LIST' }],
        }),
        getPaymentUserStats: build.query<IPaymentUserStats, void>({
            query: () => ({
                path: ApiUrls.PAYMENT_USER_STATS(),
            }),
        }),
    }),
});