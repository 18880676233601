import React from 'react';

import Wrong from '../../Icons/Wrong';
import Correct from '../../Icons/Correct';


interface IYesNoProps {
    condition: boolean,
}

const YesNo: React.FC<IYesNoProps> = ({condition}) => {
    return condition ? <Correct /> : <Wrong />;
};

export default YesNo;