import React from 'react';

import ITariff from '../../models/ITariff';
import TariffForm from './TariffForm/TariffForm';
import MainModal from '../UI/Modal/MainModal';
import {ITariffVariant} from '../../models/ITariffVariant';

interface ITariffModalProps {
    open: boolean;
    handleClose: () => void;
    item: ITariff & {
        period: number;
        price: number;
    };
    variant?: ITariffVariant;
}

const TariffModal: React.FC<ITariffModalProps> = ({open, item, handleClose, variant}) => {

    return (
        <MainModal
            header={`Оплата тарифа - ${item.name}`}
            open={open}
            handleClose={handleClose}
            size="sm"
        >
            <TariffForm onSuccess={handleClose} item={item} variant={variant}/>
        </MainModal>
    );
};

export default TariffModal;