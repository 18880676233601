import React from 'react';
import {Control, Controller} from 'react-hook-form';

import OrderBy, {IOrderByProps} from './OrderBy';

interface IOrderByControlledProps extends IOrderByProps{
    control: Control<any>,
}

const OrderByControlled: React.FC<IOrderByControlledProps> = ({control, onChange, name, ...rest}) => {

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={rest.defaultValue}
            /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
            render={({field: {ref, onChange: onChangeFrom, value, ...field}}) => (<OrderBy
                onChange={(value) => {
                    onChangeFrom(value);
                    onChange?.(value);
                }}
                defaultValue={value}
                {...field}
                {...rest}
            />
            )}
        />
    );
};

export default OrderByControlled;