// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_nav__FTiMf {
  background-color: rgba(236, 255, 254, 0.7);
  position: fixed;
  width: 225px;
  height: 100vh;
  z-index: 1000;
}
@media (max-width: 769px) {
  .Navigation_nav__FTiMf {
    display: none;
  }
  .Navigation_nav__FTiMf.Navigation_opened__ltHa0 {
    display: block;
    background-color: rgba(236, 255, 254, 0.95);
  }
}

.Navigation_sidebar__iB0Oz {
  display: inline-flex;
  padding: 35px 10px 25px 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.Navigation_top_nav__ny8dv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}

.Navigation_bottom_nav__MJp2Z {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EAPJ;IAQQ,aAAA;EAEN;EADM;IACI,cAAA;IACA,2CAAA;EAGV;AACF;;AAEA;EACI,oBAAA;EACA,4BAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;AACJ","sourcesContent":[".nav {\n    background-color: rgba(236, 255, 254, 0.7);\n    position: fixed;\n    width: 225px;\n    height: 100vh;\n    z-index: 1000;\n\n    @media(max-width: 769px) {\n        display: none;\n        &.opened{\n            display: block;\n            background-color: rgba(236, 255, 254, 0.95);\n        }\n    }\n\n}\n\n.sidebar{\n    display: inline-flex;\n    padding: 35px 10px 25px 10px;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: flex-start;\n    height: 100%;\n}\n\n.top_nav{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: flex-start;\n    flex-shrink: 0;\n    width: 100%;\n}\n\n.bottom_nav{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 20px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Navigation_nav__FTiMf`,
	"opened": `Navigation_opened__ltHa0`,
	"sidebar": `Navigation_sidebar__iB0Oz`,
	"top_nav": `Navigation_top_nav__ny8dv`,
	"bottom_nav": `Navigation_bottom_nav__MJp2Z`
};
export default ___CSS_LOADER_EXPORT___;
