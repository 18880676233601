import {BaseQueryFn} from '@reduxjs/toolkit/query';

import createHttpRequest, {HttpRequestType} from '../utils/http';

const httpBaseQuery = (): BaseQueryFn<
    HttpRequestType,
    unknown,
    unknown
    > =>
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    async(args: HttpRequestType, api) => {
        try {
            const result = await createHttpRequest(args);
            return {data: result.data};
        } catch (httpErr: any) {
            return {
                error: httpErr.message,
            };
        }
    };

export default httpBaseQuery;