// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoreActions_more__YjQ4M {
  text-align: right;
  color: var(--icon-color);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.MoreActions_more__YjQ4M:hover, .MoreActions_more__YjQ4M:focus {
  color: var(--color-primary);
}

.MoreActions_dropdown__RtMI1 {
  min-width: 170px;
  border: 1px solid var(--table-popup-border);
  border-radius: 4px;
  padding: 5px 0;
  background-color: var(--plate-background-color);
  color: var(--text-color);
  margin: -13px;
}
.MoreActions_dropdown__item__kIlt5 {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}
.MoreActions_dropdown__item__kIlt5:not(:last-child) {
  margin-bottom: 10px;
}
.MoreActions_dropdown__item__kIlt5:hover, .MoreActions_dropdown__item__kIlt5:focus {
  background-color: #ececf0;
}
.MoreActions_dropdown__item-icon__sF34Y {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/MoreActions/MoreActions.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,wBAAA;EACA,4BAAA;EACA,eAAA;AACJ;AACI;EAEI,2BAAA;AAAR;;AAIA;EACI,gBAAA;EACA,2CAAA;EACA,kBAAA;EACA,cAAA;EACA,+CAAA;EACA,wBAAA;EACA,aAAA;AADJ;AAGI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AADR;AAGQ;EACI,mBAAA;AADZ;AAIQ;EAEI,yBAAA;AAHZ;AAMQ;EACI,kBAAA;AAJZ","sourcesContent":[".more {\n    text-align: right;\n    color: var(--icon-color);\n    transition: 0.3s ease-in-out;\n    cursor: pointer;\n\n    &:hover,\n    &:focus {\n        color: var(--color-primary);\n    }\n}\n\n.dropdown {\n    min-width: 170px;\n    border: 1px solid var(--table-popup-border);\n    border-radius: 4px;\n    padding: 5px 0;\n    background-color: var(--plate-background-color);\n    color: var(--text-color);\n    margin: -13px;\n\n    &__item {\n        display: flex;\n        align-items: center;\n        cursor: pointer;\n        padding: 5px 10px;\n\n        &:not(:last-child) {\n            margin-bottom: 10px;\n        }\n\n        &:hover,\n        &:focus {\n            background-color: lighten(#d0d0d9, 10%);\n        }\n\n        &-icon {\n            margin-right: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"more": `MoreActions_more__YjQ4M`,
	"dropdown": `MoreActions_dropdown__RtMI1`,
	"dropdown__item": `MoreActions_dropdown__item__kIlt5`,
	"dropdown__item-icon": `MoreActions_dropdown__item-icon__sF34Y`
};
export default ___CSS_LOADER_EXPORT___;
