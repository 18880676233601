import React, {FC, ReactElement} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {Placement} from 'tippy.js';

import Hint from './Hint';

interface IHintProps{
    children: ReactElement | string | number,
    placement?: Placement,
    className?: string,
}

const HintQuestion: FC<IHintProps> = ({children, placement, className}) => {
    return (
        <Hint
            trigger={<span className={className}>
                <FontAwesomeIcon icon={faQuestionCircle}/>
            </span>}
            placement={placement}
        >
            {children}
        </Hint>
    );
};

export default HintQuestion;