import React from 'react';

import {useSelector} from 'react-redux';


import {Link} from 'react-router-dom';

import {userSelector} from '../../../../../redux/authSlice';

import Progress from '../../../../UI/Progress/Progress';

import {AppUrls} from '../../../../../constants/urls';

import UserInformer from '../../../../UserInformer/UserInformer';

import styles from './TariffBanner.module.scss';

const TariffBanner: React.FC = () => {
    const user = useSelector(userSelector);

    
    if (!user) {
        return <></>;
    }

    return (
        <div className={styles.link_wrapper}>
            <div className={styles.wrapper}>
                <Link to={AppUrls.TARIFFS}>

                    <div className={styles.progress}>
                        <Progress percent={user.tariff_days_left_percent} />
                    </div>
                    <div className={styles.remain}>Тариф -
                        {null !== user.tariff_days_left && ' Осталось '}
                        {user.tariff_days_left_human_readable}
                    </div>
                    <div className={styles.text}>
                        {user.tariff.name}
                    </div>
                </Link>
                <span className={styles.line}></span>
                <UserInformer user={user} />
            </div>

        </div>
    );
};

export default TariffBanner;