import React, {MouseEventHandler} from 'react';

import YesNo from '../YesNo/YesNo';

interface IStatusToggleProps {
    condition: boolean,
    onClick: MouseEventHandler<HTMLDivElement>,
    activateText?: string,
    deactivateText?: string,
}

const StatusToggle: React.FC<IStatusToggleProps> = ({
    condition,
    onClick,
    activateText = 'Активировать',
    deactivateText = 'Деактивировать',
}) => {

    return (
        <div
            onClick={onClick}
            style={{cursor: 'pointer'}}
            title={condition ? deactivateText : activateText}
        >
            <YesNo condition={condition}/>
        </div>
    );
};

export default StatusToggle;