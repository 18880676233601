import React from 'react';

import MainModal from '../../UI/Modal/MainModal';
import MonitorsExportFormHandler from './MonitorsExportFormHandler';


interface IIncidentsExportModalProps {
    open: boolean,
    handleClose: () => void,
}

const MonitorsExportModal: React.FC<IIncidentsExportModalProps> = ({open, handleClose}) => {
    return (
        <MainModal
            header="Экспорт мониторов"
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <MonitorsExportFormHandler handleClose={handleClose} />
        </MainModal>
    );
};

export default MonitorsExportModal;