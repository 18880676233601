import React from 'react';

import {generatePath} from 'react-router-dom';

import ArrowCircleRight from '@rsuite/icons/legacy/ArrowCircleRight';

import {AppUrls} from '../../../constants/urls';

import Incidents from '../../Incident/Incidents';
import IPagination from '../../../models/IPagination';
import IIncident from '../../../models/IIncident';
import TransparentLinkButton from '../../UI/Button/TransparentLinkButton';
import LoaderCentered from '../../UI/Loader/LoaderCentered';

import TableBox from '../../UI/Box/TableBox/TableBox';

import styles from '../Home.module.scss';

interface ILastIncidentsProps {
    incidentsPagination?: IPagination<IIncident>,
    isFetching: boolean,
    isLoading: boolean,
}

const LastIncidents: React.FC<ILastIncidentsProps> = ({
    incidentsPagination,
    isFetching,
    isLoading,
}) => {

    return (
        <TableBox>
            <LoaderCentered active={isFetching}/>
            <div className="block_header">
                <div className="block_title">Последние инциденты</div>
                <TransparentLinkButton
                    href={generatePath(AppUrls.INCIDENTS)}
                    className={styles.transparent_button}
                >
                    Все инциденты
                    <ArrowCircleRight width="20px" height="20px"/>
                </TransparentLinkButton>
            </div>

            <Incidents
                pagination={incidentsPagination}
                hidePagination={true}
                isLoading={isLoading}
            />
        </TableBox>
    );
};

export default LastIncidents;