type IdType = number|string;
export function idToIri(id: IdType, resourceNamePlural: string):string {
    return `/api/v1/${resourceNamePlural}/${id}`;
}

export function idsToIri(ids: IdType|IdType[], resourceNamePlural: string):string[]{
    if (!ids) {
        return [];
    }
    if (!Array.isArray(ids)){
        return [idToIri(ids, resourceNamePlural)];
    }

    return ids.map(id => idToIri(id, resourceNamePlural));
}