import React from 'react';
import cn from 'classnames';

import {Link} from 'react-router-dom';

import MainMenu from '../../../UI/MainMenu/MainMenu';

import {AppUrls} from '../../../../constants/urls';

import EmailConfirmationBanner from './EmailConfirmationBanner/EmailConfirmationBanner';

import TariffBanner from './TariffBanner/TariffBanner';

import Logo from '../../../Icons/Logo';

import styles from './Navigation.module.scss';

interface INavigationProps {
    opened: boolean
}

const Navigation: React.FC<INavigationProps> = ({opened}) => {

    return (
        <nav className={cn(styles.nav, {[styles.opened]: opened})} role="navigation">
            <div className={cn([styles.sidebar, 'sidebar-collapse'])}>
                <div className={styles.top_nav}>
                    <Link to={AppUrls.HOME}>
                        <div className="logo-wrapper">
                            <Logo />
                        </div>
                    </Link>
                    <MainMenu/>
                </div>
                <div className={styles.bottom_nav}>
                    <EmailConfirmationBanner />
                    <TariffBanner />
                </div>
            </div>
        </nav>
    );
};

export default Navigation;