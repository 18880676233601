interface PluralVariants {
    one: string;
    few: string;
    many: string;
}

export const pluralize = (counter: number, variants: PluralVariants) => {
    const lastTwo = Math.abs(counter) % 100;
    const lastDigit = lastTwo % 10;

    if (lastDigit === 1 && !(lastTwo >= 11 && lastTwo <= 14)) {
        return variants.one;
    }
    if (lastDigit >= 2 && lastDigit <= 4 && !(lastTwo >= 12 && lastTwo <= 14)) {
        return variants.few;
    }
    return variants.many;
};