import React from 'react';

import IUserOutput from '../../models/IUser';
import {Pages} from '../../constants/pages';
import Box from '../UI/Box/Box';
import ProfileForm from './ProfileForm';
import PasswordReset from './PasswordReset';
import ChangeEmailButton from './ChangeEmail';

import BoxTransparent from '../UI/Box/BoxTransparent/BoxTransparent';

import styles from './Profile.module.scss';

interface IProfileProps {
    user: IUserOutput,
}

const Profile: React.FC<IProfileProps> = ({user}) => {
    let emailChangeBlock;

    if(user.email_confirmed) {
        emailChangeBlock = (
            <>
                <BoxTransparent className={styles.wrapper}>
                    <br/>
                </BoxTransparent>
                <Box>
                    <ChangeEmailButton/>
                </Box>
            </>
        );
    }

    return (
        <>
            <h3 className={styles.title}>{Pages.SETTINGS}</h3>
            <div className={styles.container}>
                <div className={styles.profile_form}>
                    <Box>
                        <ProfileForm user={user}/>
                    </Box>
                </div>
                <div>
                    <Box>
                        <PasswordReset/>
                    </Box>
                    {emailChangeBlock}
                </div>
            </div>
        </>
    );
};

export default Profile;