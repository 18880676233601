import React, {useState} from 'react';


import EmailIcon from '../Icons/Email';

import ChangeEmailModal from './ChangeEmailModal';

import styles from './Profile.module.scss';


const ChangeEmailButton: React.FC = () => {
    const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
    const handleChangeEmailModalClose = () => setIsChangeEmailModalOpen(false);
    return (
        <>
            <div className={styles.reset}>
                <a // eslint-disable-line
                    href="#"
                    onClick={() => setIsChangeEmailModalOpen(true)}
                    className={styles.email_change}
                >
                    {<span><EmailIcon color="#1DB5B6"/></span>} Изменить E-mail
                </a>
                <p className={styles.change_text}>
                    Для операции необходимо повторно ввести пароль и подтвердить адрес.
                </p>
                <ChangeEmailModal open={isChangeEmailModalOpen} handleClose={handleChangeEmailModalClose}/>
            </div>
        </>
    );
};

export default ChangeEmailButton;