import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ITariffVariant} from '../models/ITariffVariant';
import {RootState} from '../store';

interface SelectedVariantsState {
    variant: ITariffVariant | null;
}

const initialState: SelectedVariantsState = {
    variant: null,
};

export const selectedVariantSlice = createSlice({
    name: 'selectedVariant',
    initialState,
    reducers: {
        setSelectedVariant: (state, action: PayloadAction<ITariffVariant>) => {
            state.variant = action.payload;
        },
    },
});

export const { setSelectedVariant } = selectedVariantSlice.actions;
export const selectSelectedVariant = (state: RootState) => state.selectedVariant.variant;
export default selectedVariantSlice.reducer;