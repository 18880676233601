import React from 'react';

import {useSelector} from 'react-redux';

import IMonitorLog from '../../models/Monitor/IMonitorLog';
import MonitorLogRow from './MonitorLogRow';
import {userSelector} from '../../redux/authSlice';
import Table from '../UI/Table/Table';

interface IMonitorLogsProps {
    items?: IMonitorLog[],
    isLoading: boolean,
}

const MonitorLogs: React.FC<IMonitorLogsProps> = ({items, isLoading}) => {
    const user = useSelector(userSelector);
    return (
        <Table
            columns={[
                {label: 'Время'},
                {label: 'Статус'},
                {label: 'Сообщение'},
                {label: 'Время отклика, мс'},
            ]}
            items={items}
            rowFactory={item => <MonitorLogRow
                key={item.datetime}
                item={item}
                tzString={user?.timezone}
            />}
            isLoading={isLoading}
        />
    );
};

export default MonitorLogs;