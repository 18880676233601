import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {passwordResetRequest, userSelector} from 'redux/authSlice';

import {unwrapResult} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

import {TypedDispatch} from '../../types';

import styles from './Profile.module.scss';


const PasswordReset: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const user = useSelector(userSelector);

    async function onPasswordResetRequest(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        dispatch(passwordResetRequest(user?.email ?? '')).then(resultAction => {
            const response = unwrapResult(resultAction);
            toast.info(response?.message);
        });
    }

    return (
        <div className={styles.reset}>
            <a // eslint-disable-line
                href="#"
                onClick={(e) => onPasswordResetRequest(e)}
                className={styles.password_change}
            >
                Изменить пароль
            </a>
            <p className={styles.change_text}>
                Вам будет отправлено письмо со ссылкой на форму смены пароля, это необходимо для
                безопасности вашей учетной записи.
            </p>
        </div>
    );
};

export default PasswordReset;