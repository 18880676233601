import React from 'react';

import BaseLayout, {BaseLayoutProps} from '../BaseLayout/BaseLayout';

import styles from './BlankLayout.module.scss';


const BlankLayout: React.FC<BaseLayoutProps> = (props) => {
    return (
        <BaseLayout {...props}>
            <div className={styles.wrapper}>
                {props.children}
            </div>
        </BaseLayout>
    );
};

export default BlankLayout;