import React, {useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import TransparentButton from '../UI/Button/TransparentButton';
import CreateBatchMonitorModal from './CreateBatchMonitorModal';
import MonitorLimitExceededModal from './MonitorLimitExceededModal';
import useCanCreateMonitor from '../../hooks/useCanCreateMonitor';

const CreateBatchMonitorButton: React.FC = () => {
    const canCreateMonitor = useCanCreateMonitor();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                 Массовое добавление <PlusCircleIcon width="20px" height="20px" />
            </TransparentButton>
            {
                canCreateMonitor
                    ? <CreateBatchMonitorModal open={isModalOpen} handleClose={handleClose}/>
                    : <MonitorLimitExceededModal open={isModalOpen} handleClose={handleClose}/>
            }
        </>
    );
};

export default CreateBatchMonitorButton;