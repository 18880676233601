import React from 'react';

import PlaceholderGraph from 'rsuite/cjs/Placeholder/PlaceholderGraph';

import {useSelector} from 'react-redux';

import {monitorAPI} from '../../../api/monitorAPI';
import ITimeRange from '../../../models/ITimeRange';
import TimeRanges from '../../UI/TimeRanges/TimeRanges';


import {userSelector} from '../../../redux/authSlice';

interface IMonitorLastDayIncidentsRangesProps {
    uuid: string,
    height: number,
}

const MonitorLastDayIncidentsRanges: React.FC<IMonitorLastDayIncidentsRangesProps> = ({uuid, height}) => {
    const user = useSelector(userSelector);
    const {data, isLoading} = monitorAPI.useGetMonitorLastDayIncidentsRangesQuery(uuid);

    if (isLoading || !data) {
        return <PlaceholderGraph active height={height} />;
    }

    const ranges:ITimeRange[] = data.map(range => ({
        timeFrom: range.time_from,
        timeTo: range.time_to,
        color: 'red',
    }));

    return (
        <TimeRanges
            ranges={ranges}
            backgroundColor="#04b800"
            tzString={user?.timezone}
            isBeginAtMidnight={false}
            height={height}
            hideTime={true}
        />
    );
};

export default MonitorLastDayIncidentsRanges;