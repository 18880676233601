import React from 'react';

import {Col, Grid, Row} from 'rsuite';

import {isEmpty} from 'lodash';

import {generatePath, Link} from 'react-router-dom';

import IWarning from '../../../models/IWarning';

import IncidentStatusBadge from '../../Status/IncidentStatusBadge';

import {getFormattedDate} from '../../../utils/datetime';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import {translateWarningCause} from '../../../utils/translate';

import {AppUrls} from '../../../constants/urls';

import styles from './Warning.module.scss';

interface IWarningProps {
    item: IWarning,
    tzString?: string | null,
}

const Warning: React.FC<IWarningProps> = ({item, tzString}) => {

    return (
        <ModalBodyWrapper>
            <Grid fluid>

                <Row gutter={16} className={styles.mb2}>
                    <Col xs={6}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Статус
                            </div>
                            <div>
                                <IncidentStatusBadge resolved={item.resolved}/>
                            </div>
                        </div>
                    </Col>

                    <Col xs={6}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Дата возникновения
                            </div>
                            <div>
                                {getFormattedDate(
                                    item.created_at,
                                    {format: 'DD.MM.YYYY, в HH:mm', tzString},
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col xs={6}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Дата решения
                            </div>
                            <div>
                                {getFormattedDate(item.resolved_at, {format: 'DD.MM.YYYY, в HH:mm', tzString})}
                            </div>
                        </div>
                    </Col>

                    <Col xs={6}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Решить до
                            </div>
                            <div>
                                {getFormattedDate(item.expire_at, {format: 'DD.MM.YYYY', tzString})}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.mb2}>
                    <Col xs={24}>
                        <div className={styles.well}>
                            <div className={styles.info_block}>
                            Причина:
                            </div>
                            <div className={styles.detail}>
                                {translateWarningCause(item.type)}
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    item.monitors
                && !isEmpty(item.monitors)
                && <Row className={styles.mb2}>
                    <Col xs={24}>
                        <div className={styles.well}>
                            <div className={styles.info_block}>
								Мониторы:
                            </div>
                            <div>
                                <ol>
                                    {item.monitors.map((monitor) => (
                                        <li key={monitor.uuid}>
                                            <Link to={generatePath(AppUrls.MONITOR_DETAILS, {
                                                monitorId: monitor.uuid,
                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                {monitor.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </Col>
                </Row>
                }
            </Grid>
        </ModalBodyWrapper>
    );
};

export default Warning;