import React, {useEffect} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {TypedDispatch} from '../../types';
import {authLoadingSelector, isAuthenticatedSelector, loginUser} from '../../redux/authSlice';
import ILoginForm from '../../models/forms/ILoginForm';
import EmailInput from '../UI/Input/EmailInput';
import {AppUrls} from '../../constants/urls';
import PrimaryButton from '../UI/Button/PrimaryButton';
import PasswordInput from '../UI/Input/PasswordInput';
import {redirectToDashboard, redirectToReturnUrl} from '../../utils/redirects';

import FormGroupWithError from '../UI/FormGroup/FormGroupWithError';

import AuthWrapper from './AuthWrapper';

import styles from './Auth.module.scss';

const Login: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const isLoading = useSelector(authLoadingSelector);

    const {register, handleSubmit, formState, control} = useForm<ILoginForm>({mode: 'onChange'});
    const {errors} = formState;
    useEffect(() => {
        if (isAuthenticated) {
            redirectToReturnUrl(navigate, searchParams) || redirectToDashboard(navigate);
        }
    }, [isAuthenticated]);

    const onSubmit: SubmitHandler<ILoginForm> = (formData) => {
        dispatch(loginUser(formData));
    };

    return (
        <AuthWrapper>
            <div className={styles.title_container}>
                <h2 className={styles.title}>Войдите в свой аккаунт</h2>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="register-form"
            >
                <FormGroupWithError error={errors?.email?.message}>
                    <EmailInput
                        register={register}
                        control={control}
                        required
                        placeholderToLabel
                    />
                </FormGroupWithError>


                <FormGroupWithError error={errors?.password?.message}>
                    <PasswordInput
                        register={register}
                        control={control}
                        placeholderToLabel
                    />

                </FormGroupWithError>

                <div className="form-group">
                    <PrimaryButton
                        type="submit"
                        loading={isLoading}
                        className="full-width"
                    >
                                Войти
                    </PrimaryButton>

                </div>

                <Link to={AppUrls.PASSWORD_RESET} className={styles.forgot_link}>
                            Забыли пароль?
                </Link>
            </form>

            <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                <span>Еще нет аккаунта?</span>
                <Link to={AppUrls.REGISTRATION} className={styles.register_link}>Зарегистрироваться</Link>
            </p>
        </AuthWrapper>
    );
};

export default Login;