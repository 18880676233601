import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Home from '../components/Home/Home';


const HomePage: React.FC = () => {
    return <MainLayout>
        <Home />
    </MainLayout>;
};

export default HomePage;