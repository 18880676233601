// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_wrapper__DjcOU {
  text-align: center;
  max-width: 600px;
  margin: auto;
  border: 1px solid #31c9ca;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.Error_wrapper__DjcOU h1 {
  color: #31c9ca;
}
.Error_wrapper__DjcOU p {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Error/Error.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,QAAA;EACA,6BAAA;AACJ;AACI;EACI,cAAA;AACR;AACI;EACI,eAAA;AACR","sourcesContent":[".wrapper {\n    text-align: center;\n    max-width: 600px;\n    margin: auto;\n    border: 1px solid #31c9ca;\n    padding: 20px;\n    border-radius: 5px;\n    background-color: #ffffff;\n    position: absolute;\n    left:0;\n    right:0;\n    top:50%;\n    transform:translate(0, -50%);\n\n    h1 {\n        color: #31c9ca;\n    }\n    p {\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Error_wrapper__DjcOU`
};
export default ___CSS_LOADER_EXPORT___;
