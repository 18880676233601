import React from 'react';
import {ButtonProps} from 'rsuite/esm/Button/Button';
import {Button} from 'rsuite';
import NoticeIcon from '@rsuite/icons/Notice';


const NotifyButton: React.FC<ButtonProps> = (props) => {
    return (
        <Button {...props} appearance="default">
            <NoticeIcon /> {props.children}
        </Button>
    );
};

export default NotifyButton;