import React, {useEffect} from 'react';

import BaseLayout, {BaseLayoutProps} from '../BaseLayout/BaseLayout';

import NavigationToggle from './NavigationToggler/NavigationToggle';

import styles from './MainLayout.module.scss';


const MainLayout: React.FC<BaseLayoutProps> = (props) => {
    // Костыли из Inspinia
    const updateBodyClasses = (): void => {
        const body: HTMLBodyElement = document.getElementsByTagName('body')[0];

        if (window.innerWidth < 769) {
            body.classList.add('body-small');
        } else {
            body.classList.remove('body-small');
        }
    };

    useEffect(() => {
        updateBodyClasses();
        window.addEventListener('resize', updateBodyClasses);
    });

    return (
        <BaseLayout {...props}>
            <div className={styles.layout}>
                <NavigationToggle />
                <div className={styles['layout-wrapper']}>
                    {
                        props.pageTitle && (
                            <div className="row wrapper page-heading">
                                <div className="col-12">
                                    <div
                                        className={'d-sm-flex align-items-center justify-content-between'}
                                    >
                                        <h2 className={'page_heading'}>{props.pageTitle}</h2>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <main className={styles['layout__main']}>
                        <div className={styles['layout-container']}>
                            {props.children}
                        </div>
                    </main>


                </div>
            </div>
        </BaseLayout>
    );
};

export default MainLayout;