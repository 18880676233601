import React from 'react';

import IUserOutput from '../../models/IUser';
import ExitButton from '../UI/IconButton/ExitButton';

import styles from './UserInformer.module.scss';

interface IUserInformerProps {
    user: IUserOutput | null,
    // eslint-disable-next-line no-unused-vars
    handleLogout: (event: React.MouseEvent) => Promise<void>,
}

const UserInformer: React.FC<IUserInformerProps> = ({user, handleLogout}) => {

    return (
        <div className={styles.wrapper}>

            <div className={styles.text}>
                {user?.last_name} {user?.first_name}
            </div>

            <div className={styles.icon}>
                <ExitButton className={styles.exit_btn} onClick={handleLogout} title="Выход"/>
            </div>

        </div>
    );
};

export default UserInformer;