import React from 'react';

import IPayment from '../../models/IPayment';
import Box from '../UI/Box/Box';


import LinkButton from '../UI/Button/LinkButton';
import {AppUrls} from '../../constants/urls';

import styles from './Payments.module.scss';

interface IPaymentSuccessProps {
    item: IPayment
}

const PaymentSuccess: React.FC<IPaymentSuccessProps> = ({item}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2 className="mb-3">Успешная оплата</h2>
                            <div className={styles.text}>
                                {`Вы успешно оплатили тариф "${item.tariff.name}"`}
                            </div>
                        </div>
                    </div>

                    <LinkButton
                        href={AppUrls.PAYMENTS}
                        className="full-width rs-btn-primary"
                    >Продолжить</LinkButton>
                </Box>
            </div>
        </div>
    );
};

export default PaymentSuccess;