/* eslint-disable max-len */
import React from 'react';

const Users: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68822)">
                <path d="M7.1665 11.833C7.1665 10.7284 8.06193 9.83301 9.1665 9.83301H13.4998C14.6044 9.83301 15.4998 10.7284 15.4998 11.833V13.9997C15.4998 14.8281 14.8283 15.4997 13.9998 15.4997H7.1665V11.833Z" stroke="currentColor"/>
                <path d="M14.1668 5.33366C14.1668 6.53028 13.1968 7.50033 12.0002 7.50033C10.8035 7.50033 9.8335 6.53028 9.8335 5.33366C9.8335 4.13704 10.8035 3.16699 12.0002 3.16699C13.1968 3.16699 14.1668 4.13704 14.1668 5.33366Z" stroke="currentColor"/>
                <path d="M0.5 11C0.5 9.61929 1.61929 8.5 3 8.5H7.66666C9.04738 8.5 10.1667 9.61929 10.1667 11V15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V11Z" fill="#ECFFFE" stroke="currentColor"/>
                <path d="M8.16667 3.33333C8.16667 4.89814 6.89814 6.16667 5.33333 6.16667C3.76853 6.16667 2.5 4.89814 2.5 3.33333C2.5 1.76853 3.76853 0.5 5.33333 0.5C6.89814 0.5 8.16667 1.76853 8.16667 3.33333Z" stroke="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68822">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Users;