// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeRanges_wrapper__iavaS {
  width: 100%;
}

.TimeRanges_day_range__rxFF\\+ {
  position: relative;
  width: 100%;
}

.TimeRanges_range__mQ40Y {
  position: absolute;
  top: 0;
}

.TimeRanges_label_from__jPloc {
  position: absolute;
  left: 0;
  top: -20px;
  transform: translateX(-50%);
}

.TimeRanges_label_to__Q9Vxh {
  position: absolute;
  right: 0;
  top: -20px;
  transform: translateX(50%);
}
.TimeRanges_label_to__Q9Vxh.TimeRanges_down__klOyR {
  top: 9px;
}

.TimeRanges_hint__apa\\+C {
  width: 90px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/TimeRanges/TimeRanges.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;AACJ;;AAEA;EACI,kBAAA;EACA,OAAA;EACA,UAAA;EACA,2BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,0BAAA;AACJ;AACI;EACI,QAAA;AACR;;AAGA;EACI,WAAA;EACA,kBAAA;AAAJ","sourcesContent":[".wrapper{\n    width: 100%;\n}\n\n.day_range{\n    position: relative;\n    width: 100%;\n}\n\n.range{\n    position: absolute;\n    top: 0;\n}\n\n.label_from{\n    position: absolute;\n    left: 0;\n    top: -20px;\n    transform: translateX(-50%);\n}\n\n.label_to{\n    position: absolute;\n    right: 0;\n    top: -20px;\n    transform: translateX(50%);\n\n    &.down{\n        top: 9px;\n    }\n}\n\n.hint{\n    width: 90px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TimeRanges_wrapper__iavaS`,
	"day_range": `TimeRanges_day_range__rxFF+`,
	"range": `TimeRanges_range__mQ40Y`,
	"label_from": `TimeRanges_label_from__jPloc`,
	"label_to": `TimeRanges_label_to__Q9Vxh`,
	"down": `TimeRanges_down__klOyR`,
	"hint": `TimeRanges_hint__apa+C`
};
export default ___CSS_LOADER_EXPORT___;
