/* eslint-disable max-len */
import React from 'react';

const Like: React.FC = () => {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13.25C-4.52838e-09 12.9545 0.0598446 12.6619 0.176117 12.389C0.292389 12.116 0.462811 11.8679 0.677654 11.659C0.892497 11.4501 1.14755 11.2843 1.42826 11.1713C1.70896 11.0582 2.00982 11 2.31366 11C2.61749 11 2.91835 11.0582 3.19905 11.1713C3.47976 11.2843 3.73481 11.4501 3.94966 11.659C4.1645 11.8679 4.33492 12.116 4.45119 12.389C4.56747 12.6619 4.62731 12.9545 4.62731 13.25V22.25C4.62731 22.8467 4.38355 23.419 3.94966 23.841C3.51576 24.2629 2.92728 24.5 2.31366 24.5C1.70004 24.5 1.11155 24.2629 0.677654 23.841C0.243759 23.419 9.14273e-09 22.8467 0 22.25V13.25ZM6.16975 12.9995V21.1445C6.16948 21.702 6.32896 22.2485 6.63029 22.7228C6.93162 23.1971 7.36288 23.5803 7.87568 23.8295L7.95281 23.867C8.80868 24.283 9.75232 24.4997 10.7091 24.5H19.063C19.7764 24.5003 20.4679 24.2601 21.0196 23.8203C21.5714 23.3805 21.9494 22.7683 22.0892 22.088L23.9402 13.088C24.0296 12.6528 24.0187 12.2037 23.9081 11.7732C23.7975 11.3426 23.5901 10.9412 23.3008 10.598C23.0114 10.2548 22.6474 9.9783 22.2349 9.78843C21.8225 9.59856 21.3718 9.50005 20.9154 9.5H15.4244V3.5C15.4244 2.70435 15.0994 1.94129 14.5208 1.37868C13.9423 0.81607 13.1577 0.5 12.3395 0.5C11.9304 0.5 11.5381 0.658035 11.2488 0.93934C10.9596 1.22064 10.7971 1.60218 10.7971 2V3.0005C10.7971 4.29872 10.3641 5.56192 9.56311 6.6005L7.4037 9.3995C6.60273 10.4381 6.16975 11.7013 6.16975 12.9995Z" fill="#758282"/>
    </svg>;

};

export default Like;