// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hint_hint__fl8ho {
  padding: 10px;
  max-height: 290px;
  overflow-y: scroll;
  overflow-x: clip;
}

.Hint_hint__fl8ho p {
  margin-bottom: 10px;
}

.Hint_hint__fl8ho ul {
  padding: 0;
  margin: 0 0 10px 20px;
}

.Hint_hint__fl8ho li {
  margin-top: 5px;
}

.Hint_hint__fl8ho li::marker {
  color: #1ab394;
}

.Hint_initial__cMOT8 {
  font-weight: initial;
  text-align: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Hint/Hint.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,qBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,oBAAA;EACA,mBAAA;AACJ","sourcesContent":[".hint {\n    padding: 10px;\n    max-height: 290px;\n    overflow-y: scroll;\n    overflow-x: clip;\n}\n\n.hint p {\n    margin-bottom: 10px;\n}\n\n.hint ul {\n    padding: 0;\n    margin: 0 0 10px 20px;\n}\n\n.hint li {\n    margin-top: 5px;\n}\n\n.hint li::marker {\n    color: #1ab394;\n}\n\n.initial{\n    font-weight: initial;\n    text-align: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hint": `Hint_hint__fl8ho`,
	"initial": `Hint_initial__cMOT8`
};
export default ___CSS_LOADER_EXPORT___;
