// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_wrapper__z8cSD {
  display: flex;
  width: 100%;
  padding: 10px 20px;
  gap: 20px;
  height: 65px;
}

.Search_search__TAAIR {
  width: 300px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".wrapper{\n    display: flex;\n    width: 100%;\n    padding: 10px 20px;\n    gap: 20px;\n    height: 65px;\n}\n\n.search{\n    width: 300px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Search_wrapper__z8cSD`,
	"search": `Search_search__TAAIR`
};
export default ___CSS_LOADER_EXPORT___;
