import React from 'react';

import cn from 'classnames';

import {translateMonitorStatus} from '../../utils/translate';
import {colorMonitorStatus} from '../../utils/colors';

import {MonitorStatus} from '../../models/Monitor/IMonitor';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './StatusBadge.module.scss';


interface IMonitorStatusBadgeProps {
    status: MonitorStatus,
}

const MonitorStatusBadge: React.FC<IMonitorStatusBadgeProps> = ({
    status,
}) => {
    const backgroundColor = colorMonitorStatus(status);
    const translatedStatus = translateMonitorStatus(status);
    const color = colorMonitorStatus(status);
    const currentBreakpoint = useBreakpoint();
    return currentBreakpoint === 'large'
        ? <div className={cn([styles.status, 'badge'])} style={{backgroundColor}}>
            {translatedStatus}
        </div>
        : <div className={cn([styles.status, 'badge'])} style={{color}}>
            {translatedStatus}
        </div>;
};

export default MonitorStatusBadge;