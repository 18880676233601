import {isArray, isEmpty} from 'lodash';

export function buildQuery(path: string, params?: object):string {
    if (!params || isEmpty(params)) {
        return path;
    }
    const query = [] as string[];

    Object.entries(params).forEach(([key, val]) => {
        if (isArray(val)) {
            val.forEach(((subValue:string|number|boolean) => {
                query.push(`${key}=${subValue}`);
            }));
        }else if (null !== val){
            query.push(`${key}=${val}`);
        }
    });

    return `${path}?${query.join('&')}`;
}