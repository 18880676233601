import React from 'react';

import {RegisterOptions} from 'react-hook-form';

import InputText, {IInputTextProps} from './InputText';


const InputUrl: React.FC<IInputTextProps> = (props) => {

    const PATTERN = /^https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-/]))?/;

    const rules: RegisterOptions = {
        pattern: {value: PATTERN, message: 'Некорректный URL'},
        required: 'Укажите URL',
    };

    return (
        <InputText
            rules={rules}
            {...props}
        />
    );
};

export default InputUrl;