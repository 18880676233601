/* eslint-disable max-len */
import React from 'react';

const Home: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68816)">
                <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H5C5.82843 0.5 6.5 1.17157 6.5 2V5C6.5 5.82843 5.82843 6.5 5 6.5H2C1.17157 6.5 0.5 5.82843 0.5 5V2Z" stroke="currentColor"/>
                <path d="M9.5 2C9.5 1.17157 10.1716 0.5 11 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V5C15.5 5.82843 14.8284 6.5 14 6.5H11C10.1716 6.5 9.5 5.82843 9.5 5V2Z" stroke="currentColor"/>
                <path d="M0.5 11C0.5 10.1716 1.17157 9.5 2 9.5H5C5.82843 9.5 6.5 10.1716 6.5 11V14C6.5 14.8284 5.82843 15.5 5 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V11Z" stroke="currentColor"/>
                <path d="M9.5 11C9.5 10.1716 10.1716 9.5 11 9.5H14C14.8284 9.5 15.5 10.1716 15.5 11V14C15.5 14.8284 14.8284 15.5 14 15.5H11C10.1716 15.5 9.5 14.8284 9.5 14V11Z" stroke="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68816">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Home;