import React from 'react';

import ITimeRange from '../../../models/ITimeRange';

import TimeRangeControlMidnight from './TimeRangeControlMidnight';

import TimeRangeControlLastDay from './TimeRangeControlLastDay';

import styles from './TimeRanges.module.scss';


interface ITimeRangesProps {
    ranges: ITimeRange[],
    backgroundColor: string,
    tzString?: string | null,
    isBeginAtMidnight: boolean,
    height?: number,
    hideTime?: boolean,
}

const TimeRanges: React.FC<ITimeRangesProps> = ({
    ranges,
    backgroundColor,
    tzString,
    isBeginAtMidnight,
    height = 10,
    hideTime= false,
}) => {
    return (
        <div className={styles.wrapper}>
            <div
                className={styles.day_range}
                style={{backgroundColor, height: height+'px'}}
            >
                {ranges.map((range) => (
                    isBeginAtMidnight
                        ? <TimeRangeControlMidnight
                            key={range.timeFrom}
                            range={range}
                            tzString={tzString}
                            height={height}
                            hideTime={hideTime}
                        />
                        : <TimeRangeControlLastDay
                            key={range.timeFrom}
                            range={range}
                            tzString={tzString}
                            height={height}
                            hideTime={hideTime}
                        />
                ))}
            </div>
        </div>
    );
};

export default TimeRanges;