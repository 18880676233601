import React, {Suspense} from 'react';
import {ToastContainer} from 'react-toastify';
import {HelmetProvider} from 'react-helmet-async';

import SpinnerWithOverlay from './components/UI/SpinnerWithOverlay';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

import './styles/vendor/inspinia/style.scss';
import './styles/vendor/pretty-checkbox/inspinia-styles.css';
import './styles/globals.scss';
import './styles/rsuite.css';

import Router from './Router';

export default function App() {
    return (
        <HelmetProvider>
            <Suspense fallback={<SpinnerWithOverlay/>}>
                <Router />
                <ToastContainer/>
            </Suspense>
        </HelmetProvider>
    );
}
