import React, {useEffect} from 'react';

import {Link, useNavigate} from 'react-router-dom';

import cn from 'classnames';

import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch, useSelector} from 'react-redux';

import EmailInput from '../UI/Input/EmailInput';
import PrimaryButton from '../UI/Button/PrimaryButton';
import {AppUrls} from '../../constants/urls';

import Checkbox from '../UI/Checkbox/Checkbox';
import PasswordInput from '../UI/Input/PasswordInput';
import IRegistrationForm from '../../models/forms/IRegistrationForm';

import {TypedDispatch} from '../../types';
import {fetchMe, registerUser, registerUserLoadingSelector, registerUserSuccessSelector} from '../../redux/authSlice';
import {redirectToDashboard} from '../../utils/redirects';

import {getClientDefaultTimeZone} from '../../utils/datetime';

import FormGroupWithError from '../UI/FormGroup/FormGroupWithError';

import InputName from '../UI/Input/InputName';

import AuthWrapper from './AuthWrapper';

import {generateFirstName, generateLastName} from '../../utils/faker';

import styles from './Auth.module.scss';

const Registration: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState,
        control,
    } = useForm<IRegistrationForm>({mode: 'onChange'});

    const {errors, isValid} = formState;

    const registerUserSuccess = useSelector(registerUserSuccessSelector);
    const isLoading = useSelector(registerUserLoadingSelector);

    const onSubmit: SubmitHandler<IRegistrationForm> = (formData) => {
        dispatch(registerUser({...formData, timezone: getClientDefaultTimeZone()}));
    };

    useEffect(() => {
        if (registerUserSuccess) {
            dispatch(fetchMe())
                .then(() => {
                    redirectToDashboard(navigate);
                });
        }
    }, [registerUserSuccess]);

    return (
        <AuthWrapper>
            <div className={styles.title_container}>
                <h2 className={styles.title}>Создайте свой аккаунт</h2>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="register-form"
            >
                <FormGroupWithError error={errors?.first_name?.message}>
                    <InputName
                        defaultValue={generateFirstName()}
                        name="first_name"
                        placeholder="Имя"
                        register={register}
                        control={control}
                        required
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <FormGroupWithError error={errors?.last_name?.message}>
                    <InputName
                        defaultValue={generateLastName()}
                        name="last_name"
                        placeholder="Фамилия"
                        register={register}
                        control={control}
                        required
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <FormGroupWithError error={errors?.email?.message}>
                    <EmailInput
                        register={register}
                        control={control}
                        required
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <FormGroupWithError error={errors?.password?.message}>
                    <PasswordInput
                        register={register}
                        control={control}
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.license_agreement?.message}
                >
                    <Checkbox
                        id="license_agreement"
                        {...register(
                            'license_agreement', {
                                required: {
                                    value: true,
                                    message: 'Необходимо принять условия лицензионного договора',
                                },
                            },
                        )}
                    >
                        <span className={styles.agree}>Я принимаю условия &nbsp;
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={AppUrls.LICENSE_AGREEMENT}
                                className={styles.agree_link}
                            >лицензионного договора
                            </Link>
                        </span>
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.privacy_policy?.message}
                >
                    <Checkbox
                        id="privacy_policy"
                        {...register(
                            'privacy_policy', {
                                required: {
                                    value: true,
                                    message: 'Необходимо принять условия политики конфиденциальности',
                                },
                            },
                        )}
                    >
                        <span className={styles.agree}>
                                    Я даю своё согласие на обработку моей&nbsp;
                            персональной информации на условиях, определенных &nbsp;
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={AppUrls.PRIVACY_POLICY}
                                className={styles.agree_link}
                            >политикой конфиденциальности</Link>
                        </span>
                    </Checkbox>
                </FormGroupWithError>

                <div className="form-group">
                    <PrimaryButton
                        type="submit"
                        loading={isLoading}
                        className="full-width"
                        disabled={!isValid}
                    >
                        Создать аккаунт
                    </PrimaryButton>
                </div>
            </form>

            <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                Уже есть аккаунт? &nbsp;
                <Link to={AppUrls.LOGIN} className={styles.register_link}>Войти</Link>
            </p>
        </AuthWrapper>
    );
};

export default Registration;