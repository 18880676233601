import React from 'react';
import {useSelector} from 'react-redux';

import {monitorSelectedIdsSelector} from '../../../redux/monitorSlice';

interface IMonitorBatchOperationSummaryProps {
    action?: Record<string, any>,
}

const MonitorBatchOperationSummary: React.FC<IMonitorBatchOperationSummaryProps> = ({
    action}) => {

    const selectedIds = useSelector(monitorSelectedIdsSelector);
    return (
        <>
            <p className="text-medium">Выбрано мониторов: {selectedIds.length}</p>
            <p className="text-medium">Операция: {action?.label}</p>
        </>
    );
};

export default MonitorBatchOperationSummary;