import {ControllerFieldState} from 'react-hook-form';

export type InputStateType = 'valid' | 'error' | 'active' | 'default';

export const getInputState = (
    fieldState: ControllerFieldState,
    isFocused:boolean,
    hasDefault = false
):InputStateType => {
    let state:InputStateType;
    switch (true){
        case !!fieldState.error || (!fieldState.isDirty && fieldState.isTouched && !hasDefault):
            state = 'error';
            break;
        case !fieldState.error && (fieldState.isDirty || hasDefault):
            state = 'valid';
            break;
        case isFocused:
            state = 'active';
            break;
        default:
            state = 'default';
            break;
    }

    return state;
};