import {useEffect, useRef} from 'react';

// Хук для исключения первого рендера, например, в useEffect с [deps]
export const useIsMount = (): boolean => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};