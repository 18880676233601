import React from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';

import {useSelector} from 'react-redux';

import PasswordInput from '../../UI/Input/PasswordInput';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import INewPasswordForm from '../../../models/forms/INewPasswordForm';
import {isPasswordSettingSelector} from '../../../redux/authSlice';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import styles from '../Auth.module.scss';

interface INewPasswordFormProps {
    onSubmit: SubmitHandler<INewPasswordForm>
}

const NewPasswordForm: React.FC<INewPasswordFormProps> = ({onSubmit}) => {
    const isLoading = useSelector(isPasswordSettingSelector);
    const {register, handleSubmit, formState, control} = useForm<INewPasswordForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    return (
        <>
            <div>
                <h2 className={styles.title}>Новый пароль</h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroupWithError error={errors?.password?.message}>
                    <PasswordInput
                        control={control}
                        register={register}
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <FormGroupWithError error={errors?.password_confirmation?.message}>
                    <PasswordInput
                        register={register}
                        control={control}
                        name="password_confirmation"
                        placeholder="Подтверждение пароля"
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <div className="form-group">
                    <PrimaryButton
                        type="submit"
                        loading={isLoading}
                        className="full-width"
                        disabled={!isDirty || !isValid}
                    >Изменить пароль</PrimaryButton>
                </div>
            </form>
        </>
    );
};

export default NewPasswordForm;