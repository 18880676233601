import React, {ReactElement, ReactNode} from 'react';
import Modal from 'rsuite/Modal';

import {ModalSize} from 'rsuite/esm/Modal/Modal';

import './MainModal.module.scss';

interface IMainModalProps {
    header: string|ReactElement,
    children: ReactNode,
    open: boolean,
    handleClose: () => void,
    size?: ModalSize
}

const MainModal: React.FC<IMainModalProps> = ({
    header,
    children,
    open,
    handleClose,
    size= 'md',
}) => {

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            open={open}
            onClose={handleClose}
            size={size}
        >
            <Modal.Header>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default MainModal;