import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import {tariffAPI} from '../api/tariffAPI';
import Tariffs from '../components/Tariffs/Tariffs';

const TariffsPage: React.FC = () => {
    const {data, isLoading} = tariffAPI.useGetTariffsQuery({field: 'price', direction: 'asc'});
    return (
        <MainLayout>
            <Tariffs items={data ?? []} isLoading={isLoading}/>
        </MainLayout>
    );
};

export default TariffsPage;