import React from 'react';

import cn from 'classnames';

import ITariff from '../../models/ITariff';
import NoCheck from '../Icons/NoCheck';
import Check from '../Icons/Check';

import styles from './Tariff.module.scss';

interface ITariffLimitBooleanProps {
    children?: React.ReactNode,
    tariff: ITariff,
    limit: keyof ITariff,
    hidden?: boolean,
}

const TariffLimitBoolean: React.FC<ITariffLimitBooleanProps> = ({
    children,
    tariff,
    limit,
    hidden = false,
}) => {
    const condition = !!tariff[limit];
    return <div className={cn(
        styles.monitor_limit,
        {[styles.hidden_item]: hidden}
    )}>
        <div className={
            condition
                ? styles.number_yes
                : styles.number_no
        }>
            {
                condition
                    ? (tariff.is_archive ? <NoCheck /> : <Check />)
                    : 'Нет'
            }
        </div>
        <p className={cn(
            styles.text,
            {[styles.text_no]: !condition}
        )}>
            {children}
        </p>
    </div>;
};

export default TariffLimitBoolean;