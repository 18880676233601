/* eslint-disable max-len */
import React from 'react';

const Premium: React.FC<object> = () => {

    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="check-circle">
            <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M11.9999 21.6004C14.546 21.6004 16.9878 20.589 18.7881 18.7886C20.5885 16.9883 21.5999 14.5465 21.5999 12.0004C21.5999 9.45431 20.5885 7.01252 18.7881 5.21217C16.9878 3.41182 14.546 2.40039 11.9999 2.40039C9.45382 2.40039 7.01203 3.41182 5.21168 5.21217C3.41133 7.01252 2.3999 9.45431 2.3999 12.0004C2.3999 14.5465 3.41133 16.9883 5.21168 18.7886C7.01203 20.589 9.45382 21.6004 11.9999 21.6004Z" fill="#FFD860" fillOpacity="0.6"/>
            <path id="&#226;&#130;&#189;" d="M10.2278 17C9.67812 17 9.25859 16.5652 9.25859 15.9805V15.1652H8.81013C8.36166 15.1652 8.01447 14.8204 8.01447 14.3556C8.01447 13.8909 8.36166 13.546 8.81013 13.546H9.25859V12.7871H8.81013C8.3472 12.7871 8 12.4273 8 11.9325C8 11.4378 8.3472 11.078 8.81013 11.078H9.25859V8.01949C9.25859 7.43478 9.67812 7 10.2423 7H12.9186C14.7703 7 16 8.15442 16 9.89355C16 11.6327 14.7703 12.7871 12.9186 12.7871H11.2116V13.546H13.1023C13.5652 13.546 13.898 13.8909 13.898 14.3556C13.898 14.8204 13.5652 15.1652 13.1023 15.1652H11.2116V15.9805C11.2116 16.5652 10.792 17 10.2278 17ZM11.2116 11.078H12.7884C13.5552 11.078 14.047 10.6282 14.047 9.90855C14.047 9.18891 13.5552 8.70915 12.7884 8.70915H11.2116V11.078Z" fill="#424242"/>
        </g>
    </svg>;
};

export default Premium;