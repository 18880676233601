import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useSearchParams} from 'react-router-dom';

import {DateRange} from 'rsuite/DateRangePicker';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';
import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';
import InputText from '../../UI/Input/InputText';
import InputNumber from '../../UI/Input/InputNumber';
import FormGroup from '../../UI/FormGroup/FormGroup';
import {booleanToString, getMonitorsFilters, getOrderBy, stringsToDateRange} from '../../../utils/filterHelper';

import Select from '../../UI/Input/Select';

import {translateMonitorStatus} from '../../../utils/translate';
import {MonitorStatus} from '../../../models/Monitor/IMonitor';

import DateRangePickerControlled from '../../UI/Date/DateRangePickerControlled/DateRangePickerControlled';

import OrderByControlled from '../../UI/OrderBy/OrderByControlled';

import IOrderBy from '../../../models/IOrderBy';

import styles from '../MonitorForm/MonitorForm.module.scss';


interface IMonitorFiltersFormProps {
    onSubmit: SubmitHandler<IMonitorFiltersForm>;
    button: React.ReactElement
}

export interface IMonitorFiltersForm {
    name?: string,
    url?: string,
    status?: MonitorStatus,
    ok?: string,
    sslRedirect?: string,
    sslActiveTo?: DateRange,
    domainActiveTo?: DateRange,
    days?: number,
    orderBy?: IOrderBy,
}

const MonitorFiltersForm: React.FC<IMonitorFiltersFormProps> = ({onSubmit, button}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const filters = getMonitorsFilters(searchParams);
    const sort = getOrderBy(searchParams, 'id', 'desc');

    const {
        register,
        handleSubmit,
        control,
        setValue,
    } = useForm<IMonitorFiltersForm>({
        mode: 'onSubmit',
        defaultValues: {
            status: filters.status,
            ok: booleanToString(filters.ok),
            sslRedirect: booleanToString(filters.sslRedirect),
            days: filters.days,
        },
    });

    const statusOptions = ['active', 'paused', 'unsubscribed']
        .map(status => ({
            label: translateMonitorStatus(status as MonitorStatus),
            value: status,
        }));

    const okOptions = [
        {
            label: 'Исправен',
            value: 'true',
        },
        {
            label: 'Есть проблемы',
            value: 'false',
        },
    ];

    const sslRedirectOptions = [
        {
            label: 'Есть',
            value: 'true',
        },
        {
            label: 'Нет',
            value: 'false',
        },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <FormGroupWithError
                    label="URL или его часть"
                >
                    <InputText
                        register={register}
                        defaultValue={filters?.url}
                        name="url"
                        placeholder=""
                        control={control}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Название или его часть"
                >
                    <InputText
                        defaultValue={filters?.name}
                        name="name"
                        placeholder=""
                        register={register}
                        control={control}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Состояние"
                >
                    <Select
                        control={control}
                        name="status"
                        data={statusOptions}
                        defaultValue={filters.status}
                        cleanable={true}
                        searchable={false}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Исправность"
                >
                    <Select
                        control={control}
                        name="ok"
                        data={okOptions}
                        defaultValue={
                            filters.ok === undefined
                                ? undefined
                                : String(filters.ok)
                        }
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                            if (value === null) {
                                const newParams = new URLSearchParams(searchParams);
                                newParams.delete('ok');
                                setSearchParams(newParams);
                            }
                        }}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Редирект на HTTPS"
                >
                    <Select
                        control={control}
                        name="sslRedirect"
                        data={sslRedirectOptions}
                        defaultValue={
                            filters.sslRedirect === undefined
                                ? undefined
                                : String(filters.sslRedirect)
                        }
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                            if (value === null) {
                                const newParams = new URLSearchParams(searchParams);
                                newParams.delete('sslRedirect');
                                setSearchParams(newParams);
                            }
                        }}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Диапазон дат, в который попадает окончание срока годности SSL сертификата"
                >
                    <DateRangePickerControlled
                        control={control}
                        name="sslActiveTo"
                        defaultValue={stringsToDateRange(
                            filters['sslActiveTo[after]'],
                            filters['sslActiveTo[before]'],
                        )}
                        cleanable={true}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Диапазон дат, в который попадает окончание срока регистрации домена"
                >
                    <DateRangePickerControlled
                        control={control}
                        name="domainActiveTo"
                        defaultValue={stringsToDateRange(
                            filters['domainActiveTo[after]'],
                            filters['domainActiveTo[before]'],
                        )}
                        cleanable={true}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Количество дней для расчета доступности"
                >
                    <InputNumber
                        defaultValue={filters.days}
                        min={1}
                        max={31}
                        name="days"
                        placeholder=""
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    label="Сортировка"
                >
                    <OrderByControlled
                        control={control}
                        name="orderBy"
                        defaultValue={sort}
                        data={[
                            {label: 'Название', value: 'name'},
                            {label: 'URL', value: 'url'},
                            {label: 'Интервал, мин', value: 'checkInterval'},
                            {label: 'Состояние', value: 'status'},
                            {label: 'Последний результат', value: 'lastCode'},
                            {label: 'SSL годен до', value: 'sslActiveTo'},
                            {label: 'Домен зарегистрирован до', value: 'domainActiveTo'},
                            {label: 'Редирект на https', value: 'sslRedirect'},
                        ]}
                    />
                </FormGroupWithError>

            </ModalBodyWrapper>
            <FormGroup>
                {button}
            </FormGroup>
        </form>
    );
};

export default MonitorFiltersForm;