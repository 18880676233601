/* eslint-disable max-len */
import React from 'react';

const Remind: React.FC<object> = () => {

    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7 4C7 3.44772 7.44772 3 8 3C8.55229 3 9 3.44772 9 4V8C9 8.55228 8.55229 9 8 9C7.44772 9 7 8.55228 7 8V4ZM8 13C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z" fill="#F44336"/>
    </svg>;

};

export default Remind;