import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import PrimaryButton from '../Button/PrimaryButton';
import FormGroup from '../FormGroup/FormGroup';
import InputText from '../Input/InputText';

import styles from './Search.module.scss';

interface ISearchProps {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (search: string) => void,
    placeholder: string,
}

interface ISearchForm {
    search: string
}

const Search: React.FC<ISearchProps> = ({onSubmit: searchHandler, placeholder}) => {
    const {register, control,  handleSubmit} = useForm<ISearchForm>({
        mode: 'onChange',
    });


    const onSubmit: SubmitHandler<ISearchForm> = async({search}) => {
        searchHandler(search);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.wrapper}
        >
            <FormGroup>
                <InputText
                    type="text"
                    placeholder={placeholder}
                    placeholderToLabel
                    name="search"
                    register={register}
                    control={control}
                    className={styles.search}
                />
            </FormGroup>

            <PrimaryButton type={'submit'}>Найти</PrimaryButton>
        </form>
    );
};

export default Search;