import React, {MouseEvent, useCallback, useState} from 'react';

import {useSelector} from 'react-redux';

import cn from 'classnames';

import TariffModal from './TariffModal';
import ITariff from '../../models/ITariff';
import IUserOutput from '../../models/IUser';

import {selectSelectedVariant} from '../../redux/selectedVariantSlice';

import TariffLimitBoolean from './TariffLimitBoolean';

import TariffLabel from './TariffLabel';
import TariffHeader from './TariffHeader';

import TariffPrice from './TariffPrice';

import TariffLimitNumber from './TariffLimitNumber';

import styles from './Tariff.module.scss';

interface ISingleTariffProps {
    item: ITariff,
    user: IUserOutput | null,
}

const Tariff: React.FC<ISingleTariffProps> = ({item, user}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const selectedVariant = useSelector(selectSelectedVariant);
    const currentVariant = item.variants.find(variant =>
        variant.period === selectedVariant?.period) || item.variants[0];

    const handleClose = () => setIsModalOpen(false);

    const onPayClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const isActive = item.id === user?.tariff.id;
    const isEternal = item.period === 0 && (currentVariant?.period ?? 0) === 0;

    if (item.is_archive && !isActive){
        return <></>;
    }

    const getClassName = () => {
        if (item.is_archive) {
            return styles.container_archive;
        }

        if (isActive){
            return styles.container_active;
        }

        if (item.is_recommended){
            return styles.container_recommend;
        }

        return styles.container_default;
    };

    const tariffLimits = [
        { limit: 'monitor_ssl_enabled', text: 'Мониторинг SSL', hidden: false },
        { limit: 'monitor_domain_watch_enabled', text: 'Мониторинг домена', hidden: false },
        { limit: 'monitor_ssl_redirect_watch_enabled', text: 'Проверка редиректа HTTPS', hidden: !isExpanded },
        { limit: 'monitor_robots_txt_watch_enabled', text: 'Проверка robots.txt', hidden: !isExpanded },
        { limit: 'monitor_extended_methods', text: 'Расширенный мониторинг', hidden: !isExpanded },
        { limit: 'monitor_frequent_checking', text: 'Учащенный мониторинг', hidden: !isExpanded },
        { limit: 'monitor_http_basic_auth_enabled', text: 'HTTP Basic Auth', hidden: !isExpanded },
    ] as const;
    const tariffLimitsNumber = [
        {
            value: `${item.monitor_min_check_interval_limit} мин`,
            text: 'Интервал мониторинга',
            className: styles.instances_limit,
            classNameValue: item.monitor_min_check_interval_limit > 0 ? styles.number : styles.number_no,
            classNameText: item.monitor_min_check_interval_limit > 0 ? styles.text : styles.text_no,
        },
        {
            value: item.monitor_max_count_limit,
            text: 'Мониторов',
            className: styles.monitor_limit_max,
            classNameValue: item.monitor_max_count_limit > 0 ? styles.number : styles.number_no,
            classNameText: item.monitor_max_count_limit > 0 ? styles.text : styles.text_no,
        },
        {
            value: item.telegram_chats_max_count_limit,
            text: 'Telegram чатов',
            className: cn(styles.monitor_limit_max, styles.mobile_hidden, { [styles.show_mobile]: isExpanded }),
            classNameValue: item.telegram_chats_max_count_limit > 0 ? styles.number : styles.number_no,
            classNameText: item.telegram_chats_max_count_limit > 0 ? styles.text : styles.text_no,
        },
        {
            value: item.additional_emails_max_count_limit > 0 ? item.additional_emails_max_count_limit : 'Нет',
            text: 'Дополнительных E-mail',
            className: cn(styles.monitor_limit_max, { [styles.hidden_item]: !isExpanded }),
            classNameValue: item.additional_emails_max_count_limit > 0 ? styles.number : styles.number_no,
            classNameText: item.additional_emails_max_count_limit > 0 ? styles.text : styles.text_no,
        },
    ];


    return (
        <>
            <div className={getClassName()}>
                <TariffLabel
                    isActive={isActive}
                    tariff={item}
                />

                <TariffHeader
                    name={item.name}
                    period={isEternal ? 'Бессрочно' : `${currentVariant?.period} дней`}
                />

                {tariffLimitsNumber.map((limit, index) => (
                    <TariffLimitNumber
                        key={index}
                        value={limit.value}
                        text={limit.text}
                        className={limit.className}
                        classNameValue={limit.classNameValue}
                        classNameText={limit.classNameText}
                    />
                ))}

                {tariffLimits.map(({ limit, text, hidden }) => (
                    <TariffLimitBoolean
                        key={limit}
                        tariff={item}
                        limit={limit}
                        hidden={hidden}
                    >
                        {text}
                    </TariffLimitBoolean>
                ))}

                <TariffPrice
                    price={currentVariant.price}
                    priceWithoutDiscount={currentVariant.price_without_discount}
                    saving={currentVariant.saving}
                    isActive={isActive}
                    isArchive={item.is_archive}
                    onPayClick={onPayClick}
                    onExpandClick={() => setIsExpanded(!isExpanded)}
                    isExpanded={isExpanded}
                />
            </div>

            <TariffModal
                open={isModalOpen}
                handleClose={handleClose}
                item={{
                    ...item,
                    period: currentVariant.period,
                    price: currentVariant.price,
                }}
                variant={currentVariant}
            />
        </>
    );
};

export default Tariff;