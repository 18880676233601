// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Progress_wrapper__yywcO {
  position: relative;
  height: 6px;
  overflow: hidden;
}

.Progress_full_bar__s43mG {
  background: #e8220c;
  background: linear-gradient(90deg, #e8220c 0%, #dee326 50%, #22b0b9 100%);
  height: 100%;
}

.Progress_overlay__S8tpZ {
  height: 6px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
}

.Progress_empty__2fr9f {
  height: 12px;
  border-radius: 6px;
  background: #b5b2b2;
  background: linear-gradient(180deg, #b5b2b2 65%, #323230 120%);
  position: absolute;
  right: 0;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Progress/Progress.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AACA;EACI,mBAAA;EACA,yEAAA;EACA,YAAA;AAEJ;;AACA;EACI,WAAA;EACA,WAAA;EACA,kCAAA;EACA,8FAAA;EACA,kBAAA;EACA,OAAA;EACA,SAAA;AAEJ;;AACA;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AAEJ","sourcesContent":[".wrapper{\n    position: relative;\n    height: 6px;\n    overflow: hidden;\n}\n.full_bar{\n    background: rgb(232,34,12);\n    background: linear-gradient(90deg, rgba(232,34,12,1) 0%, rgba(222,227,38,1) 50%, rgba(34,176,185,1) 100%);\n    height: 100%;\n}\n\n.overlay{\n    height: 6px;\n    width: 100%;\n    background: rgba(256,256,256,0);\n    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0) 100%);\n    position: absolute;\n    left: 0;\n    bottom: 0;\n}\n\n.empty{\n    height: 12px;\n    border-radius: 6px;\n    background: rgb(181,178,178);\n    background: linear-gradient(180deg, rgba(181,178,178,1) 65%, rgba(50,50,48,1) 120%);\n    position: absolute;\n    right: 0;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Progress_wrapper__yywcO`,
	"full_bar": `Progress_full_bar__s43mG`,
	"overlay": `Progress_overlay__S8tpZ`,
	"empty": `Progress_empty__2fr9f`
};
export default ___CSS_LOADER_EXPORT___;
