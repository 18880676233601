import React from 'react';

import {AppUrls} from '../../../constants/urls';

const UpgradeTariffButton: React.FC = () => {

    return (
        <a
            href={AppUrls.TARIFFS}
            className="btn full-width rs-btn rs-btn-primary"
            target="_blank" rel="noreferrer"
        >Улучшить тарифный план</a>
    );
};

export default UpgradeTariffButton;