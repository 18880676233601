import {ApiUrls} from 'constants/urls';

import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'store';

import createHttpRequest from 'utils/http';

interface IInitialState {
    ids: string[],
    isLoading: boolean,
    showBatchOperations: boolean,
    totalItems: number,
}

const initialState: IInitialState = {
    ids: [],
    isLoading: false,
    showBatchOperations: false,
    totalItems: 0,
};

export const selectAll = createAsyncThunk<string[], void>(
    'monitor/selectAll',
    async() => {
        const response = await createHttpRequest({
            method: 'GET',
            path: ApiUrls.MONITORS_IDS,
        });

        return response.data;
    },
);

const monitor = createSlice({
    name: 'monitor',
    initialState,
    reducers: {
        toggleOne: (state, {payload}) => {
            const index = state.ids.indexOf(payload);
            if (-1 === index) {
                state.ids.push(payload);
            }else{
                state.ids.splice(index, 1);
            }
        },
        setTotal: (state, {payload}) => {
            state.totalItems = payload;
        },
        selectAllOnPage: (state, {payload}) => {
            state.ids = payload;
            state.totalItems = payload.length;
        },
        clearAll: (state, {payload}) => {
            state.ids = [];
            state.totalItems = payload;
        },
        setShowBatchOperations: (state, {payload}) => {
            state.showBatchOperations = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(selectAll.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(selectAll.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.ids = payload;
            state.totalItems = payload.length;
        });
        builder.addCase(selectAll.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default monitor.reducer;
export const {toggleOne, setTotal, selectAllOnPage, clearAll, setShowBatchOperations} = monitor.actions;

//Селекторы
const slice = ({monitorReducer}: RootState) => monitorReducer;

export const monitorSelectedIdsSelector = createSelector(
    slice,
    ({ids}) => ids,
);

export const isLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading,
);

export const showBatchOperationsSelector = createSelector(
    slice,
    ({showBatchOperations}) => showBatchOperations,
);

export const isSelectedAllSelector = createSelector(
    slice,
    ({ids, totalItems}) => ids.length >= totalItems,
);

export const isSelectedNoneSelector = createSelector(
    monitorSelectedIdsSelector,
    (ids) => ids.length === 0,
);
