import React, {useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import MonitorModal from './MonitorModal';
import TransparentButton from '../UI/Button/TransparentButton';
import MonitorLimitExceededModal from './MonitorLimitExceededModal';
import useCanCreateMonitor from '../../hooks/useCanCreateMonitor';


const CreateMonitorButton: React.FC = () => {
    const canCreateMonitor = useCanCreateMonitor();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Добавить URL <PlusCircleIcon width="20px" height="20px"/>
            </TransparentButton>
            {
                canCreateMonitor
                    ? <MonitorModal open={isModalOpen} handleClose={handleClose}/>
                    : <MonitorLimitExceededModal open={isModalOpen} handleClose={handleClose}/>
            }
        </>
    );
};

export default CreateMonitorButton;