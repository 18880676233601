// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tariffs_head__Unsnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 12px;
}

@media (max-width: 1750px) {
  .Tariffs_tariffs_admin__9L5IY {
    white-space: normal;
  }
}

@media (max-width: 1750px) {
  .Tariffs_table_box__P2kIc {
    width: calc(100vw - 275px);
    white-space: nowrap;
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .Tariffs_table_box__P2kIc {
    width: 100vw;
  }
}

@media (max-width: 1750px) {
  .Tariffs_table_box__P2kIc td {
    white-space: nowrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Tariffs/Tariffs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAGE;EADF;IAEI,mBAAA;EACF;AACF;;AAGE;EADF;IAEI,0BAAA;IACA,mBAAA;IACA,cAAA;EACF;AACF;AACE;EAPF;IAQI,YAAA;EAEF;AACF;;AAGI;EADF;IAEI,mBAAA;EACJ;AACF","sourcesContent":[".head {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 30px;\n  margin-bottom: 12px;\n}\n\n.tariffs_admin {\n  @media(max-width: 1750px) {\n    white-space: normal;\n  }\n}\n\n.table_box {\n  @media(max-width: 1750px) {\n    width: calc(100vw - 275px);\n    white-space: nowrap;\n    overflow: auto;\n  }\n\n  @media(max-width: 768px) {\n    width: 100vw;\n  }\n}\n\n.table_box {\n  :global(td) {\n    @media(max-width: 1750px) {\n      white-space: nowrap;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `Tariffs_head__Unsnn`,
	"tariffs_admin": `Tariffs_tariffs_admin__9L5IY`,
	"table_box": `Tariffs_table_box__P2kIc`
};
export default ___CSS_LOADER_EXPORT___;
