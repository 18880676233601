// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alert_alert__NPrjL {
  width: 100%;
  padding: 4px 12px;
  font-size: 13px;
  color: #f44336;
  font-family: "Inter", serif;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Alert/Alert.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,2BAAA;AACJ","sourcesContent":[".alert {\n    width: 100%;\n    padding: 4px 12px;\n    font-size: 13px;\n    color: #f44336;\n    font-family: 'Inter', serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `Alert_alert__NPrjL`
};
export default ___CSS_LOADER_EXPORT___;
