import React, {JSX} from 'react';

import {Placeholder} from 'rsuite';

import {DEFAULT_PAGE_SIZE} from '../constants/common';

// eslint-disable-next-line no-unused-vars
export type RowFactory<T extends object> = (item: T) => JSX.Element;

export function tableRowsWithLoader<T extends object>(
    items: T[],
    isLoading: boolean,
    colNum: number,
    rowFactory: RowFactory<T>,
): JSX.Element[] | JSX.Element {
    if (items?.length === 0 && !isLoading) {
        const noDataMsg = 'Данные отсутствуют';
        return (
            <tr>
                <td colSpan={colNum} className={'text-center'}>
                    {noDataMsg}
                </td>
            </tr>
        );
    }

    if (items?.length === 0) {
        //Данные еще не загружены
        const placeHolderCells = new Array(colNum).fill(null);
        const PlaceholderRow = () => <tr>
            {
                placeHolderCells.map((item, idx) => <td key={`td_${idx}`}>
                    <Placeholder.Graph style={{height: 20}} active/>
                </td>)
            }
        </tr>;
        const placeholderRows = new Array(DEFAULT_PAGE_SIZE).fill(null);
        return placeholderRows.map((item, idx) => <PlaceholderRow key={`tr_${idx}`}/>);
    }

    return items?.map(item => rowFactory(item));
}