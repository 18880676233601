import React, {useEffect} from 'react';

import classNames from 'classnames';

import {useDispatch, useSelector} from 'react-redux';


import {ITariffVariant} from '../../../models/ITariffVariant';

import {selectSelectedVariant, setSelectedVariant} from '../../../redux/selectedVariantSlice';

import styles from './TariffVariants.module.scss';

interface TariffVariantsProps {
    variants: ITariffVariant[];
}

const TariffVariants:React.FC<TariffVariantsProps> = ({variants}) => {
    const dispatch = useDispatch();
    const selectedVariant = useSelector(selectSelectedVariant);

    useEffect(() => {
        if (variants.length > 0 && !selectedVariant) {
            dispatch(setSelectedVariant(variants[0]));
        }
    }, [dispatch, variants, selectedVariant]);

    if (!variants || variants.length === 0) {
        return <div>Варианты не найдены</div>;
    }

    const handleVariantClick = (variant: ITariffVariant) => {
        dispatch(setSelectedVariant(variant));
    };

    const isDefaultVariant = (variant: ITariffVariant) => variant.id === 0;

    const sortedVariants = [...variants].sort((a, b) => a.id - b.id);


    return (
        <div className={styles.box}>
            {sortedVariants.map((variant) =>
                <div className={classNames(styles.tariffVariant, {
                    [styles.active]: selectedVariant?.id === variant.id,
                    [styles.default]: isDefaultVariant(variant),
                })}
                key={variant.id}
                onClick={() => handleVariantClick(variant)}
                >
                    {isDefaultVariant(variant)
                        ? (<span className={styles.discount}>{`${variant.discount}%`}</span>)
                        : (<span className={styles.discount}>{`-${variant.discount}%`}</span>)
                    }
                    {`${variant.period} дней`}
                </div>
            )}
        </div>
    );
};

export default TariffVariants;