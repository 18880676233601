import React from 'react';

import MainModal from '../../UI/Modal/MainModal';
import IncidentsExportForm from './IncidentsExportForm';
import IOrderBy from '../../../models/IOrderBy';


interface IIncidentsExportModalProps {
    open: boolean,
    sort?: IOrderBy,
    handleClose: () => void,
    monitorId?: string,
}

const IncidentsExportModal: React.FC<IIncidentsExportModalProps> = ({open, sort, handleClose, monitorId}) => {
    return (
        <MainModal
            header="Экспорт инцидентов"
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <IncidentsExportForm monitorId={monitorId} handleClose={handleClose} sort={sort} />
        </MainModal>
    );
};

export default IncidentsExportModal;