import React from 'react';
import {SubmitHandler} from 'react-hook-form';

import {useSearchParams} from 'react-router-dom';

import IMonitorsFilters from '../../../models/Monitor/IMonitorsFilters';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import {dateRangeToFilters, setSearchParamsFromFilters, stringToBoolean} from '../../../utils/filterHelper';

import MonitorFiltersForm, {IMonitorFiltersForm} from './MonitorFiltersForm';

interface IMonitorFiltersFormHandlerProps {
    onSuccess: () => void;
}

const MonitorFiltersFormHandler: React.FC<IMonitorFiltersFormHandlerProps> = ({onSuccess}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmit: SubmitHandler<IMonitorFiltersForm> = async({
        sslActiveTo,
        domainActiveTo,
        ok,
        sslRedirect,
        orderBy,
        ...rest
    }) => {
        const data: IMonitorsFilters = {
            order: orderBy?.field,
            dir: orderBy?.direction,
            ...rest,
        };

        if (ok !== null && ok !== undefined) {
            data.ok = stringToBoolean(ok);
        }
        if (sslRedirect !== null && sslRedirect !== undefined) {
            data.sslRedirect = stringToBoolean(sslRedirect);
        }

        dateRangeToFilters(sslActiveTo, data, 'sslActiveTo');
        dateRangeToFilters(domainActiveTo, data, 'domainActiveTo');

        setSearchParamsFromFilters(searchParams, data);
        searchParams.set('page', '1');
        setSearchParams(searchParams);

        onSuccess();
    };

    return (
        <MonitorFiltersForm
            onSubmit={onSubmit}
            button={<PrimaryButton
                type="submit"
                className="full-width"
            >
                Применить фильтры
            </PrimaryButton>}
        />
    );
};

export default MonitorFiltersFormHandler;