import React from 'react';

import {useSelector} from 'react-redux';

import {IMonitorItem} from '../../../models/Monitor/IMonitor';

import MonitorStatusBadge from '../../Status/MonitorStatusBadge';

import MonitorAvgResponseTimeChart from './MonitorAvgResponseTimeChart';

import MonitorLastDayIncidentsRanges from './MonitorLastDayIncidentsRanges';
import useBreakpoint from '../../../hooks/useBreakpoint';

import Box from '../../UI/Box/Box';

import {getFormattedDate} from '../../../utils/datetime';
import {userSelector} from '../../../redux/authSlice';

import OpenLinkNewWindow from '../../UI/IconButton/OpenLinkNewWindow';

import styles from './MonitorDetails.module.scss';

interface IMonitorDetailsProps {
    item: IMonitorItem,
}

const MonitorDetails: React.FC<IMonitorDetailsProps> = ({item}) => {
    const user = useSelector(userSelector);
    const tzString = user?.timezone;
    const currentBreakpoint = useBreakpoint();
    return (
        <Box className={styles.container}>
            <h3 className={styles.title}>{item.name}</h3>
            <div className={styles.wrapper}>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>Статус:</td>
                            <td>
                                <MonitorStatusBadge status={item.status}/>
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td>
                                <OpenLinkNewWindow url={item.url_human_readable}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Доступность за 24 часа:</td>
                            <td>{item.availability1}%</td>
                        </tr>
                        <tr>
                            <td>Доступность за 7 дней:</td>
                            <td>{item.availability7}%</td>
                        </tr>
                        <tr>
                            <td>Доступность за 30 дней:</td>
                            <td>{item.availability30}%</td>
                        </tr>
                        <tr>
                            <td>Монитор создан:</td>
                            <td>{getFormattedDate(
                                item.created_at,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                        <tr>
                            <td>Последняя проверка:</td>
                            <td>{getFormattedDate(
                                item.last_check,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                        <tr>
                            <td>Следующая проверка:</td>
                            <td>{getFormattedDate(
                                item.next_check,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="my-2">
                <h4 className={styles.subtitle}>Время ответа за последние 24 часа:</h4>
                <MonitorAvgResponseTimeChart uuid={item.uuid} height={currentBreakpoint !== 'mini' ? 300 : 200}/>
            </div>
            <div className="my-2">
                <h4 className={styles.subtitle}>Доступность за последние 24 часа:</h4>
                <MonitorLastDayIncidentsRanges uuid={item.uuid} height={30}/>
            </div>
        </Box>
    );
};

export default MonitorDetails;