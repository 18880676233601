import React from 'react';

import BlankLayout from '../../components/Layout/BlankLayout/BlankLayout';
import PasswordResetInit from '../../components/Auth/Password/PasswordResetInit';


const PasswordResetInitPage: React.FC = () => {
    return (
        <BlankLayout pageTitle="Сброс пароля">
            <PasswordResetInit/>
        </BlankLayout>
    );
};

export default PasswordResetInitPage;