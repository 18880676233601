import React from 'react';

import MonitorForm from './MonitorForm/MonitorForm';
import IMonitorOutput from '../../models/Monitor/IMonitor';
import MainModal from '../UI/Modal/MainModal';

interface IMonitorModalProps {
    open: boolean,
    item?: IMonitorOutput,
    handleClose: () => void;
}

const MonitorModal: React.FC<IMonitorModalProps> = ({open, item, handleClose}) => {
    const isNew = !item;

    return <MainModal
        header={`${isNew ? 'Добавить' : 'Изменить'} сайт для мониторинга`}
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <MonitorForm onSuccess={handleClose} item={item}/>
    </MainModal>;
};

export default MonitorModal;