import React, {Dispatch, SetStateAction} from 'react';

import {useSelector} from 'react-redux';

import IIncident from '../../models/IIncident';
import IncidentRow from './IncidentRow';
import {userSelector} from '../../redux/authSlice';
import IPagination from '../../models/IPagination';

import Table, {IColumn, SetOrderByType} from '../UI/Table/Table';

import IOrderBy from '../../models/IOrderBy';

import styles from './Incident/Incident.module.scss';

interface IIncidentsProps {
    pagination?: IPagination<IIncident>,
    isLoading: boolean,
    hidePagination?: boolean,
    hideMonitor?: boolean,
    sort?: IOrderBy,
    setSort?: SetOrderByType,
    setPage?: Dispatch<SetStateAction<number>>,
}

const Incidents: React.FC<IIncidentsProps> = ({
    pagination,
    isLoading,
    hidePagination = false,
    hideMonitor = false,
    sort,
    setSort,
    setPage,
}) => {
    const user = useSelector(userSelector);
    let cols: IColumn[] = [
        {label: 'Статус', sort: 'resolved'},
        {label: 'Монитор', sort: 'monitor.name'},
        {label: 'Причина', sort: 'error'},
        {label: 'Начало', sort: 'id'},
        {label: 'Длительность'},
    ];
    if (hideMonitor) {
        cols = cols.filter(col => col.label !== 'Монитор');
    }

    return (
        <div className={styles.container}>
            <Table
                columns={cols}
                items={pagination?.items}
                className={styles.table_wrap}
                rowFactory={item =>
                    <IncidentRow
                        key={item.id}
                        item={item}
                        tzString={user?.timezone}
                        hideMonitor={hideMonitor}
                    />
                }
                isLoading={isLoading}
                pagination={pagination && !hidePagination ? pagination : undefined}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
            />
        </div>
    );
};

export default Incidents;