import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';

import {useForm} from 'react-hook-form';
import React, {useState} from 'react';

import Select from '../../UI/Input/Select';
import MainModal from '../../UI/Modal/MainModal';
import {paymentAPI} from '../../../api/paymentAPI';

import SpinnerSimple from '../../UI/SpinnerWithOverlay/SpinnerSimple';
import HintQuestion from '../../UI/Hint/HintQuestion';

import createHttpRequest from '../../../utils/http';
import {ApiUrls} from '../../../constants/urls';

import styles from '../../User/Users.module.scss';

interface PlotStatItem {
    month: string,
    s: number,
    noSub: number,
    withSub: number
}

interface YearItem {
    label: string,
    value: string
}

const PaymentUserListStatsWidget: React.FC = () => {
    const {data, isLoading} = paymentAPI.useGetPaymentUserStatsQuery();
    const [modalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    const [isFetchingModal, setIsFetchingModal] = useState(true);
    const [years, setYears] = useState<YearItem[]>([]);
    const [statsData, setStatsData] = useState<PlotStatItem[]>([]);
    const { control, setValue } = useForm<{year: string}>({
        mode: 'onChange',
    });

    const monthNumToLabel: any = {
        '01': 'янв.',
        '02': 'фев.',
        '03': 'мар.',
        '04': 'апр.',
        '05': 'мaй',
        '06': 'июнь',
        '07': 'июль',
        '08': 'авг.',
        '09': 'сен.',
        10: 'окт.',
        11: 'ноя.',
        12: 'дек.',
    };

    const fetchPlot = async(selectedYear: any) => {
        setIsFetchingModal(true);
        try {
            const response = await createHttpRequest({
                method: 'GET',
                path: ApiUrls.PAYMENT_USER_STATS_PLOT_DATA(Number(selectedYear)),
                errorMessage: 'Произошла ошибка',
                silent: true,
                isAnonymous: false,
            });

            const year: string = (response.data.year ?? '') as string;
            const years: string[] = (response.data.years ?? []) as string[];
            const plot: PlotStatItem[] = ((response.data.plot ?? []) as PlotStatItem[]).map((item) => {
                item.month = monthNumToLabel[item.month];
                return item;
            });
            plot.unshift({month: '', s: 0, withSub: 0, noSub: 0});

            setValue('year', year, {shouldDirty: true});

            setYears(years.map(method => ({
                label: method,
                value: method,
            })));
            setStatsData(plot);

        } catch (e) {

        } finally {
            setIsFetchingModal(false);
        }
    };

    const handleOpenModal = async() => {
        setIsModalOpen(true);
        await fetchPlot(null);
    };

    if(isLoading) {
        return (
            <>
                <div className={styles.stats}>
                    <SpinnerSimple />
                </div>
            </>
        );
    }

    let modalHtml;

    if(isFetchingModal) {
        modalHtml = (
            <>
                <SpinnerSimple />
            </>
        );
    } else {
        modalHtml = (
            <>
                <LineChart
                    width={900}
                    height={400}
                    data={statsData}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line name={'Всё'} type="monotone" dataKey="s" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name={'Разовые'} type="monotone" dataKey="noSub" stroke="#82ca9d" />
                    <Line name={'Рекуррентные'} type="monotone" dataKey="withSub" stroke="#f90199" />
                </LineChart>
            </>
        );
    }

    return (
        <>
            <div className={styles.stats}>
                <a onClick={handleOpenModal}>{data?.prev_month.all_paid_sum} руб. <HintQuestion placement="bottom">
                    <>
                        <p>Сумма оплат за предыдущий КАЛЕНДАРНЫЙ месяц</p>
                    </>
                </HintQuestion></a>
                <a onClick={handleOpenModal}>{data?.month.subscription} руб. <HintQuestion placement="bottom">
                    <>
                        {/* eslint-disable-next-line max-len */}
                        <p>Расчётный суммарный рекуррентный платеж на текущий момент (сумма платежей пользователей с подключенной автооплатой)</p>
                    </>
                </HintQuestion></a>
            </div>
            <MainModal
                header="Детализация оплат за год"
                open={modalOpen}
                handleClose={handleClose}
                size="lg"
            >
                <form>
                    <Select
                        onChange={fetchPlot}
                        rules={{required: 'Год'}}
                        name="year"
                        data={years}
                        control={control}
                        placeholder="Год"
                        cleanable={false}
                        searchable={false}
                    />
                </form>
                <hr/>

                {modalHtml}

            </MainModal>
        </>
    );
};

export default PaymentUserListStatsWidget;