import React, {MouseEventHandler} from 'react';

import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './MoreActions.module.scss';

interface IMoreActionsItemProps {
    label: string,
    icon?: IconDefinition,
    onClick?: MouseEventHandler | undefined
}

const MoreActionsItem: React.FC<IMoreActionsItemProps> = (
    {
        label,
        icon,
        onClick,
    }) => {

    return (
        <div
            className={styles['dropdown__item']}
            onClick={onClick}
        >
            {icon && (
                <div className={styles['dropdown__item-icon']}>
                    <FontAwesomeIcon icon={icon} size="1x"/>
                </div>
            )}
            {label}
        </div>
    );
};

export default React.memo(MoreActionsItem);