import React, { useCallback, useMemo, MouseEvent } from 'react';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { tariffVariantAPI } from '../../../api/tariffVariantAPI';
import MoreActionsDots from '../../UI/MoreActions/MoreActionsDots';
import MoreActionsItem from '../../UI/MoreActions/MoreActionsItem';

import type { ITariffVariant } from '../../../models/ITariffVariant';

type TariffVariantMenuProps = {
    item: ITariffVariant;
    // eslint-disable-next-line no-unused-vars
    onEditClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const TariffVariantMenu: React.FC<TariffVariantMenuProps> = ({item, onEditClick}) => {
    const [deleteTariffVariant, { isLoading }] = tariffVariantAPI.useDeleteTariffVariantMutation();

    const handleDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        if (!window.confirm('Удалить вариант тарифа?')) return;
        try {
            await deleteTariffVariant(item.id).unwrap();
            toast.success('Вариант тарифа успешно удалён');
        } catch (error) {
            const message = error instanceof Error
                ? error.message
                : 'Произошла ошибка при удалении';
            toast.error(message);
        }
    }, [deleteTariffVariant, item.id]);

    const actions = useMemo(() => [
        <MoreActionsItem
            key="edit"
            label="Редактировать"
            icon={faPencil}
            onClick={onEditClick}
        />,
        <MoreActionsItem
            key="delete"
            label="Удалить"
            icon={faTrash}
            onClick={handleDelete}
        />,
    ], [onEditClick, handleDelete, isLoading]);

    return <MoreActionsDots>{actions}</MoreActionsDots>;
};

export default React.memo(TariffVariantMenu);