import React from 'react';

import cn from 'classnames';

import PauseCircle from '@rsuite/icons/legacy/PauseCircle';

import {Link} from 'react-router-dom';

import ISummaryMonitors from '../../../models/ISummaryMonitors';
import successMonitorsIcon from '../../../images/monitors-success.svg';
import problemMonitorsIcon from '../../../images/monitors-problems.svg';

import BoxTransparent from '../../UI/Box/BoxTransparent/BoxTransparent';
import CreateMonitorButton from '../../Monitor/CreateMonitorButton';

import {AppUrls} from '../../../constants/urls';

import LoaderCentered from '../../UI/Loader/LoaderCentered';

import styles from './SummaryMonitors.module.scss';

interface IMonitorsSummaryProps {
    summary: ISummaryMonitors,
    isFetching: boolean,
}

const SummaryMonitors: React.FC<IMonitorsSummaryProps> = ({summary, isFetching}) => {
    const HasProblems = () => <div className={styles.has_problems}>
        <Link to={AppUrls.INCIDENTS}>
            <img src={problemMonitorsIcon} alt="" className={styles.informer_icon_problem}/>
            <div className={styles.has_problems_info}>
                <div className={styles.counter}>{summary.down}</div>
                <p>Проблемы</p>
            </div>
        </Link>
    </div>;

    const NoProblems = () =>
        <div className={styles.no_problems}>
            <p>Проблем нет</p>
        </div>
    ;

    const availabilityClass = (availability: number): string => {
        switch (true) {
            case availability >= 99:
                return styles.availability_high;
            case availability >= 95:
                return styles.availability_medium;
            default:
                return styles.availability_low;
        }
    };

    return (
        <BoxTransparent className={styles.wrapper}>
            <LoaderCentered active={isFetching}/>
            <div className="block_header">
                <div className="block_title">Мониторинг</div>
                <CreateMonitorButton/>
            </div>
            <div className={styles.monitors_wrapper}>

                <Link to={AppUrls.MONITORS}>
                    <div className={styles.up}>
                        <img src={successMonitorsIcon} alt="" className={styles.informer_icon}/>
                        <div className={styles.up_info}>
                            <div className={styles.counter}>{summary.up}</div>
                            <p>Исправные</p>
                        </div>
                    </div>
                </Link>
                <div
                    className={cn(
                        styles.problems_container,
                        summary.down ? styles.problems_bg_green : styles.problems_bg_gray
                    )}>
                    {
                        summary.down ? <HasProblems/> : <NoProblems/>
                    }
                </div>
            </div>

            <div className={styles.info_wrapper}>
                <div className={styles.availability_wrapper}>
                    <div className={styles.title}>
                        Доступность
                    </div>
                    <div className={styles.availability}>
                        <div className={cn(styles.availability_block, availabilityClass(summary.availability1))}>
                            <span className={cn(styles.availability_value, styles.availability_value__large)}>
                                {summary.availability1}%
                            </span>
                            <div className={styles.availability_period}>24 часа</div>
                        </div>

                        <div className={cn(styles.availability_block, availabilityClass(summary.availability7))}>
                            <span className={styles.availability_value}>
                                {summary.availability7}%
                            </span>
                            <div className={styles.availability_period}>7 дней</div>
                        </div>

                        <div className={cn(styles.availability_block, availabilityClass(summary.availability30))}>
                            <span className={styles.availability_value}>
                                {summary.availability30}%
                            </span>
                            <div className={styles.availability_period}>Месяц</div>
                        </div>
                    </div>
                </div>

                <div className={styles.paused_wrapper}>
                    <div className={styles.title}>
                        Приостановлены
                    </div>
                    <div className={styles.paused_informer}>
                        <PauseCircle
                            color="#ffffff"
                            width="24px"
                            height="24px"
                        />
                        <div className={styles.paused_value}>
                            {summary.paused}
                        </div>
                    </div>
                </div>
            </div>
        </BoxTransparent>
    );
};

export default SummaryMonitors;