import React from 'react';

import Premium from '../../Icons/Premium';
import Hint from './Hint';
import useCheapestTariffWithBooleanLimit, {TariffCondition} from '../../../hooks/useCheapestTariffWithBooleanLimit';
import {AppUrls} from '../../../constants/urls';

interface IHintPremiumProps {
    condition: TariffCondition,
}

const HintPremium: React.FC<IHintPremiumProps> = ({condition}) => {

    const tariff = useCheapestTariffWithBooleanLimit(condition);
    if (null === tariff){
        return <></>;
    }

    return <Hint trigger={<span><Premium/></span>}>
        <>
            Доступно на тарифах от {tariff.price} рублей в месяц. <a
                href={AppUrls.TARIFFS}
                target="_blank"
                rel="noreferrer"
            >Подключить</a>
        </>
    </Hint>;
};

export default HintPremium;