import React, {useEffect, useRef, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {useForm} from 'react-hook-form';

import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';

import IPagination from '../../../models/IPagination';
import IMonitorOutput from '../../../models/Monitor/IMonitor';
import {
    clearAll,
    isLoadingSelector,
    isSelectedAllSelector,
    isSelectedNoneSelector,
    monitorSelectedIdsSelector,
    selectAll,
    selectAllOnPage,
    setShowBatchOperations,
    setTotal,
    showBatchOperationsSelector,
} from '../../../redux/monitorSlice';
import Checkbox from '../../UI/Checkbox/Checkbox';

import PrimaryButton from '../../UI/Button/PrimaryButton';

import {TypedDispatch} from '../../../types';

import Select from '../../UI/Input/Select';

import CommonBatchMonitorModal from './CommonBatchMonitorModal';

import IconButton from '../../UI/IconButton/IconButton';

import styles from './MonitorBatchOperations.module.scss';


interface IMonitorBatchOperationsProps {
    pagination?: IPagination<IMonitorOutput>,
}

const MonitorBatchOperations: React.FC<IMonitorBatchOperationsProps> = ({pagination}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const selectedIds = useSelector(monitorSelectedIdsSelector);
    const isSelectedAll = useSelector(isSelectedAllSelector);
    const isSelectedNone = useSelector(isSelectedNoneSelector);
    const showBatchOperations = useSelector(showBatchOperationsSelector);
    const isLoading = useSelector(isLoadingSelector);

    const [selectedAction, setSelectedAction] = useState<Record<string, any> | undefined>();
    const [showModal, setShowModal] = useState(false);

    const ref = useRef<HTMLFormElement>(null);

    const handleClose = () => {
        setShowModal(false);
        setSelectedAction(undefined);
    };

    const actions = [
        {
            value: 'start',
            label: 'Включить',
        },
        {
            value: 'stop',
            label: 'На паузу',
        },
        {
            value: 'edit',
            label: 'Изменить настройки',
        },
        {
            value: 'delete',
            label: 'Удалить',
        },
    ];

    const {handleSubmit, formState, control} = useForm<{ action: string }>({
        mode: 'onChange',
    });
    const {isValid} = formState;

    const itemsOnPage = pagination?.items.length ?? 0;

    useEffect(() => {
        dispatch(setTotal(itemsOnPage));
    }, [pagination]);

    const onSubmit = ({action}: { action: string }) => {
        setSelectedAction(actions.find(item => item.value === action));
        setShowModal(true);
    };

    const onSelectAllOnPageClick = () => {
        if (isSelectedNone) {
            dispatch(selectAllOnPage(pagination?.items.map(item => item.uuid)));
        } else {
            dispatch(clearAll(itemsOnPage));
        }
    };

    const onSelectAllClick = () => {
        dispatch(selectAll());
    };

    const selectedIdsNumber = selectedIds.length;

    return showBatchOperations
        ? <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.wrapper}
                ref={ref}
            >
                <Checkbox
                    checked={!isSelectedNone}
                    indeterminate={!isSelectedNone && !isSelectedAll}
                    onChange={onSelectAllOnPageClick}
                />
                <div className="text-medium">
                    Выбрано: {selectedIdsNumber}
                </div>

                {
                    isSelectedAll
                    && selectedIdsNumber < (pagination?.totalItems ?? 0)
                    && <PrimaryButton
                        onClick={onSelectAllClick}
                        loading={isLoading}
                    >
                        Выбрать все ({pagination?.totalItems})
                    </PrimaryButton>
                }


                <Select
                    rules={{required: 'Выберите действие'}}
                    control={control}
                    name="action"
                    data={actions}
                    placeholder="Выберите действие"
                    cleanable={false}
                    searchable={false}
                />

                <PrimaryButton
                    type="submit"
                    disabled={!isValid || 0 === selectedIdsNumber}
                >
                    Применить
                </PrimaryButton>
                <IconButton
                    className="mx-3"
                    icon={faTimes}
                    title="Закрыть массовые операции"
                    onClick={(event) => {
                        event.preventDefault();
                        dispatch(setShowBatchOperations(false));
                    }}
                />
            </form>
            <CommonBatchMonitorModal
                open={showModal}
                handleClose={handleClose}
                action={selectedAction}
            />
        </>
        : <></>;
};

export default MonitorBatchOperations;