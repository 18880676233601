import React from 'react';

import {useDispatch} from 'react-redux';

import {useNavigate} from 'react-router-dom';

import IUserOutput from '../../models/IUser';
import ExitButton from '../UI/IconButton/ExitButton';

import {isImpersonator, logout} from '../../utils/auth';
import {AppUrls} from '../../constants/urls';

import {TypedDispatch} from '../../types';

import styles from './UserInformer.module.scss';


interface IUserInformerProps {
    user: IUserOutput | null,
    // eslint-disable-next-line no-unused-vars
}

const UserInformer: React.FC<IUserInformerProps> = ({user}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();


    const handleLogout = async(event: React.MouseEvent) => {
        event.preventDefault();
        const impersonator = isImpersonator();
        await logout(dispatch);
        const returnPath = impersonator ? AppUrls.ADMIN_USERS : AppUrls.LOGIN;
        navigate(returnPath);
    };

    return (
        <div className={styles.wrapper}>

            <div className={styles.text}>
                {user?.last_name} {user?.first_name}
            </div>

            <div className={styles.icon} onClick={handleLogout}>
                <ExitButton className={styles.exit_btn} title="Выход"/>
            </div>

        </div>
    );
};

export default UserInformer;