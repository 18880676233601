import React, {useState} from 'react';

import TariffRow from './TariffRow';
import CreateTariffButton from './CreateTariffButton';
import {Pages} from '../../../constants/pages';
import Table from '../../UI/Table/Table';
import {tariffAPI} from '../../../api/tariffAPI';
import TableBox from '../../UI/Box/TableBox/TableBox';

import IOrderBy from '../../../models/IOrderBy';

import styles from './Tariffs.module.scss';

const Tariffs: React.FC = () => {
    const [sort, setSort] = useState<IOrderBy>();
    const {data: items, isLoading} = tariffAPI.useGetTariffsQuery(sort);
    return (
        <TableBox className={styles.table_box}>
            <div className={styles.head}>
                <h3>{Pages.ADMIN_TARIFFS}</h3>
                <CreateTariffButton/>
            </div>

            <Table
                columns={[
                    {label: 'ID', sort: 'id'},
                    {label: 'Название', sort: 'name'},
                    {label: 'Стоимость', sort: 'price'},
                    {label: 'Срок', sort: 'period'},
                    {label: 'Арх', sort: 'is_archive'},
                    {label: 'Умолч', sort: 'is_default'},
                    {label: 'Реком', sort: 'is_recommended'},
                    {label: 'Мин. инт'},
                    {label: 'Мон-ов'},
                    {label: 'Telegram чатов'},
                    {label: 'Проверка SSL'},
                    {label: 'Монитор домена'},
                    {label: 'Редирект https'},
                    {label: 'Расш. методы'},
                    {label: 'Разбор проблем'},
                    {label: 'Учащ. монит.'},
                    {label: ''},
                ]}
                items={items}
                rowFactory={item => <TariffRow
                    key={item.id}
                    item={item}
                />}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
                className={styles.tariffs_admin}
            />
        </TableBox>
    );
};

export default Tariffs;