import React, {useEffect, useState} from 'react';

import BoxTransparent from '../../UI/Box/BoxTransparent/BoxTransparent';

import GradientButton from '../../UI/Button/GradientButton';

import Arrow from '../../Icons/Arrow';

import Advantage from '../../Icons/Advantage';

import {AppUrls} from '../../../constants/urls';

import MonitorModal from '../../Monitor/MonitorModal';

import Rocket from '../../../images/rocket.png';

import Logo from '../../Icons/Logo';

import styles from './Plug.module.scss';

const Plug: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const advantages = [
        {
            number: 1,
            label: 'Мониторинг SSL',
        },
        {
            number: 2,
            label: 'Мониторинг регистрации домена',
        },
        {
            number: 3,
            label: 'Проверка редиректа с http на https',
        },
        {
            number: 4,
            label: 'Учащенный мониторинг',
        },
        {
            number: 5,
            label: 'Разбор проблем',
        },
        {
            number: 6,
            label: 'Проверка контента страницы',
        },
    ];
    const [isWideScreen, setIsWideScreen] = React.useState(window.innerWidth > 1029);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth < 1029);
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        };
    }, []);

    const onClickModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div className={styles.fullScreenPlug}>
            <BoxTransparent className={styles.box}>
                <MonitorModal handleClose={handleCloseModal} open={openModal}/>
                <header className={styles.header}>
                    <div className={styles.container}>
                        <a className={styles.logo} href={'/'}>
                            <Logo/>
                        </a>
                    </div>
                </header>
                <div className={styles.content}>
                    <h1>Узнавайте о сбоях раньше,
                        <span>
                            чем их заметят клиенты!
                            <text className={styles.iconRocket}>&#128640;</text>
                        </span>
                    </h1>
                    <div className={styles.contentButton}>
                        <GradientButton
                            onClick={onClickModal}
                            href={''}
                            className={`${styles.button} ${styles.left}`}
                        >
                            <span className={`${styles.icon} ${styles.plus}`}></span>
                        Добавить монитор
                        </GradientButton>
                        <span className={styles.text}>Нажмите, чтобы отслеживать URL</span>
                        <Arrow/>
                    </div>
                    <img className={styles.rocket} src={Rocket} width={1023} height={400} alt={'rocket'}/>
                    <p>До двадцати отслеживаемых страниц, проверка каждые 5 минут,
                    уведомления на почту и в Telegram без ограничений.
                    Наш базовый бесплатный тариф навсегда останется бесплатным.
                    </p>
                    <div className={styles.advantages}>
                        <div className={styles.text}>
                            <h2>Продвинутые возможности Overseer,<br/><span>всего от 100₽ в месяц!</span></h2>
                            <p>Всегда лучше предупредить, чем лечить!<br/> Мы можем
                            не только проверять доступность URL, но и предотвратим
                            потенциальные проблемы. Для продвинутых пользователей доступна
                            проверка каждую минуту и другие ценные возможности.
                            </p>
                        </div>
                        <ul className={styles.list}>
                            {advantages.map((items) => (
                                <li className={styles.item} key={items.number}>
                                    <span className={styles.check}><Advantage/></span>
                                    <span>{items.label}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <GradientButton
                        href={AppUrls.TARIFFS}
                        className={`${styles.button} ${styles.right}`}
                    >
                        {isWideScreen ? 'Подключите продвинутые возможности с любым платным тарифом' : 'Подключить'}
                        <span className={`${styles.icon} ${styles.arrow}`}></span>
                    </GradientButton>
                </div>
            </BoxTransparent>
        </div>
    );
};

export default Plug;