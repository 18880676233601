import React, {ComponentProps, ElementType} from 'react';
import cn from 'classnames';
import {RegisterOptions} from 'react-hook-form';

interface BaseInputProps {
    register?: any;
    rules?: RegisterOptions;
    placeholderToLabel?: boolean;
}

export type InputProps = BaseInputProps &
    Omit<Omit<ComponentProps<'input'>, keyof BaseInputProps>, 'ref'>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            name,
            placeholder,
            className,
            placeholderToLabel = false,
            register,
            rules,
            ...rest
        },
        ref
    ) => {

        const Component: ElementType<InputProps> = 'input';
        return (
            <>
                <Component
                    ref={ref}
                    name={name}
                    placeholder={!placeholderToLabel ? placeholder : ''}
                    className={cn('form-control', className)}
                    {...(register &&
                        register(`${name}`, {...rules}))}
                    {...rest}
                />
                {
                    placeholderToLabel
                    && <label htmlFor={name}>{placeholder}</label>
                }
            </>
        );
    }
);

Input.displayName = 'Input';

export default React.memo(Input);