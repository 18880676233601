import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import ModalBodyWrapper from '../UI/Modal/ModalBodyWrapper';
import UpgradeTariffButton from '../UI/Button/UpgradeTariffButton';

interface IMonitorLimitExceededModalProps {
    open: boolean,
    handleClose: () => void;
}

const MonitorLimitExceededModal: React.FC<IMonitorLimitExceededModalProps> = ({open, handleClose}) => {

    return <MainModal
        header="Лимит достигнут"
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <ModalBodyWrapper>
            <p className="text-medium">
            Используется максимальное количество мониторов для вашего тарифа.
            </p>
            <p className="text-medium">
            Чтобы добавить мониторы, перейдите на тариф с большими возможностями.
            </p>
        </ModalBodyWrapper>
        <UpgradeTariffButton/>
    </MainModal>;
};

export default MonitorLimitExceededModal;