import {useState} from 'react';

import IBatchResult from '../models/IBatchResult';
import createHttpRequest from '../utils/http';
import {HttpMethodType} from '../types';
import {ApiUrls} from '../constants/urls';

const useBatchOperation = () => {
    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const trigger = async(
        path: string,
        method: HttpMethodType,
        data: object,
        // eslint-disable-next-line no-unused-vars
        onSuccess: (result: IBatchResult) => void
    ) => {
        setIsLoading(true);
        const response = await createHttpRequest({
            path,
            method,
            data,
        });

        const operationId = response.data.operation_id;
        setTimeout(() => polling(operationId, onSuccess), 5000);
    };

    // eslint-disable-next-line no-unused-vars
    const polling = async(operationId: string, onSuccess: (result: IBatchResult) => void) => {
        const response = await createHttpRequest({
            path: ApiUrls.BATCH_CHECK(operationId),
            method: 'GET',
        });

        if (response.data.ready) {
            onSuccess(response.data);
            setIsLoading(false);
        }else {
            setTimeout(() => polling(operationId, onSuccess), 5000);
        }
    };

    return { trigger, isLoading };
};

export default useBatchOperation;