import React, {useCallback, useEffect, useState} from 'react';
import {Control, Controller, useController, UseFormSetValue} from 'react-hook-form';

import {InputNumberProps} from 'rsuite/esm/InputNumber/InputNumber';

import cn from 'classnames';
import {InputGroup, InputNumber as RInputNumber} from 'rsuite';

import {getInputState} from '../../../utils/inputHelper';

import {useIsMount} from '../../../hooks/useIsMount';

import styles from './InputNumber.module.scss';


interface IInputNumberProps extends InputNumberProps<string | number>{
    control: Control<any>;
    name: string,
    setValue: UseFormSetValue<any>,
}

const InputNumber: React.FC<IInputNumberProps> = ({
    control,
    name,
    className,
    setValue,
    ...rest
}) => {
    const { fieldState } = useController({name, control});
    const isMount = useIsMount();
    const [stateValue, setStateValue] = useState<number>(Number(rest.defaultValue ?? 0));
    const [isFocused, setIsFocused] = useState(false);
    const state = getInputState(fieldState, isFocused);

    const classMap = {
        valid: styles.input_valid,
        error: styles.input_error,
        active: styles.input_active,
        default: styles.input_default,
    };

    const step = rest.step ?? 1;
    const min = rest.min ?? null;
    const max = rest.max ?? null;

    const isMinusDisabled = min !== null && stateValue - step < min;
    const isPlusDisabled = max !== null && stateValue + step > max;

    const handleMinus = () => {
        if (isMinusDisabled) {
            return;
        }
        setStateValue((prev) => Number(prev) - step);
    };
    const handlePlus = () => {
        if (isPlusDisabled) {
            return;
        }
        setStateValue((prev) => Number(prev) + step);
    };

    useEffect(() => {
        if (!isMount) {
            setValue(name, stateValue, { shouldDirty: true });
        }
    }, [stateValue, setValue]);

    const onInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    return (
        <InputGroup
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            className={cn(styles.input, classMap[state])}
        >
            <InputGroup.Button
                onClick={handleMinus}
                disabled={isMinusDisabled}
            >-</InputGroup.Button>

            <Controller
                name={name}
                control={control}
                /* eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars */
                render={({field: { value, onChange, onBlur, ...field}}) => (
                    <RInputNumber
                        className={cn(
                            'custom-input-number',
                            className
                        )}
                        value={stateValue}
                        onChange={(val)=>{
                            setStateValue(Number(val));
                        }}
                        {...field}
                        {...rest}
                    />
                )}
            />
            <InputGroup.Button
                onClick={handlePlus}
                disabled={isPlusDisabled}
            >+</InputGroup.Button>
        </InputGroup>
    );
};

export default InputNumber;