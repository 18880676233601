export function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    listSuffix?: string | number,
) {
    const tagId = 'LIST'+(listSuffix ?? '');
    return resultsWithIds
        ? [
            { type: tagType, id: tagId },
            ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: tagId }];
}

export function providesListUuid<R extends { uuid: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    listSuffix?: string | number,
) {
    const tagId = 'LIST'+(listSuffix ?? '');
    return resultsWithIds
        ? [
            { type: tagType, id: tagId },
            ...resultsWithIds.map(({ uuid }) => ({ type: tagType, id: uuid })),
        ]
        : [{ type: tagType, id: tagId }];
}