import React from 'react';


import MainModal from '../../UI/Modal/MainModal';
import MonitorFiltersFormHandler from './MonitorFiltersFormHandler';

interface IMonitorFiltersModalProps {
    open: boolean,
    handleClose: () => void;
}

const MonitorFiltersModal: React.FC<IMonitorFiltersModalProps> = ({open, handleClose}) => {

    return <MainModal
        header="Фильтры"
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <MonitorFiltersFormHandler onSuccess={handleClose}/>
    </MainModal>;
};

export default MonitorFiltersModal;