import React, {ReactNode} from 'react';

import styles from './MainModal.module.scss';

interface IModalBodyWrapperProps {
    children: ReactNode,
}

const ModalBodyWrapper: React.FC<IModalBodyWrapperProps> = ({children}) => {
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    );
};

export default ModalBodyWrapper;