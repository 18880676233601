export const Pages = {
    HOME: 'Обзор',
    MONITORS: 'Мониторинг сайтов',
    MONITOR_LOG: 'Журнал мониторинга сайта',
    MONITOR_DETAILS: 'Монитор',
    SETTINGS: 'Настройки',
    TARIFFS: 'Тарифы',
    PAYMENTS: 'Платежи',
    USERS: 'Пользователи',
    ADMIN_TARIFFS: 'Редактор тарифов',
    ADMIN_TARIFF_VARIANTS: 'Редактор пакетов',
    ADMIN_PAYMENTS: 'Платежи пользователей',
    INCIDENTS: 'Инциденты',
    WARNINGS: 'Предупреждения',
    SUPPORT: 'Тех. поддержка',
};