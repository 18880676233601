import React from 'react';

import {round} from 'lodash';

import ITimeRange from '../../../models/ITimeRange';
import {getFormattedDate, getMinutesFromDayBeginning} from '../../../utils/datetime';
import TimeRange from './TimeRange';

interface ITimeRangeControlMidnightProps {
    range: ITimeRange,
    tzString?: string|null,
    height: number,
    hideTime?: boolean,
}

const TimeRangeControlMidnight: React.FC<ITimeRangeControlMidnightProps> = ({
    range,
    tzString,
    height,
    hideTime= false,
}) => {
    const minutesInDay = 60 * 24;

    const formatDate = (datetime: string): string => getFormattedDate(datetime, {format: 'HH:mm', tzString});

    const getWidth = (from: number, to: number): string => `${round((to - from) / minutesInDay * 100, 2)}%`;

    const getLeft = (from: number): string => `${round(from / minutesInDay * 100, 2)}%`;

    const minutesFrom = getMinutesFromDayBeginning(range.timeFrom, tzString);
    const minutesTo = getMinutesFromDayBeginning(range.timeTo, tzString);

    if (minutesFrom > minutesTo) {
        //Верхняя часть интервала выходит за пределы дня - разбиваем на 2 интервала
        return <>
            <TimeRange
                width={getWidth(minutesFrom, minutesInDay)}
                left={getLeft(minutesFrom)}
                color={range.color}
                timeFrom={formatDate(range.timeFrom)}
                timeTo={'23:59'}
                height={height}
                hideTime={hideTime}
            />
            {
                minutesTo > 0
                && <TimeRange
                    width={getWidth(0, minutesTo)}
                    left={'0%'}
                    color={range.color}
                    timeFrom={'00:00'}
                    timeTo={formatDate(range.timeTo)}
                    height={height}
                    hideTime={hideTime}
                />
            }
        </>;
    }

    //Интервал в пределах дня
    return <TimeRange
        width={getWidth(minutesFrom, minutesTo)}
        left={getLeft(minutesFrom)}
        color={range.color}
        timeFrom={formatDate(range.timeFrom)}
        timeTo={formatDate(range.timeTo)}
        height={height}
        hideTime={hideTime}
    />;
};

export default TimeRangeControlMidnight;