// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_head__9VfpB {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 12px;
  padding: 0 30px;
}

.Users_stats__dq9o3 {
  max-height: 30px;
  display: flex;
  justify-content: end;
  flex: auto;
  gap: 10px;
}
.Users_stats__dq9o3 a {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/User/Users.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,UAAA;EACA,SAAA;AACF;AACE;EACE,sBAAA;EACA,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".head {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n  margin-bottom: 12px;\n  padding: 0 30px;\n}\n\n.stats {\n  max-height: 30px;\n  display: flex;\n  justify-content: end;\n  flex: auto;\n  gap: 10px;\n\n  a {\n    border: 1px solid gray;\n    border-radius: 10px;\n    padding: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `Users_head__9VfpB`,
	"stats": `Users_stats__dq9o3`
};
export default ___CSS_LOADER_EXPORT___;
