// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/checkbox-checked.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/checkbox-indeterminate.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_checkbox_label__eny\\+B {
  display: inline-flex;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 0;
}

.Checkbox_checkbox_text__Z-ebR {
  margin-left: -20px;
  margin-top: -2px;
}

.Checkbox_checkbox_container__MXGLW .pretty.p-inspinia input[type=checkbox]:checked ~ .state label:after {
  background-color: #fff !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  border: none !important;
  width: 26px;
  height: 26px;
  left: -5px;
  top: -5px;
}
.Checkbox_checkbox_container__MXGLW .pretty.p-inspinia.indeterminate input[type=checkbox]:checked ~ .state label:after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
}
.Checkbox_checkbox_container__MXGLW .pretty.p-inspinia .state label:before {
  border: 2px solid #E0E0E0 !important;
  border-radius: 4px !important;
}
.Checkbox_checkbox_container__MXGLW .pretty.p-inspinia input[type=checkbox]:checked ~ .state label:before {
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAGI;EACI,iCAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;AAAR;AAGI;EACI,oEAAA;AADR;AAII;EACI,oCAAA;EACA,6BAAA;AAFR;AAKI;EACI,uBAAA;AAHR","sourcesContent":[".checkbox_label {\n    display: inline-flex;\n    cursor: pointer;\n    margin-right: 10px;\n    margin-bottom: 0;\n}\n\n.checkbox_text {\n    margin-left: -20px;\n    margin-top: -2px;\n}\n\n.checkbox_container {\n    :global(.pretty.p-inspinia input[type=checkbox]:checked ~ .state label:after) {\n        background-color: #fff !important;\n        background-image: url('../../../images/checkbox-checked.svg');\n        background-repeat: no-repeat;\n        background-size: contain;\n        border: none !important;\n        width: 26px;\n        height: 26px;\n        left: -5px;\n        top: -5px;\n    }\n\n    :global(.pretty.p-inspinia.indeterminate input[type=checkbox]:checked ~ .state label:after) {\n        background-image: url('../../../images/checkbox-indeterminate.svg')!important;\n    }\n\n    :global(.pretty.p-inspinia .state label:before) {\n        border: 2px solid #E0E0E0 !important;\n        border-radius: 4px !important;\n    }\n\n    :global(.pretty.p-inspinia input[type=checkbox]:checked ~ .state label:before) {\n        border: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox_label": `Checkbox_checkbox_label__eny+B`,
	"checkbox_text": `Checkbox_checkbox_text__Z-ebR`,
	"checkbox_container": `Checkbox_checkbox_container__MXGLW`
};
export default ___CSS_LOADER_EXPORT___;
