// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailConfirmationBanner_text__aB4zs {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
  padding: 10px 0;
}

.EmailConfirmationBanner_notification__WudJd {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #FF0000;
}

.EmailConfirmationBanner_button__UroqI {
  width: 100%;
  font-size: 13px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/EmailConfirmationBanner/EmailConfirmationBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACI,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,WAAA;EACA,0BAAA;AACJ","sourcesContent":[".text {\n    font-family: Inter, sans-serif;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 18px;\n    color: #383838;\n    padding: 10px 0;\n}\n\n.notification {\n    font-family: Inter, sans-serif;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 17px;\n    color: #FF0000;\n}\n\n.button {\n    width: 100%;\n    font-size: 13px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `EmailConfirmationBanner_text__aB4zs`,
	"notification": `EmailConfirmationBanner_notification__WudJd`,
	"button": `EmailConfirmationBanner_button__UroqI`
};
export default ___CSS_LOADER_EXPORT___;
