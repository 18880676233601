import React from 'react';

import BlankLayout from '../../components/Layout/BlankLayout/BlankLayout';
import NewPassword from '../../components/Auth/Password/NewPassword';


const NewPasswordPage: React.FC = () => {
    return (
        <BlankLayout pageTitle="Новый пароль">
            <NewPassword/>
        </BlankLayout>
    );
};

export default NewPasswordPage;