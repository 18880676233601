import React from 'react';

import Hint from '../Hint/Hint';

import styles from './TimeRanges.module.scss';

interface ITimeRangeProps {
    width: string,
    left: string,
    color: string,
    timeFrom: string,
    timeTo: string,
    height: number,
    hideTime?: boolean,
}

const TimeRange: React.FC<ITimeRangeProps> = ({
    width,
    left,
    color,
    timeFrom,
    timeTo,
    height,
    hideTime= false,
}) => {
    return (
        <div
            className={styles.range}
            style={{
                backgroundColor: color,
                width,
                left,
                height: height+'px',
            }}
        >
            <Hint trigger={<div style={{width: '100%', height: '100%'}}/>}>
                <p className={styles.hint}>{timeFrom} - {timeTo}</p>
            </Hint>
            {
                !hideTime
                && <>
                    <div className={styles.label_from}>
                        {timeFrom}
                    </div>
                    <div
                        className={styles.label_to}
                        style={{top: height+'px'}}
                    >
                        {timeTo}
                    </div>
                </>
            }
        </div>
    );
};

export default TimeRange;