// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button_submit_disabled__\\+3xj7 {
  background-color: #e1e1e1 !important;
  color: #7D8797 !important;
}
.Button_button_submit_disabled__\\+3xj7:hover {
  color: #7D8797;
  transform: none;
}

.Button_transparent__kVJUC {
  background: none;
  border: none;
  color: #1DB5B6;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500 !important;
  height: 34px;
  z-index: 100;
}
.Button_transparent__kVJUC.btn {
  padding: 7px 11px !important;
}
.Button_transparent__kVJUC.btn:hover {
  transform: none !important;
  border-radius: 6px !important;
}
@media (max-width: 550px) {
  .Button_transparent__kVJUC.rs-btn-ghost.rs-btn {
    font-size: 13px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,yBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;AACJ;;AAGA;EACE,gBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;EACA,YAAA;EACA,YAAA;AAAF;AAEE;EACE,4BAAA;AAAJ;AAEI;EACE,0BAAA;EACA,6BAAA;AAAN;AAKI;EADF;IAEI,0BAAA;EAFJ;AACF","sourcesContent":[".button_submit_disabled {\n  background-color: #e1e1e1 !important;\n  color: #7D8797 !important;\n\n  &:hover {\n    color: #7D8797;\n    transform: none;\n  }\n}\n\n.transparent {\n  background: none;\n  border: none;\n  color: #1DB5B6;\n  font-size: 15px;\n  line-height: 21px;\n  font-weight: 500 !important;\n  height: 34px;\n  z-index: 100;\n\n  &:global(.btn) {\n    padding: 7px 11px !important;\n\n    &:hover {\n      transform: none !important;\n      border-radius: 6px !important;\n    }\n  }\n\n  &:global(.rs-btn-ghost.rs-btn) {\n    @media (max-width: 550px) {\n      font-size: 13px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_submit_disabled": `Button_button_submit_disabled__+3xj7`,
	"transparent": `Button_transparent__kVJUC`
};
export default ___CSS_LOADER_EXPORT___;
