import React from 'react';
import cn from 'classnames';
import {Tooltip, Whisper} from 'rsuite';

import styles from './Tooltip.module.scss';

type Trigger = Array<'click' | 'contextMenu' | 'hover' | 'focus' | 'active'>
    | 'click'
    | 'contextMenu'
    | 'hover'
    | 'focus'
    | 'active'
    | 'none';

type Placement =
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'bottomStart'
    | 'bottomEnd'
    | 'topStart'
    | 'topEnd'
    | 'leftStart'
    | 'leftEnd'
    | 'rightStart'
    | 'rightEnd'
    | 'auto'
    | 'autoVerticalStart'
    | 'autoVerticalEnd'
    | 'autoHorizontalStart'
    | 'autoHorizontalEnd';

interface ILightTooltipProps {
    title: string,
    children: React.ReactElement,
    trigger?: Trigger,
    placement?: Placement,
    className?: string,
}

const LightTooltip: React.FC<ILightTooltipProps> = (
    {
        title,
        trigger = 'hover',
        placement = 'top',
        children,
        className,
    }) => {
    const tooltip = (
        <Tooltip className={cn(styles['tooltip'], styles['tooltip--light'], className)}>
            {title}
        </Tooltip>
    );

    return (
        <Whisper
            placement={placement}
            trigger={trigger}
            speaker={tooltip}
        >
            {children}
        </Whisper>
    );
};

export default React.memo(LightTooltip);