import React from 'react';

import {useParams} from 'react-router';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import MonitorUnsubscribe from '../components/Monitor/MonitorUnsubscribe';
import {monitorAPI} from '../api/monitorAPI';
import NotFound from './NotFound';

const MonitorUnsubscribePage: React.FC = () => {
    const {uuid} = useParams();
    if (!uuid) {
        return <NotFound/>;
    }
    const {data, isLoading, isError} = monitorAPI.useGetDomainOfMonitorQuery(uuid ?? '');
    const [trigger] = monitorAPI.useLazyUnsubscribeMonitorQuery();
    const unsubscribeHandler = async() => {
        return trigger(uuid ?? '').unwrap();
    };

    if (isError) {
        return <NotFound/>;
    }

    return (
        <BlankLayout pageTitle="Отписаться от мониторинга сайта" isLoading={isLoading}>
            <MonitorUnsubscribe
                unsubscribeHandler={unsubscribeHandler}
                domain={data?.domain ?? ''}
            />
        </BlankLayout>
    );
};

export default MonitorUnsubscribePage;