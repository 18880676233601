import React, {ReactNode} from 'react';

import Logo from '../Icons/Logo';
import Box from '../UI/Box/Box';

import LogoOwner from '../Icons/LogoOwner';

import styles from './Auth.module.scss';

interface IAuthWrapperProps {
    children: ReactNode,
}

const AuthWrapper: React.FC<IAuthWrapperProps> = ({children}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <div className={styles.logo}><Logo /></div>
                <Box className={styles.box_container}>
                    {children}
                </Box>
            </div>
            <div className={styles.owner}>
                <LogoOwner />
                <span className={styles.owner_name}>brotherhood.software</span>
            </div>
        </div>
    );
};

export default AuthWrapper;