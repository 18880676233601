import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import ConfirmEmail from '../components/Auth/ConfirmEmail';
import {isEmailConfirmingSelector} from '../redux/authSlice';

const ConfirmEmailPage: React.FC = () => {
    const isLoading = useSelector(isEmailConfirmingSelector);
    return (
        <BlankLayout pageTitle="Подтверждение email" isLoading={isLoading}>
            <ConfirmEmail/>
        </BlankLayout>
    );
};

export default ConfirmEmailPage;