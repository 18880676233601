import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Table from '../UI/Table/Table';
import TableBox from '../UI/Box/TableBox/TableBox';
import {additionalEmailAPI} from '../../api/additionalEmailAPI';
import CreateAdditionalEmailButton from './CreateAdditionalEmailButton';
import AdditionalEmailRow from './AdditionalEmailRow';
import IOrderBy from '../../models/IOrderBy';
import IAdditionalEmail from '../../models/IAdditionalEmail';


import {userSelector} from '../../redux/authSlice';

import styles from '../TelegramChat/TelegramChats.module.scss';


const AdditionalEmails: React.FC = () => {
    const user = useSelector(userSelector);
    const [sort, setSort] = useState<IOrderBy>();
    const {data: items, isLoading} = additionalEmailAPI.useGetAdditionalEmailsQuery(sort);
    const emails = [];
    if(!isLoading) {
        const mainEmail = {
            id: -1,
            email: user?.email,
            description: '',
            active: user?.email_active,
            is_main: true,
        } as IAdditionalEmail;

        emails.push(mainEmail);
        items?.forEach((item) => emails.push(item));
    }
    return (
        <TableBox>
            <div className={styles.head}>
                <h3>Адреса для уведомлений</h3>
                <CreateAdditionalEmailButton/>
            </div>

            <Table
                columns={[
                    {label: 'E-mail', sort: 'email'},
                    {label: 'Описание', sort: 'description'},
                    {label: 'Активен', sort: 'active'},
                    {label: ''},
                    {label: ''},
                ]}
                items={emails}
                rowFactory={item => <AdditionalEmailRow
                    key={item.id}
                    item={item}
                />}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>
    );
};

export default AdditionalEmails;