import React from 'react';

import TariffVariants from 'components/Admin/TariffVariants/TariffVariants';
import MainLayout from 'components/Layout/MainLayout/MainLayout';


const AdminTariffVariantsPage: React.FC = () => {

    return (
        <MainLayout>
            <TariffVariants/>
        </MainLayout>
    );
};

export default AdminTariffVariantsPage;