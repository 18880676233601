import React from 'react';

import IUserOutput from '../../models/IUser';
import UserTariffForm from './UserTariffForm/UserTariffForm';
import MainModal from '../UI/Modal/MainModal';

interface IUserTariffModalProps {
    open: boolean,
    item: IUserOutput,
    handleClose: () => void;
}

const UserTariffModal: React.FC<IUserTariffModalProps> = ({open, item, handleClose}) => {

    return (
        <MainModal
            header={<>Назначить тариф пользователю &quot;{item.email}&quot;</>}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <UserTariffForm onSuccess={handleClose} item={item}/>
        </MainModal>
    );
};

export default UserTariffModal;