import React, {ReactElement} from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {routes} from 'Router';
import {Permission} from 'models/Permission';
import {isGrantedAccess} from 'utils/permissionsHelper';
import {userPermissionsSelector, userSelector} from 'redux/authSlice';

import {IconProps} from '@rsuite/icons/lib/Icon';

import IUserOutput from '../../../models/IUser';

import styles from './MainMenu.module.scss';

interface IMenuItemProps {
    path: string,
    title: string,
    icon: React.ComponentType<IconProps>;
    auth?: Permission[],
    target?: string,
    // eslint-disable-next-line no-unused-vars
    condition?: (user: IUserOutput) => boolean,
}

const MainMenu: React.FC = () => {
    const userPermissions = useSelector(userPermissionsSelector);
    const menu = routes.filter(item => item.title && item.icon) as IMenuItemProps[];
    const user = useSelector(userSelector);
    const isConditionFulfilled = (item: IMenuItemProps) => (
        !item.condition
        || (null !== user && item.condition(user))
    );

    return (
        <ul className={styles.list}>
            {
                menu.map(item => {
                    return (
                        isGrantedAccess(userPermissions, item.auth)
                        && isConditionFulfilled(item)
                    )
                        ? <li
                            className={styles.item}
                            key={item.path}>
                            <NavLink
                                to={item.path}
                                end={item.path.length === 1}
                                target={item.target}
                                className={({isActive}) => isActive ? styles.active_link : undefined}>
                                <span className={styles.icon}>
                                    <item.icon />
                                </span>
                                <span>{item.title}</span>
                            </NavLink>
                        </li>
                        : null;
                })
            }
        </ul>
    );
};

export default MainMenu;