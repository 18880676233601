import React from 'react';

import IMonitorLog from '../../models/Monitor/IMonitorLog';
import {getFormattedDate} from '../../utils/datetime';
import MonitorPingMessageBadge from '../Status/MonitorPingMessageBadge';
import {nv} from '../../utils/stringHelper';

interface IMonitorLogRowProps {
    item: IMonitorLog,
    tzString?: string|null,

}

const MonitorLogRow: React.FC<IMonitorLogRowProps> = ({item, tzString}) => {
    return (
        <tr>
            <td>{getFormattedDate(item.datetime, {format: 'DD.MM.YYYY, в HH:mm', tzString})}</td>
            <td>{item.status}</td>
            <td>
                <MonitorPingMessageBadge code={item.status} message={item.message} />
            </td>
            <td>{nv(item.request_time)}</td>
        </tr>
    );
};

export default MonitorLogRow;