import React from 'react';
import {ButtonProps} from 'rsuite/esm/Button/Button';
import {Button} from 'rsuite';


const SecondaryButton: React.FC<ButtonProps> = props => {
    return (
        <Button {...props} appearance="default">
            {props.children}
        </Button>
    );
};

export default SecondaryButton;