import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import {providesList} from '../rtk-query/RTKQueryHelper';
import ITelegramChat, {ITelegramChatAdd, ITelegramChatEdit} from '../models/ITelegramChat';
import IOrderBy from '../models/IOrderBy';

export const telegramChatAPI = createApi({
    reducerPath: 'telegramChatAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['TelegramChat', 'TelegramChatActive'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addTelegramChat: build.mutation<ITelegramChat, ITelegramChatAdd>({
            query: (data) => ({
                path: ApiUrls.TELEGRAM_CHATS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'TelegramChat', id: 'LIST' }, { type: 'TelegramChatActive', id: 'LIST' }],
        }),
        getTelegramChats: build.query<ITelegramChat[], IOrderBy|undefined>({
            query: (sort) => ({
                path: buildQuery(ApiUrls.TELEGRAM_CHATS(sort)),
            }),
            providesTags: (result) => providesList(result, 'TelegramChat'),
        }),
        getActiveTelegramChats: build.query<ITelegramChat[], void>({
            query: () => ({
                path: buildQuery(ApiUrls.TELEGRAM_CHATS(undefined, ['active=true'])),
            }),
            providesTags: (result) => providesList(result, 'TelegramChatActive'),
        }),
        getTelegramChat: build.query<ITelegramChat, number>({
            query: (id) => ({
                path: ApiUrls.TELEGRAM_CHAT(id),
            }),
            providesTags: (result, error, id) => [{ type: 'TelegramChat', id }],
        }),
        updateTelegramChat: build.mutation<ITelegramChat, ITelegramChatEdit>({
            query: ({id, ...data}) => ({
                path: ApiUrls.TELEGRAM_CHAT(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    telegramChatAPI.util.updateQueryData('getTelegramChat', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'TelegramChat', id: 'LIST' }, { type: 'TelegramChatActive', id: 'LIST' }],
        }),
        deleteTelegramChat: build.mutation<void,  number>({
            query: (id) => ({
                path: ApiUrls.TELEGRAM_CHAT(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'TelegramChat', id: 'LIST' },
                { type: 'TelegramChat', id },
                { type: 'TelegramChatActive', id: 'LIST' },
                { type: 'TelegramChatActive', id },
            ],
        }),
        sendTestNotify: build.mutation<void, number>({
            query: (id) => ({
                path: ApiUrls.TELEGRAM_CHAT_SEND_TEST_NOTIFY(id),
                method: 'POST',
                data: {},
            }),
        }),
    }),
});