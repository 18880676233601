import React from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {Pages} from '../../constants/pages';
import RefreshButton from '../UI/IconButton/RefreshButton';
import CreateMonitorButton from './CreateMonitorButton';
import CreateBatchMonitorButton from './CreateBatchMonitorButton';
import TransparentButton from '../UI/Button/TransparentButton';
import {setShowBatchOperations, showBatchOperationsSelector} from '../../redux/monitorSlice';


import {TypedDispatch} from '../../types';

import RemoveBatchMonitorButton from './RemoveBatchMonitorButton';

import MonitorFiltersButton from './MonitorsFilters/MonitorFiltersButton';

import MonitorsExportButton from './MonitorsExport/MonitorsExportButton';

import styles from './Monitor.module.scss';

interface IMonitorsToolbarProps {
    refetch: () => void;
}

const MonitorsToolbar: React.FC<IMonitorsToolbarProps> = ({refetch}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const showBatchOperations = useSelector(showBatchOperationsSelector);
    return (
        <div className={styles.head}>
            <div className={styles.head_title}>
                <h3 className={styles.h3}>{Pages.MONITORS}</h3>
                <RefreshButton
                    title="Обновить"
                    onClick={refetch}
                    className={styles.refresh_btn}
                /></div>
            <div className={styles.create_btn}>
                <CreateMonitorButton/>
            </div>
            <div className={styles.create_btn_batch}>
                <CreateBatchMonitorButton/>
            </div>
            <div className={styles.create_btn_batch}>
                <RemoveBatchMonitorButton/>
            </div>
            <div className={styles.batch_btn}>
                <TransparentButton
                    onClick={() => {
                        dispatch(setShowBatchOperations(!showBatchOperations));
                    }}
                >
                    Массовые операции
                </TransparentButton>
            </div>

            <div className={styles.filters_btn}>
                <MonitorFiltersButton />
            </div>

            <div className={styles.export_btn}>
                <MonitorsExportButton />
            </div>
        </div>
    );
};

export default MonitorsToolbar;