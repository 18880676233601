import React, {MouseEvent, useCallback, useState} from 'react';

import {toast} from 'react-toastify';

import {useDispatch} from 'react-redux';


import ITelegramChat from '../../models/ITelegramChat';
import TrashButton from '../UI/IconButton/TrashButton';
import {telegramChatAPI} from '../../api/telegramChatAPI';
import {TypedDispatch} from '../../types';
import {fetchMe} from '../../redux/authSlice';

import StatusToggle from '../UI/StatusToggler/StatusToggle';


import NotifyButton from '../UI/Button/NotifyButton';

import styles from './TelegramChats.module.scss';

interface ITelegramChatRowProps {
    item: ITelegramChat
}

const TelegramChatRow: React.FC<ITelegramChatRowProps> = ({item}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [deleteTelegramChat] = telegramChatAPI.useDeleteTelegramChatMutation();
    const [updateTelegramChat] = telegramChatAPI.useUpdateTelegramChatMutation();
    const [apiSendTestNotify]  = telegramChatAPI.useSendTestNotifyMutation();

    const [sendTestingNotifyState, setSendTestingNotifyState] = useState({
        disabled: !item.active,
        sending: false,
    });

    const onClickToggleStatus = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateTelegramChat({
                id: item.id,
                active: !item.active,
            }).unwrap();
            toast.info(item.active ? 'Чат деактивирован' : 'Чат активирован');
            if(item.active) {
                setSendTestingNotifyState({
                    disabled: true,
                    sending: false,
                });
            } else {
                setSendTestingNotifyState({
                    disabled: false,
                    sending: false,
                });
            }

        }catch (e) {}
    }, [item.active]);

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteTelegramChat(item.id).unwrap();
            dispatch(fetchMe());
            toast.info('Чат удален');
        }catch (e) {}
    }, []);

    const onClickNotifyButton = async() => {
        setSendTestingNotifyState({
            disabled: false,
            sending: true,
        });

        try {
            await apiSendTestNotify(item.id).unwrap();
            toast.info('Уведомление отправлено');
        } catch (e) {
        }

        // Блокировка повторных нажатий (защита от спама)
        setSendTestingNotifyState({
            disabled: true,
            sending: false,
        });
        // Разблокировка через минуту.
        setTimeout(() => {
            setSendTestingNotifyState({
                disabled: false,
                sending: false,
            });
        }, 1000 * 60);
    };

    return (
        <tr className={styles.row}>
            <td>{item.chat}</td>
            <td>{item.description}</td>
            <td><StatusToggle condition={item.active} onClick={onClickToggleStatus} /></td>
            <td><TrashButton onClick={onClickDelete} title="Удалить"/></td>
            <td>
                <NotifyButton
                    loading={sendTestingNotifyState.sending}
                    disabled={sendTestingNotifyState.disabled}
                    onClick={onClickNotifyButton}
                    className={styles.notifyBtn}>Отправить тестовое уведомление</NotifyButton>
            </td>
        </tr>
    );
};

export default TelegramChatRow;