import React, {MouseEvent} from 'react';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

import {To, useLocation, useNavigate} from 'react-router-dom';

import IconButton from './IconButton';

interface IBackButtonProps{
    to: To,
    text?: string,
}

const BackButton: React.FC<IBackButtonProps> = ({to,  text}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onIconButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        // Если история пуста (location.key === 'default'), то переходим по адресу страницы в "to".
        // А если известна предыдущая страница, то возвращаемся на нее
        const navigateTo = location.key === 'default' ? to : -1;
        // @ts-ignore
        navigate(navigateTo);
    };
    const buttonText = text ?? 'Назад';
    return (
        <IconButton icon={faArrowLeft} title={buttonText} text={buttonText} onClick={onIconButtonClick}/>
    );
};

export default BackButton;
