import React, {MouseEvent, ReactNode, useCallback} from 'react';

import {faEye, faPause, faPencil, faPlay, faTrash} from '@fortawesome/free-solid-svg-icons';

import {toast} from 'react-toastify';

import MoreActionsItem from '../UI/MoreActions/MoreActionsItem';
import MoreActionsDots from '../UI/MoreActions/MoreActionsDots';
import {monitorAPI} from '../../api/monitorAPI';
import IMonitorOutput from '../../models/Monitor/IMonitor';

interface IMonitorMenuProps {
    item: IMonitorOutput,
    // eslint-disable-next-line no-unused-vars
    onEditClick: (event: MouseEvent<HTMLTableRowElement>) => void,
    // eslint-disable-next-line no-unused-vars
    onLogClick: (event: MouseEvent<HTMLTableRowElement>) => void,
    // eslint-disable-next-line no-unused-vars
    onIncidentsClick: (event: MouseEvent<HTMLTableRowElement>) => void,
}

const MonitorMenu: React.FC<IMonitorMenuProps> = ({item, onEditClick, onLogClick, onIncidentsClick}) => {
    const [deleteMonitor] = monitorAPI.useDeleteMonitorMutation();
    const [updateMonitor] = monitorAPI.useUpdateMonitorMutation();
    const onClickActivate = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateMonitor({
                uuid: item.uuid,
                status: 'active',
            }).unwrap();
            toast.info('Монитор активирован');
        }catch (e){

        }

    }, [item.uuid]);

    const onClickPaused = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateMonitor({
                uuid: item.uuid,
                status: 'paused',
            }).unwrap();
            toast.info('Монитор поставлен на паузу');
        }catch (e){

        }
    }, [item.uuid]);

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteMonitor(item.uuid).unwrap();
            toast.info('Монитор удален');
        }catch (e){

        }
    }, []);

    const getActions = (): ReactNode[] => {
        const actions: ReactNode[] = [];
        if (item.status === 'active') {
            actions.push(
                <MoreActionsItem
                    key="off"
                    label="На паузу"
                    icon={faPause}
                    onClick={onClickPaused}
                />
            );
        } else if (item.status === 'paused') {
            actions.push(
                <MoreActionsItem
                    key="on"
                    label="Включить"
                    icon={faPlay}
                    onClick={onClickActivate}
                />
            );
        }

        actions.push(
            <MoreActionsItem
                key="edit"
                label="Редактировать"
                icon={faPencil}
                onClick={onEditClick}/>
        );

        actions.push(
            <MoreActionsItem
                key="incidents"
                label="Подробнее"
                icon={faEye}
                onClick={onIncidentsClick}/>
        );

        actions.push(
            <MoreActionsItem
                key="log"
                label="Журнал"
                icon={faEye}
                onClick={onLogClick}/>
        );

        actions.push(
            <MoreActionsItem
                key="trash"
                label="Удалить"
                icon={faTrash}
                onClick={onClickDelete}
            />
        );

        return actions;
    };

    return (
        <MoreActionsDots>
            {
                getActions().map(el => el)
            }
        </MoreActionsDots>
    );
};

export default React.memo(MonitorMenu);