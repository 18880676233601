import React from 'react';
import {Link} from 'react-router-dom';

import LogoOwner from '../Icons/LogoOwner';

import Logo from '../Icons/Logo';

import styles from './NotFoundPage.module.scss';

export default function NotFoundPage() {
    return (
        <div className={styles.container}>
            <div className={styles.logo}><Logo /></div>

            <div className={styles.content}>
                <h1 className={styles.title}>404</h1>
                <p className={styles.description}>Ошибка 404.<br/> Нет такой страницы!</p>
                <Link to={'/login'} className={styles.link_home}>На главную</Link>
            </div>
            <div className={styles.owner}>
                <LogoOwner />
                <span className={styles.owner_name}>brotherhood.software</span>
            </div>
        </div>
    );
}