// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableBox_table_box__XJY7Y {
  background-color: #f6f6f6;
  padding: 25px 0 0 0;
  width: 100%;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .TableBox_table_box__XJY7Y {
    width: calc(100vw - 265px);
  }
}
@media (max-width: 960px) {
  .TableBox_table_box__XJY7Y {
    width: calc(100vw - 10px);
    padding: 25px 5px 0 5px;
  }
}
@media (max-width: 769px) {
  .TableBox_table_box__XJY7Y {
    width: calc(100vw - 5px);
  }
}
.TableBox_table_box__XJY7Y .block_header {
  padding: 0 30px;
  margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Box/TableBox/TableBox.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EANF;IAOI,0BAAA;EAEF;AACF;AAAE;EAVF;IAWI,yBAAA;IACA,uBAAA;EAGF;AACF;AADE;EAfF;IAgBI,wBAAA;EAIF;AACF;AAFE;EACE,eAAA;EACA,mBAAA;AAIJ","sourcesContent":[".table_box{\n  background-color: #f6f6f6;\n  padding: 25px 0 0 0;\n  width: 100%;\n  max-width:100%;\n\n  @media (max-width: 1200px) {\n    width: calc(100vw - 265px);\n  }\n\n  @media (max-width: 960px) {\n    width: calc(100vw - 10px);\n    padding: 25px 5px 0 5px;\n  }\n\n  @media (max-width: 769px) {\n    width: calc(100vw - 5px);\n  }\n\n  :global(.block_header){\n    padding: 0 30px;\n    margin-bottom: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_box": `TableBox_table_box__XJY7Y`
};
export default ___CSS_LOADER_EXPORT___;
