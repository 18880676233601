import React, {useCallback, useState} from 'react';

import {useSelector} from 'react-redux';

import {useSearchParams} from 'react-router-dom';

import {userSelector} from '../../redux/authSlice';
import RefreshButton from '../UI/IconButton/RefreshButton';
import PaymentRow from './PaymentRow';
import {Pages} from '../../constants/pages';
import LoaderCentered from '../UI/Loader/LoaderCentered';
import Table from '../UI/Table/Table';
import {paymentAPI} from '../../api/paymentAPI';
import {getPollingInterval} from '../../utils/pollingHelper';
import TableBox from '../UI/Box/TableBox/TableBox';

import YesNo from '../UI/YesNo/YesNo';
import DisableRecurring from './DisableRecurring';

import IOrderBy from '../../models/IOrderBy';

import styles from './Payments.module.scss';


const Payments: React.FC = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') ?? 1);
    const user = useSelector(userSelector);
    const [sort, setSort] = useState<IOrderBy>();

    const {data: pagination, isLoading, refetch, isFetching} = paymentAPI.useGetPaymentsQuery({page, sort}, {
        pollingInterval: getPollingInterval(),
    });

    const onRefreshButtonClick = useCallback(() => {
        refetch();
    }, []);

    return (
        <TableBox>
            <div className={styles.head}>
                <LoaderCentered active={isFetching}/>
                <div className={styles.header}>
                    <h3 className={styles.title}>{Pages.PAYMENTS}</h3>
                    <RefreshButton
                        title="Обновить"
                        onClick={onRefreshButtonClick}
                    />
                </div>
                <div className={styles.text}>
                    <YesNo condition={user?.is_recurring ?? false}/>&nbsp;
                    Автоплатеж{!user?.is_recurring && ' не'} подключен
                </div>
                {
                    user?.is_recurring
                    && <DisableRecurring/>
                }
            </div>

            <Table
                columns={[
                    {label: '№', sort: 'number'},
                    {label: 'Дата создания', sort: 'created_at'},
                    {label: 'Сумма', sort: 'sum'},
                    {label: 'Тариф', sort: 'tariff.name'},
                    {label: 'Статус', sort: 'status'},
                    {label: 'Дата оплаты', sort: 'payed_at'},
                    {label: ''},
                ]}
                items={pagination?.items}
                rowFactory={item => <PaymentRow
                    key={item.number}
                    item={item}
                    tzString={user?.timezone}
                />}
                isLoading={isLoading}
                pagination={pagination}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>

    );
};

export default Payments;