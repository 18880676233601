import React, {ReactNode} from 'react';

import styles from './NavigationBanner.module.scss';

interface INavigationBannerProps {
    icon?: ReactNode,
    children: ReactNode,
}

const NavigationBanner: React.FC<INavigationBannerProps> = ({icon, children}) => {
    return (
        <div className={styles.wrapper}>
            {
                icon
                && <div>
                    {icon}
                </div>
            }
            {children}
        </div>
    );
};

export default NavigationBanner;