// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelegramChats_head__RPsA6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 12px;
}

.TelegramChats_row__1DfPv {
  cursor: unset !important;
}

.TelegramChats_notifyBtn__CoTwd {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/TelegramChat/TelegramChats.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".head{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 30px;\n  margin-bottom: 12px;\n}\n\n.row{\n  cursor: unset!important;\n}\n\n.notifyBtn {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `TelegramChats_head__RPsA6`,
	"row": `TelegramChats_row__1DfPv`,
	"notifyBtn": `TelegramChats_notifyBtn__CoTwd`
};
export default ___CSS_LOADER_EXPORT___;
