import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';

import {ITariffVariant} from '../models/ITariffVariant';
import {ApiUrls} from '../constants/urls';
import IOrderBy from '../models/IOrderBy';
import {providesList} from '../rtk-query/RTKQueryHelper';

export type EditTariffVariantType = {
    period: number;
    discount: number;
    tariffs: string[];
};

export const tariffVariantAPI = createApi({
    reducerPath: 'tariffVariantAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['TariffVariant'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addTariffVariant: build.mutation<ITariffVariant, EditTariffVariantType>({
            query: (data) => ({
                path: ApiUrls.TARIFF_VARIANTS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'TariffVariant', id: 'LIST' }],
        }),
        getTariffVariants: build.query<ITariffVariant[], IOrderBy|undefined>({
            query: (sort) => ({
                path: ApiUrls.TARIFF_VARIANTS(sort),
            }),
            providesTags: (result) => providesList(result, 'TariffVariant'),
        }),
        updateTariffVariant: build.mutation<ITariffVariant, EditTariffVariantType & { id: number }>({
            query: ({id, ...data}) => ({
                path: ApiUrls.TARIFF_VARIANT(id),
                method: 'PUT',
                data,
            }),
            invalidatesTags: [{ type: 'TariffVariant', id: 'LIST' }],
        }),
        deleteTariffVariant: build.mutation<void, number>({
            query: (id) => ({
                path: ApiUrls.TARIFF_VARIANT(id),
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'TariffVariant', id: 'LIST' }],
        }),
    }),
});