import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';

import {registerUserLoadingSelector} from '../redux/authSlice';
import Registration from '../components/Auth/Registration';


const RegistrationPage: React.FC = () => {

    const isLoading = useSelector(registerUserLoadingSelector);

    return (
        <BlankLayout pageTitle="Регистрация" isLoading={isLoading}>
            <Registration/>
        </BlankLayout>
    );
};

export default RegistrationPage;