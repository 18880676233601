// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInformer_wrapper__tkZ6F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 0 20px;
}

.UserInformer_text__OJ1y9 {
  color: #242424;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.UserInformer_icon__1Ajvf svg {
  height: 20px;
  width: 20px;
}

.UserInformer_exit_btn__dFi3G:hover svg path {
  fill: #1DB5B6 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UserInformer/UserInformer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,cAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAGI;EACI,YAAA;EACA,WAAA;AAAR;;AAII;EACI,wBAAA;AADR","sourcesContent":[".wrapper{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    width: 100%;\n    padding: 0 20px;\n}\n\n.text{\n    color:  #242424;\n    font-family: Inter, sans-serif;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 20px;\n}\n\n.icon{\n    svg{\n        height: 20px;\n        width: 20px;\n    }\n}\n.exit_btn:hover{\n    & svg path{\n        fill: #1DB5B6!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UserInformer_wrapper__tkZ6F`,
	"text": `UserInformer_text__OJ1y9`,
	"icon": `UserInformer_icon__1Ajvf`,
	"exit_btn": `UserInformer_exit_btn__dFi3G`
};
export default ___CSS_LOADER_EXPORT___;
