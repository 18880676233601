import React from 'react';

import IIncident from '../../models/IIncident';
import Incident from './Incident/Incident';
import MainModal from '../UI/Modal/MainModal';

interface IIncidentModalProps {
    open: boolean,
    item: IIncident,
    handleClose: () => void,
    tzString?: string | null,
}

const IncidentModal: React.FC<IIncidentModalProps> = ({open, item, handleClose, tzString}) => {
    return (
        <MainModal
            header={<>Инцидент монитора <b>{item.monitor.name}</b></>}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <Incident item={item} tzString={tzString}/>
        </MainModal>
    );
};

export default IncidentModal;