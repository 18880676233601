import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {toast} from 'react-toastify';

import {ApiUrls} from '../../constants/urls';
import createHttpRequest from '../../utils/http';
import IChangeEmailForm from '../../models/forms/IChangeEmailForm';


import ModalBodyWrapper from '../UI/Modal/ModalBodyWrapper';
import FormGroupWithError from '../UI/FormGroup/FormGroupWithError';
import EmailInput from '../UI/Input/EmailInput';
import PasswordInput from '../UI/Input/PasswordInput';
import PrimaryButton from '../UI/Button/PrimaryButton';

import styles from './Profile.module.scss';

const ChangeEmailForm: React.FC = () => {
    const {register, handleSubmit, formState, control} = useForm<IChangeEmailForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSent, setIsFormSent] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const onSubmit: SubmitHandler<IChangeEmailForm> = async(data) => {
        if(isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            await createHttpRequest({
                method: 'PUT',
                path: ApiUrls.USER_CHANGE_EMAIL,
                data,
                errorMessage: 'При изменении E-mail произошла ошибка',
            });

            toast(`Отправили письмо на ${data.email}`);
            setNewEmail(data.email);
            setIsFormSent(true);

        } catch (e) {
        }

        setIsLoading(false);
    };

    return (
        <>
            {
                isFormSent ? <div>
                    <p className={styles.title}>
                        На указанный вами адрес <b>{newEmail}</b> отправлено письмо,
                        содержащее ссылку для изменения E-mail.
                    </p>
                </div> :  <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <ModalBodyWrapper>
                        <FormGroupWithError>
                            <EmailInput
                                name="email"
                                register={register}
                                control={control}
                                required
                                placeholder="Новый E-mail"
                                placeholderToLabel
                            />
                        </FormGroupWithError>
                        <FormGroupWithError
                            error={errors?.password?.message}
                        >
                            <PasswordInput
                                register={register}
                                name="password"
                                placeholder="Пароль"
                                placeholderToLabel
                                control={control}
                            />
                        </FormGroupWithError>

                        <div className="form-group">
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                className="full-width"
                                disabled={!(isValid && isDirty)}
                            >
                                Изменить E-mail
                            </PrimaryButton>

                        </div>

                    </ModalBodyWrapper>
                </form>
            }
        </>
    );
};

export default ChangeEmailForm;