// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorStatus_icon__6nfbx {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
}
.MonitorStatus_icon__6nfbx svg {
  display: block;
  width: 24px;
  height: 24px;
  transform: translate(6px, 6px);
}

.MonitorStatus_content__r2wZ\\+ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.MonitorStatus_content__r2wZ\\+ .MonitorStatus_counter__Q6Zqb {
  font-weight: 600;
  transition: font-size 0.3s ease;
  line-height: 36px;
  letter-spacing: -1%;
  min-width: 60px;
  text-align: center;
}
.MonitorStatus_content__r2wZ\\+ .MonitorStatus_text__bsBzn {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/SummaryMonitors/MonitorStatus/MonitorStatus.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AAAF;AAEE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AADF;AAGE;EACE,gBAAA;EACA,+BAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAFJ","sourcesContent":["\n.icon {\n  width: 36px;\n  height: 36px;\n  border-radius: 50%;\n  position: absolute;\n  top: -5px;\n  left: -5px;\n\n  svg {\n    display: block;\n    width: 24px;\n    height: 24px;\n    transform: translate(6px, 6px);\n  }\n\n}\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 2px;\n\n  .counter {\n    font-weight: 600;\n    transition: font-size 0.3s ease;\n    line-height: 36px;\n    letter-spacing: -1%;\n    min-width: 60px;\n    text-align: center;\n  }\n\n  .text {\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `MonitorStatus_icon__6nfbx`,
	"content": `MonitorStatus_content__r2wZ+`,
	"counter": `MonitorStatus_counter__Q6Zqb`,
	"text": `MonitorStatus_text__bsBzn`
};
export default ___CSS_LOADER_EXPORT___;
