import React from 'react';

import {Col, Grid, Row} from 'rsuite';

import {isEmpty} from 'lodash';

import {generatePath} from 'react-router-dom';

import ArrowCircleRight from '@rsuite/icons/legacy/ArrowCircleRight';

import ArrowCircleDown from '@rsuite/icons/legacy/ArrowCircleDown';

import IIncident from '../../../models/IIncident';

import IncidentStatusBadge from '../../Status/IncidentStatusBadge';

import {getFormattedDate} from '../../../utils/datetime';


import TransparentLinkButton from '../../UI/Button/TransparentLinkButton';

import {AppUrls} from '../../../constants/urls';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import styles from './Incident.module.scss';

interface IIncidentProps {
    item: IIncident,
    tzString?: string | null,
}

const Incident: React.FC<IIncidentProps> = ({item, tzString}) => {
    return (
        <ModalBodyWrapper>
            <Grid fluid>
                <Row gutter={16}>
                    <Col xs={12}>
                        <div className="d-flex justify-content-start p-2">
                            {
                                item?.content_file
                            && <TransparentLinkButton
                                href={item.content_file}
                            >
								Полный ответ
                                <ArrowCircleDown width="20px" height="20px"/>
                            </TransparentLinkButton>
                            }
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="d-flex justify-content-end p-2">
                            <TransparentLinkButton
                                href={generatePath(AppUrls.MONITOR_DETAILS, {monitorId: item.monitor.uuid})}
                            >
                            Подробности монитора
                                <ArrowCircleRight width="20px" height="20px"/>
                            </TransparentLinkButton>
                        </div>
                    </Col>
                </Row>

                <Row gutter={16} className={styles.mb2}>
                    <Col xs={8}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Статус
                            </div>
                            <div>
                                <IncidentStatusBadge resolved={item.resolved}/>
                            </div>
                        </div>
                    </Col>

                    <Col xs={8}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Начало
                            </div>
                            <div>
                                {getFormattedDate(
                                    item.created_at,
                                    {format: 'DD.MM.YYYY, в HH:mm', tzString},
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col xs={8}>
                        <div className="show-col">
                            <div className={styles.info_block}>
                            Длительность
                            </div>
                            <div>
                                {item.duration_formatted}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.mb2}>
                    <Col xs={24}>
                        <div className={styles.well}>
                            <div className={styles.info_block}>
                            Причина:
                            </div>
                            <div className={styles.detail}>
                                {item.error}
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    item.parsed_errors
                && !isEmpty(item.parsed_errors)
                && <Row className={styles.mb2}>
                    <Col xs={24}>
                        <div className={styles.well}>
                            <div className={styles.info_block}>
								Обнаруженные проблемы:
                            </div>
                            <div>
                                <ol>
                                    {item.parsed_errors.map((parsedError) => (
                                        <li key={parsedError}>{parsedError}</li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </Col>
                </Row>
                }

                {
                    item.headers
                && !isEmpty(item.headers)
                && <Row>
                    <Col xs={24}>
                        <div className={styles.well}>
                            <div className={styles.info_block}>
								Заголовки ответа
                            </div>
                            <pre>
                                {JSON.stringify(item.headers, null, 2)}
                            </pre>
                        </div>
                    </Col>
                </Row>
                }
            </Grid>
        </ModalBodyWrapper>
    );
};

export default Incident;