import {configureStore} from '@reduxjs/toolkit';

import rootReducer from 'redux/reducers';

import {monitorAPI} from './api/monitorAPI';
import {incidentAPI} from './api/incidentAPI';
import {warningAPI} from './api/warningAPI';
import {tariffAPI} from './api/tariffAPI';
import {telegramChatAPI} from './api/telegramChatAPI';
import {paymentAPI} from './api/paymentAPI';
import {userAPI} from './api/userAPI';


export const makeStore = (preloadedState: object | null = null) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(monitorAPI.middleware)
                .concat(incidentAPI.middleware)
                .concat(warningAPI.middleware)
                .concat(tariffAPI.middleware)
                .concat(telegramChatAPI.middleware)
                .concat(paymentAPI.middleware)
                .concat(userAPI.middleware),
        ...preloadedState ? {preloadedState} : {},
    });
};

export type RootState = ReturnType<typeof rootReducer>;