import {createApi} from '@reduxjs/toolkit/query/react';

import {AxiosRequestHeaders} from 'axios';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';
import IMonitorOutput, {IMonitorItem} from '../models/Monitor/IMonitor';

import {buildQuery} from '../utils/query';
import {providesListUuid} from '../rtk-query/RTKQueryHelper';
import UpdateMonitorType from '../models/Monitor/UpdateMonitorType';
import CreateMonitorType from '../models/Monitor/CreateMonitorType';
import IMonitorLog from '../models/Monitor/IMonitorLog';
import IHydraResponse from '../models/HydraResponse';
import IPagination from '../models/IPagination';
import {paginationAdapter} from '../utils/paginationHelper';
import IMonitorAvgResponseTime from '../models/Monitor/IMonitorAvgResponseTime';
import IMonitorIncidentRange from '../models/Monitor/MonitorIncidentRange';
import ISummaryMonitors from '../models/ISummaryMonitors';
import ICollectionParams from '../models/ICollectionParams';

export const monitorAPI = createApi({
    reducerPath: 'monitorAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Monitor', 'SummaryMonitors'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addMonitor: build.mutation<IMonitorOutput, CreateMonitorType>({
            query: (data) => ({
                path: ApiUrls.MONITORS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'Monitor', id: 'LIST' }, 'SummaryMonitors'],
        }),
        getMonitors: build.query<IPagination<IMonitorOutput>, ICollectionParams>({
            query: ({page, sort, filters}) => ({
                path: buildQuery(ApiUrls.MONITORS(page, sort, filters)),
                headers: {Accept: 'application/ld+json'} as unknown as AxiosRequestHeaders,
            }),
            providesTags: (result) => providesListUuid(result?.items, 'Monitor'),
            transformResponse(
                rawResult: IHydraResponse<IMonitorOutput>
            ): Promise<IPagination<IMonitorOutput>> | IPagination<IMonitorOutput> {
                return paginationAdapter(rawResult);
            },
        }),
        getMonitor: build.query<IMonitorItem, string>({
            query: (uuid) => ({
                path: ApiUrls.MONITOR(uuid),
            }),
            providesTags: (result, error, uuid) => [{ type: 'Monitor', id: uuid }],
        }),
        getMonitorLogs: build.query<IMonitorLog[], string>({
            query: (uuid) => ({
                path: ApiUrls.MONITOR_LOGS(uuid),
            }),
        }),
        getMonitorAvgResponseTime: build.query<IMonitorAvgResponseTime[], string>({
            query: (uuid) => ({
                path: ApiUrls.MONITOR_AVG_RESPONSE_TIME(uuid),
            }),
        }),
        getMonitorLastDayIncidentsRanges: build.query<IMonitorIncidentRange[], string>({
            query: (uuid) => ({
                path: ApiUrls.MONITOR_LAST_DAY_INCIDENTS_RANGES(uuid),
            }),
        }),
        updateMonitor: build.mutation<IMonitorOutput, UpdateMonitorType>({
            query: ({uuid, ...data}) => ({
                path: ApiUrls.MONITOR(uuid ?? ''),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ uuid, ...patch }, { dispatch }) {
                dispatch(
                    monitorAPI.util.updateQueryData('getMonitor', uuid ?? '', (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'Monitor', id: 'LIST' }, 'SummaryMonitors'],
        }),
        deleteMonitor: build.mutation<void,  string>({
            query: (uuid) => ({
                path: ApiUrls.MONITOR(uuid),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, uuid) => [
                { type: 'Monitor', id: 'LIST' },
                { type: 'Monitor', id: uuid },
                'SummaryMonitors',
            ],
        }),
        getDomainOfMonitor: build.query<{domain: string}, string>({
            query: (uuid) => ({
                path: ApiUrls.MONITORS_UNSUBSCRIBE(uuid),
                method: 'GET',
                isAnonymous: true,
            }),
        }),
        unsubscribeMonitor: build.query<{success: boolean}, string>({
            query: (uuid) => ({
                path: ApiUrls.MONITORS_UNSUBSCRIBE(uuid),
                method: 'POST',
                isAnonymous: true,
                data: {},
            }),
        }),
        getSummaryMonitors: build.query<ISummaryMonitors, void>({
            query: () => ({
                path: ApiUrls.SUMMARY_MONITORS,
            }),
            providesTags: ['SummaryMonitors'],
        }),
    }),
});