import React from 'react';

import cn from 'classnames';

import {truncate} from 'lodash';

import {colorMonitorPingMessage} from '../../utils/colors';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './StatusBadge.module.scss';

interface IMonitorPingMessageBadgeProps {
    code: number | null,
    message: string,
}

const MonitorPingMessageBadge: React.FC<IMonitorPingMessageBadgeProps> = ({
    code,
    message,
}) => {
    if (code === null) {
        return <>-</>;
    }
    const backgroundColor = colorMonitorPingMessage(Number(code));
    const color = colorMonitorPingMessage(Number(code));
    const currentBreakpoint = useBreakpoint();
    return currentBreakpoint === 'large'
        ? <div className={cn([styles.status, 'badge'])} style={{backgroundColor}}>
            {truncate(message, {length: 40})}
        </div>
        : <div className={cn([styles.status, 'badge'])} style={{color}}>
            {truncate(message, {length: 40})}
        </div>;
};

export default MonitorPingMessageBadge;