/* eslint-disable max-len */
import React from 'react';

const Settings: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0745 5.88583L13.1861 6.15369L13.474 6.18968L15.0525 6.387C15.3027 6.41827 15.4905 6.63097 15.4905 6.88313L15.4905 9.11757C15.4905 9.36973 15.3027 9.58243 15.0525 9.61371L13.4787 9.81044L13.1908 9.84643L13.0791 10.1142C12.9886 10.3315 12.883 10.5455 12.762 10.7552C12.641 10.9648 12.5084 11.1633 12.3655 11.3503L12.1894 11.5809L12.3021 11.8482L12.9187 13.3096C13.0167 13.5419 12.9264 13.8109 12.708 13.9369L10.7729 15.0542C10.5546 15.1802 10.2765 15.124 10.1243 14.9229L9.16415 13.6545L8.98902 13.4232L8.70125 13.4605C8.23289 13.5212 7.75549 13.5219 7.28112 13.4597L6.99289 13.4219L6.81744 13.6537L5.85668 14.9229C5.70449 15.124 5.4264 15.1802 5.20802 15.0541L3.27295 13.9369C3.05457 13.8109 2.96425 13.5419 3.06227 13.3096L3.68438 11.835L3.7969 11.5683L3.62166 11.3379C3.33456 10.9605 3.09966 10.5497 2.92021 10.1172L2.80881 9.84875L2.5204 9.8127L0.928468 9.61371C0.678254 9.58243 0.490486 9.36973 0.490485 9.11757L0.490485 6.88313C0.490486 6.63097 0.678254 6.41827 0.928469 6.38699L2.52426 6.18752L2.81142 6.15163L2.92334 5.88476C3.0128 5.67144 3.1168 5.46117 3.23572 5.25519C3.35465 5.0492 3.48474 4.85401 3.62475 4.66987L3.79991 4.43951L3.68742 4.17288L3.06227 2.69114C2.96425 2.45881 3.05457 2.18985 3.27295 2.06377L5.20802 0.946553C5.4264 0.820472 5.70449 0.876734 5.85668 1.07779L6.82498 2.35695L7.00041 2.58869L7.2886 2.55093C7.75287 2.4901 8.2261 2.48812 8.69651 2.54804L8.98365 2.58462L9.15835 2.35383L10.1243 1.07779C10.2765 0.876734 10.5546 0.820472 10.7729 0.946553L12.708 2.06377C12.9264 2.18985 13.0167 2.45882 12.9187 2.69114L12.2999 4.15779L12.1869 4.42562L12.3638 4.65633C12.6548 5.03604 12.8928 5.44988 13.0745 5.88583Z" stroke="currentColor"/>
            <path d="M8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8C10.5 9.38071 9.38071 10.5 8 10.5Z" stroke="currentColor"/>
        </svg>

    );
};

export default Settings;