// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Progress_wrapper__yywcO {
  position: relative;
  height: 7px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.Progress_full_bar__s43mG {
  background: #e8220c;
  background: linear-gradient(90deg, #FE4A4A 0%, #FFC700 44.8%, #05FF00 100%);
  height: 100%;
}

.Progress_empty__2fr9f {
  height: 12px;
  border-radius: 6px;
  background: #b5b2b2;
  background: linear-gradient(180deg, #b5b2b2 65%, #323230 120%);
  position: absolute;
  right: 0;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Progress/Progress.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,4BAAA;AACJ;;AACA;EACI,mBAAA;EACA,2EAAA;EACA,YAAA;AAEJ;;AAEA;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AACJ","sourcesContent":[".wrapper{\n    position: relative;\n    height: 7px;\n    overflow: hidden;\n    border-radius: 12px 12px 0 0;\n}\n.full_bar{\n    background: rgb(232,34,12);\n    background: linear-gradient(90deg, #FE4A4A 0%, #FFC700 44.8%, #05FF00 100%);\n    height: 100%;\n}\n\n\n.empty{\n    height: 12px;\n    border-radius: 6px;\n    background: rgb(181,178,178);\n    background: linear-gradient(180deg, rgba(181,178,178,1) 65%, rgba(50,50,48,1) 120%);\n    position: absolute;\n    right: 0;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Progress_wrapper__yywcO`,
	"full_bar": `Progress_full_bar__s43mG`,
	"empty": `Progress_empty__2fr9f`
};
export default ___CSS_LOADER_EXPORT___;
