import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import MonitorBatchCreateForm from './MonitorForm/MonitorBatchCreateForm';

interface ICreateBatchMonitorModalProps {
    open: boolean,
    handleClose: () => void;
}

const CreateBatchMonitorModal: React.FC<ICreateBatchMonitorModalProps> = ({open,  handleClose}) => {
    return <MainModal
        header="Массовое добавление мониторов"
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <MonitorBatchCreateForm handleClose={handleClose} />
    </MainModal>;
};

export default CreateBatchMonitorModal;