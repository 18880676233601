import React from 'react';

import {useSelector} from 'react-redux';

import {useSearchParams} from 'react-router-dom';

import MonitorRow from './MonitorRow';
import {userSelector} from '../../redux/authSlice';

import LoaderCentered from '../UI/Loader/LoaderCentered';

import Table from '../UI/Table/Table';

import {monitorAPI} from '../../api/monitorAPI';
import {getPollingInterval} from '../../utils/pollingHelper';

import TableBox from '../UI/Box/TableBox/TableBox';

import useBreakpoint from '../../hooks/useBreakpoint';

import {showBatchOperationsSelector} from '../../redux/monitorSlice';

import MonitorBatchOperations from './MonitorBatchOperations/MonitorBatchOperations';


import MonitorsToolbar from './MonitorsToolbar';
import {getMonitorsFilters, getOrderBy, objectToArray} from '../../utils/filterHelper';

const Monitors: React.FC = () => {

    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') ?? 1);
    const filters = getMonitorsFilters(searchParams);
    const user = useSelector(userSelector);
    const currentBreakpoint = useBreakpoint();
    const showBatchOperations = useSelector(showBatchOperationsSelector);

    const sort = getOrderBy(searchParams, 'id', 'desc');

    const {
        data: pagination,
        isLoading,
        refetch,
        isFetching,
    } = monitorAPI.useGetMonitorsQuery({page, sort, filters: objectToArray(filters)}, {
        pollingInterval: getPollingInterval(),
    });

    let columns = [
        {label: 'Название', sort: 'name'},
        {label: 'URL', sort: 'url'},
        {label: 'Интервал, мин', sort: 'checkInterval'},
        {label: 'Состояние', sort: 'status'},
        {label: 'Последний результат', sort: 'lastCode'},
        {label: 'SSL годен до', sort: 'sslActiveTo'},
        {label: 'Домен зарегистрирован до', sort: 'domainActiveTo'},
        {label: 'Редирект на https', sort: 'sslRedirect'},
        {label: ''},
    ];

    if (currentBreakpoint !== 'large') {
        columns[0].label = 'Название/URL';
        columns.splice(1, 1);
    }

    if (showBatchOperations) {
        columns = [{label: ''}, ...columns];
    }

    return (
        <TableBox>
            <LoaderCentered active={isFetching}/>
            <MonitorsToolbar refetch={refetch}/>
            <MonitorBatchOperations pagination={pagination}/>
            <Table
                columns={columns}
                items={pagination?.items}
                rowFactory={(item) =>
                    <MonitorRow
                        key={item.uuid}
                        item={item}
                        user={user}
                    />
                }
                isLoading={isLoading}
                pagination={pagination}
                sort={sort}
                sortToSearchParams={true}
            />
        </TableBox>
    );
};

export default Monitors;