import React, {MouseEvent, useCallback, useState} from 'react';

import {generatePath, useNavigate} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';

import cn from 'classnames';

import IMonitorOutput from '../../models/Monitor/IMonitor';
import MonitorStatusBadge from '../Status/MonitorStatusBadge';
import MonitorMenu from './MonitorMenu';
import MonitorModal from './MonitorModal';
import {AppUrls} from '../../constants/urls';
import MonitorPingMessageBadge from '../Status/MonitorPingMessageBadge';

import IUserOutput from '../../models/IUser';

import {monitorSelectedIdsSelector, showBatchOperationsSelector, toggleOne} from '../../redux/monitorSlice';

import Checkbox from '../UI/Checkbox/Checkbox';

import useBreakpoint from '../../hooks/useBreakpoint';

import HintPremium from '../UI/Hint/HintPremium';

import YesNo from '../UI/YesNo/YesNo';

import DateWithColorWarnings from '../UI/Date/DateWithColorWarnings/DateWithColorWarnings';

import OpenLinkNewWindow from '../UI/IconButton/OpenLinkNewWindow';

import styles from './Monitor.module.scss';

interface IMonitorRowProps {
    item: IMonitorOutput,
    user: IUserOutput | null,
}

const MonitorRow: React.FC<IMonitorRowProps> = ({item, user}) => {
    const dispatch = useDispatch();
    const tzString = user?.timezone;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedIds = useSelector(monitorSelectedIdsSelector);
    const showCheckbox = useSelector(showBatchOperationsSelector);

    const onClickCheckbox = () => {
        dispatch(toggleOne(item.uuid));
    };
    const currentBreakpoint = useBreakpoint();

    const handleClose = () => setIsModalOpen(false);

    const onIncidentsClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        navigate(generatePath(AppUrls.MONITOR_DETAILS, {monitorId: item.uuid}));
    }, [item.uuid]);

    const onLogClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        navigate(generatePath(AppUrls.MONITOR_LOGS, {monitorId: item.uuid}));
    }, [item.uuid]);

    const onEditClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const onTdClick = useCallback((event: MouseEvent<HTMLTableDataCellElement>) => {
        event.stopPropagation();
    }, []);
    
    const sslRedirect = (null !== item.ssl_redirect && typeof item.ssl_redirect !== 'undefined')
        ? <YesNo condition={item.ssl_redirect} />
        : <>-</>;

    return (
        <>
            <tr className={cn(styles.clickable, styles.row)} onClick={onIncidentsClick}>
                {
                    showCheckbox
                    && <td onClick={e => e.stopPropagation()}>
                        <Checkbox
                            onChange={onClickCheckbox}
                            checked={selectedIds.includes(item.uuid)}
                        />
                    </td>
                }
                {currentBreakpoint === 'large' ? (
                    <>
                        <td>{item.name}</td>
                        <td onClick={onTdClick}>
                            <OpenLinkNewWindow
                                url={item.url_human_readable}
                                className={styles.show_on_hover}
                            />
                        </td>
                    </>
                ) : (
                    <td onClick={onTdClick}>
                        <strong>{item.name}/</strong><br/>
                        <OpenLinkNewWindow url={item.url_human_readable}/>
                    </td>
                )}
                <td>{item.check_interval}</td>
                <td>
                    <MonitorStatusBadge status={item.status}/>
                </td>
                <td>
                    <MonitorPingMessageBadge
                        code={item.last_code}
                        message={item.last_message}
                    />
                </td>
                <td>
                    {
                        user?.tariff.monitor_ssl_enabled
                            ? <DateWithColorWarnings
                                dateString={item.ssl_active_to}
                                options={{format: 'DD.MM.YYYY', tzString}}
                            />
                            : <HintPremium condition={'monitor_ssl_enabled'} />
                    }
                </td>
                <td>
                    {
                        user?.tariff.monitor_domain_watch_enabled
                            ? <DateWithColorWarnings
                                dateString={item.domain_active_to}
                                options={{format: 'DD.MM.YYYY', tzString}}
                            />
                            : <HintPremium condition={'monitor_domain_watch_enabled'} />
                    }
                </td>
                <td>
                    {
                        user?.tariff.monitor_ssl_redirect_watch_enabled
                            ? sslRedirect
                            : <HintPremium condition={'monitor_ssl_redirect_watch_enabled'} />
                    }
                </td>
                <td onClick={onTdClick}>
                    <div className="d-flex justify-content-center">
                        <MonitorMenu
                            item={item}
                            onEditClick={onEditClick}
                            onLogClick={onLogClick}
                            onIncidentsClick={onIncidentsClick}
                        />
                    </div>
                </td>
            </tr>
            <MonitorModal open={isModalOpen} item={item} handleClose={handleClose}/>
        </>
    );
};

export default MonitorRow;