import React from 'react';

import {Button} from 'rsuite';

import {ButtonProps} from 'rsuite/esm/Button/Button';

import cn from 'classnames';

import styles from './Button.module.scss';

const PrimaryButton: React.FC<ButtonProps> = props => {
    return (
        <Button {...props} appearance="primary"
            className={cn(
                'btn',
                {[styles.button_submit_disabled]: props.disabled},
                props.className
            )}>
            {props.children}
        </Button>
    );
};

export default PrimaryButton;