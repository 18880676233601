import React from 'react';
import cn from 'classnames';

import LinkButton, {LinkButtonProps} from './LinkButton';

import styles from './Button.module.scss';


const TransparentLinkButton : React.FC<LinkButtonProps> = ({className, children, ...rest}) => {
    return (
        <LinkButton
            className={cn(
                'btn',
                'text-decoration-none',
                'rs-btn',
                'rs-btn-ghost',
                styles.transparent,
                className,
            )}
            {...rest}
        >
            {children}
        </LinkButton>
    );
};

export default React.memo(TransparentLinkButton);