import React, {useState} from 'react';

import Table from '../UI/Table/Table';
import TableBox from '../UI/Box/TableBox/TableBox';

import {telegramChatAPI} from '../../api/telegramChatAPI';

import TelegramChatRow from './TelegramChatRow';

import CreateTelegramChatButton from './CreateTelegramChatButton';

import IOrderBy from '../../models/IOrderBy';

import styles from './TelegramChats.module.scss';

const TelegramChats: React.FC = () => {
    const [sort, setSort] = useState<IOrderBy>();
    const {data: items, isLoading} = telegramChatAPI.useGetTelegramChatsQuery(sort);
    return (
        <TableBox>
            <div className={styles.head}>
                <h3>Telegram-чаты для уведомлений</h3>
                <CreateTelegramChatButton/>
            </div>

            <Table
                columns={[
                    {label: 'ID чата', sort: 'id'},
                    {label: 'Описание', sort: 'description'},
                    {label: 'Активен', sort: 'active'},
                    {label: ''},
                    {label: ''},
                ]}
                items={items}
                rowFactory={item => <TelegramChatRow
                    key={item.id}
                    item={item}
                />}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>
    );
};

export default TelegramChats;