import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';

import cn from 'classnames';

import Navigation from '../Navigation/Navigation';

import styles from './NavigationToggle.module.scss';

const NavigationToggle: React.FC = () => {
    const [opened, setOpened] = useState(false);
    return (
        <>
            <a className={cn(styles.toggle, 'rs-btn rs-btn-primary')}
                href="components/Layout/MainLayout/NavigationToggler/NavigationToggle#"
                onClick={(e) => {
                    e.preventDefault();
                    setOpened(prev => !prev);
                }}
            >
                {
                    opened
                        ? <FontAwesomeIcon icon={faTimes}/>
                        : <FontAwesomeIcon icon={faBars}/>
                }
            </a>

            <Navigation opened={opened}/>
        </>
    );
};

export default NavigationToggle;