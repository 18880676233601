import React from 'react';
import cn from 'classnames';

import {useSearchParams} from 'react-router-dom';

import {IColumn, SetOrderByType} from './Table';

import Sort from '../../Icons/Sort';

import IOrderBy from '../../../models/IOrderBy';

import styles from './Table.module.scss';

interface ISortableThProps {
    column: IColumn,
    sort?: IOrderBy,
    setSort?: SetOrderByType,
    sortToSearchParams: boolean,
}

const SortableTh: React.FC<ISortableThProps> = ({
    column,
    sort,
    setSort,
    sortToSearchParams,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isActive = sort?.field === column.sort;
    const isAsc = sort?.direction === 'asc';

    const clickHandler = () => {
        if (column.sort) {
            const direction = (isActive && isAsc)
                ? 'desc'
                : 'asc';

            if (sortToSearchParams) {
                searchParams.set('order', column.sort);
                searchParams.set('dir', direction);
                setSearchParams(searchParams);
            }

            setSort?.({field: column.sort, direction});
        }
    };

    return (
        <th
            className={styles.sortable}
            onClick={clickHandler}
        >
            <div className={styles.table__sorting}>
                {column.label}

                <div className={
                    cn(
                        styles['table__sorting-icon'],
                        {[styles['table__sorting-icon--active']]: isActive},
                        {[styles['table__sorting-icon--desc']]: isActive && !isAsc},
                    )
                }>
                    <Sort />
                </div>
                
            </div>
        </th>
    );
};

export default React.memo(SortableTh);