/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import FileDownloadIcon from '@rsuite/icons/lib/icons/legacy/FileDownload';

import IBatchResult from 'models/IBatchResult';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import LinkButton from '../../UI/Button/LinkButton';


interface IIncidentsExportResultProps {
    result: IBatchResult,
    handleClose: () => void;
}

const IncidentsExportResult: React.FC<IIncidentsExportResultProps> = ({result, handleClose}) => {

    const url = result.payload.url;

    return <>
        <ModalBodyWrapper>
            <p className="text-medium">Экспортировано инцидентов: {result.success_count}</p>
            {
                result.errors.length > 0
                && <div className="mt-2">
                    <p className="text-medium">Ошибки:</p>
                    <ol>
                        {
                            result.errors.map((error, index) => <li
                                key={`${index}_${error}`}>
                                {error}
                            </li>)
                        }
                    </ol>
                </div>
            }
        </ModalBodyWrapper>
        {
            url
                ? <LinkButton
                    className="full-width" href={url}>
                    Скачать <FileDownloadIcon width="20px" height="20px"/>
                </LinkButton>

                : <PrimaryButton
                    className="full-width"
                    type="button"
                    onClick={handleClose}
                >
                    Закрыть
                </PrimaryButton>
        }
    </>;
};

export default IncidentsExportResult;