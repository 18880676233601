import React from 'react';

import {useSearchParams} from 'react-router-dom';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import NotFound from './NotFound';
import {paymentAPI} from '../api/paymentAPI';
import PaymentCancel from '../components/Payment/PaymentCancel';

const PaymentCancelPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const id = Number(searchParams.get('InvId'));

    if (!id) {
        return <NotFound/>;
    }

    const {data, isLoading, isFetching} = paymentAPI.useGetPaymentQuery(id);
    if (!data && !isLoading && !isFetching) {
        return <NotFound/>;
    }
    
    return (
        <BlankLayout pageTitle="Отмена платежа" isLoading={isLoading || isFetching}>
            {data && <PaymentCancel item={data}/>}
        </BlankLayout>
    );
};

export default PaymentCancelPage;