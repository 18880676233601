import React, {ReactNode, useEffect, useState} from 'react';

import {Link} from 'react-router-dom';

import cn from 'classnames';

import styles from './MonitorStatus.module.scss';

interface MonitorStatusProps {
    text:string;
    counter: number;
    className: string;
    icon: ReactNode;
    link: string;
    color: string
}

const MonitorStatus: React.FC<MonitorStatusProps> = ({className, icon, link, text, counter, color}) => {
    const [fontSize, setFontSize] = useState<number>(36);

    useEffect(() => {
        const length = counter.toString().length;
        setFontSize(length < 5 ? 36 : 24);
    }, [counter]);
    return (
        <Link to={link}>
            <div className={className}>
                <div className={cn(styles.icon, color)}>{icon}</div>
                <div className={styles.content}>
                    <span className={styles.counter} style={{ fontSize: `${fontSize}px` }}>{counter}</span>
                    <span className={styles.text}>{text}</span>
                </div>
            </div>
        </Link>
    );
};

export default MonitorStatus;