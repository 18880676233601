import React from 'react';

import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import PlaceholderGraph from 'rsuite/cjs/Placeholder/PlaceholderGraph';

import {monitorAPI} from '../../../api/monitorAPI';

import styles from './MonitorDetails.module.scss';

interface IMonitorAvgResponseTimeChartProps {
    uuid: string,
    height: number,
}

const MonitorAvgResponseTimeChart: React.FC<IMonitorAvgResponseTimeChartProps> = ({uuid, height}) => {
    const {data, isLoading} = monitorAPI.useGetMonitorAvgResponseTimeQuery(uuid);

    if (isLoading) {
        return <PlaceholderGraph active height={height} />;
    }

    return (
        <div style={{height: height+'px', width: '100%'}}>
            <ResponsiveContainer width="100%" height="100%" className={styles.responsive_container}>
                <AreaChart height={height} data={data} margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" name="мс" />
                    <Tooltip />
                    <YAxis />
                    <Area type="monotone" name="Миллисекунды" dataKey="value" stroke="#1db5b6" fill="#1db5b6" />
                </AreaChart>

            </ResponsiveContainer>
        </div>
    );
};

export default MonitorAvgResponseTimeChart;