import React, {useState} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';

import {DateRange} from 'rsuite/DateRangePicker';

import IBatchResult from '../../../models/IBatchResult';
import useBatchOperation from '../../../hooks/useBatchOperation';
import {ApiUrls} from '../../../constants/urls';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';


import IIncidentExportRequest from '../../../models/Incident/IIncidentExportRequest';

import IncidentsExportResult from './IncidentsExportResult';

import DateRangePickerControlledWithLastTwoMonths
    from '../../UI/Date/DateRangePickerControlled/DateRangePickerControlledWithLastTwoMonthes';

import {dateRangeToDateAfterBefore} from '../../../utils/filterHelper';

import OrderByControlled from '../../UI/OrderBy/OrderByControlled';

import IOrderBy from '../../../models/IOrderBy';

import styles from './IncidentsExportForm.module.scss';

interface IIncidentsExportFormProps {
    handleClose: () => void,
    monitorId?: string,
    sort?: IOrderBy,
}

interface IIncidentsExportFiltersForm {
    createdAt: DateRange,
    orderBy: IOrderBy,
}

const IncidentsExportForm: React.FC<IIncidentsExportFormProps> = ({
    handleClose,
    monitorId,
    sort,
}) => {
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<IBatchResult | undefined>();
    const {trigger, isLoading} = useBatchOperation();

    const {handleSubmit,  control  } = useForm<IIncidentsExportFiltersForm>({
        mode: 'onChange',
    });


    const onSubmit: SubmitHandler<IIncidentsExportFiltersForm> = async({createdAt}) => {
        const data: IIncidentExportRequest = {
            monitorId: monitorId,
            created_at: dateRangeToDateAfterBefore(createdAt),
        };


        try {
            await trigger(
                ApiUrls.INCIDENTS_EXPORT,
                'POST',
                data,
                (result) => {
                    setResult(result);
                    setShowResult(true);
                }
            );

        } catch (e) {

        }
    };

    const handleCloseResult = () => {
        handleClose();
        setShowResult(false);
        setResult(undefined);
    };

    return (
        <>
            {
                (showResult && result)
                    ? <IncidentsExportResult
                        result={result}
                        handleClose={handleCloseResult}
                    />
                    : <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <ModalBodyWrapper>
                            <FormGroupWithError
                                label="Диапазон дат, в который попадает возникновение инцидента"
                            >
                                <DateRangePickerControlledWithLastTwoMonths
                                    control={control}
                                    name="createdAt"
                                    cleanable={true}
                                />
                            </FormGroupWithError>

                            <FormGroupWithError
                                label="Сортировка"
                            >
                                <OrderByControlled
                                    control={control}
                                    name="orderBy"
                                    defaultValue={sort}
                                    data={[
                                        {label: 'Статус', value: 'resolved'},
                                        {label: 'Причина', value: 'error'},
                                        {label: 'Начало', value: 'id'},
                                    ]}
                                />
                            </FormGroupWithError>
                        </ModalBodyWrapper>
                        <FormGroup>
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                className="full-width"
                            >
                                Экспортировать
                            </PrimaryButton>
                        </FormGroup>
                    </form>
            }
        </>
    );
};

export default IncidentsExportForm;