import React, {Dispatch, SetStateAction} from 'react';

import {useSelector} from 'react-redux';

import IWarning from '../../models/IWarning';
import WarningRow from './WarningRow';
import {userSelector} from '../../redux/authSlice';
import IPagination from '../../models/IPagination';

import Table, {IColumn, SetOrderByType} from '../UI/Table/Table';

import IOrderBy from '../../models/IOrderBy';

import styles from './Warning/Warning.module.scss';

interface IWarningsProps {
    pagination?: IPagination<IWarning>,
    isLoading: boolean,
    hidePagination?: boolean,
    hideDomain?: boolean,
    sort?: IOrderBy,
    setSort?: SetOrderByType,
    setPage?: Dispatch<SetStateAction<number>>,
}

const Warnings: React.FC<IWarningsProps> = ({
    pagination,
    isLoading,
    hidePagination = false,
    hideDomain = false,
    sort,
    setSort,
    setPage,
}) => {
    const user = useSelector(userSelector);
    let cols: IColumn[] = [
        {label: 'Статус', sort: 'resolved'},
        {label: 'Домен', sort: 'domain'},
        {label: 'Причина', sort: 'type'},
        {label: 'Дата возникновения', sort: 'id'},
        {label: 'Решить до', sort: 'expire_at'},
        {label: 'Дата решения', sort: 'resolved_at'},
        {label: ''},
    ];
    if (hideDomain) {
        cols = cols.filter(col => col.label !== 'Домен');
    }

    return (
        <div className={styles.container}>
            <Table
                columns={cols}
                items={pagination?.items}
                className={styles.table_wrap}
                rowFactory={item =>
                    <WarningRow
                        key={item.id}
                        item={item}
                        tzString={user?.timezone}
                        hideDomain={hideDomain}
                    />
                }
                isLoading={isLoading}
                pagination={pagination && !hidePagination ? pagination : undefined}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
            />
        </div>
    );
};

export default Warnings;