import React from 'react';

import {useSelector} from 'react-redux';

import {Keyboard, Navigation, Scrollbar} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

// @ts-ignore
import {SwiperOptions} from 'swiper/types';

import PlaceholderGraph from 'rsuite/cjs/Placeholder/PlaceholderGraph';

import ITariff from '../../models/ITariff';
import {userSelector} from '../../redux/authSlice';

import {Pages} from '../../constants/pages';

import Tariff from './Tariff';

import styles from './Tariff.module.scss';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';


interface ITariffsProps {
    items: ITariff[],
    isLoading: boolean,
}

const Tariffs: React.FC<ITariffsProps> = ({items, isLoading}) => {
    const user = useSelector(userSelector);

    const swiperParams: SwiperOptions = {
        grabCursor: true,
        spaceBetween: 20,
        navigation: true,
        scrollbar: {
            draggable: true,
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            1500: {
                slidesPerView: 4,
            },
            1250: {
                slidesPerView: 3,
            },
            950: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
            },
        },
        modules: [Keyboard, Scrollbar, Navigation],

    };
    return (
        <section>
            <h3 className={styles.h3}>{Pages.TARIFFS}</h3>
            {
                isLoading
                    ? <PlaceholderGraph height={520} active/>
                    : <div
                        className={styles.tariffs}>
                        <Swiper
                            {...swiperParams}
                            className="mySwiper"
                        >
                            {items.map((item) =>
                                <SwiperSlide key={item.id}>
                                    <Tariff key={item.id} item={item} user={user}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
            }
        </section>
    );
};

export default Tariffs;