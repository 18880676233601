import React from 'react';

import {round} from 'lodash';

import ITimeRange from '../../../models/ITimeRange';
import {getFormattedDate, getMinutesFromLastDay} from '../../../utils/datetime';
import TimeRange from './TimeRange';

interface ITimeRangeControlLastDayProps {
    range: ITimeRange,
    tzString?: string|null,
    height: number,
    hideTime?: boolean,
}

const TimeRangeControlLastDay: React.FC<ITimeRangeControlLastDayProps> = ({
    range,
    tzString,
    height,
    hideTime= false,
}) => {
    const minutesInDay = 60 * 24;

    const formatDate = (datetime: string): string => getFormattedDate(datetime, {format: 'HH:mm', tzString});

    const getLeft = (from: number): string => `${round(from / minutesInDay * 100, 2)}%`;

    const minutesFrom = getMinutesFromLastDay(range.timeFrom, tzString);
    const minutesTo = getMinutesFromLastDay(range.timeTo, tzString);
    const width = round((minutesTo - minutesFrom) / minutesInDay * 100, 2);

    return <TimeRange
        width={`${width}%`}
        left={getLeft(minutesFrom)}
        color={range.color}
        timeFrom={formatDate(range.timeFrom)}
        timeTo={formatDate(range.timeTo)}
        height={height}
        //Не показываем время начала и конца, если ширина интервала менее 5%
        hideTime={hideTime || width < 5}
    />;
};

export default TimeRangeControlLastDay;