/* eslint-disable max-len */
import React from 'react';

const Corrected: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 23.999C5.37258 23.999 0 18.6264 0 11.999C0 5.37161 5.37258 -0.000976562 12 -0.000976562C18.6274 -0.000976562 24 5.37161 24 11.999C24 18.6264 18.6274 23.999 12 23.999ZM8.33205 10.2471L12 7.8018L15.6679 10.2471C16.3572 10.7066 17.2885 10.5204 17.7481 9.83107C18.2076 9.14178 18.0213 8.21048 17.3321 7.75095L12.8321 4.75095C12.3282 4.41505 11.6718 4.41505 11.1679 4.75095L6.66795 7.75095C5.97866 8.21048 5.7924 9.14178 6.25192 9.83107C6.71145 10.5204 7.64276 10.7066 8.33205 10.2471ZM8.33205 17.7471L12 15.3018L15.6679 17.7471C16.3572 18.2066 17.2885 18.0204 17.7481 17.3311C18.2076 16.6418 18.0213 15.7105 17.3321 15.2509L12.8321 12.2509C12.3282 11.915 11.6718 11.915 11.1679 12.2509L6.66795 15.2509C5.97866 15.7105 5.7924 16.6418 6.25192 17.3311C6.71145 18.0204 7.64276 18.2066 8.33205 17.7471Z" fill="white"/>
        </svg>
    );
};

export default Corrected;