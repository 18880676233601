import React from 'react';
import cn from 'classnames';

import {useDispatch, useSelector} from 'react-redux';

import {Link, useNavigate} from 'react-router-dom';

import MainMenu from '../../../UI/MainMenu/MainMenu';
import UserInformer from '../../../UserInformer/UserInformer';

import {userSelector} from '../../../../redux/authSlice';
import {isImpersonator, logout} from '../../../../utils/auth';
import {AppUrls} from '../../../../constants/urls';
import {TypedDispatch} from '../../../../types';

import EmailConfirmationBanner from './EmailConfirmationBanner/EmailConfirmationBanner';

import TariffBanner from './TariffBanner/TariffBanner';

import Logo from '../../../Icons/Logo';

import styles from './Navigation.module.scss';

interface INavigationProps {
    opened: boolean
}

const Navigation: React.FC<INavigationProps> = ({opened}) => {

    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const user = useSelector(userSelector);

    const handleLogout = async(event: React.MouseEvent) => {
        event.preventDefault();
        const impersonator = isImpersonator();
        await logout(dispatch);
        const returnPath = impersonator ? AppUrls.ADMIN_USERS : AppUrls.LOGIN;
        navigate(returnPath);
    };

    return (
        <nav className={cn(styles.nav, {[styles.opened]: opened})} role="navigation">
            <div className={cn([styles.sidebar, 'sidebar-collapse'])}>

                <div className={styles.top_nav}>
                    <Link to={AppUrls.HOME}>
                        <div className="logo-wrapper">
                            <Logo />
                        </div>
                    </Link>
                    <MainMenu/>
                </div>

                <div className={styles.bottom_nav}>
                    <EmailConfirmationBanner />
                    
                    <TariffBanner />

                    <UserInformer user={user} handleLogout={handleLogout} />
                </div>

            </div>
        </nav>
    );
};

export default Navigation;