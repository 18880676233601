import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {SubmitHandler, useForm} from 'react-hook-form';

import {HTTPMethod} from 'workbox-routing/utils/constants';

import IBatchResult from '../../../models/IBatchResult';
import useBatchOperation from '../../../hooks/useBatchOperation';
import {userSelector} from '../../../redux/authSlice';
import CreateMonitorType from '../../../models/Monitor/CreateMonitorType';
import MonitorFormType from '../../../models/Monitor/CreateMonitorType';
import {ApiUrls, TELEGRAM_CHATS_RESOURCE_NAME} from '../../../constants/urls';
import {monitorAPI} from '../../../api/monitorAPI';
import BatchResult from '../../UI/BatchResult';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';
import MonitorCommonSettingsSubform from './MonitorCommonSettingsSubform';
import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import MonitorBatchOperationSummary from '../MonitorBatchOperations/MonitorBatchOperationSummary';

import {clearAll, monitorSelectedIdsSelector} from '../../../redux/monitorSlice';

import {idsToIri} from '../../../utils/iriHelper';

import styles from './MonitorForm.module.scss';

interface IMonitorBatchOperationsFormProps {
    handleClose: () => void;
    action?: Record<string, any>,
}

const MonitorBatchOperationsForm: React.FC<IMonitorBatchOperationsFormProps> = ({
    handleClose,
    action,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const selectedIds = useSelector(monitorSelectedIdsSelector);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<IBatchResult | undefined>();
    const {trigger, isLoading} = useBatchOperation();

    const {register, handleSubmit, formState, control, setValue, getValues} = useForm<CreateMonitorType>({
        mode: 'onChange',
        defaultValues: {
            ssl_watch: user?.tariff.monitor_ssl_enabled,
            domain_watch: user?.tariff.monitor_domain_watch_enabled,
            ssl_redirect_watch: user?.tariff.monitor_ssl_redirect_watch_enabled,
            method: 'HEAD',
            notify_by_email: user?.email_confirmed,
        },
    });
    const {errors, isValid} = formState;


    const onSubmit: SubmitHandler<MonitorFormType> = async(formData) => {
        formData.telegram_chats = idsToIri(formData.telegram_chats, TELEGRAM_CHATS_RESOURCE_NAME);
        let data = {ids: selectedIds};
        let method: HTTPMethod = 'PUT';
        switch (action?.value) {
            case 'edit':
                data = {...data, ...formData};
                break;
            case 'delete':
                method = 'DELETE';
                break;
        }

        try {
            await trigger(
                ApiUrls.MONITORS_BATCH_ACTION(action?.value),
                method,
                data,
                (result) => {
                    if (action?.value === 'delete') {
                        dispatch(clearAll(0));
                    }
                    dispatch(monitorAPI.util.invalidateTags([{type: 'Monitor', id: 'LIST'}, 'SummaryMonitors']));
                    setResult(result);
                    setShowResult(true);
                }
            );

        } catch (e) {

        }
    };

    const handleCloseResult = () => {
        handleClose();
        setShowResult(false);
        setResult(undefined);
    };

    return (
        <>
            {
                (showResult && result)
                    ? <BatchResult
                        result={result}
                        handleClose={handleCloseResult}
                    />
                    : <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <ModalBodyWrapper>
                            {
                                action?.value === 'edit'
                                    ? <MonitorCommonSettingsSubform
                                        getValues={getValues}
                                        errors={errors}
                                        setValue={setValue}
                                        control={control}
                                        register={register}
                                    />
                                    : <MonitorBatchOperationSummary action={action}/>
                            }
                        </ModalBodyWrapper>
                        <FormGroup>
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                className="full-width"
                                disabled={!isValid}
                            >
                                Подтвердить
                            </PrimaryButton>
                        </FormGroup>
                    </form>
            }
        </>
    );
};

export default MonitorBatchOperationsForm;