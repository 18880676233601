// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TariffBanner_wrapper__WChoN {
  position: relative;
  width: 215px;
  padding: 25px 0 10px 20px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 #0000000D;
  font-family: Inter, sans-serif;
}
.TariffBanner_wrapper__WChoN a {
  text-decoration: none;
}
.TariffBanner_wrapper__WChoN .TariffBanner_progress__kVHdz {
  position: absolute;
  top: 0;
  left: 0;
  width: 215px;
  height: 7px;
  border-radius: 12px 12px 0 0;
}
.TariffBanner_wrapper__WChoN .TariffBanner_remain__DpaS5 {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #616E6E;
}
.TariffBanner_wrapper__WChoN .TariffBanner_text__JXf9z {
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #383838;
}
.TariffBanner_wrapper__WChoN .TariffBanner_line__69OVY {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  background: #F3F3F4;
}

.TariffBanner_link_wrapper__pkTa8:hover {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/TariffBanner/TariffBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;EACA,8BAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,4BAAA;AAAJ;AAGE;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIE;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;AAHJ;;AAUE;EACE,qBAAA;AAPJ","sourcesContent":[".wrapper {\n  position: relative;\n  width: 215px;\n  padding: 25px 0 10px 20px;\n  border-radius: 12px;\n  background: #FFFFFF;\n  box-shadow: 0 0 20px 0 #0000000D;\n  font-family: Inter, sans-serif;\n\n  a {\n    text-decoration: none;\n  }\n\n  .progress {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 215px;\n    height: 7px;\n    border-radius: 12px 12px 0 0;\n  }\n\n  .remain {\n    padding-bottom: 5px;\n    font-weight: 500;\n    font-size: 10px;\n    line-height: 10px;\n    color: #616E6E;\n  }\n\n  .text {\n    padding-bottom: 15px;\n    font-weight: 700;\n    font-size: 17px;\n    line-height: 20px;\n    color: #383838;\n  }\n\n  .line {\n    position: absolute;\n    content: '';\n    display: block;\n    left: 0;\n    width: 100%;\n    height: 1px;\n    background: #F3F3F4;\n  }\n}\n\n\n\n.link_wrapper {\n  &:hover {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TariffBanner_wrapper__WChoN`,
	"progress": `TariffBanner_progress__kVHdz`,
	"remain": `TariffBanner_remain__DpaS5`,
	"text": `TariffBanner_text__JXf9z`,
	"line": `TariffBanner_line__69OVY`,
	"link_wrapper": `TariffBanner_link_wrapper__pkTa8`
};
export default ___CSS_LOADER_EXPORT___;
