import {DateRange} from 'rsuite/DateRangePicker';

import IMonitorsFilters from '../models/Monitor/IMonitorsFilters';
import IDateAfterBefore from '../models/IDateAfterBefore';
import IOrderBy, {DirectionType} from '../models/IOrderBy';

export function getMonitorsFilters(params: URLSearchParams): IMonitorsFilters {
    const filters: IMonitorsFilters = {};
    fromUrlToFilters(params, filters, 'name');
    fromUrlToFilters(params, filters, 'url');
    fromUrlToFilters(params, filters, 'status');
    fromUrlToFilters(params, filters, 'ok');
    fromUrlToFilters(params, filters, 'sslRedirect');
    fromUrlToFilters(params, filters, 'sslActiveTo[after]');
    fromUrlToFilters(params, filters, 'sslActiveTo[before]');
    fromUrlToFilters(params, filters, 'domainActiveTo[after]');
    fromUrlToFilters(params, filters, 'domainActiveTo[before]');
    fromUrlToFilters(params, filters, 'order');
    fromUrlToFilters(params, filters, 'dir');
    fromUrlToFilters(params, filters, 'days');

    return filters;
}

function fromUrlToFilters<T, K extends keyof T & string>(params: URLSearchParams, filters: T, field: K) {
    if (!params.has(field)) {
        return;
    }
    const value = params.get(field);
    if (value) {
        filters[field] = <T[K]>value;
    }
}

export function objectToArray(object: object): string[] {
    // @ts-ignore
    return Object.keys(object).map((key) => `${key}=${object[key]}`);
}

export function setSearchParamsFromFilters(params: URLSearchParams, object: object): void {
    for (const [key, value] of Object.entries(object)) {
        if (value === '' || value === undefined || value === null) {
            params.delete(key);
        } else {
            params.set(key, value);
        }
    }
}

export function stringsToDateRange(after: string | undefined, before: string | undefined): DateRange | undefined {
    const start = after ? new Date(after) : undefined;
    const end = before ? new Date(before) : undefined;

    if (start === undefined || end === undefined) {
        return undefined;
    }

    return [start, end];
}

export function dateRangeToFilters(dateRange: DateRange | undefined, filters: any, field: string): void {

    if (dateRange === undefined || dateRange === null) {
        filters[field + '[after]'] = undefined;
        filters[field + '[before]'] = undefined;
        return;
    }
    const after = dateRange[0];
    after.setHours(0, 0, 0, 0);

    const before = dateRange[1];
    before.setHours(23, 59, 59);

    filters[field + '[after]'] = after.toISOString();
    filters[field + '[before]'] = before.toISOString();
}

export function dateRangeToDateAfterBefore(dateRange: DateRange | undefined): IDateAfterBefore | undefined {

    if (dateRange === undefined || dateRange === null) {
        return undefined;
    }
    const after = dateRange[0];
    after.setHours(0, 0, 0, 0);

    const before = dateRange[1];
    before.setHours(23, 59, 59);

    return {
        after: after.toISOString(),
        before: before.toISOString(),
    };
}

export function stringToBoolean(value: string | undefined): boolean | undefined{
    if (value === undefined) {
        return undefined;
    }
    return value === 'true';
}

export function booleanToString(value: boolean | undefined): string | undefined{
    if (value === undefined) {
        return undefined;
    }
    return value.toString();
}

export function getOrderBy(
    params: URLSearchParams,
    defaultField: string,
    defaultDirection: DirectionType,
): IOrderBy{
    return {
        field: params.get('order') ?? defaultField,
        direction: (params.get('dir') ?? defaultDirection) as unknown as DirectionType,
    };
}
