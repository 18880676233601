/* eslint-disable max-len */
import React from 'react';

const Sort: React.FC<object> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
            fill="none">
            <path d="M4.75 10.5H12.75" strokeLinecap="round"/>
            <path d="M4.75 8H9.75" strokeLinecap="round"/>
            <path d="M4.75 5.5H7.75"  strokeLinecap="round"/>
        </svg>
    );
};

export default Sort;