/* eslint-disable max-len */
import React from 'react';

const Exit: React.FC = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3872_1231)">
            <path d="M12.0739 11.3336L15.3332 8.00022M15.3332 8.00022L12.0739 4.66688M15.3332 8.00022H3.92576M8.81465 11.9993V12.8327C8.81465 13.4957 8.55711 14.1316 8.09869 14.6005C7.64027 15.0693 7.01851 15.3327 6.37021 15.3327H3.11095C2.46264 15.3327 1.84089 15.0693 1.38247 14.6005C0.924043 14.1316 0.666504 13.4957 0.666504 12.8327L0.666504 3.16602C0.666504 2.50297 0.924043 1.86709 1.38247 1.39825C1.84089 0.929408 2.46264 0.666016 3.11095 0.666016H6.37021C7.01851 0.666016 7.64027 0.929408 8.09869 1.39825C8.55711 1.86709 8.81465 2.50297 8.81465 3.16602V3.99935" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_3872_1231">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>;
};

export default Exit;