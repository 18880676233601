/* eslint-disable max-len */
import React from 'react';

const Warning: React.FC = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5Z" fill="white"/>
            <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(1 0 0 -1 10.5 18.5)" fill="#FE974A"/>
            <path d="M10.5 12.5C10.5 13.3284 11.1716 14 12 14C12.8284 14 13.5 13.3284 13.5 12.5V6.90909C13.5 6.08066 12.8284 5.40909 12 5.40909C11.1716 5.40909 10.5 6.08066 10.5 6.90909V12.5Z" fill="#FE974A"/>
        </svg>

    );
};

export default Warning;