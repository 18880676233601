/* eslint-disable max-len */
import React from 'react';

interface ILockProps {
    color?: string,
}

const Lock: React.FC<ILockProps> = ({color = '#758282'}) => {
    return <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 11.5V13.1667M2.99999 16.5H13C13.442 16.5 13.8659 16.3244 14.1785 16.0118C14.4911 15.6993 14.6667 15.2754 14.6667 14.8333V9.83333C14.6667 9.39131 14.4911 8.96738 14.1785 8.65482C13.8659 8.34226 13.442 8.16667 13 8.16667H2.99999C2.55797 8.16667 2.13404 8.34226 1.82148 8.65482C1.50892 8.96738 1.33333 9.39131 1.33333 9.83333V14.8333C1.33333 15.2754 1.50892 15.6993 1.82148 16.0118C2.13404 16.3244 2.55797 16.5 2.99999 16.5ZM11.3333 8.16667V4.83333C11.3333 3.94928 10.9821 3.10143 10.357 2.47631C9.7319 1.85119 8.88405 1.5 7.99999 1.5C7.11594 1.5 6.26809 1.85119 5.64297 2.47631C5.01785 3.10143 4.66666 3.94928 4.66666 4.83333V8.16667H11.3333Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>;
};

export default Lock;