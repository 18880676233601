import React from 'react';

import {IColumn} from './Table';

import styles from './Table.module.scss';

interface ITableHeadProps {
    columns: IColumn[],
}

const TableHead: React.FC<ITableHeadProps> = ({columns}) => {

    return (
        <thead className={styles.thead}>
            <tr>
                {columns.map((col, i) =>
                    <th key={i}>
                        {col.label}
                    </th>
                )}
            </tr>
        </thead>
    );
};

export default React.memo(TableHead);