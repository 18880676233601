import React, {MouseEvent, ReactNode, useCallback} from 'react';

import {faEnvelope, faMoneyBills, faPencil, faUser} from '@fortawesome/free-solid-svg-icons';

import {toast} from 'react-toastify';

import {useDispatch} from 'react-redux';

import {useNavigate} from 'react-router-dom';

import MoreActionsItem from '../UI/MoreActions/MoreActionsItem';
import MoreActionsDots from '../UI/MoreActions/MoreActionsDots';
import IUserOutput from '../../models/IUser';
import {userAPI} from '../../api/userAPI';
import {fetchMe, impersonate} from '../../redux/authSlice';

import {TypedDispatch} from '../../types';
import {redirectToDashboard} from '../../utils/redirects';
import {clearCachedData} from '../../utils/auth';

interface IUserMenuProps {
    item: IUserOutput,
    // eslint-disable-next-line no-unused-vars
    onEditClick: (event: MouseEvent<HTMLTableRowElement>) => void,
    // eslint-disable-next-line no-unused-vars
    onTariffClick: (event: MouseEvent<HTMLTableRowElement>) => void,
}

const UserMenu: React.FC<IUserMenuProps> = ({item, onEditClick, onTariffClick}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const [confirmUserEmail] = userAPI.useConfirmUserEmailMutation();

    const onClickActivate = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await confirmUserEmail({
                id: item.id,
                email_confirmed: true,
            }).unwrap();
            toast.info('Email подтвержден');
        }catch (e){

        }

    }, [item.id]);

    const onImpersonateClick = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await dispatch(impersonate(item.id)).unwrap();
            toast.info('Вы вошли как пользователь '+item.email);
            await dispatch(fetchMe()).unwrap();
            redirectToDashboard(navigate);
            await clearCachedData(dispatch);
        }catch (e){

        }
    }, [item]);

    const getActions = (): ReactNode[] => {
        const actions: ReactNode[] = [];
        if (!item.email_confirmed) {
            actions.push(
                <MoreActionsItem
                    key="off"
                    label="Подтвердить Email"
                    icon={faEnvelope}
                    onClick={onClickActivate}
                />
            );
        }

        actions.push(
            <MoreActionsItem
                key="edit"
                label="Редактировать"
                icon={faPencil}
                onClick={onEditClick}/>
        );

        actions.push(
            <MoreActionsItem
                key="tariff"
                label="Установить тариф"
                icon={faMoneyBills}
                onClick={onTariffClick}/>
        );

        actions.push(
            <MoreActionsItem
                key="impersonate"
                label="Войти как"
                icon={faUser}
                onClick={onImpersonateClick}/>
        );

        return actions;
    };

    return (
        <MoreActionsDots>
            {
                getActions().map(el => el)
            }
        </MoreActionsDots>
    );
};

export default React.memo(UserMenu);