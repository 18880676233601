import React, {useCallback, useMemo, useState} from 'react';

import {ITariffVariant} from '../../../models/ITariffVariant';
import TariffVariantModal from './TariffVariantModal';
import TariffVariantMenu from './TariffVariantMenu';

interface ITariffVariantRowProps {
    item: ITariffVariant
}

const TariffVariantRow: React.FC<ITariffVariantRowProps> = ({item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalClose = useCallback(() => setIsModalOpen(false), []);

    const handleRowClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const tariffNames = useMemo(() => item.tariffs.map(t => t.name).join(', '), [item.tariffs]);

    return (
        <>
            <tr
                className="clickable"
                onClick={handleRowClick}
                role="button"
                aria-label="Редактировать вариант тарифа"
            >
                <td>{item.id}</td>
                <td>{item.discount}%</td>
                <td>{item.period} дн.</td>
                <td>{tariffNames}</td>
                <td onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex justify-content-center">
                        <TariffVariantMenu
                            item={item}
                            onEditClick={handleRowClick}
                        />
                    </div>
                </td>
            </tr>
            <TariffVariantModal
                open={isModalOpen}
                handleClose={handleModalClose}
                item={item}/>
        </>
    );
};

export default React.memo(TariffVariantRow);