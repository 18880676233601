import ITariff from '../models/ITariff';
import {tariffAPI} from '../api/tariffAPI';

export type TariffCondition =  keyof Pick<ITariff,
    'monitor_ssl_enabled'
    | 'monitor_extended_methods'
    | 'monitor_parse_errors'
    | 'monitor_frequent_checking'
    | 'monitor_domain_watch_enabled'
    | 'monitor_ssl_redirect_watch_enabled'
    >;

const useCheapestTariffWithBooleanLimit = (condition: TariffCondition): ITariff | null => {
    const {data, isLoading} = tariffAPI.useGetTariffsQuery({field: 'price', direction: 'asc'});
    let candidate : ITariff | null = null;
    if (isLoading || !data) {
        return candidate;
    }

    let minPrice: number|null = null;

    for (const tariff of data) {
        if(!tariff[condition]){
            continue;
        }

        if (null === minPrice || tariff.price < minPrice){
            candidate = tariff;
            minPrice = tariff.price;
        }
    }

    return candidate;
};

export default useCheapestTariffWithBooleanLimit;