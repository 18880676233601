/* eslint-disable max-len */
import React, { SVGProps } from 'react';

interface ArrowDownCardProps extends SVGProps<SVGSVGElement> {
    className?: string;
}

const ArrowDownCard: React.FC<ArrowDownCardProps> = ({ className, ...props }) => {

    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <path d="M3.31445 2.81445L8.49992 7.99992" stroke="#1DB5B6" strokeLinecap="round"/>
            <path d="M13.6855 2.81445L8.50065 7.99935" stroke="#1DB5B6" strokeLinecap="round"/>
            <path d="M3.31445 8.14844L8.49992 13.3339" stroke="#1DB5B6" strokeLinecap="round"/>
            <path d="M13.6855 8.14844L8.50065 13.3333" stroke="#1DB5B6" strokeLinecap="round"/>
        </svg>
    );
};

export default ArrowDownCard;