import React from 'react';

import BlankLayout from '../Layout/BlankLayout/BlankLayout';

import styles from './Error.module.scss';

const Error: React.FC = () => {
    return <BlankLayout pageTitle="Сервис не доступен">
        <div className={styles.outer}>
            <div className={styles.wrapper}>
                <h1>Сервис временно недоступен</h1>
                <p>Мы проводим технические работы на сервере.</p>
                <p>Пожалуйста, вернитесь позже.</p>
                <p>Спасибо за ваше понимание!</p>
            </div>
        </div>
    </BlankLayout>;
};

export default Error;