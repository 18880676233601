// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IncidentsExportForm_form__zEDEr {
  min-width: 400px;
}
@media (max-width: 550px) {
  .IncidentsExportForm_form__zEDEr {
    min-width: auto;
  }
}

.IncidentsExportForm_error__aNiOa {
  color: rgba(244, 67, 54, 0.8);
}

.IncidentsExportForm_panel__6oCYG {
  margin-bottom: 10px;
}
.IncidentsExportForm_panel__6oCYG .rs-panel-title {
  text-decoration: underline;
  text-decoration-style: dotted;
}
.IncidentsExportForm_panel__6oCYG .rs-panel-header {
  padding: 10px 15px;
}
.IncidentsExportForm_panel__6oCYG .rs-panel-body {
  padding: 10px 15px 10px 10px;
}
.IncidentsExportForm_panel__6oCYG .rs-icon {
  top: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Incident/IncidentsExport/IncidentsExportForm.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EAHF;IAII,eAAA;EAEF;AACF;;AACA;EACE,6BAAA;AAEF;;AACA;EACE,mBAAA;AAEF;AAAE;EACE,0BAAA;EACA,6BAAA;AAEJ;AACE;EACE,kBAAA;AACJ;AAEE;EACE,4BAAA;AAAJ;AAGE;EACE,oBAAA;AADJ","sourcesContent":[".form {\n  min-width: 400px;\n\n  @media (max-width: 550px) {\n    min-width: auto;\n  }\n}\n\n.error{\n  color: rgba(244, 67, 54, 0.8);\n}\n\n.panel {\n  margin-bottom: 10px;\n\n  :global(.rs-panel-title) {\n    text-decoration: underline;\n    text-decoration-style: dotted;\n  }\n\n  :global(.rs-panel-header) {\n    padding: 10px 15px;\n  }\n\n  :global(.rs-panel-body) {\n    padding: 10px 15px 10px 10px;\n  }\n\n  :global(.rs-icon) {\n    top: 14px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `IncidentsExportForm_form__zEDEr`,
	"error": `IncidentsExportForm_error__aNiOa`,
	"panel": `IncidentsExportForm_panel__6oCYG`
};
export default ___CSS_LOADER_EXPORT___;
