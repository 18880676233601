/* eslint-disable camelcase */
import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch} from 'react-redux';

import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import {monitorAPI} from '../../../api/monitorAPI';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import TextArea from '../../UI/TextArea/TextArea';

import BatchResult from '../../UI/BatchResult';
import IBatchResult from '../../../models/IBatchResult';

import HintQuestion from '../../UI/Hint/HintQuestion';

import useBatchOperation from '../../../hooks/useBatchOperation';

import {ApiUrls} from '../../../constants/urls';


import styles from './MonitorForm.module.scss';

interface IMonitorBatchRemoveFormProps {
    handleClose: () => void;
}
interface IRemoveForm {
    url: string
}


const MonitorBatchRemoveForm: React.FC<IMonitorBatchRemoveFormProps> = ({
    handleClose,
}) => {
    const dispatch = useDispatch();
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<IBatchResult | undefined>();
    const {trigger, isLoading} = useBatchOperation();

    const {register, handleSubmit, formState} = useForm<IRemoveForm>({
        mode: 'onChange',
    });
    const {errors, isValid} = formState;


    const onSubmit: SubmitHandler<IRemoveForm> = async(data) => {

        try {
            await trigger(
                ApiUrls.MONITORS_BATCH_ACTION('remove-by-url'),
                'DELETE',
                data,
                (result) => {
                    dispatch(monitorAPI.util.invalidateTags([{type: 'Monitor', id: 'LIST'}, 'SummaryMonitors']));
                    setResult(result);
                    setShowResult(true);
                },
            );

        } catch (e) {

        }
    };

    const handleCloseResult = () => {
        handleClose();
        setShowResult(false);
        setResult(undefined);
    };

    return (
        <>
            {
                (showResult && result)
                    ? <BatchResult
                        result={result}
                        handleClose={handleCloseResult}
                    />
                    : <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <ModalBodyWrapper>
                            <FormGroupWithError
                                error={errors?.url?.message}
                            >
                                <label>Список URL <HintQuestion placement="right">
                                    <>
                                        <p>Каждый URL с новой строки</p>
                                        <p>Будут удалены все мониторы, у которых URL будет совпадать с введенными</p>
                                    </>
                                </HintQuestion></label>
                                <TextArea
                                    rows={5}
                                    register={register}
                                    name="url"
                                />
                            </FormGroupWithError>

                        </ModalBodyWrapper>
                        <FormGroup>
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                className="full-width"
                                disabled={!isValid}
                            >
                                Удалить несколько мониторов
                            </PrimaryButton>
                        </FormGroup>
                    </form>
            }
        </>
    );
};

export default MonitorBatchRemoveForm;