// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationBanner_wrapper__gN9IO {
  border-radius: 12px;
  border: 1px solid rgba(49, 201, 202, 0.1);
  background: var(--white, #FFF);
  margin: 5px;
  padding: 10px;
  width: 195px;
  /* Shadow */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/NavigationBanner/NavigationBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,yCAAA;EACA,8BAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EAEA,WAAA;EACA,0CAAA;AAAJ","sourcesContent":[".wrapper{\n    border-radius: 12px;\n    border: 1px solid rgba(49, 201, 202, 0.10);\n    background: var(--white, #FFF);\n    margin: 5px;\n    padding: 10px;\n    width: 195px;\n\n    /* Shadow */\n    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NavigationBanner_wrapper__gN9IO`
};
export default ___CSS_LOADER_EXPORT___;
