import React from 'react';
import {Routes} from 'react-router-dom';

import {useSelector} from 'react-redux';

import {AppUrls} from './constants/urls';

import Error from './components/Error/Error';

import {isFetchMeErrorSelector} from './redux/authSlice';
import {getProtectedRoute, IProtectedRouteProps} from './utils/permissionsHelper';
import NoAuth from './pages/NoAuth';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import HomePage from './pages/HomePage';
import {Permission} from './models/Permission';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import ConfirmChangeEmailPage from './pages/ConfirmChangeEmailPage';
import PasswordResetInitPage from './pages/password/PasswordResetInitPage';
import PasswordResetSentPage from './pages/password/PasswordResetSentPage';
import NewPasswordPage from './pages/password/NewPasswordPage';
import ProfilePage from './pages/ProfilePage';
import {Pages} from './constants/pages';
import NotFound from './pages/NotFound';
import MonitorUnsubscribePage from './pages/MonitorUnsubscribePage';
import MonitorsPage from './pages/MonitorsPage';
import MonitorLogsPage from './pages/MonitorLogsPage';
import MonitorIncidentsPage from './pages/MonitorIncidentsPage';
import AdminTariffsPage from './pages/AdminTariffsPage';
import TariffsPage from './pages/TariffsPage';
import PaymentsPage from './pages/PaymentsPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import AdminUsersPage from './pages/AdminUsersPage';
import IncidentsPage from './pages/IncidentsPage';
import IUserOutput from './models/IUser';
import WarningsPage from './pages/WarningsPage';
import AdminPaymentsPage from './pages/AdminPaymentsPage';
import AdditionalEmailConfirm from './pages/AdditionalEmailConfirm';
import NotificationsUnsubscribePage from './pages/NotificationsUnsubscribePage';
import AdminTariffVariantsPage from './pages/AdminTariffVariantsPage';
import Home from './components/Icons/Home';
import Monitors from './components/Icons/Monitors';
import Incidents from './components/Icons/Incidents';
import Warnings from './components/Icons/Warnings';
import Tariffs from './components/Icons/Tariffs';
import Payments from './components/Icons/Payments';
import Users from './components/Icons/Users';
import Settings from './components/Icons/Settings';
import Support from './components/Icons/Support';


export const routes: IProtectedRouteProps[] = [
    {
        path: AppUrls.LOGIN,
        element: <LoginPage/>,
    },
    {
        path: AppUrls.REGISTRATION,
        element: <RegistrationPage/>,
    },
    {
        path: AppUrls.EMAIL_CONFIRMATION,
        element: <ConfirmEmailPage/>,
    },
    {
        path: AppUrls.ADDITIONAL_EMAIL_CONFIRM,
        element: <AdditionalEmailConfirm/>,
    },
    {
        path: AppUrls.CHANGE_EMAIL_CONFIRMATION,
        element: <ConfirmChangeEmailPage/>,
    },
    {
        path: AppUrls.MONITOR_UNSUBSCRIBE,
        element: <MonitorUnsubscribePage />,
    },
    {
        path: AppUrls.NOTIFICATIONS_UNSUBSCRIBE,
        element: <NotificationsUnsubscribePage />,
    },
    {
        path: AppUrls.PASSWORD_RESET,
        element: <PasswordResetInitPage/>,
    },
    {
        path: AppUrls.PASSWORD_RESET_SENT,
        element: <PasswordResetSentPage/>,
    },
    {
        path: AppUrls.NEW_PASSWORD,
        element: <NewPasswordPage/>,
    },
    {
        path: AppUrls.FORBIDDEN,
        element: <NoAuth/>,
    },
    {
        path: AppUrls.HOME,
        element: <HomePage/>,
        auth: [Permission.USER],
        title: Pages.HOME,
        icon: Home,
    },
    {
        path: AppUrls.MONITORS,
        element: <MonitorsPage/>,
        auth: [Permission.USER],
        title: Pages.MONITORS,
        icon: Monitors,
    },
    {
        path: AppUrls.INCIDENTS,
        element: <IncidentsPage/>,
        auth: [Permission.USER],
        title: Pages.INCIDENTS,
        icon: Incidents,
    },
    {
        path: AppUrls.WARNINGS,
        element: <WarningsPage/>,
        auth: [Permission.USER],
        title: Pages.WARNINGS,
        icon: Warnings,
    },
    {
        path: AppUrls.MONITOR_LOGS,
        element: <MonitorLogsPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.MONITOR_DETAILS,
        element: <MonitorIncidentsPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.PAYMENT_SUCCESS,
        element: <PaymentSuccessPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.PAYMENT_CANCEL,
        element: <PaymentCancelPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.TARIFFS,
        element: <TariffsPage/>,
        auth: [Permission.USER],
        title: Pages.TARIFFS,
        icon: Tariffs,
    },
    {
        path: AppUrls.ADMIN_TARIFFS,
        element: <AdminTariffsPage/>,
        auth: [Permission.ADMIN],
        title: Pages.ADMIN_TARIFFS,
        icon: Tariffs,
    },
    {
        path: AppUrls.ADMIN_TARIFF_VARIANTS,
        element: <AdminTariffVariantsPage/>,
        auth: [Permission.ADMIN],
        title: Pages.ADMIN_TARIFF_VARIANTS,
        icon: Tariffs,
    },
    {
        path: AppUrls.ADMIN_PAYMENTS,
        element: <AdminPaymentsPage/>,
        auth: [Permission.ADMIN],
        title: Pages.ADMIN_PAYMENTS,
        icon: Payments,
    },
    {
        path: AppUrls.PAYMENTS,
        element: <PaymentsPage/>,
        auth: [Permission.USER],
        title: Pages.PAYMENTS,
        icon: Payments,
        condition: (user: IUserOutput) => user.has_payments,
    },
    {
        path: AppUrls.ADMIN_USERS,
        element: <AdminUsersPage/>,
        auth: [Permission.ADMIN],
        title: Pages.USERS,
        icon: Users,
    },
    {
        path: AppUrls.SETTINGS,
        element: <ProfilePage/>,
        auth: [Permission.USER],
        title: Pages.SETTINGS,
        icon: Settings,
    },
    {
        path: AppUrls.NOT_FOUND,
        element: <NotFound/>,
    },
    {
        path: AppUrls.SUPPORT,
        element: <WarningsPage/>,
        auth: [Permission.USER],
        title: Pages.SUPPORT,
        icon: Support,
        target: '_blank',
    },
];

const Router = () => {
    const isFetchMeError = useSelector(isFetchMeErrorSelector);

    if (isFetchMeError) {
        return <Error/>;
    }

    return (
        <Routes>
            {
                routes.map(routeProps => getProtectedRoute(routeProps))
            }
        </Routes>
    );
};

export default Router;