import React from 'react';

import TariffForm from './TariffForm/TariffForm';
import ITariff from '../../../models/ITariff';
import MainModal from '../../UI/Modal/MainModal';

interface ITariffModalProps {
    open: boolean,
    item?: ITariff,
    handleClose: () => void;
}

const TariffModal: React.FC<ITariffModalProps> = ({open, item, handleClose}) => {
    const isNew = !item;

    return (
        <MainModal
            header={`${isNew ? 'Добавить' : 'Изменить'} тариф`}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <TariffForm onSuccess={handleClose} item={item}/>
        </MainModal>
    );
};

export default TariffModal;