import React, {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {unwrapResult} from '@reduxjs/toolkit';

import {toast} from 'react-toastify';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faCancel} from '@fortawesome/free-solid-svg-icons/faCancel';

import ConfirmModal from '../UI/Modal/ConfirmModal/ConfirmModal';
import {disableRecurring, fetchMe, isUserLoadingSelector} from '../../redux/authSlice';
import {TypedDispatch} from '../../types';
import TransparentButton from '../UI/Button/TransparentButton';

const DisableRecurring: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const isLoading = useSelector(isUserLoadingSelector);

    const onClickConfirm = useCallback(async() => {
        const resultAction = await dispatch(disableRecurring());
        const result = unwrapResult(resultAction);
        if (result.status) {
            toast.info(result.message);
            await dispatch(fetchMe());
            setConfirmModalVisible(true);
        } else {
            toast.error(result.message);
        }
    }, []);

    return (
        <>
            <TransparentButton onClick={() => setConfirmModalVisible(true)}>
                <FontAwesomeIcon icon={faCancel} size={'sm'} color="red" />
                Отключить автоплатеж
            </TransparentButton>
            <ConfirmModal
                open={confirmModalVisible}
                handleClose={() => setConfirmModalVisible(false)}
                handleConfirm={onClickConfirm}
                description="Вы уверены что хотите отключить автоплатеж?"
                confirmActionText="Отключить"
                isLoading={isLoading}
            />
        </>
    );
};

export default DisableRecurring;