import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Monitors from '../components/Monitor/Monitors';

const MonitorsPage: React.FC = () => {
    return (
        <MainLayout>
            <Monitors/>
        </MainLayout>
    );
};

export default MonitorsPage;