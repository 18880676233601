// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/background.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/background-360.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlankLayout_wrapper__Eop7h {
  padding: 0 1rem;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f3f3f4;
  display: flex;
  align-items: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100%;
  background-origin: border-box;
}
@media (max-width: 1000px) {
  .BlankLayout_wrapper__Eop7h {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-position: top right;
    background-size: contain;
  }
}
@media (max-width: 500px) {
  .BlankLayout_wrapper__Eop7h {
    padding: 0 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/BlankLayout/BlankLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,yDAAA;EACA,4BAAA;EACA,qBAAA;EACA,6BAAA;AACF;AACE;EAbF;IAcI,yDAAA;IACA,8BAAA;IACA,wBAAA;EAEF;AACF;AACE;EApBF;IAqBI,eAAA;EAEF;AACF","sourcesContent":[".wrapper {\n  padding: 0 1rem;\n  width: 100%;\n  min-height: 100vh;\n  height: 100%;\n  background-color: #f3f3f4;\n  display: flex;\n  align-items: center;\n  background-image: url(\"../../../images/background.svg\");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-origin: border-box;\n\n  @media(max-width: 1000px) {\n    background-image: url(\"../../../images/background-360.svg\");\n    background-position: top right;\n    background-size: contain;\n\n  }\n\n  @media(max-width: 500px) {\n    padding: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BlankLayout_wrapper__Eop7h`
};
export default ___CSS_LOADER_EXPORT___;
