import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import TelegramChatForm from './TelegramChatForm/TelegramChatForm';

interface ITelegramChatModalProps {
    open: boolean,
    handleClose: () => void;
}

const TelegramChatModal: React.FC<ITelegramChatModalProps> = ({
    open,
    handleClose,
}) => {

    return (
        <MainModal
            header="Добавить чат"
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <TelegramChatForm onSuccess={handleClose}/>
        </MainModal>
    );
};

export default TelegramChatModal;