/* eslint-disable max-len */
import React from 'react';

const Support: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68824)">
                <path d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5C12.1421 0.5 15.5 3.85787 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85787 15.5 0.5 12.1421 0.5 8Z" stroke="currentColor"/>
                <path d="M5.3335 5.84505C5.3335 4.40205 6.53185 3.33301 7.99985 3.33301C9.48785 3.33301 10.6668 4.51067 10.6668 5.99967C10.6668 6.96567 9.71085 8.19634 8.58285 8.66634V9.16699C8.58285 9.32161 8.52142 9.4699 8.41209 9.57924C8.30276 9.68857 8.15447 9.74999 7.99985 9.74999C7.84522 9.74999 7.69694 9.68857 7.5876 9.57924C7.47827 9.4699 7.41685 9.32161 7.41685 9.16699V8.26134C7.41689 8.13895 7.45545 8.01967 7.52706 7.92042C7.59868 7.82117 7.69971 7.74697 7.81585 7.70834C8.76985 7.39034 9.50083 6.48067 9.50083 5.99967C9.50083 5.15467 8.84485 4.49901 7.99985 4.49901C7.18285 4.49901 6.4995 5.03905 6.4995 5.84505C6.4995 5.99967 6.43807 6.14796 6.32874 6.2573C6.21941 6.36663 6.07112 6.42805 5.9165 6.42805C5.76188 6.42805 5.61359 6.36663 5.50425 6.2573C5.39492 6.14796 5.3335 5.99967 5.3335 5.84505Z" fill="currentColor"/>
                <path d="M8.70711 10.9599C8.51957 10.7723 8.26522 10.667 8 10.667C7.73478 10.667 7.48043 10.7723 7.29289 10.9599C7.10536 11.1474 7 11.4018 7 11.667C7 11.9322 7.10536 12.1866 7.29289 12.3741C7.48043 12.5616 7.73478 12.667 8 12.667C8.26522 12.667 8.51957 12.5616 8.70711 12.3741C8.89464 12.1866 9 11.9322 9 11.667C9 11.4018 8.89464 11.1474 8.70711 10.9599Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68824">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Support;