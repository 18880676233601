import React, {MouseEvent, useCallback, useState} from 'react';

import ITariff from '../../../models/ITariff';
import TariffMenu from './TariffMenu';
import TariffModal from './TariffModal';
import YesNo from '../../UI/YesNo/YesNo';

interface ITariffRowProps {
    item: ITariff,
}

const TariffRow: React.FC<ITariffRowProps> = ({item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => setIsModalOpen(false);

    const onEditClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const onTdClick = useCallback((event: MouseEvent<HTMLTableDataCellElement>) => {
        event.stopPropagation();
    }, []);

    return (
        <>
            <tr className="clickable" onClick={onEditClick}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.price > 0 ? `${item.price} руб`: 'Бесплатный'} </td>
                <td>{item.period > 0 ? `${item.period} дн.`: 'Бессрочный'} </td>
                <td><YesNo condition={item.is_archive}/></td>
                <td><YesNo condition={item.is_default}/></td>
                <td><YesNo condition={item.is_recommended}/></td>
                <td>{item.monitor_min_check_interval_limit}</td>
                <td>{item.monitor_max_count_limit}</td>
                <td>{item.telegram_chats_max_count_limit}</td>
                <td><YesNo condition={item.monitor_ssl_enabled}/></td>
                <td><YesNo condition={item.monitor_domain_watch_enabled}/></td>
                <td><YesNo condition={item.monitor_ssl_redirect_watch_enabled}/></td>
                <td><YesNo condition={item.monitor_extended_methods}/></td>
                <td><YesNo condition={item.monitor_parse_errors}/></td>
                <td><YesNo condition={item.monitor_frequent_checking}/></td>
                <td onClick={onTdClick}>
                    <div className="d-flex justify-content-center">
                        <TariffMenu item={item} onEditClick={onEditClick}/>
                    </div>
                </td>
            </tr>
            <TariffModal open={isModalOpen} handleClose={handleClose} item={item}/>
        </>
    );
};

export default TariffRow;