/* eslint-disable max-len */
import React from 'react';

const Plus: React.FC = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 1.5V19.5M19.5 10.5L1.5 10.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );
};

export default Plus;