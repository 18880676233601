/* eslint-disable max-len */
import React from 'react';

const Wrong: React.FC<object> = () => {

    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.06055 6.93945L9.06061 6.93939L9.05436 6.93336C8.77145 6.66012 8.39255 6.50893 7.99925 6.51234C7.60596 6.51576 7.22974 6.67352 6.95163 6.95163C6.67352 7.22974 6.51576 7.60596 6.51234 7.99925C6.50893 8.39255 6.66012 8.77145 6.93336 9.05436L6.9333 9.05441L6.93945 9.06055L7.87889 10L6.94219 10.9367C6.80054 11.0744 6.68747 11.2387 6.60949 11.4202C6.53088 11.6033 6.4895 11.8001 6.48777 11.9993C6.48604 12.1984 6.52399 12.3959 6.59941 12.5803C6.67483 12.7646 6.78621 12.9321 6.92705 13.0729C7.06789 13.2138 7.23537 13.3252 7.41971 13.4006C7.60406 13.476 7.80157 13.514 8.00075 13.5122C8.19991 13.5105 8.39674 13.4691 8.57975 13.3905C8.76129 13.3125 8.92562 13.1994 9.06331 13.0578L10 12.1211L10.9394 13.0606L10.9394 13.0606L10.9456 13.0666C11.2285 13.3399 11.6075 13.4911 12.0007 13.4877C12.394 13.4842 12.7703 13.3265 13.0484 13.0484C13.3265 12.7703 13.4842 12.394 13.4877 12.0007C13.4911 11.6075 13.3399 11.2285 13.0666 10.9456L13.0667 10.9456L13.0606 10.9394L12.1211 10L13.0606 9.06055L13.0606 9.06061L13.0666 9.05436C13.3399 8.77145 13.4911 8.39255 13.4877 7.99925C13.4842 7.60596 13.3265 7.22974 13.0484 6.95163C12.7703 6.67352 12.394 6.51576 12.0007 6.51234C11.6074 6.50893 11.2285 6.66012 10.9456 6.93336L10.9456 6.9333L10.9394 6.93945L10 7.87889L9.06055 6.93945ZM15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5C8.01088 17.5 6.10322 16.7098 4.6967 15.3033C3.29018 13.8968 2.5 11.9891 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033Z" fill="#F44336" stroke="#F44336"/>
    </svg>;
};

export default Wrong;