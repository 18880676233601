import React from 'react';

import {getFormattedDate} from '../../../utils/datetime';

interface IDateWithTodayYesterdayAccentProps {
    datetime: string|null,
    tzString?: string|null,
}

const DateWithTodayYesterdayAccent: React.FC<IDateWithTodayYesterdayAccentProps> = ({
    datetime,
    tzString,
}) => {
    if (null === datetime) {
        return <>-</>;
    }

    let dateText = getFormattedDate(datetime, {format: 'DD.MM.YYYY, в HH:mm', tzString});
    let color = '#758282';

    const itemDate = new Date(datetime);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const timeText = getFormattedDate(datetime, {format: 'HH:mm', tzString});

    if (itemDate.toDateString() === today.toDateString()) {
        color = '#F44336';
        dateText = `Сегодня, в ${timeText}`;
    } else if (itemDate.toDateString() === yesterday.toDateString()) {
        color = '#9440FF';
        dateText = `Вчера, в ${timeText}`;
    }

    return (
        <span style={{color}}>{dateText}</span>
    );
};

export default DateWithTodayYesterdayAccent;