import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';

import {TypedDispatch} from 'types';

import {emailConfirmationRequest, userSelector} from '../../../../../redux/authSlice';

import NavigationBanner from '../NavigationBanner/NavigationBanner';


import Remind from '../../../../Icons/Remind';

import styles from './EmailConfirmationBanner.module.scss';


const EmailConfirmationBanner: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const user = useSelector(userSelector);

    const [linkSent, setLinkSent] = useState(false);

    const clickHandler = async() => {
        try {
            const resultAction = await dispatch(emailConfirmationRequest());
            const response = unwrapResult(resultAction);
            toast.info(response.message);
            setLinkSent(true);
        }catch (e){}
    };


    return (
        user && !user.email_confirmed
            ? <NavigationBanner icon={<Remind/>}>
                {
                    linkSent
                        ? <div className={styles.banner__text}>
                            Ссылка для активации отправлена, проверьте вашу почту
                        </div>
                        : <>
                            <div className={styles.banner__text}>
                        Необходимо подтвердить ваш адрес {user.email}
                            </div>

                            <button className={styles.banner__btn} onClick={clickHandler}>Подтвердить</button>
                        </>
                }
            </NavigationBanner>
            : <></>
    );
};

export default EmailConfirmationBanner;