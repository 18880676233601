import {
    ACCESS_TOKEN_NAME,
    IMPERSONATOR_ACCESS_TOKEN_NAME,
    IMPERSONATOR_REFRESH_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
} from 'constants/common';

import Token from 'models/Token';
import AuthTokens from 'models/AuthTokens';

import {ThunkDispatch} from 'redux-thunk';

import {AnyAction} from 'redux';

import {isBrowser} from './evironment';
import {currentDatePlusHours} from './datetime';
import {TypedDispatch} from '../types';
import {fetchMe, logoutAction} from '../redux/authSlice';
import createHttpRequest from './http';
import {ApiUrls} from '../constants/urls';
import {incidentAPI} from '../api/incidentAPI';
import {monitorAPI} from '../api/monitorAPI';
import {tariffAPI} from '../api/tariffAPI';
import {paymentAPI} from '../api/paymentAPI';
import {userAPI} from '../api/userAPI';
import {telegramChatAPI} from '../api/telegramChatAPI';
import {clearAll} from '../redux/monitorSlice';
import {warningAPI} from '../api/warningAPI';
import {additionalEmailAPI} from '../api/additionalEmailAPI';

export const setTokens = (tokens: AuthTokens) => {
    if (isBrowser()) {
        setToken(ACCESS_TOKEN_NAME, tokens.token, currentDatePlusHours(1));
        setToken(REFRESH_TOKEN_NAME, tokens.refresh_token, currentDatePlusHours(30*24));
    }
};

export const backupImpersonatorTokens = () => {
    if (isBrowser()) {
        copyToken(ACCESS_TOKEN_NAME, IMPERSONATOR_ACCESS_TOKEN_NAME);
        copyToken(REFRESH_TOKEN_NAME, IMPERSONATOR_REFRESH_TOKEN_NAME);
    }
};

export const restoreImpersonatorTokens = () => {
    if (isBrowser()) {
        copyToken(IMPERSONATOR_ACCESS_TOKEN_NAME, ACCESS_TOKEN_NAME);
        copyToken(IMPERSONATOR_REFRESH_TOKEN_NAME, REFRESH_TOKEN_NAME);

        localStorage.removeItem(IMPERSONATOR_ACCESS_TOKEN_NAME);
        localStorage.removeItem(IMPERSONATOR_REFRESH_TOKEN_NAME);
    }
};

const copyToken = (source: string, target: string):void => {
    const sourceToken = getTokenData(source);
    if (sourceToken?.value && sourceToken?.expires) {
        setToken(
            target,
            sourceToken?.value,
            sourceToken?.expires
        );
    }
};

export const isImpersonator = ():boolean => {
    return getToken(IMPERSONATOR_REFRESH_TOKEN_NAME) !== null;
};

export const clearTokens = () => {
    if (isBrowser()) {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
    }
};

export const clearAccessTokens = () => {
    if (isBrowser()) {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
    }
};

export async function clearCachedData(dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
    dispatch(incidentAPI.util.resetApiState());
    dispatch(warningAPI.util.resetApiState());
    dispatch(monitorAPI.util.resetApiState());
    dispatch(tariffAPI.util.resetApiState());
    dispatch(telegramChatAPI.util.resetApiState());
    dispatch(additionalEmailAPI.util.resetApiState());
    dispatch(paymentAPI.util.resetApiState());
    dispatch(userAPI.util.resetApiState());
    dispatch(clearAll(0));
}

export const logout = async(dispatch: TypedDispatch) => {
    clearTokens();

    if (isImpersonator()) {
        restoreImpersonatorTokens();
        dispatch(fetchMe());
    } else {
        dispatch(logoutAction());
    }

    await clearCachedData(dispatch);
};

export const getAccessToken = (): string | null => {
    return getToken(ACCESS_TOKEN_NAME);
};

export const getRefreshToken = (): string | null => {
    return getToken(REFRESH_TOKEN_NAME);
};

const getToken = (tokenName: string): string | null => {
    const token = getTokenData(tokenName);
    if (!token) {
        return null;
    }
    if (new Date() >= new Date(token.expires)) {
        return null;
    }

    return token.value;
};

const getTokenData = (tokenName: string): Token | null => {
    const tokenData: string | null = isBrowser()
        ? localStorage.getItem(tokenName)
        : null;

    if (!tokenData) {
        return null;
    }
    return JSON.parse(tokenData);
};

export const setToken = (tokenName: string, value: string, expires: string|Date) => {
    const token: Token = {value, expires};
    if (isBrowser()) {
        localStorage.setItem(tokenName, JSON.stringify(token));
    }
};
export const refreshTokens = async():Promise<void> => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        throw new Error('Отсутствует RefreshToken');
    }
    const response = await createHttpRequest({
        method: 'POST',
        path: ApiUrls.TOKEN_REFRESH,
        data: {refresh_token: refreshToken},
        isAnonymous: true,
    });
    setTokens(response.data);
};