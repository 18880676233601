import React from 'react';
import {Col, Grid, Row} from 'rsuite';
import cn from 'classnames';
import PlaceholderGraph from 'rsuite/cjs/Placeholder/PlaceholderGraph';

import SummaryMonitors from './SummaryMonitors/SummaryMonitors';
import LastIncidents from './LastIncidents/LastIncidents';
import {incidentAPI} from '../../api/incidentAPI';
import {getPollingInterval} from '../../utils/pollingHelper';
import {monitorAPI} from '../../api/monitorAPI';

import styles from './Home.module.scss';


const Home: React.FC = () => {

    const {
        data: incidentsPagination,
        isLoading: isLoadingIncidents,
        isFetching: isFetchingIncidents,
    } = incidentAPI.useGetIncidentsQuery({limit: 5}, {
        pollingInterval: getPollingInterval(),
    });

    const {
        data: summaryMonitors,
        isLoading: isLoadingMonitors,
        isFetching: isFetchingMonitors,
    } = monitorAPI.useGetSummaryMonitorsQuery(undefined, {
        pollingInterval: getPollingInterval(),
    });

    return (
        <Grid fluid className={styles.wrapper}>
            <Row gutter={15}>
                <Col xl={12} lg={24} xs={24} className={styles.col}>
                    {
                        (!isLoadingMonitors && summaryMonitors)
                            ? <SummaryMonitors
                                summary={summaryMonitors}
                                isFetching={isFetchingMonitors}
                            />
                            : <PlaceholderGraph active height={356}/>
                    }
                </Col>
            </Row>

            <Row gutter={15}>
                <Col lg={24} className={cn([styles.col, styles.full_block])}>
                    <LastIncidents
                        incidentsPagination={incidentsPagination}
                        isFetching={isFetchingIncidents}
                        isLoading={isLoadingIncidents}
                    />
                </Col>
            </Row>
        </Grid>
    );
};

export default Home;