// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-modal-dialog {
  background: linear-gradient(180deg, #ECFFFE 49.99%, #FFF 50%, #FFF 100%);
  border-radius: 10px;
}

.rs-modal-content {
  background-color: unset;
}

.rs-modal-body {
  overflow-x: hidden !important;
  padding-bottom: 0;
  max-height: unset !important;
}

.rs-modal-title {
  font-size: 20px;
}

.rs-modal {
  margin: 30px auto !important;
}

.MainModal_wrapper__1DxP9 {
  border-radius: 12px;
  border: 1px solid #31C9CA;
  background: #FFF;
  padding: 25px 30px 30px;
  margin: 0 10px 15px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 550px) {
  .MainModal_wrapper__1DxP9 {
    margin: 0 0 15px 0;
    padding: 10px;
  }
  .MainModal_wrapper__1DxP9 .form-group {
    font-size: 10px;
    gap: 5px;
  }
  .MainModal_wrapper__1DxP9 input {
    font-size: 12px;
  }
  .MainModal_wrapper__1DxP9 .rs-col-xs-12:has(.btn) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Modal/MainModal.module.scss"],"names":[],"mappings":"AAAA;EACE,wEAAA;EACA,mBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,4BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gDAAA;AACF;AACE;EARF;IASI,kBAAA;IACA,aAAA;EAEF;EAAE;IACE,eAAA;IACA,QAAA;EAEJ;EACE;IACE,eAAA;EACJ;EAEE;IACE,WAAA;IACA,cAAA;IACA,aAAA;IACA,uBAAA;EAAJ;AACF","sourcesContent":[":global(.rs-modal-dialog) {\n  background: linear-gradient(180deg, #ECFFFE 49.99%, #FFF 50%, #FFF 100%);\n  border-radius: 10px;\n}\n\n:global(.rs-modal-content) {\n  background-color: unset;\n}\n\n:global(.rs-modal-body) {\n  overflow-x: hidden !important;\n  padding-bottom: 0;\n  max-height: unset !important;\n}\n\n:global(.rs-modal-title) {\n  font-size: 20px;\n}\n\n:global(.rs-modal) {\n  margin: 30px auto !important;\n}\n\n.wrapper {\n  border-radius: 12px;\n  border: 1px solid #31C9CA;\n  background: #FFF;\n  padding: 25px 30px 30px;\n  margin: 0 10px 15px;\n  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);\n\n  @media (max-width: 550px) {\n    margin: 0 0 15px 0;\n    padding: 10px;\n\n    :global(.form-group) {\n      font-size: 10px;\n      gap: 5px;\n    }\n\n    :global(input) {\n      font-size: 12px;\n    }\n\n    :global(.rs-col-xs-12:has(.btn)) {\n      width: 100%;\n      margin: 0 auto;\n      display: flex;\n      justify-content: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MainModal_wrapper__1DxP9`
};
export default ___CSS_LOADER_EXPORT___;
