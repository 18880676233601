// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warning_info_block__Fvip7 {
  margin-bottom: 5px;
  color: #75788b;
  font-size: 16px;
  font-weight: 400;
  text-shadow: 0 1px 0 #fff;
}
@media (max-width: 550px) {
  .Warning_info_block__Fvip7 {
    font-weight: 800;
  }
}

.Warning_well__iiMYe {
  color: #333;
  background-color: #f6f7fe;
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.1);
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.Warning_detail__f2q-A {
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 550px) {
  .Warning_detail__f2q-A {
    font-size: 16px;
  }
}

.Warning_mb2__57c9- {
  margin-bottom: 2em;
}
@media (max-width: 550px) {
  .Warning_mb2__57c9- {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;
  }
}

.Warning_container__fYr3z {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Warning/Warning/Warning.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AACF;AACE;EAPF;IAQI,gBAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,gCAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,+CAAA;AAEF;;AACA;EACE,eAAA;EACA,iBAAA;AAEF;AAAE;EAJF;IAKI,eAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF;AADE;EAHF;IAII,aAAA;IACA,sBAAA;IACA,SAAA;IACA,SAAA;EAIF;AACF;;AADA;EACE,kBAAA;AAIF","sourcesContent":[".info_block {\n  margin-bottom: 5px;\n  color: #75788b;\n  font-size: 16px;\n  font-weight: 400;\n  text-shadow: 0 1px 0 #fff;\n\n  @media (max-width: 550px) {\n    font-weight: 800;\n  }\n}\n\n.well {\n  color: #333;\n  background-color: #f6f7fe;\n  border-color: #ddd;\n  border-color: rgba(0, 0, 0, .1);\n  min-height: 20px;\n  padding: 19px;\n  margin-bottom: 20px;\n  border: 1px solid #e3e3e3;\n  border-radius: 4px;\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);\n}\n\n.detail {\n  font-size: 18px;\n  font-weight: bold;\n\n  @media (max-width: 550px) {\n    font-size: 16px;\n  }\n}\n\n.mb2 {\n  margin-bottom: 2em;\n\n  @media (max-width: 550px) {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    margin: 0;\n  }\n}\n\n.container {\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info_block": `Warning_info_block__Fvip7`,
	"well": `Warning_well__iiMYe`,
	"detail": `Warning_detail__f2q-A`,
	"mb2": `Warning_mb2__57c9-`,
	"container": `Warning_container__fYr3z`
};
export default ___CSS_LOADER_EXPORT___;
