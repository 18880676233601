import React from 'react';

import cn from 'classnames';

import {colorIncidentStatus} from '../../utils/colors';

import {translateIncidentStatus} from '../../utils/translate';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './StatusBadge.module.scss';

interface IIncidentStatusBadgeProps {
    resolved: boolean
}

const IncidentStatusBadge: React.FC<IIncidentStatusBadgeProps> = ({resolved}) => {
    const backgroundColor = colorIncidentStatus(resolved);
    const color = colorIncidentStatus(resolved);
    const currentBreakpoint = useBreakpoint();
    return currentBreakpoint === 'large'
        ? <div className={resolved ? styles.resolve : styles.reject}>
            <span className={cn([styles.status, 'badge', styles.status_incident])} style={{backgroundColor}}>
                {translateIncidentStatus(resolved)}
            </span>
        </div>
        : <div className={resolved ? styles.resolve : styles.reject}>
            <span className={cn([styles.status, 'badge', styles.status_incident])} style={{color}}>
                {translateIncidentStatus(resolved)}
            </span>
        </div>;
};
export default IncidentStatusBadge;