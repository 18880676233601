import React, {ComponentProps} from 'react';
import cn from 'classnames';

interface BaseTextAreaProps {
    register?: any,
    errorMsg?: string | boolean | object | null;
    minLength?: number,
    minLengthErrorMsg?: string,
    constraints?: object;
}

export type TextAreaProps = BaseTextAreaProps & Omit<Omit<ComponentProps<'textarea'>, keyof BaseTextAreaProps>, 'ref'>;

const TextArea: React.FC<TextAreaProps> = ({
    name,
    className,
    register,
    errorMsg,
    minLength,
    minLengthErrorMsg,
    constraints,
    ...rest
}) => {

    return (
        <textarea
            name={name}
            className={cn('form-control', className)}
            {...register && register(`${name}`, {
                required: errorMsg,
                ...minLength && {minLength: {value: minLength, message: minLengthErrorMsg}},
                ...constraints,
            })}
            {...rest}
        />
    );
};

TextArea.displayName = 'TextArea';

export default React.memo(TextArea);