import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {store} from '../../index';
import {fetchMe, isAuthenticatedSelector} from '../../redux/authSlice';
import {getAccessToken, getRefreshToken} from '../../utils/auth';

type AppDispatch = typeof store.dispatch;

export const UserFetchMe = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const shouldFetch = isAuthenticated || !!getAccessToken() || !!getRefreshToken();
    const fetchPeriod = 120; //2 минуты

    const fetch = ()=>{
        if (shouldFetch) {
            dispatch(fetchMe());
        }
    };


    useEffect(() => {
        fetch();
        const interval = setInterval(() => {
            fetch();
        }, fetchPeriod * 1000);

        return () => clearInterval(interval);
    }, [shouldFetch]);

    return null;
};