import React, {useState} from 'react';

import {SubmitHandler} from 'react-hook-form';

import IBatchResult from '../../../models/IBatchResult';
import useBatchOperation from '../../../hooks/useBatchOperation';
import {ApiUrls} from '../../../constants/urls';
import PrimaryButton from '../../UI/Button/PrimaryButton';


import IMonitorsExportRequest from '../../../models/Monitor/IMonitorsExportRequest';

import MonitorsExportResult from './MonitorsExportResult';
import {dateRangeToDateAfterBefore, stringToBoolean} from '../../../utils/filterHelper';
import MonitorFiltersForm, {IMonitorFiltersForm} from '../MonitorsFilters/MonitorFiltersForm';

interface IMonitorsExportFormProps {
    handleClose: () => void,
}
const MonitorsExportFormHandler: React.FC<IMonitorsExportFormProps> = ({
    handleClose,
}) => {
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<IBatchResult | undefined>();
    const {trigger, isLoading} = useBatchOperation();

    const onSubmit: SubmitHandler<IMonitorFiltersForm> = async({
        sslActiveTo,
        domainActiveTo,
        ok,
        sslRedirect,
        ...rest
    }) => {
        const data: IMonitorsExportRequest = {
            sslActiveTo: dateRangeToDateAfterBefore(sslActiveTo),
            domainActiveTo: dateRangeToDateAfterBefore(domainActiveTo),
            ok: stringToBoolean(ok),
            sslRedirect: stringToBoolean(sslRedirect),
            ...rest,
        };

        try {
            await trigger(
                ApiUrls.MONITORS_EXPORT,
                'POST',
                data,
                (result) => {
                    setResult(result);
                    setShowResult(true);
                }
            );

        } catch (e) {

        }
    };

    const handleCloseResult = () => {
        handleClose();
        setShowResult(false);
        setResult(undefined);
    };

    return (
        <>
            {
                (showResult && result)
                    ? <MonitorsExportResult
                        result={result}
                        handleClose={handleCloseResult}
                    />
                    : <MonitorFiltersForm
                        onSubmit={onSubmit}
                        button={<PrimaryButton
                            type="submit"
                            loading={isLoading}
                            className="full-width"
                        >
                            Экспортировать
                        </PrimaryButton>}
                    />
            }
        </>
    );
};

export default MonitorsExportFormHandler;