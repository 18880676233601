// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckingIntervalSliderInput_container__gQLfw {
  padding: 0 10px 22px 10px;
}

.CheckingIntervalSliderInput_slider__LJlAF {
  height: 12px;
  border: none;
  border-radius: 10px;
  background: #f2f2f5;
  box-shadow: none;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-connect {
  background: #1db5b6;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-handle {
  background-color: var(--rs-slider-thumb-bg);
  height: 16px;
  width: 16px;
  top: -1.8px;
  right: -12px;
  border-radius: 100%;
  cursor: pointer;
  content: "";
  position: absolute;
  border: 2px solid var(--rs-slider-thumb-border);
  margin-left: -6px;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
  box-shadow: none;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-handle:before {
  background-color: transparent;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-handle:after {
  background-color: transparent;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-marker-horizontal.noUi-marker-sub {
  height: 0;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-marker-horizontal.noUi-marker {
  height: 0;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-value-sub, .CheckingIntervalSliderInput_slider__LJlAF .noUi-pips {
  color: #575757;
  font-size: 14px;
}
.CheckingIntervalSliderInput_slider__LJlAF .noUi-value-horizontal {
  height: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Slider/CheckingIntervalSliderInput.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,2CAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,+CAAA;EACA,iBAAA;EACA,yGAAA;EACA,gBAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAIE;EACE,6BAAA;AAFJ;AAKE;EACE,SAAA;AAHJ;AAKE;EACE,SAAA;AAHJ;AAKE;EACE,cAAA;EACA,eAAA;AAHJ;AAKE;EACE,WAAA;AAHJ","sourcesContent":[".container {\n  padding: 0 10px 22px 10px;\n}\n\n.slider {\n  height: 12px;\n  border: none;\n  border-radius: 10px;\n  background: #f2f2f5;\n  box-shadow: none;\n\n  :global(.noUi-connect) {\n    background: #1db5b6;\n  }\n\n  :global(.noUi-handle) {\n    background-color: var(--rs-slider-thumb-bg);\n    height: 16px;\n    width: 16px;\n    top: -1.8px;\n    right: -12px;\n    border-radius: 100%;\n    cursor: pointer;\n    content: '';\n    position: absolute;\n    border: 2px solid var(--rs-slider-thumb-border);\n    margin-left: -6px;\n    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;\n    box-shadow: none;\n  }\n\n  :global(.noUi-handle:before) {\n    background-color: transparent;\n  }\n\n  :global(.noUi-handle:after) {\n    background-color: transparent;\n  }\n\n  :global(.noUi-marker-horizontal.noUi-marker-sub) {\n    height: 0;\n  }\n  :global(.noUi-marker-horizontal.noUi-marker) {\n    height: 0;\n  }\n  :global(.noUi-value-sub), :global(.noUi-pips) {\n    color: #575757;\n    font-size: 14px;\n  }\n  :global(.noUi-value-horizontal) {\n    height: 2px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CheckingIntervalSliderInput_container__gQLfw`,
	"slider": `CheckingIntervalSliderInput_slider__LJlAF`
};
export default ___CSS_LOADER_EXPORT___;
