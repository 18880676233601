import React from 'react';

import SortableTh from './SortableTh';

import {IColumn, SetOrderByType} from './Table';

import IOrderBy from '../../../models/IOrderBy';

import styles from './Table.module.scss';

interface ISortableTableHeadProps {
    columns: IColumn[],
    sort?: IOrderBy,
    set?: SetOrderByType,
    sortToSearchParams: boolean,
}

const SortableTableHead: React.FC<ISortableTableHeadProps> = (
    {
        columns,
        sort,
        set,
        sortToSearchParams,
    }) => {
    return (
        <thead className={styles.thead}>
            <tr>
                {columns.map((col, idx) => (
                    col.sort
                        ? (
                            <SortableTh
                                key={idx}
                                sort={sort}
                                setSort={set}
                                column={col}
                                sortToSearchParams={sortToSearchParams}
                            />
                        )
                        : <th key={idx}>{col.label}</th>
                ))}
            </tr>
        </thead>
    );
};

export default React.memo(SortableTableHead);