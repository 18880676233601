import React from 'react';

import {useSelector} from 'react-redux';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import ConfirmChangeEmail from '../components/Auth/ConfirmChangeEmail';
import {isUserLoadingSelector, userSelector} from '../redux/authSlice';

const ConfirmChangeEmailPage: React.FC = () => {
    const isLoading = useSelector(isUserLoadingSelector);
    const user = useSelector(userSelector);

    return (
        <BlankLayout pageTitle="Подтверждение изменения E-mail" isLoading={isLoading}>
            {
                user && <ConfirmChangeEmail user={user} />
            }
        </BlankLayout>
    );
};

export default ConfirmChangeEmailPage;