import React from 'react';

import styles from './Alert.module.scss';


export interface AlertProps  {
    children?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = (props) => {
    return <div className={styles.alert} role="alert">{props.children}</div>;
};

export default React.memo(Alert);