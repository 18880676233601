// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/bg-main.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_heading__LaqaP {
  color: #131A26;
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 40px;
  padding-left: 30px;
}

.MainLayout_layout__fmY1b {
  position: relative;
  display: flex;
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #F3F3F4;
  overflow-x: hidden;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: top 100px left 77px;
  background-attachment: fixed;
}
.MainLayout_layout-wrapper__WuYmY {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
@media (max-width: 960px) {
  .MainLayout_layout-wrapper__WuYmY {
    margin-left: 0;
    padding: 0;
  }
}
.MainLayout_layout-container__oW-94 {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  max-width: 100%;
}
.MainLayout_layout__main__RMRCP {
  flex: 1 0 auto;
  padding: 15px;
}
@media (max-width: 960px) {
  .MainLayout_layout__main__RMRCP {
    padding: 15px 5px;
  }
}
.MainLayout_layout__footer__2cWfg {
  flex-shrink: 0;
  width: 100%;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/MainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,yDAAA;EACA,4BAAA;EACA,6BAAA;EACA,wCAAA;EACA,4BAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EANF;IAOI,cAAA;IACA,UAAA;EAEJ;AACF;AAEE;EACE,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAGE;EACE,cAAA;EACA,aAAA;AADJ;AAGI;EAJF;IAKI,iBAAA;EAAJ;AACF;AAGE;EACE,cAAA;EACA,WAAA;EACA,YAAA;AADJ","sourcesContent":[".heading {\n  color: #131A26;\n  text-align: left;\n  font-family: Inter, sans-serif;\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 30px;\n  margin-top: 40px;\n  padding-left: 30px;\n}\n\n.layout {\n  position: relative;\n  display: flex;\n  height: auto;\n  min-height: 100vh;\n  width: 100%;\n  background-color: #F3F3F4;\n  overflow-x: hidden;\n  background-image: url(\"../../../images/bg-main.svg\");\n  background-repeat: no-repeat;\n  background-origin: border-box;\n  background-position: top 100px left 77px;\n  background-attachment: fixed;\n\n  &-wrapper {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n\n    @media(max-width: 960px) {\n      margin-left: 0;\n      padding: 0;\n    }\n\n  }\n\n  &-container {\n    width: 100%;\n    height: 100%;\n    padding: 0;\n    position: relative;\n    max-width: 100%;\n  }\n\n  &__main {\n    flex: 1 0 auto;\n    padding: 15px;\n\n    @media (max-width: 960px) {\n      padding: 15px 5px;\n    }\n  }\n\n  &__footer {\n    flex-shrink: 0;\n    width: 100%;\n    height: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `MainLayout_heading__LaqaP`,
	"layout": `MainLayout_layout__fmY1b`,
	"layout-wrapper": `MainLayout_layout-wrapper__WuYmY`,
	"layout-container": `MainLayout_layout-container__oW-94`,
	"layout__main": `MainLayout_layout__main__RMRCP`,
	"layout__footer": `MainLayout_layout__footer__2cWfg`
};
export default ___CSS_LOADER_EXPORT___;
