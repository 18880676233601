import React from 'react';

import styles from './SpinnerSimple.module.scss';

const SpinnerWithOverlay = () => {
    return (
        <div className={styles['spinner-overlay']}>
            <div className={'sk-spinner sk-spinner-double-bounce'}>
                <div className="sk-double-bounce1"/>
                <div className="sk-double-bounce2"/>
            </div>
        </div>
    );
};

export default SpinnerWithOverlay;