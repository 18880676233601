import {useEffect, useState} from 'react';

export const breakPoints = {
    large: 1450,
    medium: 1024,
    small: 768,
    mini: 500,
};

const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const getBreakpoint = () => {
    const windowWidth = getWindowWidth();

    if (windowWidth >= breakPoints.large) {
        return 'large';
    } else if (windowWidth >= breakPoints.medium) {
        return 'medium';
    } else if (windowWidth >= breakPoints.small) {
        return 'small';
    } else {
        return 'mini';
    }
};

const useBreakpoint = () => {
    const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint);

    const handleResize = () => {
        const newBreakpoint = getBreakpoint();
        if (newBreakpoint !== currentBreakpoint) {
            setCurrentBreakpoint(newBreakpoint);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentBreakpoint]);

    return currentBreakpoint;
};

export default useBreakpoint;