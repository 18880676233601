/* eslint-disable max-len */
import React from 'react';

const Warnings: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68819)">
                <path d="M1.3335 5.99967C1.3335 5.99967 1.40647 4.52038 2.00016 3.33301C2.66683 1.99967 4.00024 1.33301 4.00024 1.33301" stroke="currentColor" strokeLinecap="round"/>
                <path d="M14.6665 5.99967C14.6665 5.99967 14.5935 4.52038 13.9998 3.33301C13.3332 1.99967 11.9998 1.33301 11.9998 1.33301" stroke="currentColor" strokeLinecap="round"/>
                <path d="M10 15.333L6 15.333" stroke="currentColor" strokeLinecap="round"/>
                <path d="M7.99984 2.66699C7.99984 2.66699 5.33317 2.66699 4.6665 4.66699C3.79728 7.27467 3.99885 8.96447 2.6665 11.3337C2.4856 11.6553 2.13778 12.0332 1.78227 12.3734C1.43712 12.7037 1.66154 13.3337 2.13925 13.3337H7.99984" stroke="currentColor"/>
                <path d="M8.00016 2.66699C8.00016 2.66699 10.6668 2.66699 11.3335 4.66699C12.2027 7.27467 12.0011 8.96447 13.3335 11.3337C13.5144 11.6553 13.8622 12.0332 14.2177 12.3734C14.5629 12.7037 14.3385 13.3337 13.8608 13.3337H8.00016" stroke="currentColor"/>
                <path d="M6.6665 2.66699L7.95984 1.69699C7.98502 1.67811 7.99984 1.64847 7.99984 1.61699V0.666992" stroke="currentColor" strokeLinecap="round"/>
                <path d="M9.3335 2.66699L8.04016 1.69699C8.01498 1.67811 8.00016 1.64847 8.00016 1.61699V0.666992" stroke="currentColor" strokeLinecap="round"/>
                <path d="M9.13153 2.73137C9.43159 2.43131 9.60016 2.02435 9.60016 1.6C9.60016 1.17565 9.43159 0.768687 9.13153 0.46863C8.83148 0.168573 8.42451 0 8.00016 0C7.57582 0 7.16885 0.168573 6.86879 0.46863C6.56873 0.768687 6.40016 1.17565 6.40016 1.6C6.40016 2.02435 6.56873 2.43131 6.86879 2.73137C7.16885 3.03143 7.57582 3.2 8.00016 3.2C8.42451 3.2 8.83148 3.03143 9.13153 2.73137Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68819">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Warnings;