import React from 'react';

import {Control, Controller, RegisterOptions} from 'react-hook-form';

import {Slider, SliderProps} from 'rsuite';

import styles from './SliderInput.module.scss';

interface ISliderProps extends SliderProps  {
    control: Control<any>,
    name: string,
    rules?: RegisterOptions
}

const SliderInput: React.FC<ISliderProps> = ({
    control,
    name,
    rules,
    onChange,
    ...rest
}) => {
    return (
        <div className={styles.slider_container}>
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={rest.defaultValue}
                /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
                render={({field: {ref, onChange: onChangeFrom, ...field}}) => (
                    <Slider
                        className={styles.override}
                        graduated
                        progress
                        onChange={(value: any, event) => {
                            onChangeFrom(value);
                            onChange && onChange(value, event);
                        }}
                        {...rest}
                        {...field}
                    />
                )}
            />

        </div>
    );
};

export default SliderInput;