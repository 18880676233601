import React from 'react';

import LinkButton from '../../UI/Button/LinkButton';
import {AppUrls} from '../../../constants/urls';

import styles from '../Auth.module.scss';


const NewPasswordComplete: React.FC = () => {

    return (
        <>
            <div>
                <h2 className={styles.title}>Пароль успешно изменен</h2>
            </div>

            <LinkButton
                href={AppUrls.HOME}
                className="rs-btn-primary full-width mb-2 mb-sm-0"
            >Продолжить</LinkButton>
        </>
    );
};

export default NewPasswordComplete;