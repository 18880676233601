import React, {useState} from 'react';

import TimesCircleIcon from '@rsuite/icons/legacy/TimesCircle';

import TransparentButton from '../UI/Button/TransparentButton';
import RemoveBatchMonitorModal from './RemoveBatchMonitorModal';

const RemoveBatchMonitorButton: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >Массовое удаление <TimesCircleIcon width="20px" height="20px" /> </TransparentButton>
            <RemoveBatchMonitorModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default RemoveBatchMonitorButton;