import React, {MouseEvent, ReactNode, useCallback} from 'react';

import {faCheck, faPencil, faTrash} from '@fortawesome/free-solid-svg-icons';

import {toast} from 'react-toastify';

import ITariff from '../../../models/ITariff';
import MoreActionsDots from '../../UI/MoreActions/MoreActionsDots';
import MoreActionsItem from '../../UI/MoreActions/MoreActionsItem';
import {tariffAPI} from '../../../api/tariffAPI';

interface ITariffMenuProps {
    item: ITariff,
    // eslint-disable-next-line no-unused-vars
    onEditClick: (event: MouseEvent<HTMLTableRowElement>) => void,
}

const TariffMenu: React.FC<ITariffMenuProps> = ({
    item,
    onEditClick,
}) => {
    const [deleteTariff] = tariffAPI.useDeleteTariffMutation();
    const [defaultTariff] = tariffAPI.useDefaultTariffMutation();

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteTariff(item.id).unwrap();
            toast.info('Тариф удален');
        }catch (e) {}
    }, []);

    const onClickDefault = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        const response = await defaultTariff(item.id).unwrap();
        toast.info(response.message);
    }, []);

    const getActions = (): ReactNode[] => {
        const actions: ReactNode[] = [];
        actions.push(
            <MoreActionsItem
                key="edit"
                label="Редактировать"
                icon={faPencil}
                onClick={onEditClick}/>
        );

        if (!item.is_default) {
            actions.push(
                <MoreActionsItem
                    key="default"
                    label="По умолчанию"
                    icon={faCheck}
                    onClick={onClickDefault}
                />
            );
        }

        actions.push(
            <MoreActionsItem
                key="trash"
                label="Удалить"
                icon={faTrash}
                onClick={onClickDelete}
            />
        );

        return actions;
    };

    return (
        <MoreActionsDots>
            {
                getActions().map(el => el)
            }
        </MoreActionsDots>
    );
};

export default React.memo(TariffMenu);