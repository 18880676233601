import React from 'react';

import {useParams} from 'react-router';

import {generatePath} from 'react-router-dom';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import NotFound from './NotFound';
import BackButton from '../components/UI/IconButton/BackButton';
import {AppUrls} from '../constants/urls';
import MonitorLogs from '../components/Monitor/MonitorLogs';
import {monitorAPI} from '../api/monitorAPI';
import BoxTransparent from '../components/UI/Box/BoxTransparent/BoxTransparent';

const MonitorLogsPage: React.FC = () => {

    const { monitorId} = useParams();

    if (!monitorId) {
        return <NotFound/>;
    }

    const {data, isLoading} = monitorAPI.useGetMonitorLogsQuery(monitorId);

    return (
        <MainLayout>
            <BoxTransparent>
                <div className="mb-2">
                    <BackButton
                        to={generatePath(AppUrls.MONITORS)}
                        text="Назад"
                    />
                </div>
                <h3>Результаты последних 10 проверок</h3>
                <MonitorLogs items={data} isLoading={isLoading}/>
            </BoxTransparent>
        </MainLayout>
    );
};

export default MonitorLogsPage;