/* eslint-disable max-len */
import React from 'react';

interface IEditProps {
    color?: string,
}

const Edit: React.FC<IEditProps> = ({color = '#758282'}) => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.49984 14.9999H5.83317C6.08317 14.9999 6.24984 14.9166 6.4165 14.7499L15.5832 5.58325C15.9165 5.24992 15.9165 4.74992 15.5832 4.41659L12.2498 1.08325C11.9165 0.749919 11.4165 0.749919 11.0832 1.08325L1.9165 10.2499C1.74984 10.4166 1.6665 10.5833 1.6665 10.8333V14.1666C1.6665 14.6666 1.99984 14.9999 2.49984 14.9999ZM3.33317 11.1666L11.6665 2.83325L13.8332 4.99991L5.49983 13.3332H3.33317V11.1666ZM17.4998 19.1666C17.9998 19.1666 18.3332 18.8333 18.3332 18.3333C18.3332 17.8333 17.9998 17.4999 17.4998 17.4999H2.49984C1.99984 17.4999 1.6665 17.8333 1.6665 18.3333C1.6665 18.8333 1.99984 19.1666 2.49984 19.1666H17.4998Z" fill={color}/>
        <mask id="mask0_465_2981" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="20">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.49984 14.9999H5.83317C6.08317 14.9999 6.24984 14.9166 6.4165 14.7499L15.5832 5.58325C15.9165 5.24992 15.9165 4.74992 15.5832 4.41659L12.2498 1.08325C11.9165 0.749919 11.4165 0.749919 11.0832 1.08325L1.9165 10.2499C1.74984 10.4166 1.6665 10.5833 1.6665 10.8333V14.1666C1.6665 14.6666 1.99984 14.9999 2.49984 14.9999ZM3.33317 11.1666L11.6665 2.83325L13.8332 4.99991L5.49983 13.3332H3.33317V11.1666ZM17.4998 19.1666C17.9998 19.1666 18.3332 18.8333 18.3332 18.3333C18.3332 17.8333 17.9998 17.4999 17.4998 17.4999H2.49984C1.99984 17.4999 1.6665 17.8333 1.6665 18.3333C1.6665 18.8333 1.99984 19.1666 2.49984 19.1666H17.4998Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_465_2981)">
            <rect width="20" height="20" fill={color}/>
        </g>
    </svg>;
};

export default Edit;