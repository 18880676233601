import React, {Dispatch, ReactNode, SetStateAction} from 'react';
import {Checkbox as PrettyCheckbox} from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox/dist/pretty-checkbox.css';

import cn from 'classnames';

import styles from './Checkbox.module.scss';

export interface CheckboxProps {
    id?: string | number,
    name?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    onBlur?: React.ChangeEventHandler<HTMLInputElement>,
    required?: boolean,
    checked?: boolean,
    defaultChecked?: boolean,
    children?: ReactNode,
    value?: string,
    state?: string | boolean | any[],
    setState?: Dispatch<SetStateAction<string | boolean | any[]>>,
    disabled?: boolean,
    indeterminate?: boolean,
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const Component: React.FC<CheckboxProps> = ({
        id,
        name,
        onChange,
        onBlur,
        required,
        checked,
        defaultChecked,
        children,
        value,
        state,
        setState,
        disabled,
        indeterminate,
        ...rest
    }) => ((
        <div className={styles.checkbox_container}>
            <label className={styles.checkbox_label}>
                <PrettyCheckbox
                    ref={ref}
                    id={String(id ?? name)}
                    name={name}
                    className={cn('p-inspinia', {indeterminate})}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required}
                    style={{zIndex: 0}}
                    checked={checked}
                    value={value}
                    state={state}
                    // @ts-ignore
                    setState={setState}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    {...rest}
                />
                <div className={styles.checkbox_text}>
                    {children}
                </div>
            </label>
        </div>
    ));

    return <Component {...props} />;
});

Checkbox.displayName = 'Checkbox';

export default React.memo(Checkbox);