import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Tariffs from '../components/Admin/Tariffs/Tariffs';

const AdminTariffsPage: React.FC = () => {

    return (
        <MainLayout>
            <Tariffs/>
        </MainLayout>
    );
};

export default AdminTariffsPage;