import {combineReducers} from '@reduxjs/toolkit';

import authReducer from './authSlice';
import monitorReducer from './monitorSlice';
import {monitorAPI} from '../api/monitorAPI';
import {incidentAPI} from '../api/incidentAPI';
import {warningAPI} from '../api/warningAPI';
import {tariffAPI} from '../api/tariffAPI';
import {paymentAPI} from '../api/paymentAPI';
import {userAPI} from '../api/userAPI';
import {telegramChatAPI} from '../api/telegramChatAPI';
import {additionalEmailAPI} from '../api/additionalEmailAPI';
import selectedVariantReducer from './selectedVariantSlice';
import {tariffVariantAPI} from '../api/tariffVariantAPI';


export default combineReducers({
    authReducer,
    monitorReducer,
    selectedVariant: selectedVariantReducer,
    [monitorAPI.reducerPath]: monitorAPI.reducer,
    [incidentAPI.reducerPath]: incidentAPI.reducer,
    [warningAPI.reducerPath]: warningAPI.reducer,
    [tariffAPI.reducerPath]: tariffAPI.reducer,
    [tariffVariantAPI.reducerPath]: tariffVariantAPI.reducer,
    [telegramChatAPI.reducerPath]: telegramChatAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [additionalEmailAPI.reducerPath]: additionalEmailAPI.reducer,
});