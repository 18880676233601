import React, {forwardRef} from 'react';
import {ButtonProps} from 'rsuite/esm/Button/Button';
import {Button} from 'rsuite';

import styles from './Button.module.scss';

const TransparentButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <Button ref={ref} {...props} appearance="ghost" className={styles.transparent}>
            {props.children}
        </Button>
    );
});

TransparentButton.displayName = 'TransparentButton';

export default TransparentButton;