import React, {useState} from 'react';

import {generatePath, useSearchParams} from 'react-router-dom';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import BackButton from '../components/UI/IconButton/BackButton';
import {AppUrls} from '../constants/urls';
import {warningAPI} from '../api/warningAPI';
import Warnings from '../components/Warning/Warnings';
import {getPollingInterval} from '../utils/pollingHelper';
import LoaderCentered from '../components/UI/Loader/LoaderCentered';
import TableBox from '../components/UI/Box/TableBox/TableBox';
import RefreshButton from '../components/UI/IconButton/RefreshButton';
import IOrderBy from '../models/IOrderBy';

const WarningsPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') ?? 1);

    const [sort, setSort] = useState<IOrderBy>();

    const {data: pagination, isLoading, isFetching, refetch} = warningAPI.useGetWarningsQuery({page, sort}, {
        pollingInterval: getPollingInterval(),
    });

    return (
        <MainLayout>
            <TableBox>
                <LoaderCentered active={isFetching}/>
                <div className="incidents_head">
                    <div className="arrow_back">
                        <BackButton
                            to={generatePath(AppUrls.MONITORS)}
                            text="Назад"
                        />
                    </div>

                    <h3 className="h3_title_incidents">Все предупреждения</h3>
                    {
                        refetch
                        && <div className="incidents_refresh_btn">
                            <div>
                                <RefreshButton
                                    title="Обновить"
                                    onClick={refetch}
                                />
                            </div>
                        </div>
                    }
                </div>

                {
                    <Warnings
                        pagination={pagination}
                        isLoading={isLoading}
                        sort={sort}
                        setSort={setSort}
                    />
                }
            </TableBox>
        </MainLayout>
    );
};

export default WarningsPage;