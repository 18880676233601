/* eslint-disable max-len */
import React from 'react';

const Problem: React.FC = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 0.499023C5.37258 0.499023 0 5.87161 0 12.499C0 19.1264 5.37258 24.499 12 24.499C18.6274 24.499 24 19.1264 24 12.499C24 5.87161 18.6274 0.499023 12 0.499023ZM8.33205 14.2509L12 16.6962L15.6679 14.2509C16.3572 13.7914 17.2885 13.9777 17.7481 14.667C18.2076 15.3563 18.0213 16.2876 17.3321 16.7471L12.8321 19.7471C12.3282 20.083 11.6718 20.083 11.1679 19.7471L6.66795 16.7471C5.97866 16.2876 5.7924 15.3563 6.25192 14.667C6.71145 13.9777 7.64276 13.7914 8.33205 14.2509ZM8.33205 6.75095L12 9.19625L15.6679 6.75095C16.3572 6.29142 17.2885 6.47768 17.7481 7.16697C18.2076 7.85627 18.0213 8.78757 17.3321 9.2471L12.8321 12.2471C12.3282 12.583 11.6718 12.583 11.1679 12.2471L6.66795 9.2471C5.97866 8.78757 5.7924 7.85627 6.25192 7.16697C6.71145 6.47768 7.64276 6.29142 8.33205 6.75095Z" fill="white"/>
        </svg>
    );
};

export default Problem;