import {toast} from 'react-toastify';

import {isDev} from 'utils/evironment';


type apiErrorType = {
    message: string,
    response: responseDataType
};

type responseDataType = {
    data: {
        violations?: violationType[],
        detail?: string,
        message?: string,
    }
};

type violationType = {
    message: string
};


export const apiErrorHandler = async(error: apiErrorType, errorMessage: string, silent: boolean) => {
    // eslint-disable-next-line no-console
    isDev() && console.error(error.response.data);
    const response = error.response;
    let messages = [error.message];
    if (response?.data?.violations) {
        messages = response.data.violations
            .map((violation) => violation.message);
    } else if (response?.data?.detail) {
        messages = [response.data.detail];
    } else if (response?.data?.message) {
        messages = [response.data.message];
    } else if (errorMessage) {
        messages = [errorMessage || 'Произошла ошибка'];
    }
    if (!silent) {
        messages.forEach(message => {
            toast.warning(message);
        });
    }

    return Promise.reject(new Error(messages.join('\n')));
};