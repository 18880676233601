import React from 'react';
import cn from 'classnames';


export interface FormGroupProps  {
    hasError?: boolean,
    children?: React.ReactNode;
    className?: string,
}

const FormGroup: React.FC<FormGroupProps> = (props) => {
    return (
        <div className={cn('form-group d-flex flex-column', {'has-error': props.hasError}, props.className)}>
            {props.children}
        </div>
    );
};

export default FormGroup;