import React from 'react';

import {useParams} from 'react-router';

import {generatePath} from 'react-router-dom';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import {Pages} from '../constants/pages';
import NotFound from './NotFound';
import BackButton from '../components/UI/IconButton/BackButton';
import {AppUrls} from '../constants/urls';
import {monitorAPI} from '../api/monitorAPI';
import MonitorDetails from '../components/Monitor/MonitorDetails/MonitorDetails';
import BoxTransparent from '../components/UI/Box/BoxTransparent/BoxTransparent';
import {getPollingInterval} from '../utils/pollingHelper';
import MonitorIncidents from '../components/Monitor/MonitorDetails/MonitorIncidents';
import MonitorWarnings from '../components/Monitor/MonitorDetails/MonitorWarnings';


const MonitorIncidentsPage: React.FC = () => {

    const {monitorId} = useParams();

    if (!monitorId) {
        return <NotFound/>;
    }

    const {data: monitor} = monitorAPI.useGetMonitorQuery(monitorId, {
        pollingInterval: getPollingInterval(),
    });


    return (
        <MainLayout>
            <BoxTransparent>
                <div className="monitor_head">
                    <BackButton
                        to={generatePath(AppUrls.MONITORS)}
                        text="Назад"
                    />

                    <h3>{Pages.MONITOR_DETAILS}</h3>
                </div>
                {
                    monitor && <MonitorDetails item={monitor}/>
                }
            </BoxTransparent>

            {
                monitor
                && <>
                    <MonitorIncidents key="incidents" monitor={monitor} />
                    <MonitorWarnings key="warnings" monitor={monitor} />
                </>
            }

        </MainLayout>
    );
};

export default MonitorIncidentsPage;