// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pretty.p-inspinia {
    z-index: 1;
}

.pretty.p-inspinia .state label:before {
    border-color: #e5e6e7;
}

.pretty.p-inspinia input[type=checkbox]:checked ~ .state label:after {
    background-color: #1ab394 !important;
}

.pretty.p-inspinia {
    white-space: inherit;
}

.pretty.p-inspinia .state label{
    text-indent: 0;
    padding-left: 2rem;
}

.pretty.p-inspinia .state label:after,
.pretty.p-inspinia .state label:before{
    top: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/vendor/pretty-checkbox/inspinia-styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;;IAEI,MAAM;AACV","sourcesContent":[".pretty.p-inspinia {\n    z-index: 1;\n}\n\n.pretty.p-inspinia .state label:before {\n    border-color: #e5e6e7;\n}\n\n.pretty.p-inspinia input[type=checkbox]:checked ~ .state label:after {\n    background-color: #1ab394 !important;\n}\n\n.pretty.p-inspinia {\n    white-space: inherit;\n}\n\n.pretty.p-inspinia .state label{\n    text-indent: 0;\n    padding-left: 2rem;\n}\n\n.pretty.p-inspinia .state label:after,\n.pretty.p-inspinia .state label:before{\n    top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
