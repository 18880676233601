import React, {ComponentProps, MouseEvent, useState} from 'react';

import cn from 'classnames';

import refresh from '../../../images/refresh.svg';

import CustomImage from '../Image/CustomImage';

import styles from './IconButton.module.scss';

const RefreshButton: React.FC<ComponentProps<'img'>> = ({
    className,
    onClick,
    ...rest
}) => {
    const [isRotate, setIsRotate] = useState(false);

    const newClassName = cn(className ?? styles.refresh, {[styles.rotate]: isRotate});

    const newOnClick = (event: MouseEvent<HTMLImageElement>) => {
        setIsRotate(true);
        onClick && onClick(event);
    };

    return (
        <CustomImage
            src={refresh}
            alt={'*'}
            className={cn(newClassName, styles.refresh)}
            onClick={newOnClick}
            onAnimationEnd={() => setIsRotate(false)}
            {...rest}
        />
    );
};

export default RefreshButton;