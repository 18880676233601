import React from 'react';

import styles from './Tariff.module.scss';

interface TariffHeaderProps {
    name: string;
    period: string;
}

const TariffHeader: React.FC<TariffHeaderProps> = ({ name, period }) => (
    <div className={styles.header_card}>
        <div className={styles.title}>{name}</div>
        <div className={styles.period}>
            {<span className={styles.text}>Срок действия </span>}
            <div>{period}</div>
        </div>
    </div>
);

export default TariffHeader;