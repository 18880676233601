// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationToggle_toggle__ihpZd {
  display: none;
  position: fixed;
  width: 24px;
  height: 24px;
  top: 18px;
  right: 20px;
  z-index: 1001;
  padding: 0;
}
@media (max-width: 960px) {
  .NavigationToggle_toggle__ihpZd {
    display: block;
    top: 28px;
  }
}
@media (max-width: 540px) {
  .NavigationToggle_toggle__ihpZd {
    top: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/NavigationToggler/NavigationToggle.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,UAAA;AACJ;AACI;EAVJ;IAWQ,cAAA;IACA,SAAA;EAEN;AACF;AAAI;EAfJ;IAgBQ,SAAA;EAGN;AACF","sourcesContent":[".toggle{\n    display: none;\n    position: fixed;\n    width: 24px;\n    height: 24px;\n    top: 18px;\n    right: 20px;\n    z-index: 1001;\n    padding: 0;\n\n    @media(max-width: 960px) {\n        display: block;\n        top: 28px;\n    }\n\n    @media(max-width: 540px) {\n        top: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `NavigationToggle_toggle__ihpZd`
};
export default ___CSS_LOADER_EXPORT___;
