import {createApi} from '@reduxjs/toolkit/query/react';

import {AxiosRequestHeaders} from 'axios';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import {providesList} from '../rtk-query/RTKQueryHelper';
import IUserOutput, {IUserInput} from '../models/IUser';
import IPagination from '../models/IPagination';
import IHydraResponse from '../models/HydraResponse';
import {paginationAdapter} from '../utils/paginationHelper';
import ICollectionParams from '../models/ICollectionParams';

export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['User'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getUsers: build.query<IPagination<IUserOutput>, ICollectionParams>({
            query: ({page, sort, filters}) => ({
                path: buildQuery(ApiUrls.USERS(page, sort, filters)),
                headers: {Accept: 'application/ld+json'} as unknown as AxiosRequestHeaders,
            }),
            providesTags: (result) => providesList(result?.items, 'User'),
            transformResponse(
                rawResult: IHydraResponse<IUserOutput>
            ): Promise<IPagination<IUserOutput>> | IPagination<IUserOutput> {
                return paginationAdapter(rawResult);
            },
        }),
        getUser: build.query<IUserOutput, number>({
            query: (id) => ({
                path: ApiUrls.USER(id),
            }),
            providesTags: (result, error, id) => [{ type: 'User', id }],
        }),
        updateUser: build.mutation<IUserOutput, Pick<IUserOutput, 'id' | 'first_name' | 'last_name' | 'timezone'>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.USER(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    userAPI.util.updateQueryData('getUser', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'User', id: 'LIST' }],
        }),
        changeUserTariff: build.mutation<IUserOutput, Pick<IUserInput, 'id' | 'tariff' | 'active_to'>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.USER_TARIFF(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    userAPI.util.updateQueryData('getUser', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'User', id: 'LIST' }],
        }),
        confirmUserEmail: build.mutation<IUserOutput, Pick<IUserInput, 'id' | 'email_confirmed'>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.USER_CONFIRM_EMAIL(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    userAPI.util.updateQueryData('getUser', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'User', id: 'LIST' }],
        }),
    }),
});