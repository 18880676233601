import React from 'react';

import {Control, UseFormRegister} from 'react-hook-form';

import {ITelegramNotifySettings} from '../../../models/ITelegramChat';
import DelaySelector from './DelaySelector';
import InputHidden from '../Input/InputHidden';

interface IDelaySelectorTelegramProps {
    values: ITelegramNotifySettings[],
    index: number,
    control: Control<any>,
    register: UseFormRegister<any>,
    chatId?: number,
}

const DelaySelectorTelegram: React.FC<IDelaySelectorTelegramProps> = ({
    values,
    index,
    control,
    register,
    chatId,
}) => {
    const getTgDelayValue = () =>  values
        .find(s => Number(s?.id) === Number(chatId))
        ?.delay ?? 0;

    return (
        <>
            <DelaySelector
                control={control}
                name={`notify_telegram_chats_settings.${index}.delay`}
                value={getTgDelayValue()}
            />

            <InputHidden {...register(`notify_telegram_chats_settings.${index}.id`)} value={chatId} />
        </>

    );
};

export default DelaySelectorTelegram;