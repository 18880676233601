// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationsUnsbscribePage_wrapper__Sls94 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NotificationsUnsbscribePage_box_wrapper__xtVrt {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.NotificationsUnsbscribePage_text__Wv0ng {
  padding: 1em 0;
  margin: 0;
  font-size: 18px;
}

.NotificationsUnsbscribePage_url__RS4W9 {
  display: inline-block;
  overflow-x: hidden;
  overflow-y: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotificationsUnsbscribePage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.box_wrapper {\n  width: 100%;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.text{\n  padding: 1em 0;\n  margin: 0;\n  font-size: 18px;\n}\n\n.url {\n  display: inline-block;\n  overflow-x: hidden;\n  overflow-y: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NotificationsUnsbscribePage_wrapper__Sls94`,
	"box_wrapper": `NotificationsUnsbscribePage_box_wrapper__xtVrt`,
	"text": `NotificationsUnsbscribePage_text__Wv0ng`,
	"url": `NotificationsUnsbscribePage_url__RS4W9`
};
export default ___CSS_LOADER_EXPORT___;
