import React from 'react';

import {Loader} from 'rsuite';

import styles from './LoaderCentered.module.scss';

interface ILoaderCenteredProps {
    active: boolean,
}

const LoaderCentered: React.FC<ILoaderCenteredProps> = ({active}) => {

    if (!active) {
        return <></>;
    }

    return (
        <div className={styles.wrapper}>
            <Loader/>
        </div>
    );
};

export default LoaderCentered;