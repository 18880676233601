import React, {SyntheticEvent} from 'react';
import {SelectPicker} from 'rsuite';
import {SelectPickerProps} from 'rsuite/esm/SelectPicker/SelectPicker';

import {Control, Controller, RegisterOptions} from 'react-hook-form';

import styles from './SelectSimple.module.scss';

interface ISelectProps extends SelectPickerProps<number|string>{
    control: Control<any>,
    name: string,
    cleanable?: boolean,
    rules?: RegisterOptions,
    // eslint-disable-next-line no-unused-vars
    onChange?: (value: string | number | null, event: SyntheticEvent<Element, Event>) => void,
}

const SelectSimple: React.FC<ISelectProps> = ({
    control,
    name,
    rules,
    onChange,
    placeholder,
    ...rest
}) => {


    return (
        <div className={styles.select_container}>
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={rest.defaultValue}
                /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
                render={({field: {ref, onChange: onChangeFrom, ...field}}) => (
                    <SelectPicker
                        className={styles.select}
                        onChange={(value, event) => {
                            onChangeFrom(value);
                            onChange && onChange(value, event);
                        }}
                        placeholder={placeholder ?? 'Выберите...'}
                        {...rest}
                        {...field}
                    />
                )}
            />

        </div>
    );
};


export default React.memo(SelectSimple);