// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TariffBanner_wrapper__WChoN {
  border-radius: 12px;
  border: none;
  background: linear-gradient(180deg, #1DB5B6 0%, #9440FF 100%);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  width: 195px;
  padding: 10px 10px 22px 10px;
  margin: 5px;
  position: relative;
  overflow: hidden;
}

.TariffBanner_text__JXf9z, .TariffBanner_text_remain__DG0w4 {
  color: #ECFFFE;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
.TariffBanner_text_remain__DG0w4 {
  font-weight: 500;
  font-size: 13px;
}

.TariffBanner_progress__kVHdz {
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 199px;
}
.TariffBanner_progress__kVHdz .rs-progress-line {
  padding: 0;
}
.TariffBanner_progress__kVHdz .rs-progress-line-inner {
  height: 12px;
  background: #9ba7d2;
}
.TariffBanner_progress__kVHdz .rs-progress-line-bg {
  border-radius: 6px;
  height: 12px;
}
.TariffBanner_progress__kVHdz .rs-progress-line-outer {
  border-radius: 6px;
}

.TariffBanner_link_wrapper__pkTa8:hover {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/TariffBanner/TariffBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,6DAAA;EACA,0CAAA;EACA,YAAA;EACA,4BAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AACE;EAEE,gBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AADF;AAGE;EACE,UAAA;AADJ;AAIE;EACE,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,YAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;;AASE;EACE,qBAAA;AANJ","sourcesContent":[".wrapper {\n  border-radius: 12px;\n  border: none;\n  background: linear-gradient(180deg, #1DB5B6 0%, #9440FF 100%);\n  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);\n  width: 195px;\n  padding: 10px 10px 22px 10px;\n  margin: 5px;\n  position: relative;\n  overflow: hidden;\n}\n\n.text {\n  color: #ECFFFE;\n  font-family: Inter, sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 20px;\n  text-align: center;\n\n  &_remain {\n    @extend .text;\n    font-weight: 500;\n    font-size: 13px;\n  }\n}\n\n.progress {\n  position: absolute;\n  bottom: 0;\n  left: -2px;\n  width: 199px;\n\n  :global(.rs-progress-line) {\n    padding: 0;\n  }\n\n  :global(.rs-progress-line-inner) {\n    height: 12px;\n    background: #9ba7d2;\n  }\n\n  :global(.rs-progress-line-bg) {\n    border-radius: 6px;\n    height: 12px;\n  }\n\n  :global(.rs-progress-line-outer) {\n    border-radius: 6px;\n  }\n}\n\n.link_wrapper {\n  &:hover {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TariffBanner_wrapper__WChoN`,
	"text": `TariffBanner_text__JXf9z`,
	"text_remain": `TariffBanner_text_remain__DG0w4`,
	"progress": `TariffBanner_progress__kVHdz`,
	"link_wrapper": `TariffBanner_link_wrapper__pkTa8`
};
export default ___CSS_LOADER_EXPORT___;
