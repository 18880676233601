import React, {Dispatch, PropsWithChildren, ReactNode, RefObject, SetStateAction} from 'react';
import {isEmpty} from 'lodash';
import cn from 'classnames';

import {RowFactory, tableRowsWithLoader} from 'utils/tableHelper';

import TableHead from './TableHead';
import SortableTableHead from './SortableTableHead';

import Paginator from '../Paginator/Paginator';

import IPagination from '../../../models/IPagination';

import IOrderBy from '../../../models/IOrderBy';

import styles from './Table.module.scss';

export type SetOrderByType = Dispatch<SetStateAction<IOrderBy | undefined>>;

export interface IColumn {
    label: string | ReactNode,
    sort?: string,
    key?: string,
}

export interface ITableProps<T extends object> {
    columns: IColumn[],
    items?: T[] | null,
    rowFactory: RowFactory<T>,
    isLoading: boolean,
    pagination?: IPagination<T>,
    className?: string,
    sort?: IOrderBy,
    setSort?: SetOrderByType,
    sortToSearchParams?: boolean,
    setPage?: Dispatch<SetStateAction<number>>,
    tableRef?: RefObject<HTMLTableElement>,
}

function Table<T extends object>(
    {
        columns,
        items,
        rowFactory,
        isLoading,
        pagination,
        className,
        sort,
        setSort,
        setPage,
        tableRef,
        sortToSearchParams = false,
    }: PropsWithChildren<ITableProps<T>>,
) {
    const sortableNum = columns.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.sort ? 1 : 0), 0);
    const tableHead = (sortableNum > 0 && (setSort || sortToSearchParams))
        ? <SortableTableHead
            columns={columns}
            sort={sort}
            set={setSort}
            sortToSearchParams={sortToSearchParams}
        />
        : <TableHead columns={columns}/>;

    return (
        <div className={styles.wrap}>
            <div className={styles.table_container}>
                <table
                    ref={tableRef}
                    className={cn(styles.table_content, 'table table-hover table-condensed', className)}>

                    {tableHead}

                    <tbody className={styles.tbody}>
                        {tableRowsWithLoader<T>(
                            items ?? [],
                            isLoading,
                            columns.length,
                            rowFactory,
                        )}
                    </tbody>

                    {!isEmpty(pagination) && (
                        <tfoot className={styles.tfoot}>
                            <tr>
                                <td colSpan={columns.length}>
                                    <Paginator
                                        initialPage={pagination.currentPage}
                                        lastPage={pagination.lastPage}
                                        setPage={setPage}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
        </div>
    );
}

export default React.memo(Table) as typeof Table;