import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {SubmitHandler, useForm} from 'react-hook-form';

import {toast} from 'react-toastify';

import {editUser, isUserEditingSelector} from '../../redux/authSlice';
import {TypedDispatch} from '../../types';
import FormGroup from '../UI/FormGroup/FormGroup';
import EmailInput from '../UI/Input/EmailInput';
import PrimaryButton from '../UI/Button/PrimaryButton';

import IProfileForm from '../../models/forms/IProfileForm';
import {timezones} from '../../constants/timezones';
import Select from '../UI/Input/Select';
import IUserOutput from '../../models/IUser';
import FormGroupWithError from '../UI/FormGroup/FormGroupWithError';
import InputName from '../UI/Input/InputName';
import {getClientDefaultTimeZone} from '../../utils/datetime';

import styles from './Profile.module.scss';

interface IProfileFormProps {
    user: IUserOutput
}

const ProfileForm: React.FC<IProfileFormProps> = ({user}) => {
    const isEditing = useSelector(isUserEditingSelector);
    const dispatch = useDispatch<TypedDispatch>();
    const {register, handleSubmit, formState, control} = useForm<IProfileForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IProfileForm> = async({timezone, ...rest}) => {
        await dispatch(editUser({
            id: user?.id ?? 0,
            timezone: timezone ?? getClientDefaultTimeZone(),
            ...rest,
        })).unwrap();
        toast.info('Данные сохранены');
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="profile-form"
        >

            <FormGroupWithError
                error={errors?.first_name?.message}
                label="Имя"
                className={styles.form_group}
            >
                <InputName
                    name="first_name"
                    placeholder="Имя"
                    control={control}
                    register={register}
                    defaultValue={user.first_name}
                    required
                />
            </FormGroupWithError>

            <FormGroupWithError
                error={errors?.last_name?.message}
                label="Фамилия"
                className={styles.form_group}
            >
                <InputName
                    name="last_name"
                    placeholder="Фамилия"
                    control={control}
                    register={register}
                    defaultValue={user.last_name}
                    required
                />
            </FormGroupWithError>

            <FormGroupWithError
                error={errors?.timezone?.message}
                label="Часовой пояс"
                className={styles.form_group}
            >
                <Select
                    control={control}
                    name="timezone"
                    data={timezones.map(zone => ({value: zone, label: zone}))}
                    defaultValue={user?.timezone ?? getClientDefaultTimeZone()}
                />
            </FormGroupWithError>

            <FormGroup className={styles.form_group}>
                <EmailInput
                    register={register}
                    control={control}
                    defaultValue={user.email}
                    className={styles.form_group}
                    disabled
                />
            </FormGroup>

            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isEditing}
                    className="full-width"
                    disabled={!isDirty || !isValid}
                >
                    Сохранить
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default ProfileForm;