import React, {useEffect, useState} from 'react';

import {ItemDataType} from 'rsuite/cjs/@types/common';

import {SelectPicker} from 'rsuite';

import IOrderBy, {DirectionType} from '../../../models/IOrderBy';

import styles from './OrderBy.module.scss';


export interface IOrderByProps {
    data: ItemDataType<string>[],
    name: string,
    // eslint-disable-next-line no-unused-vars
    onChange?: (value?: IOrderBy) => void,
    defaultValue?: IOrderBy,
}

const OrderBy: React.FC<IOrderByProps> = ({
    data,
    onChange,
    defaultValue,
    name,
}) => {
    const [value, setValue] = useState<IOrderBy|undefined>(defaultValue);

    useEffect(() => {
        onChange?.(value);
    }, [value]);

    const handleChangeField = (field: string|null) => {
        const newValue = field
            ? {...value, field}
            : undefined;
        setValue(newValue);
    };
    const handleChangeDirection = (direction: DirectionType|null) => {
        const newValue = direction
            ? {...value, direction}
            : undefined;
        setValue(newValue);
    };

    const directions: ItemDataType<DirectionType>[] = [
        {label: 'По возрастанию', value: 'asc'},
        {label: 'По убыванию', value: 'desc'},
    ];


    return (
        <div className={styles.wrapper}>
            <div className={styles.select_container}>
                <SelectPicker
                    className={styles.select}
                    name={`${name}.field`}
                    cleanable={true}
                    onChange={(value) => {
                        handleChangeField(value);
                    }}
                    defaultValue={value?.field}
                    data={data}
                    placeholder={'Выберите поле'}
                    searchable={false}
                    preventOverflow
                />
            </div>
            <div className={styles.select_container}>
                <SelectPicker
                    className={styles.select}
                    name={`${name}.direction`}
                    cleanable={true}
                    onChange={(value) => {
                        handleChangeDirection(value);
                    }}
                    data={directions}
                    defaultValue={value?.direction}
                    placeholder={'Выберите направление'}
                    searchable={false}
                    preventOverflow
                />
            </div>
        </div>
    );
};

export default OrderBy;