import React from 'react';

import {ITariffVariant} from '../../../models/ITariffVariant';
import MainModal from '../../UI/Modal/MainModal';
import TariffVariantForm from './TariffVariantForm';

interface ITariffVariantModalProps {
    open: boolean;
    handleClose: () => void;
    item?: ITariffVariant;
}

const TariffVariantModal: React.FC<ITariffVariantModalProps> = ({open, handleClose, item}) => {
    const isNew = !item;

    return (
        <MainModal
            header={`${isNew ? 'Добавить' : 'Изменить'} пакет`}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <TariffVariantForm onSuccess={handleClose} item={item}/>
        </MainModal>
    );
};

export default TariffVariantModal;