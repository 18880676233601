import dateFormatter from 'date-and-time';
import ru from 'date-and-time/locale/ru';

const defaultTimeZone = 'Europe/Moscow';

export interface IDateOptions {
    tzString?: string | null,
    format?: string | null,
    nullValue?: string | null,
}

// Параметры по умолчанию
const defaultDateOptions: IDateOptions = {
    tzString: null,
    format: 'HH:mm, DD.MM.YYYY',
    nullValue: '-',
};

/**
 * Форматирование даты
 * @param dateString - Дата
 * @param options - Опции
 * @return string
 */
export function getFormattedDate(
    dateString?: Date | string | null,
    options: IDateOptions = defaultDateOptions,
): string {
    options = {...defaultDateOptions, ...options};
    dateFormatter.locale(ru);
    // Если не передан dateString
    if (null === dateString || typeof dateString === 'undefined') {
        return <string>options.nullValue ?? defaultDateOptions.nullValue;
    }

    const convertedDate = convertTZ(dateString, options.tzString);
    return dateFormatter.format(convertedDate, <string>options.format ?? defaultDateOptions.format);
}

export function currentDatePlusHours(hours: number): Date {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);

    return date;
}

export function convertTZ(date: string | Date, tzString: string | null | undefined) {
    return new Date(getDate(date)
        .toLocaleString(
            'en-US',
            {timeZone: tzString ?? getClientDefaultTimeZone()}
        ));
}

function getDate(date: string | Date): Date {
    return <Date>(typeof date === 'string' ? new Date(date) : date);
}

export function getClientDefaultTimeZone() {
    if (typeof Intl === 'undefined') {
        return defaultTimeZone;
    }

    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getMinutesFromDayBeginning(
    dateString: Date | string,
    tzString?: string | null,
): number {
    const date = new Date(convertTZ(dateString, tzString));
    return date.getHours() * 60 + date.getMinutes();
}

export function getMinutesFromLastDay(
    dateString: Date | string,
    tzString?: string | null,
): number {
    const firstPoint = convertTZ(new Date(), tzString);
    firstPoint.setDate(firstPoint.getDate() - 1);

    const date = new Date(convertTZ(dateString, tzString));
    const diff = date.getTime() - firstPoint.getTime();

    return Math.round(diff / 60000);
}

export function secondsToFormattedString(seconds: number): string {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600*24));
    const h = Math.floor(seconds % (3600*24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);
    const display = [];

    d > 0 && display.push(`${d} дн.`);
    h > 0 && display.push(`${h} час`);
    m > 0 && display.push(`${m} мин`);
    s > 0 && display.push(`${s} сек`);

    return display.join(', ');
}
/**
 * Разница в днях между двумя датами. Если первая дата раньше второй, то значение будет отрицательным
 * @param date1 - первая дата
 * @param date2 - вторая дата
 * @return number
 */
export function getDiffInDays(
    date1: Date | string,
    date2: Date | string,
):number {
    const diffTime = getDate(date1).getTime() - getDate(date2).getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
