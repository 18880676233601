/* eslint-disable max-len */
import React from 'react';

const Advantage: React.FC<object> = () => {

    return <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99999 20.5C12.6522 20.5 15.1957 19.4465 17.071 17.571C18.9464 15.6957 20 13.1522 20 10.5C20 7.84783 18.9464 5.3043 17.071 3.42894C15.1957 1.55357 12.6522 0.5 9.99999 0.5C7.34783 0.5 4.8043 1.55357 2.92894 3.42894C1.05357 5.3043 0 7.84783 0 10.5C0 13.1522 1.05357 15.6957 2.92894 17.571C4.8043 19.4465 7.34783 20.5 9.99999 20.5Z" fill="#00B86B"/>
        <path
            d="M6 10.8676L8.58755 13.6525C9.00268 14.0993 9.71762 14.0736 10.0996 13.5982L15 7.5"
            stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </svg>;
};

export default Advantage;