/* eslint-disable max-len */
import React, { SVGProps } from 'react';

interface ArrowDownCardProps extends SVGProps<SVGSVGElement> {
    className?: string;
}

const LeftArrow: React.FC<ArrowDownCardProps> = ({ className, ...props }) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3461_4544)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2349 22.7358 20.4852 20.4853C22.7357 18.2349 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4852 3.51472C18.2349 1.26429 15.1826 0 12 0C8.8174 0 5.76516 1.26429 3.51472 3.51472C1.26429 5.76516 0 8.8174 0 12C0 15.1826 1.26429 18.2349 3.51472 20.4853C5.76516 22.7358 8.8174 24 12 24ZM8.31715 11.2686L13.2924 6.29255C13.6829 5.902 14.3161 5.90195 14.7067 6.29244C15.0972 6.68293 15.0973 7.3161 14.7068 7.70665L10.4145 11.9996L14.7078 16.2929C15.0984 16.6834 15.0984 17.3166 14.7078 17.7071C14.3173 18.0976 13.6841 18.0976 13.2936 17.7071L8.35607 12.7695C8.33359 12.7506 8.31174 12.7307 8.2906 12.7095C7.9342 12.3532 7.90299 11.7947 8.197 11.403C8.22581 11.3643 8.25777 11.3272 8.2929 11.2921C8.30088 11.2841 8.30897 11.2763 8.31715 11.2686Z" fill="#899696"/>
            </g>
            <defs>
                <clipPath id="clip0_3461_4544">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default LeftArrow;