import {SubmitHandler, useForm} from 'react-hook-form';

import FormGroupWithError from 'components/UI/FormGroup/FormGroupWithError';

import React from 'react';

import {toast} from 'react-toastify';

import {ITariffVariant} from '../../../models/ITariffVariant';
import {EditTariffVariantType, tariffVariantAPI} from '../../../api/tariffVariantAPI';
import {tariffAPI} from '../../../api/tariffAPI';
import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import InputNumber from '../../UI/Input/InputNumber';
import Checkbox from '../../UI/Checkbox/Checkbox';


import PrimaryButton from '../../UI/Button/PrimaryButton';

import styles from '../Tariffs/Tariffs.module.scss';

interface ITariffVariantFormProps {
    onSuccess: () => void;
    item?: ITariffVariant;
}

interface FormData {
    period: number;
    discount: number;
    tariff_names: string[];
}

const TariffVariantForm: React.FC<ITariffVariantFormProps> = ({onSuccess, item}) => {
    const [addTariffVariant] = tariffVariantAPI.useAddTariffVariantMutation();
    const [updateTariffVariant] = tariffVariantAPI.useUpdateTariffVariantMutation();
    const {data: tariffs} = tariffAPI.useGetTariffsQuery(undefined);

    const {handleSubmit, formState, control, setValue, watch} = useForm<FormData>({
        mode: 'onChange',
        defaultValues: {
            period: item?.period ?? 0,
            discount: item?.discount ?? 0,
            tariff_names: item?.tariffs.map(t => t.name) ?? [],
        },
    });

    const handleCheckboxChange = (tariffName: string) => {
        const newTariffs = selectedTariffs.includes(tariffName)
            ? selectedTariffs.filter(name => name !== tariffName)
            : [...selectedTariffs, tariffName];
        setValue('tariff_names', newTariffs);
    };

    const selectedTariffs = watch('tariff_names') || [];
    const {errors, isValid} = formState;
    const isNew = !item;

    const onSubmit: SubmitHandler<FormData> = async(data) => {
        try {
            const selectedTariffIris = tariffs
                ?.filter(t => selectedTariffs.includes(t.name))
                .map(t => `/api/v1/tariffs/${t.id}`) ?? [];

            const formData: EditTariffVariantType = {
                period: Number(data.period),
                discount: Number(data.discount),
                tariffs: selectedTariffIris,
            };

            if (isNew) {
                await addTariffVariant(formData).unwrap();
                toast.success('Вариант тарифа успешно добавлен');
            } else {
                await updateTariffVariant({
                    id: item.id,
                    ...formData,
                }).unwrap();
                toast.success('Вариант тарифа успешно обновлен');
            }
            setTimeout(onSuccess, 1000);
        } catch (error) {
            const message = error instanceof Error
                ? error.message
                : 'Произошла ошибка при отправке';
            toast.error(message);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <FormGroupWithError
                    error={errors?.discount?.message}
                    label="Скидка, %"
                >
                    <InputNumber
                        min={0}
                        max={100}
                        name="discount"
                        defaultValue={item?.discount ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.period?.message}
                    label="Количество дней"
                >
                    <InputNumber
                        min={0}
                        name="period"
                        defaultValue={item?.period ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError label="Тарифы">
                    {tariffs?.map((tariff) => (
                        <div key={tariff.id}>
                            <Checkbox
                                checked={selectedTariffs.includes(tariff.name)}
                                onChange={() => handleCheckboxChange(tariff.name)}
                            >
                                {tariff.name}
                            </Checkbox>
                        </div>
                    ))}
                </FormGroupWithError>

                <div className={styles.buttons}>
                    <PrimaryButton
                        type="submit"
                        disabled={!isValid}
                    >
                        {isNew ? 'Создать' : 'Сохранить'}
                    </PrimaryButton>
                </div>
            </ModalBodyWrapper>
        </form>
    );
};

export default TariffVariantForm;