import React from 'react';

import {useSelector} from 'react-redux';

import {isUserLoadingSelector, userSelector} from '../redux/authSlice';
import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Profile from '../components/Profile/Profile';
import TelegramChats from '../components/TelegramChat/TelegramChats';


const ProfilePage: React.FC = () => {
    const isLoading = useSelector(isUserLoadingSelector);
    const user = useSelector(userSelector);
    return (
        <MainLayout isLoading={isLoading}>
            {
                user 
                && <Profile user={user}/>
            }
            <div className="mt-3">
                <TelegramChats/>
            </div>
        </MainLayout>
    );
};

export default ProfilePage;