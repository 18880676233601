import {Pages} from 'constants/pages';

import React, {useCallback, useState} from 'react';

import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {userSelector} from 'redux/authSlice';
import Table from 'components/UI/Table/Table';
import {paymentAPI} from 'api/paymentAPI';
import {getPollingInterval} from 'utils/pollingHelper';
import LoaderCentered from 'components/UI/Loader/LoaderCentered';
import TableBox from 'components/UI/Box/TableBox/TableBox';
import RefreshButton from 'components/UI/IconButton/RefreshButton';

import PaymentRow from './PaymentRow';

import Search from '../../UI/Search/Search';

import IOrderBy from '../../../models/IOrderBy';

import styles from './Payments.module.scss';


const Payments: React.FC = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') ?? 1);
    const user = useSelector(userSelector);
    const [sort, setSort] = useState<IOrderBy>();
    const [filters, setFilters] = useState<string[]>([]);
    const {
        data: pagination,
        isLoading,
        refetch,
        isFetching,
    } = paymentAPI.useGetPaymentsQuery({page, sort, all: true, filters}, {
        pollingInterval: getPollingInterval(),
    });

    const onRefreshButtonClick = useCallback(() => {
        refetch();
    }, []);

    const onSearchSubmit = useCallback((search: string) => {
        setFilters([`user.email=${search}`]);
    }, []);

    return (
        <TableBox>
            <div className={styles.head}>
                <LoaderCentered active={isFetching}/>
                <div className={styles.header}>
                    <h3 className={styles.title}>{Pages.PAYMENTS}</h3>
                    <RefreshButton
                        title="Обновить"
                        onClick={onRefreshButtonClick}
                    />
                </div>
            </div>

            <Search  onSubmit={onSearchSubmit} placeholder="Email"/>

            <Table
                columns={[
                    {label: 'ID', sort: 'id'},
                    {label: '№', sort: 'number'},
                    {label: 'Пользователь', sort: 'user.email'},
                    {label: 'Дата создания', sort: 'created_at'},
                    {label: 'Сумма', sort: 'sum'},
                    {label: 'Тариф', sort: 'tariff.name'},
                    {label: 'Статус', sort: 'status'},
                    {label: 'Дата оплаты', sort: 'payed_at'},
                ]}
                items={pagination?.items}
                rowFactory={item => <PaymentRow
                    key={item.id}
                    item={item}
                    tzString={user?.timezone}
                />}
                isLoading={isLoading}
                pagination={pagination}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>

    );
};

export default Payments;