import React from 'react';

import cn from 'classnames';

import {PaymentStatus} from '../../models/PaymentStatus';

import {colorPaymentStatus} from '../../utils/colors';
import {translatePaymentStatus} from '../../utils/translate';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './StatusBadge.module.scss';

interface IPaymentStatusBadgeProps {
    status: PaymentStatus,
}

const PaymentStatusBadge: React.FC<IPaymentStatusBadgeProps> = ({
    status,
}) => {
    const backgroundColor = colorPaymentStatus(status);
    const color = colorPaymentStatus(status);
    const translatedStatus = translatePaymentStatus(status);
    const currentBreakpoint = useBreakpoint();
    return currentBreakpoint === 'large'
        ? <div className={cn([styles.status, 'badge'])} style={{backgroundColor}}>
            {translatedStatus}
        </div>
        : <div className={cn([styles.status, 'badge'])} style={{color}}>
            {translatedStatus}
        </div>;
};

export default PaymentStatusBadge;