import React, {useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import TransparentButton from '../UI/Button/TransparentButton';
import TelegramChatModal from './TelegramChatModal';

const CreateTelegramChatButton: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Добавить чат<PlusCircleIcon width="20px" height="20px" />
            </TransparentButton>
            <TelegramChatModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default CreateTelegramChatButton;