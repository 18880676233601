/* eslint-disable camelcase */
import React from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';


import {DatePicker} from 'rsuite';

import Checkbox from '../../UI/Checkbox/Checkbox';
import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import IUserOutput from '../../../models/IUser';

import {userAPI} from '../../../api/userAPI';

import {tariffAPI} from '../../../api/tariffAPI';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';
import Select from '../../UI/Input/Select';
import {getTariffOption} from '../../../utils/optionsHelper';

import {getFormattedDate} from '../../../utils/datetime';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import styles from './UserTariffForm.module.scss';

interface IUserTariffFormProps {
    onSuccess: () => void,
    item?: IUserOutput,
}

interface IUserTariffForm {
    tariff: string,
    active_to?: Date | null
    endless: boolean,
}

const UserTariffForm: React.FC<IUserTariffFormProps> = ({item, onSuccess}) => {
    const [changeTariff, {isLoading: isLoadingChange}] = userAPI.useChangeUserTariffMutation();
    const {data: tariffs} = tariffAPI.useGetTariffsQuery({field: 'price', direction: 'asc'});

    const {register, handleSubmit, watch, formState, control} = useForm<IUserTariffForm>({mode: 'onChange'});
    const {errors} = formState;

    const onSubmit: SubmitHandler<IUserTariffForm> = async({
        tariff,
        endless,
        active_to = null,
    }) => {
        try {
            await changeTariff({
                id: item?.id ?? 0,
                tariff: tariff,
                active_to: endless ? null : getFormattedDate(active_to, {format: 'YYYY-MM-DD'}),
            }).unwrap();

            onSuccess();
        } catch (e) {
        }
    };

    const isEndlessChecked = watch('endless');

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <FormGroupWithError
                    error={errors?.tariff?.message}
                    label="Тариф"
                >
                    {
                        tariffs
                        && <Select
                            rules={{required: 'Выберите тариф'}}
                            control={control}
                            name="tariff"
                            data={tariffs.map(getTariffOption)}
                            placeholder="Выберите тариф"
                        />
                    }
                </FormGroupWithError>

                {
                    !isEndlessChecked
                    && <FormGroupWithError
                        error={errors?.active_to?.message}
                        label="Активен до"
                    >
                        <Controller
                            control={control}
                            name="active_to"
                            rules={{required: 'Выберите дату активности тарифа'}}
                            render={({field: {onChange, value}}) => (
                                <DatePicker
                                    value={value}
                                    onChange={onChange}
                                    format="dd.MM.yyyy"
                                    placeholder="ДД.ММ.ГГГГ"
                                    oneTap
                                    appearance="subtle"
                                />
                            )}
                        />
                    </FormGroupWithError>
                }
                <div className={styles.text}>
                    <Checkbox
                        {...register('endless')}
                    >
                        Бессрочно?
                    </Checkbox>
                </div>
            </ModalBodyWrapper>
            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingChange}
                    className="full-width"
                >
                    Назначить
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default UserTariffForm;