// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoxTransparent_transparent_box__vFBlN {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 30px;
}
@media (max-width: 767px) {
  .BoxTransparent_transparent_box__vFBlN {
    padding: 25px 20px 35px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Box/BoxTransparent/BoxTransparent.module.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,aAAA;AACJ;AACI;EAJJ;IAKQ,4BAAA;EAEN;AACF","sourcesContent":[".transparent_box{\n    background-color: rgba(256,256,256,0.5);\n    padding: 30px;\n\n    @media (max-width: 767px) {\n        padding: 25px 20px 35px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transparent_box": `BoxTransparent_transparent_box__vFBlN`
};
export default ___CSS_LOADER_EXPORT___;
