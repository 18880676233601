import React, {FC, ReactNode} from 'react';
import cn from 'classnames';

import styles from './withSpinner.module.scss';

interface IWithSpinnerProps {
    children: ReactNode,
    isSpinnerVisible: boolean,
    styleOptions?: { [key: string]: string }
}

const WithSpinner: FC<IWithSpinnerProps> = ({children, isSpinnerVisible = false, styleOptions}) => {
    return (
        <>
            {children}

            <div
                className={cn(styles['spinner-overlay'], {[styles['spinner-overlay--show']]: isSpinnerVisible})}
                style={{
                    ...styleOptions,
                }}
            >
                {isSpinnerVisible && (
                    <div className={'sk-spinner sk-spinner-double-bounce'}>
                        <div className="sk-double-bounce1"/>
                        <div className="sk-double-bounce2"/>
                    </div>
                )}
            </div>
        </>
    );
};

export default React.memo(WithSpinner);