import React, {useCallback, useState} from 'react';
import {DateRangePicker, DateRangePickerProps} from 'rsuite';

import {Control, Controller, RegisterOptions, useController} from 'react-hook-form';

import cn from 'classnames';

import {getInputState} from '../../../../utils/inputHelper';
import Correct from '../../../Icons/Correct';
import Wrong from '../../../Icons/Wrong';
import Edit from '../../../Icons/Edit';

import styles from './DateRangePickerControlled.module.scss';

export interface IDateRangePickerControlledProps extends DateRangePickerProps{
    control: Control<any>,
    name: string,
    rules?: RegisterOptions,
}

const DateRangePickerControlled: React.FC<IDateRangePickerControlledProps> = ({
    control,
    name,
    rules,
    onChange,
    placeholder= 'Выберите диапазон дат...',
    format = 'dd.MM.yyyy',
    ...rest
}) => {
    const { fieldState } = useController({name, control});

    const [isFocused, setIsFocused] = useState(false);
    const state = getInputState(fieldState, isFocused, !!rest.defaultValue);

    const iconsMap = {
        valid: <Correct/>,
        error: <Wrong/>,
        active: <Edit color="#000000" />,
        default: <Edit />,
    };

    const classMap = {
        valid: styles.item_valid,
        error: styles.item_error,
        active: styles.item_active,
        default: styles.item_default,
    };

    const onOpen = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onClose = useCallback(() => {
        setIsFocused(false);
    }, []);
    
    return (
        <div className={styles.item_container}>
            <div className={styles.icon}>
                {iconsMap[state]}
            </div>
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={rest.defaultValue}
                /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
                render={({field: {ref, onChange: onChangeFrom, ...field}}) => (
                    <DateRangePicker
                        onEnter={onOpen}
                        onExit={onClose}
                        onChange={(value, event) => {
                            onChangeFrom(value);
                            onChange && onChange(value, event);
                        }}
                        className={cn(styles.item, classMap[state])}
                        placeholder={placeholder}
                        format={format}
                        showOneCalendar
                        preventOverflow
                        {...rest}
                        {...field}
                    />
                )}
            />
        </div>
    );
};

export default DateRangePickerControlled;