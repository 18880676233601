import React from 'react';

import {faTrash} from '@fortawesome/free-solid-svg-icons';

import IconButton, {IIconButtonProps} from './IconButton';

type ICancelButtonProps = Omit<IIconButtonProps, 'icon'>;

const TrashButton: React.FC<ICancelButtonProps> = (props) => {

    return (
        <IconButton icon={faTrash} size="1x" {...props}/>
    );
};

export default TrashButton;