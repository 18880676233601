import React from 'react';

import {Control} from 'react-hook-form';

import SelectSimple from '../Input/SelectSimple';
import HintQuestion from '../Hint/HintQuestion';

interface IDelaySelectorProps {
    control: Control<any>,
    name: string,
    value?: string | number | null,
}

const DelaySelector: React.FC<IDelaySelectorProps> = ({
    control,
    name,
    value,
}) => {
    const buildDelaySelectOptions = () => {
        const delaySelectOptions = [
            {
                value: 0,
                label: 'Без задержки',
            },
        ];

        let minute = 1;
        let minuteStep = 1;
        let delayLabel = '';
        const unitMin = ' мин.';
        const unitHour = ' час.';

        while (minute <= 720) {
            if (minute >= 20 && minute < 60) {
                minuteStep = 5;
                delayLabel = minute + unitMin;
            } else if (minute >= 60 && minute < 120) {
                minuteStep = 10;
                delayLabel = '1 ' + unitHour + (minute > 60 ? ' ' + String(minute - 60) + unitMin : '');
            } else if (minute >= 120) {
                minuteStep = 30;
                if ((minute / 60) % 1 === 0) {
                    delayLabel = String(minute / 60) + unitHour;
                } else {
                    delayLabel = String((minute / 60) - 0.5) + unitHour + ' 30' + unitMin;
                }
            } else {
                delayLabel = minute + unitMin;
            }

            delaySelectOptions.push({
                value: minute,
                label: delayLabel,
            });

            minute += minuteStep;
        }

        return delaySelectOptions;
    };

    // eslint-disable-next-line max-len
    const descHint = 'Отправлять уведомления только, если инцидент продолжается дольше указанного времени. Эта настройка влияет только на отправку уведомлений. В журнале монитора регистрируются ВСЕ инциденты независимо от их продолжительности.';

    return <>
        <div className="col-sm-2"><label className="d-flex justify-content-end">Задержка</label></div>
        <div className="col-sm-3">
            <div className="d-flex justify-content-end">
                <SelectSimple
                    defaultValue={Number(value)}
                    control={control}
                    name={name}
                    data={buildDelaySelectOptions()}
                    cleanable={false}
                    searchable={false}
                />
                <div className="p-2 align-content-center">
                    <HintQuestion placement="top">{descHint}</HintQuestion>
                </div>
            </div>
        </div>
    </>;
};

export default DelaySelector;