import React from 'react';

import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';

import FormGroup from './FormGroup';
import Alert from '../Alert/Alert';


export interface FormGroupFormGroupWithErrorProps  {
    error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
    label?: string,
    children?: React.ReactNode;
    className?: string,
}

const FormGroupWithError: React.FC<FormGroupFormGroupWithErrorProps> = ({
    error,
    label,
    children,
    className,
}) => {
    return (
        <FormGroup
            className={className}
            hasError={!!error}
        >
            {
                label && <label>{label}</label>
            }
            {children}
            {error && <Alert>{String(error)}</Alert>}
        </FormGroup>
    );
};

export default FormGroupWithError;