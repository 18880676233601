import React from 'react';
import ReactDOM from 'react-dom/client';

import {Provider} from 'react-redux';

import {BrowserRouter as Router} from 'react-router-dom';
import {CustomProvider} from 'rsuite';
import ru from 'rsuite/locales/ru_RU';

import {makeStore} from './store';
import 'react-toastify/dist/ReactToastify.css';

import './styles/globals.scss';

import App from './App';

const store = makeStore();

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <CustomProvider locale={ru}>
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </CustomProvider>
    </React.StrictMode>,
);