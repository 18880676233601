// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_icon_button__b9LMW {
  outline: none;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
}

.IconButton_text__gv0EL {
  color: #7D8797;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin-left: 10px;
}

.IconButton_rotate__Xnssf {
  animation: IconButton_spin__AV\\+Cv 1s;
}

@keyframes IconButton_spin__AV\\+Cv {
  100% {
    transform: rotate(-360deg);
  }
}
.IconButton_refresh__4pUSt {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AACA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACE,iBAAA;AAEJ;;AACA;EACI,qCAAA;AAEJ;;AACA;EACI;IACI,0BAAA;EAEN;AACF;AAAA;EACE,eAAA;AAEF","sourcesContent":[".icon_button{\n    outline: none;\n    border: 0;\n    background: transparent;\n    display: flex;\n    align-items: center;\n}\n.text{\n  color: #7D8797;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 21px;\n    margin-left: 10px;\n}\n\n.rotate {\n    animation: spin 1s;\n}\n\n@keyframes spin {\n    100% {\n        transform:rotate(-360deg);\n    }\n}\n.refresh{\n  cursor:pointer\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_button": `IconButton_icon_button__b9LMW`,
	"text": `IconButton_text__gv0EL`,
	"rotate": `IconButton_rotate__Xnssf`,
	"spin": `IconButton_spin__AV+Cv`,
	"refresh": `IconButton_refresh__4pUSt`
};
export default ___CSS_LOADER_EXPORT___;
