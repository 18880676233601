import {timezones} from 'constants/timezones';

import React from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import FormGroup from 'components/UI/FormGroup/FormGroup';
import PrimaryButton from 'components/UI/Button/PrimaryButton';
import Select from 'components/UI/Input/Select';
import IUserOutput from 'models/IUser';

import {userAPI} from '../../../api/userAPI';
import IProfileForm from '../../../models/forms/IProfileForm';
import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';
import InputName from '../../UI/Input/InputName';
import {getClientDefaultTimeZone} from '../../../utils/datetime';
import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

interface IUserEditFormProps{
    onSuccess: () => void,
    item: IUserOutput,
}

const UserEditForm: React.FC<IUserEditFormProps> = ({item, onSuccess}) => {
    const [changeTariff, {isLoading}] = userAPI.useUpdateUserMutation();
    const {register, handleSubmit, formState, control} = useForm<IProfileForm>({mode: 'onChange'});
    const {errors, isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IProfileForm> = async({timezone, ...rest}) => {
        try {
            await changeTariff({
                id: item?.id ?? 0,
                timezone: timezone ?? getClientDefaultTimeZone(),
                ...rest,
            }).unwrap();
            onSuccess();
        } catch (e) {
        }
    };

    return <form
        onSubmit={handleSubmit(onSubmit)}
        className="profile-form"
    >
        <ModalBodyWrapper>
            <FormGroupWithError
                error={errors?.first_name?.message}
                label="Имя"
            >
                <InputName
                    name="first_name"
                    placeholder="Имя"
                    control={control}
                    register={register}
                    defaultValue={item.first_name}
                    required
                />
            </FormGroupWithError>

            <FormGroupWithError
                error={errors?.last_name?.message}
                label="Фамилия"
            >
                <InputName
                    name="last_name"
                    placeholder="Фамилия"
                    control={control}
                    register={register}
                    defaultValue={item.last_name}
                    required
                />
            </FormGroupWithError>

            <FormGroupWithError
                error={errors?.timezone?.message}
                label="Часовой пояс"
            >
                <Select
                    control={control}
                    name="timezone"
                    data={timezones.map(zone => ({value: zone, label: zone}))}
                    defaultValue={item?.timezone ?? getClientDefaultTimeZone()}
                />
            </FormGroupWithError>
        </ModalBodyWrapper>
        <FormGroup>
            <PrimaryButton
                type="submit"
                loading={isLoading}
                className="full-width"
                disabled={!isDirty || !isValid}
            >
                        Сохранить
            </PrimaryButton>
        </FormGroup>
    </form>;
};

export default UserEditForm;