/* eslint-disable max-len */
import React from 'react';

const LogoOwner: React.FC<object> = () => {

    return <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.46472 8.35558C6.8486 7.25298 7.14709 7.85508 7.14709 5.65158C7.14709 2.53017 9.0966 0 11.5012 0C13.9058 0 15.8553 2.53017 15.8553 5.65158C15.8553 7.85508 16.1538 7.25298 18.5376 8.35563C20.1848 9.1173 21.5975 9.85537 21.8362 11.6542L22.9798 20.2745C22.9635 20.6423 22.6421 21.5039 21.4868 21.7686C20.4803 21.9995 19.1826 22.1741 17.7256 22.2971L18.0078 17.6696C18.0274 17.3439 17.69 17.0768 17.3011 17.0768H5.69894C5.31009 17.0768 4.97288 17.3436 4.99226 17.6696L5.27295 22.2737C3.79599 22.1485 2.52279 21.9782 1.60918 21.7686C0.458714 21.5046 -0.0173347 20.7453 0.0225006 20.2745L1.1661 11.6543C1.40471 9.85542 2.81765 9.11734 4.46467 8.35567L4.46472 8.35558ZM8.22726 7.30219C8.69811 7.11221 8.70394 7.06758 9.26119 6.84422C9.87316 6.59812 10.3406 6.91366 11.5996 6.88594C12.8587 6.85795 13.2725 6.56667 14.0315 6.96507C14.8646 7.40218 15.2152 7.55448 14.7944 7.88711C13.9997 8.51568 12.5171 9.58554 11.6917 9.60438C10.7513 9.62601 7.35524 7.6538 8.22726 7.30219Z" fill="#bdbcbc"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.69891 16.7943H17.3011L17.3706 16.7971C17.6096 16.8121 17.8323 16.9028 17.9959 17.0411C18.1772 17.1938 18.291 17.405 18.291 17.6426C18.0622 19.2123 18.0574 21.4876 17.9566 23.1413C17.9418 23.3859 17.8231 23.6071 17.6361 23.7637C17.4624 23.9094 17.2289 24.0001 16.9686 24.0001H6.03141C5.77087 24.0001 5.5376 23.9096 5.36372 23.7638C5.17706 23.6058 5.05801 23.3853 5.04343 23.1413C4.94258 21.4876 4.93778 19.2123 4.70895 17.6428C4.70895 17.405 4.82294 17.1938 5.00422 17.0409C5.16832 16.9022 5.3919 16.8113 5.63194 16.7969L5.69887 16.7943H5.69891ZM17.3011 17.3592L5.66496 17.3595C5.54886 17.3665 5.44304 17.4082 5.36839 17.4712C5.31043 17.5201 5.27387 17.581 5.27387 17.6428L5.60614 23.1083C5.6113 23.1938 5.65545 23.2731 5.72516 23.3318C5.80214 23.3952 5.90881 23.4351 6.03146 23.4351H16.9686C17.0913 23.4351 17.1978 23.3952 17.2743 23.3311C17.3443 23.2725 17.389 23.1931 17.3939 23.1083L17.7272 17.6426C17.7262 17.581 17.6896 17.5202 17.6318 17.4715C17.5572 17.4084 17.4516 17.3666 17.3354 17.3597L17.3012 17.3592H17.3011Z" fill="#bdbcbc"/>
    </svg>;
};

export default LogoOwner;