import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Payments from '../components/Admin/Payments/Payments';

const AdminPaymentsPage: React.FC = () => {

    return (
        <MainLayout>
            <Payments />
        </MainLayout>
    );
};

export default AdminPaymentsPage;