import React from 'react';

import Exit from '../../Icons/Exit';
import {IIconButtonProps} from './IconButton';

type IExitButtonProps = Omit<IIconButtonProps, 'icon'>;

const ExitButton: React.FC<IExitButtonProps> = () => {

    return (
        <Exit/>
    );
};

export default ExitButton;