/* eslint-disable max-len */
import React from 'react';

const Arrow: React.FC<object> = () => {

    return <svg width="67" height="58" viewBox="0 0 67 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.833333 54.3711C0.833333 55.8439 2.02724 57.0378 3.5 57.0378C4.97276 57.0378 6.16667 55.8439 6.16667 54.3711C6.16667 52.8983 4.97276 51.7044 3.5 51.7044C2.02724 51.7044 0.833333 52.8983 0.833333 54.3711ZM36.1901 6.01754C35.9949 6.2128 35.9949 6.52939 36.1901 6.72465L39.3721 9.90663C39.5674 10.1019 39.8839 10.1019 40.0792 9.90663C40.2745 9.71137 40.2745 9.39478 40.0792 9.19952L37.2508 6.37109L40.0792 3.54267C40.2745 3.3474 40.2745 3.03082 40.0792 2.83556C39.8839 2.6403 39.5674 2.6403 39.3721 2.83556L36.1901 6.01754ZM3.5 54.8711C13.9419 54.8711 25.6118 50.4915 32.5221 42.5678L31.7684 41.9106C25.0828 49.5766 13.7104 53.8711 3.5 53.8711V54.8711ZM32.5183 41.9063C27.2223 35.9723 26.0177 31.1098 26.6878 27.6753C27.3587 24.2368 29.9241 22.1147 32.4397 21.6357C33.6959 21.3966 34.916 21.5705 35.8843 22.1636C36.8438 22.7512 37.6049 23.7795 37.9037 25.3504C38.2045 26.9321 38.0357 29.0705 37.0963 31.8332C36.1579 34.5931 34.4587 37.9513 31.7319 41.9579L32.5586 42.5205C35.3199 38.4633 37.0677 35.0238 38.0431 32.1551C39.0175 29.2893 39.2293 26.9685 38.886 25.1635C38.5407 23.3476 37.6327 22.0617 36.4066 21.3108C35.1893 20.5653 33.7071 20.3765 32.2527 20.6534C29.3471 21.2067 26.4578 23.6327 25.7063 27.4838C24.9542 31.3388 26.3597 36.5075 31.7722 42.5721L32.5183 41.9063ZM31.8403 42.6355C39.2612 48.3457 46.5631 48.9543 52.517 46.572C58.4445 44.2003 62.9698 38.8907 64.9307 32.8807C66.8936 26.8642 66.3027 20.0874 61.9081 14.8131C57.5161 9.54195 49.4089 5.87109 36.5437 5.87109V6.87109C49.2357 6.87109 57.0049 10.4906 61.1398 15.4532C65.272 20.4126 65.8583 26.8131 63.98 32.5705C62.0995 38.3342 57.7651 43.3951 52.1455 45.6436C46.5524 47.8815 39.6199 47.3599 32.4501 41.8429L31.8403 42.6355Z"
            fill="#FE974A"/>
        <path
            d="M32.1454 42.2392C10.7285 18.2421 54.0983 9.984 32.1454 42.2392ZM32.1454 42.2392C61.3266 64.6937 87.6583 6.37109 36.5439 6.37109"
            stroke="#FE974A" strokeWidth="1.5" strokeMiterlimit="2.61313"/>
    </svg>;
};

export default Arrow;