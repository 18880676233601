import React, {MouseEvent, useState} from 'react';

import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import {Control, useController} from 'react-hook-form';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import Input, {InputProps} from './Input';

import {getInputState} from '../../../utils/inputHelper';

import Lock from '../../Icons/Lock';
import Correct from '../../Icons/Correct';
import Wrong from '../../Icons/Wrong';

import styles from './Input.module.scss';

type PasswordInputProps = Omit<InputProps, 'type' >;

interface IPasswordInputProps extends PasswordInputProps {
    control: Control<any>;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({ control, ...rest }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const { fieldState } = useController({
        name: rest.name ?? 'password',
        control,
    });
    const state = getInputState(fieldState, isFocused);

    const iconsMap = {
        valid: <Correct />,
        error: <Wrong />,
        active: <Lock color="#000000" />,
        default: <Lock />,
    };

    const classMap = {
        valid: styles.input_valid,
        error: styles.input_error,
        active: styles.input_active,
        default: styles.input_default,
    };

    const toggleVisibility = (event: MouseEvent<SVGSVGElement>) => {
        event.preventDefault();
        setIsVisible((prev) => !prev);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <div className={styles.input_container}>
            <div className={styles.icon}>
                {iconsMap[state]}
            </div>

            <Input
                type={isVisible ? 'text' : 'password'}
                name="password"
                placeholder="Пароль"
                className={cn(styles.input, classMap[state])}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                rules={{
                    minLength: {
                        value: 10,
                        message: 'Не менее 10 символов',
                    },
                    required: 'Необходимо ввести пароль',
                }}
                {...rest}
            />


            <FontAwesomeIcon
                className={styles.eye}
                icon={isVisible ? faEye : faEyeSlash}
                size={'1x'}
                color="grey"
                title={isVisible ? 'Скрыть пароль' : 'Показать пароль'}
                onClick={toggleVisibility}
                tabIndex={-1}
            />

        </div>
    );
};

export default PasswordInput;