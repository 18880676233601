import React from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';

import EmailInput from '../../UI/Input/EmailInput';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import {AppUrls} from '../../../constants/urls';


import {TypedDispatch} from '../../../types';
import {isPasswordResettingSelector, passwordResetRequest} from '../../../redux/authSlice';
import IPasswordResetRequestForm from '../../../models/forms/IPasswordResetRequestForm';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import AuthWrapper from '../AuthWrapper';

import styles from '../Auth.module.scss';

const PasswordResetInit: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();

    const isLoading = useSelector(isPasswordResettingSelector);
    const {
        register,
        handleSubmit,
        getValues,
        formState,
        control,
    } = useForm<IPasswordResetRequestForm>({mode: 'onChange'});
    const {errors} = formState;

    const onSubmit: SubmitHandler<IPasswordResetRequestForm> = async(formData) => {
        try {
            const resultAction = await dispatch(passwordResetRequest(formData.email));
            const response = unwrapResult(resultAction);
            if (response.status) {
                navigate(`${AppUrls.PASSWORD_RESET_SENT}?email=${getValues('email')}`);
            }
        }catch (e){}
    };

    return (
        <AuthWrapper>
            <div className={styles.title_container}>
                <h2 className={cn(styles.title, styles.reset_title)}>Восстановление доступа</h2>
                <p>Введите адрес email вашего аккаунта и мы пришлем на него ссылку для сброса пароля.</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="reset-password-form">

                <FormGroupWithError
                    error={errors?.email?.message}
                >
                    <EmailInput
                        register={register}
                        required
                        control={control}
                        placeholderToLabel
                    />
                </FormGroupWithError>

                <div className="form-group">
                    <PrimaryButton
                        type="submit"
                        loading={isLoading}
                        className="full-width"
                    >Сбросить пароль</PrimaryButton>
                </div>
            </form>

            <p className={cn(styles.new_account_wrapper, 'text-muted')}>
                        Вспомнили? <Link to={AppUrls.LOGIN} className={styles.register_link}>Войти!</Link>
            </p>
        </AuthWrapper>
    );
};

export default PasswordResetInit;