// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationBanner_wrapper__gN9IO {
  width: 215px;
  padding: 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 0 20px 0 #0000000D;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/NavigationBanner/NavigationBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gCAAA;AACJ","sourcesContent":[".wrapper{\n    width: 215px;\n    padding: 20px;\n    border-radius: 12px;\n    background: #FFF;\n    box-shadow: 0 0 20px 0 #0000000D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NavigationBanner_wrapper__gN9IO`
};
export default ___CSS_LOADER_EXPORT___;
