// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderCentered_wrapper__lA3Mq {
  position: absolute;
  height: 18px;
  width: 18px;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
.LoaderCentered_wrapper__lA3Mq .rs-loader-spin::after {
  border-color: #1db5b6 transparent transparent;
}
.LoaderCentered_wrapper__lA3Mq .rs-loader-spin::before {
  border: 3px solid #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Loader/LoaderCentered.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,SAAA;EACA,6BAAA;AACJ;AACI;EACI,6CAAA;AACR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".wrapper {\n    position: absolute;\n    height: 18px;\n    width: 18px;\n    top: 20px;\n    left: 50%;\n    transform: translate(-50%, 0);\n\n    :global(.rs-loader-spin::after) {\n        border-color: #1db5b6 transparent transparent;\n    }\n\n    :global(.rs-loader-spin::before) {\n        border: 3px solid #ffffff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoaderCentered_wrapper__lA3Mq`
};
export default ___CSS_LOADER_EXPORT___;
