import React from 'react';

import {useSearchParams} from 'react-router-dom';

import cn from 'classnames';

import Box from '../../UI/Box/Box';
import {AppUrls} from '../../../constants/urls';
import LinkButton from '../../UI/Button/LinkButton';

import styles from '../Auth.module.scss';

const PasswordResetSent: React.FC = () => {
    const [query] = useSearchParams();
    const email = query.get('email') ?? '';
    return (
        <div className={styles.wrapper}>
            <div className={cn(styles.box_wrapper, styles.reset_sent)}>
                <Box>
                    <div className={styles.title_container}>
                        <h2 className={styles.title}>Восстановление доступа</h2>
                        <p>На указанный вами адрес {email} отправлено письмо,
                            содержащее ссылку для сброса пароля.</p>
                    </div>

                    <LinkButton
                        href={AppUrls.LOGIN}
                        className="rs-btn-primary full-width mb-2 mb-sm-0"
                    >Продолжить</LinkButton>
                </Box>
            </div>
        </div>
    );
};

export default PasswordResetSent;