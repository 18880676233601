// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpinnerWithOverlay_spinner-overlay__F-SWU {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999999;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SpinnerWithOverlay/SpinnerWithOverlay.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,eAAA;AACF","sourcesContent":[".spinner-overlay {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  z-index: 999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-overlay": `SpinnerWithOverlay_spinner-overlay__F-SWU`
};
export default ___CSS_LOADER_EXPORT___;
