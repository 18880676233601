import React, {ComponentProps, ElementType} from 'react';
import {RegisterOptions} from 'react-hook-form';

interface BaseInputProps {
    register?: any;
    rules?: RegisterOptions;
    placeholderToLabel?: boolean;
}

export type InputProps = BaseInputProps &
    Omit<Omit<ComponentProps<'input'>, keyof BaseInputProps>, 'ref'>;

const InputHidden = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            name,
            register,
            rules,
            ...rest
        },
        ref
    ) => {

        const Component: ElementType<InputProps> = 'input';
        return (
            <>
                <Component
                    ref={ref}
                    name={name}
                    type={'hidden'}
                    {...(register &&
                        register(`${name}`, {...rules}))}
                    {...rest}
                />
            </>
        );
    }
);

InputHidden.displayName = 'InputHidden';

export default React.memo(InputHidden);