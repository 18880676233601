/* eslint-disable camelcase */
import React from 'react';
import {Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue} from 'react-hook-form';

import {useSelector} from 'react-redux';

import {Panel} from 'rsuite';

import IMonitorOutput from '../../../models/Monitor/IMonitor';

import {userSelector} from '../../../redux/authSlice';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import ExtraSettingsSubform from './ExtraSettingsSubform';

import CheckingIntervalSliderInput from '../../UI/Slider/CheckingIntervalSliderInput';

import NotifySettingsSubform from './NotifySettingsSubform';

import styles from './MonitorForm.module.scss';


interface IMonitorCommonSettingsSubformProps {
    item?: IMonitorOutput,
    getValues: UseFormGetValues<any>,
    errors: FieldErrors<any>,
    setValue: UseFormSetValue<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
}

const MonitorCommonSettingsSubform: React.FC<IMonitorCommonSettingsSubformProps> = ({
    item,
    getValues,
    errors,
    setValue,
    control,
    register,
}) => {
    const user = useSelector(userSelector);

    return (
        <>
            <FormGroupWithError
                error={errors?.check_interval?.message}
            >
                <CheckingIntervalSliderInput
                    name="check_interval"
                    setValue={setValue}
                    minimalValue={user?.tariff?.monitor_min_check_interval_limit ?? 5}
                    defaultValue={item?.check_interval ?? 5}
                />
            </FormGroupWithError>

            <Panel header="Уведомления" defaultExpanded={true} collapsible bordered className={styles.panel}>
                <NotifySettingsSubform
                    item={item}
                    errors={errors}
                    control={control}
                    register={register}
                />
            </Panel>

            <Panel header="Расширенные настройки" collapsible bordered className={styles.panel}>
                <ExtraSettingsSubform
                    item={item}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    register={register}
                />
            </Panel>
        </>);
};

export default MonitorCommonSettingsSubform;