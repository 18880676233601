import React from 'react';
import {Routes} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';

import GridIcon from '@rsuite/icons/Grid';

import NoticeIcon from '@rsuite/icons/Notice';

import RemindOutlineIcon from '@rsuite/icons/RemindOutline';

import GearIcon from '@rsuite/icons/Gear';

import PeopleGroupIcon from '@rsuite/icons/legacy/PeopleGroup';

import {Realtime} from '@rsuite/icons/lib/icons/legacy';

import CreditCardMinusIcon from '@rsuite/icons/CreditCardMinus';

import ListIcon from '@rsuite/icons/List';

import SettingIcon from '@rsuite/icons/Setting';

import {AppUrls} from './constants/urls';

import {fetchMe, isAuthenticatedSelector, isUserLoadingSelector, userSelector} from './redux/authSlice';
import {TypedDispatch} from './types';
import {getProtectedRoute, IProtectedRouteProps} from './utils/permissionsHelper';
import {getAccessToken, getRefreshToken} from './utils/auth';
import NoAuth from './pages/NoAuth';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import HomePage from './pages/HomePage';
import {Permission} from './models/Permission';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import ConfirmChangeEmailPage from './pages/ConfirmChangeEmailPage';
import PasswordResetInitPage from './pages/password/PasswordResetInitPage';
import PasswordResetSentPage from './pages/password/PasswordResetSentPage';
import NewPasswordPage from './pages/password/NewPasswordPage';
import ProfilePage from './pages/ProfilePage';
import {Pages} from './constants/pages';
import NotFound from './pages/NotFound';
import MonitorUnsubscribePage from './pages/MonitorUnsubscribePage';
import MonitorsPage from './pages/MonitorsPage';
import MonitorLogsPage from './pages/MonitorLogsPage';
import MonitorIncidentsPage from './pages/MonitorIncidentsPage';
import AdminTariffsPage from './pages/AdminTariffsPage';
import TariffsPage from './pages/TariffsPage';
import PaymentsPage from './pages/PaymentsPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import AdminUsersPage from './pages/AdminUsersPage';
import IncidentsPage from './pages/IncidentsPage';
import IUserOutput from './models/IUser';
import WarningsPage from './pages/WarningsPage';
import AdminPaymentsPage from './pages/AdminPaymentsPage';

export const routes: IProtectedRouteProps[] = [
    {
        path: AppUrls.LOGIN,
        element: <LoginPage/>,
    },
    {
        path: AppUrls.REGISTRATION,
        element: <RegistrationPage/>,
    },
    {
        path: AppUrls.EMAIL_CONFIRMATION,
        element: <ConfirmEmailPage/>,
    },
    {
        path: AppUrls.CHANGE_EMAIL_CONFIRMATION,
        element: <ConfirmChangeEmailPage/>,
    },
    {
        path: AppUrls.MONITOR_UNSUBSCRIBE,
        element: <MonitorUnsubscribePage />,
    },
    {
        path: AppUrls.PASSWORD_RESET,
        element: <PasswordResetInitPage/>,
    },
    {
        path: AppUrls.PASSWORD_RESET_SENT,
        element: <PasswordResetSentPage/>,
    },
    {
        path: AppUrls.NEW_PASSWORD,
        element: <NewPasswordPage/>,
    },
    {
        path: AppUrls.FORBIDDEN,
        element: <NoAuth/>,
    },
    {
        path: AppUrls.HOME,
        element: <HomePage/>,
        auth: [Permission.USER],
        title: Pages.HOME,
        icon: GridIcon,
    },
    {
        path: AppUrls.MONITORS,
        element: <MonitorsPage/>,
        auth: [Permission.USER],
        title: Pages.MONITORS,
        icon: Realtime,
    },
    {
        path: AppUrls.INCIDENTS,
        element: <IncidentsPage/>,
        auth: [Permission.USER],
        title: Pages.INCIDENTS,
        icon: RemindOutlineIcon,
    },
    {
        path: AppUrls.WARNINGS,
        element: <WarningsPage/>,
        auth: [Permission.USER],
        title: Pages.WARNINGS,
        icon: NoticeIcon,
    },
    {
        path: AppUrls.MONITOR_LOGS,
        element: <MonitorLogsPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.MONITOR_DETAILS,
        element: <MonitorIncidentsPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.PAYMENT_SUCCESS,
        element: <PaymentSuccessPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.PAYMENT_CANCEL,
        element: <PaymentCancelPage/>,
        auth: [Permission.USER],
    },
    {
        path: AppUrls.TARIFFS,
        element: <TariffsPage/>,
        auth: [Permission.USER],
        title: Pages.TARIFFS,
        icon: SettingIcon,
    },
    {
        path: AppUrls.ADMIN_TARIFFS,
        element: <AdminTariffsPage/>,
        auth: [Permission.ADMIN],
        title: Pages.ADMIN_TARIFFS,
        icon: ListIcon,
    },
    {
        path: AppUrls.ADMIN_PAYMENTS,
        element: <AdminPaymentsPage/>,
        auth: [Permission.ADMIN],
        title: Pages.ADMIN_PAYMENTS,
        icon: ListIcon,
    },
    {
        path: AppUrls.PAYMENTS,
        element: <PaymentsPage/>,
        auth: [Permission.USER],
        title: Pages.PAYMENTS,
        icon: CreditCardMinusIcon,
        condition: (user: IUserOutput) => user.has_payments,
    },
    {
        path: AppUrls.ADMIN_USERS,
        element: <AdminUsersPage/>,
        auth: [Permission.ADMIN],
        title: Pages.USERS,
        icon: PeopleGroupIcon,
    },
    {
        path: AppUrls.SETTINGS,
        element: <ProfilePage/>,
        auth: [Permission.USER],
        title: Pages.SETTINGS,
        icon: GearIcon,
    },
    {
        path: AppUrls.NOT_FOUND,
        element: <NotFound/>,
    },
];

const Router = () => {
    const user = useSelector(userSelector);
    const isUserLoading = useSelector(isUserLoadingSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const dispatch = useDispatch<TypedDispatch>();

    if (
        (isAuthenticated || getAccessToken() || getRefreshToken())
        && null === user
        && !isUserLoading
    ) {
        dispatch(fetchMe());
    }

    return (
        <Routes>
            {
                routes.map(routeProps => getProtectedRoute(routeProps))
            }
        </Routes>
    );
};

export default Router;