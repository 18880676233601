// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Payments_wrapper__PlegW {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
}
@media (max-width: 700px) {
  .Payments_wrapper__PlegW {
    grid-template-rows: auto min-content;
  }
}

.Payments_box_wrapper__isfXk {
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
}
@media (max-width: 700px) {
  .Payments_box_wrapper__isfXk {
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
  }
}

.Payments_text__AeKgO {
  font-size: 16px;
  text-align: center;
}

.Payments_head__fb62t {
  padding: 0 30px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.Payments_header__raWlY {
  display: flex;
  align-items: center;
  gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Payments/Payments.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;AACF;AACE;EAPF;IAQI,oCAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;AAEF;AAAE;EARF;IASI,8BAAA;IACA,YAAA;IACA,sBAAA;EAGF;AACF;;AAAA;EACE,eAAA;EACA,kBAAA;AAGF;;AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGF","sourcesContent":[".wrapper {\n  width: 100%;\n  min-height: 100%;\n  height: 100%;\n  display: grid;\n  justify-items: center;\n\n  @media (max-width: 700px) {\n    grid-template-rows: auto min-content;\n  }\n}\n\n.box_wrapper {\n  width: 100%;\n  max-width: 360px;\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n  justify-content: center;\n\n  @media(max-width: 700px) {\n    justify-content: space-between;\n    height: 100%;\n    box-sizing: border-box;\n  }\n}\n\n.text {\n  font-size: 16px;\n  text-align: center;\n}\n\n.head {\n  padding: 0 30px;\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n  justify-content: space-between;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Payments_wrapper__PlegW`,
	"box_wrapper": `Payments_box_wrapper__isfXk`,
	"text": `Payments_text__AeKgO`,
	"head": `Payments_head__fb62t`,
	"header": `Payments_header__raWlY`
};
export default ___CSS_LOADER_EXPORT___;
