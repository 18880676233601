// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_col__HotFO {
  margin-bottom: 15px;
}
@media (max-width: 769px) {
  .Home_col__HotFO {
    padding: 0 !important;
  }
}
.Home_col__HotFO button {
  margin-bottom: -7px;
}
@media (max-width: 550px) {
  .Home_col__HotFO button {
    font-size: 13px;
    margin-bottom: -2px;
  }
}

.Home_wrapper__cLM2H .block_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.Home_wrapper__cLM2H .block_header .block_title {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}
@media (max-width: 550px) {
  .Home_wrapper__cLM2H .block_header .block_title {
    font-size: 14px;
    line-height: unset;
  }
}

.Home_full_block__erZAS {
  width: 100%;
}

.Home_transparent_button__yeeyx {
  margin-bottom: -7px;
}
@media (max-width: 550px) {
  .Home_transparent_button__yeeyx {
    font-size: 13px !important;
    margin-bottom: -2px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EAHF;IAII,qBAAA;EAEF;AACF;AAAE;EACE,mBAAA;AAEJ;AAAI;EAHF;IAII,eAAA;IACA,mBAAA;EAGJ;AACF;;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACE,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACN;AACM;EANF;IAOI,eAAA;IACA,kBAAA;EAEN;AACF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;AAEE;EAHF;IAII,0BAAA;IACA,mBAAA;EACF;AACF","sourcesContent":[".col {\n  margin-bottom: 15px;\n\n  @media (max-width: 769px) {\n    padding: 0 !important;\n  }\n\n  :global(button) {\n    margin-bottom: -7px;\n\n    @media (max-width: 550px) {\n      font-size: 13px;\n      margin-bottom: -2px;\n    }\n  }\n}\n\n.wrapper {\n  :global(.block_header) {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 30px;\n\n    :global(.block_title) {\n      font-family: Inter, sans-serif;\n      font-weight: 600;\n      font-size: 17px;\n      line-height: 20px;\n\n      @media (max-width: 550px) {\n        font-size: 14px;\n        line-height: unset;\n      }\n    }\n  }\n}\n\n.full_block {\n  width: 100%;\n}\n\n.transparent_button {\n  margin-bottom: -7px;\n\n  @media (max-width: 550px) {\n    font-size: 13px !important;\n    margin-bottom: -2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col": `Home_col__HotFO`,
	"wrapper": `Home_wrapper__cLM2H`,
	"full_block": `Home_full_block__erZAS`,
	"transparent_button": `Home_transparent_button__yeeyx`
};
export default ___CSS_LOADER_EXPORT___;
