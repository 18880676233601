import {MonitorStatus} from '../models/Monitor/IMonitor';
import {PaymentStatus} from '../models/PaymentStatus';

export const colorMonitorStatus = (status: MonitorStatus): string => {
    const map = {
        active: '#00B86B',
        paused: '#a3a3a3',
        unsubscribed: '#F44336',
    };

    return map[status];
};

export const colorMonitorPingMessage = (code: number): string => {
    switch (true){
        case code === 0:
        case code >= 400:
            return  '#F44336';
        case code >=200 && code < 300:
            return '#00B86B';
        default:
            return '#a3a3a3';
    }
};

export const colorIncidentStatus = (resolved: boolean): string => {
    return resolved ? '#00B86B':'#F44336';
};


export const colorPaymentStatus = (status: PaymentStatus): string => {
    const map = {
        [PaymentStatus.Created]: '#a3a3a3',
        [PaymentStatus.Paid]: '#00B86B',
        [PaymentStatus.Canceled]: '#F44336',
    };

    return map[status];
};