import React, {useCallback, useMemo} from 'react';

import Table from '../../UI/Table/Table';
import TableBox from '../../UI/Box/TableBox/TableBox';
import {Pages} from '../../../constants/pages';
import CreateTariffVariantsButton from './CreateTariffVariantsButton';
import TariffVariantRow from './TariffVariantRow';

import {ITariffVariant} from '../../../models/ITariffVariant';

import {tariffVariantAPI} from '../../../api/tariffVariantAPI';

import styles from '../Tariffs/Tariffs.module.scss';

const TABLE_COLUMNS = [
    {label: 'ID', sort: 'id', key: 'id'},
    {label: 'Скидка', sort: 'discount', key: 'discount'},
    {label: 'Кол-во дней', sort: 'period', key: 'period'},
    {label: 'Тарифы', key: 'tariffs'},
    {label: '', key: 'actions'},
];

const TariffVariants: React.FC = () => {
    const {data: items, isLoading} = tariffVariantAPI.useGetTariffVariantsQuery(undefined);

    const processedItems = useMemo(() => {
        return (items || [])
            .filter(item => item.id !== 0)
            .sort((a, b) => a.id - b.id);
    }, [items]);

    const renderRow = useCallback((item: ITariffVariant) => (
        <TariffVariantRow key={item.id} item={item} />
    ), []);


    return (
        <TableBox className={styles.table_box}>
            <div className={styles.head}>
                <h3>{Pages.ADMIN_TARIFF_VARIANTS}</h3>
                <CreateTariffVariantsButton/>
            </div>
            <Table
                columns={TABLE_COLUMNS}
                items={processedItems}
                rowFactory={renderRow}
                isLoading={isLoading}
                className={styles.tariffs_admin}
                aria-rowcount={items?.length}
            />
        </TableBox>
    );
};

export default React.memo(TariffVariants);