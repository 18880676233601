/* eslint-disable max-len */
import React from 'react';

const Incidents: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68818)">
                <path d="M8 4C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5L8.66667 9.33333C8.66667 9.59855 8.89464 9.8529 8.70711 10.0404C8.51957 10.228 8.26522 10.3333 8 10.3333C7.73478 10.3333 7.52087 10.228 7.33333 10.0404C7.1458 9.8529 7.33333 9.59855 7.33333 9.33333L7 5C7 4.73478 7.10536 4.48043 7.29289 4.29289C7.48043 4.10536 7.73478 4 8 4ZM8 11.3333C8.26522 11.3333 8.51957 11.4387 8.70711 11.6262C8.89464 11.8138 9 12.0681 9 12.3333C9 12.5985 8.89464 12.8529 8.70711 13.0404C8.51957 13.228 8.26522 13.3333 8 13.3333C7.73478 13.3333 7.48043 13.228 7.29289 13.0404C7.10536 12.8529 7 12.5985 7 12.3333C7 12.0681 7.10536 11.8138 7.29289 11.6262C7.48043 11.4387 7.73478 11.3333 8 11.3333Z" fill="currentColor"/>
                <path d="M8.09299 15.5C8.06308 15.4944 8.03223 15.4915 8.00071 15.4915H3.15199C1.88717 15.4915 1.10415 15.0181 0.748217 14.374C0.386663 13.7198 0.379263 12.7351 1.00275 11.5406C1.00276 11.5406 1.00277 11.5406 1.00277 11.5406C1.00281 11.5405 1.00284 11.5404 1.00287 11.5404L3.49882 6.76514L3.49911 6.76459L5.85115 2.2781L5.85154 2.27735C6.52184 0.99348 7.32635 0.5 8.0007 0.5C8.67505 0.5 9.47955 0.993478 10.1498 2.27735L10.15 2.27776L12.5023 6.77309L12.5024 6.77329L14.9985 11.5489C15.6218 12.7428 15.6129 13.7275 15.2504 14.3821C14.8933 15.0269 14.1097 15.5 12.8494 15.5H8.09299Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68818">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Incidents;