import React from 'react';

import {getFormattedDate} from 'utils/datetime';
import IPayment from 'models/IPayment';
import PaymentStatusBadge from 'components/Status/PaymentStatusBadge';

interface IPaymentRowProps {
    item: IPayment,
    tzString?: string | null,
}

const PaymentRow: React.FC<IPaymentRowProps> = ({item, tzString}) => {

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.number}</td>
            <td>{item.user?.email}</td>
            <td>{getFormattedDate(item.created_at, {tzString})}</td>
            <td>{item.sum} руб</td>
            <td>{item.tariff.name}</td>
            <td><PaymentStatusBadge status={item.status}/></td>
            <td>{getFormattedDate(item.payed_at, {tzString})}</td>
        </tr>
    );
};

export default PaymentRow;