import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import ChangeEmailForm from './ChangeEmailForm';


interface IModalProps {
    open: boolean,
    handleClose: () => void;
}

const ChangeEmailModal: React.FC<IModalProps> = ({open, handleClose}) => {

    return <MainModal
        header="Изменить E-mail"
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <ChangeEmailForm />
    </MainModal>;
};

export default ChangeEmailModal;