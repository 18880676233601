// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMenu_list__RhCMB {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 0 32px 20px;
  margin: 0;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #616E6E !important;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a .MainMenu_icon__Uyxe9 {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  color: #1DB5B6 !important;
  transition: color 0.2s;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a:hover {
  color: #383838 !important;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a:hover .MainMenu_icon__Uyxe9 {
  color: #383838 !important;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a.MainMenu_active_link__wFSUg {
  color: #383838 !important;
}
.MainMenu_list__RhCMB .MainMenu_item__h1Urr a.MainMenu_active_link__wFSUg .MainMenu_icon__Uyxe9 {
  color: #383838 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/MainMenu/MainMenu.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;EACA,qBAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACZ;AAEY;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,sBAAA;AAAhB;AAGY;EACI,yBAAA;AADhB;AAGgB;EACI,yBAAA;AADpB;AAKY;EACI,yBAAA;AAHhB;AAKgB;EACI,yBAAA;AAHpB","sourcesContent":[".list {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    padding: 0 0 32px 20px;\n    margin: 0;\n\n    .item {\n        display: flex;\n        align-items: center;\n        list-style-type: none;\n\n        a {\n            display: flex;\n            align-items: center;\n            gap: 10px;\n            color: #616E6E !important;\n            text-decoration: none;\n            font-family: Inter, sans-serif;\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 20px;\n\n            // Базовый стиль иконки\n            .icon {\n                display: flex;\n                align-items: center;\n                width: 16px;\n                height: 16px;\n                color: #1DB5B6 !important;\n                transition: color 0.2s;\n            }\n\n            &:hover {\n                color: #383838 !important;\n\n                .icon {\n                    color: #383838 !important;\n                }\n            }\n\n            &.active_link {\n                color: #383838 !important;\n\n                .icon {\n                    color: #383838 !important;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `MainMenu_list__RhCMB`,
	"item": `MainMenu_item__h1Urr`,
	"icon": `MainMenu_icon__Uyxe9`,
	"active_link": `MainMenu_active_link__wFSUg`
};
export default ___CSS_LOADER_EXPORT___;
