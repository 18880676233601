import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Payments from '../components/Payment/Payments';

const PaymentsPage: React.FC = () => {
    return (
        <MainLayout>
            <Payments />
        </MainLayout>
    );
};

export default PaymentsPage;