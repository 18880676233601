import React from 'react';

import IWarning from '../../models/IWarning';
import Warning from './Warning/Warning';
import MainModal from '../UI/Modal/MainModal';

interface IWarningModalProps {
    open: boolean,
    item: IWarning,
    handleClose: () => void,
    tzString?: string | null,
}

const WarningModal: React.FC<IWarningModalProps> = ({open, item, handleClose, tzString}) => {
    return (
        <MainModal
            header={<>Проблема домена <b>{item.domain}</b></>}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <Warning item={item} tzString={tzString}/>
        </MainModal>
    );
};

export default WarningModal;