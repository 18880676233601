// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmModal_title__dNXa4 {
  text-align: center;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Modal/ConfirmModal/ConfirmModal.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ","sourcesContent":[".title{\n    text-align: center;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `ConfirmModal_title__dNXa4`
};
export default ___CSS_LOADER_EXPORT___;
