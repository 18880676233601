import React from 'react';

import MainLayout from '../components/Layout/MainLayout/MainLayout';
import Users from '../components/User/Users';

const AdminUsersPage: React.FC = () => {

    return (
        <MainLayout>
            <Users />
        </MainLayout>
    );
};

export default AdminUsersPage;