import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch} from 'react-redux';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';
import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';
import InputName from '../../UI/Input/InputName';
import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';
import {telegramChatAPI} from '../../../api/telegramChatAPI';
import {ITelegramChatAdd} from '../../../models/ITelegramChat';

import {fetchMe} from '../../../redux/authSlice';

import {TypedDispatch} from '../../../types';

import styles from '../../Admin/Tariffs/TariffForm/TariffForm.module.scss';


interface ITelegramChatFormProps {
    onSuccess: () => void,
}

const TelegramChatForm: React.FC<ITelegramChatFormProps> = ({onSuccess}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [addTelegramChat, {isLoading}] = telegramChatAPI.useAddTelegramChatMutation();

    const {register, handleSubmit, formState, control} = useForm<ITelegramChatAdd>({mode: 'onChange'});
    const {errors, isValid} = formState;


    const onSubmit: SubmitHandler<ITelegramChatAdd> = async(data) => {
        await addTelegramChat(data).unwrap();
        dispatch(fetchMe());
        onSuccess();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <p className="mb-3">
                    {/* eslint-disable-next-line max-len */}
                    Найдите в Telegram контакт <strong>@OverseerObserverBot</strong> и нажмите кнопку &quot;Запустить&quot; (или кликните по <a href="https://t.me/OverseerObserverBot" target="_blank" rel="noreferrer">ссылке</a>). Бот отправит Вам токен, который необходимо будет ввести ниже
                </p>
                <p className="mb-3">
                    {/* eslint-disable-next-line max-len */}
                    Бота также можно добавить в группу. Чтобы получить токен, отправьте в чат группы команду &quot;<strong>/start</strong>&quot;
                </p>

                <FormGroupWithError
                    error={errors?.token?.message}
                    label="Токен, полученный от бота"
                >
                    <InputName
                        control={control}
                        name="token"
                        placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                        register={register}
                        required
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.description?.message}
                    label="Описание"
                >
                    <InputName
                        defaultValue="Мой первый чат"
                        control={control}
                        name="description"
                        placeholder="Укажите описание"
                        register={register}
                        required
                    />
                </FormGroupWithError>
            </ModalBodyWrapper>
            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isLoading}
                    className="full-width"
                    disabled={!isValid}
                >
                    Добавить
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default TelegramChatForm;