/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import IBatchResult from '../../models/IBatchResult';
import ModalBodyWrapper from './Modal/ModalBodyWrapper';
import UpgradeTariffButton from './Button/UpgradeTariffButton';
import PrimaryButton from './Button/PrimaryButton';


interface IBatchResultProps {
    result: IBatchResult,
    handleClose: () => void;
}

const BatchResult: React.FC<IBatchResultProps> = ({ result, handleClose}) => {

    return <>
        <ModalBodyWrapper>
            <p className="text-medium">Успешно: {result.success_count}</p>
            <p className="text-medium">Ошибок: {result.errors.length}</p>
            {
                result.errors.length > 0
                && <div className="mt-2">
                    <p className="text-medium">Ошибки:</p>
                    <ol>
                        {
                            result.errors.map((error, index) => <li
                                key={`${index}_${error}`}>
                                {error}
                            </li>)
                        }
                    </ol>
                </div>
            }
        </ModalBodyWrapper>
        {
            result.show_tariff_button
                ? <UpgradeTariffButton/>
                : <PrimaryButton
                    type="button"
                    className="full-width"
                    onClick={handleClose}
                >
                    Отлично!
                </PrimaryButton>
        }
    </>;
};

export default BatchResult;