import React from 'react';

import {toast} from 'react-toastify';

import Box from '../UI/Box/Box';

import PrimaryButton from '../UI/Button/PrimaryButton';

import styles from './MonitorUnsubscribe.module.scss';

interface IMonitorUnsubscribeProps {
    unsubscribeHandler: () => Promise<{success: boolean}>,
    domain: string,
}

const MonitorUnsubscribe: React.FC<IMonitorUnsubscribeProps> = ({unsubscribeHandler, domain}) => {

    const unsubscribe = async() => {
        const result = await unsubscribeHandler();
        if (result.success) {
            toast.info('Вы успешно отписались от мониторинга Вашего сайта');
        } else {
            toast.error('Произошла ошибка при отписке от мониторинга Вашего сайта');
        }
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <p className={styles.text}>
                                {/* eslint-disable-next-line max-len */}
                                Вы действительно хотите запретить сервису <strong>Overseer</strong> мониторить все адреса, принадлежащие доменному имени <strong>{domain}</strong>?
                            </p>
                            <p className={styles.text}>
                                {/* eslint-disable-next-line max-len */}
                                Отменить запрет можно будет только через обращение в техническую поддержку, подтвердив что вы - владелец доменного имени
                            </p>

                            <PrimaryButton
                                onClick={unsubscribe}
                            >
                                Отписаться от мониторинга сайта
                            </PrimaryButton>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default MonitorUnsubscribe;