/* eslint-disable camelcase */
import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {Link} from 'react-router-dom';

import {Toggle} from 'rsuite';

import cn from 'classnames';

import ITariff from '../../../models/ITariff';
import Checkbox from '../../UI/Checkbox/Checkbox';
import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import {paymentAPI} from '../../../api/paymentAPI';

import {iri} from '../../../utils/stringHelper';

import {AppUrls, ROBOKASSA_PAYMENT_PAGE} from '../../../constants/urls';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import {ITariffVariant} from '../../../models/ITariffVariant';

import styles from './TariffForm.module.scss';

interface ITariffFormProps{
    onSuccess: () => void,
    item ?: ITariff,
    variant?: ITariffVariant,
}

interface ICreatePaymentForm{
    recurring: boolean,
}

const TariffForm: React.FC<ITariffFormProps> = ({item, variant}) => {
    const [addPayment, {isLoading: isLoadingCreate}] = paymentAPI.useAddPaymentMutation();
    const {register, handleSubmit} = useForm<ICreatePaymentForm>({mode: 'onChange'});

    const [isRecurringChecked, setIsRecurringChecked] = useState(false);

    const onSubmit: SubmitHandler<ICreatePaymentForm> = async({recurring}) => {
        try {
            const payment = await addPayment({
                tariff: iri('tariffs', item?.id ?? 0),
                variant: variant?.id
                    ? iri('tariff-variants', variant.id)
                    : null,
                recurring,
            }).unwrap();
            
            if (payment?.invoice_id) {
                window.location.href = ROBOKASSA_PAYMENT_PAGE(payment.invoice_id);
            }
        }catch (e) {}
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <div className={styles.title}>
                    {item?.name}
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Срок действия:</div>
                    <div className={styles.value}>{item?.period} дней</div>
                </div>

                <div className={styles.divider}/>

                <div className={styles.row}>
                    <div className={styles.label}>Стоимость:</div>
                    <div className={styles.value}>{item?.price} руб</div>
                </div>

                <div className={styles.row2}>
                    <Toggle
                        checked={isRecurringChecked}
                        onChange={setIsRecurringChecked}
                    />
                    <div className={cn(
                        styles.toggle_text,
                        {[styles.toggle_text__active]: isRecurringChecked}
                    )}>
                        Автоплатеж
                        {isRecurringChecked ? ' включен' : ' отключен'}
                    </div>
                </div>

                {
                    isRecurringChecked
                    &&
                    <>
                        <p className={styles.accent}>
                            Внимание! Автоплатежи доступны только при оплате банковской картой, Яндекс Pay и Mir Pay
                        </p>
                        <div className={styles.text_small}>
                            <Checkbox
                                id="recurring"
                                {...register('recurring')}
                                required={true}
                            >
                                {/* eslint-disable-next-line max-len */}
                                Я даю согласие на регулярные списания, на обработку персональных данных и принимаю
                                условия&nbsp;
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={AppUrls.PAYMENT_OFFER}
                                    className={styles.agree_link}
                                >публичной оферты</Link>
                            </Checkbox>
                        </div>
                    </>
                }
            </ModalBodyWrapper>

            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingCreate}
                    className="full-width"
                >
                    Оплатить
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default TariffForm;