/* eslint-disable max-len */
import React from 'react';

interface IEmailProps {
    color?: string,
}

const Email: React.FC<IEmailProps> = ({color = '#758282'}) => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3333 9C12.3333 8.11595 11.9821 7.2681 11.357 6.64298C10.7319 6.01786 9.88406 5.66667 9 5.66667C8.11595 5.66667 7.2681 6.01786 6.64298 6.64298C6.01786 7.2681 5.66667 8.11595 5.66667 9C5.66667 9.88406 6.01786 10.7319 6.64298 11.357C7.2681 11.9821 8.11595 12.3333 9 12.3333C9.88406 12.3333 10.7319 11.9821 11.357 11.357C11.9821 10.7319 12.3333 9.88406 12.3333 9ZM12.3333 9V10.25C12.3333 10.8025 12.5528 11.3324 12.9435 11.7231C13.3342 12.1138 13.8641 12.3333 14.4167 12.3333C14.9692 12.3333 15.4991 12.1138 15.8898 11.7231C16.2805 11.3324 16.5 10.8025 16.5 10.25V9C16.5 7.51664 16.0601 6.0666 15.236 4.83323C14.4119 3.59986 13.2406 2.63856 11.8701 2.07091C10.4997 1.50325 8.99168 1.35472 7.53683 1.64411C6.08197 1.9335 4.7456 2.64781 3.6967 3.6967C2.64781 4.7456 1.9335 6.08197 1.64411 7.53683C1.35472 8.99168 1.50325 10.4997 2.07091 11.8701C2.63856 13.2406 3.59986 14.4119 4.83323 15.236C6.0666 16.0601 7.51664 16.5 9 16.5C10.3168 16.5013 11.6106 16.1551 12.75 15.495" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>;
};

export default Email;