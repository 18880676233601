import React, {useState} from 'react';

import {SubmitHandler} from 'react-hook-form';

import {useDispatch} from 'react-redux';

import {unwrapResult} from '@reduxjs/toolkit';

import {useParams} from 'react-router';

import cn from 'classnames';

import Box from '../../UI/Box/Box';

import {TypedDispatch} from '../../../types';
import {setNewPassword} from '../../../redux/authSlice';

import INewPasswordForm from '../../../models/forms/INewPasswordForm';

import NewPasswordForm from './NewPasswordForm';
import NewPasswordComplete from './NewPasswordComplete';

import styles from '../Auth.module.scss';

const NewPassword: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const {userId, token} = useParams();
    const [isComplete, setIsComplete] = useState<boolean>(false);


    const onSubmit: SubmitHandler<INewPasswordForm> = (formData) => {
        if (userId && token) {
            dispatch(setNewPassword({userId: Number(userId), token, ...formData}))
                .then(resultAction => {
                    const response = unwrapResult(resultAction);
                    setIsComplete(response.status);
                });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={cn(styles.box_wrapper, styles.reset_sent)}>
                <Box>
                    {
                        isComplete
                            ? <NewPasswordComplete/>
                            : <NewPasswordForm onSubmit={onSubmit}/>
                    }
                </Box>
            </div>
        </div>
    );
};

export default NewPassword;