import React, {useEffect} from 'react';

import IPayment from '../../models/IPayment';
import Box from '../UI/Box/Box';


import LinkButton from '../UI/Button/LinkButton';
import {AppUrls} from '../../constants/urls';

import {paymentAPI} from '../../api/paymentAPI';

import {PaymentStatus} from '../../models/PaymentStatus';

import styles from './Payments.module.scss';

interface IPaymentCancelProps {
    item: IPayment
}

const PaymentCancel: React.FC<IPaymentCancelProps> = ({item}) => {
    const [updatePayment] = paymentAPI.useUpdatePaymentMutation();
    useEffect(() => {
        updatePayment({
            id: item.id,
            status: PaymentStatus.Canceled,
        });
    }, []);
    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2 className="mb-3">Отмена платежа</h2>
                            <div className={styles.text}>
                                {`Оплата тарифа "${item.tariff.name}" отменена`}
                            </div>
                        </div>
                    </div>

                    <LinkButton
                        href={AppUrls.PAYMENTS}
                        className="full-width rs-btn-primary"
                    >Продолжить</LinkButton>
                </Box>
            </div>
        </div>
    );
};

export default PaymentCancel;