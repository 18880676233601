// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip_tooltip__D3VCJ {
  --rs-tooltip-bg: var(--plate-nested-background-color);
}
.Tooltip_tooltip--light__-LzS0 {
  color: var(--text-color) !important;
  background-color: var(--rs-tooltip-bg) !important;
  border: 1px solid var(--divider-color) !important;
  font-size: 14px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  line-height: 1.2 !important;
}
.Tooltip_tooltip--light__-LzS0::after {
  border-color: var(--divider-color);
  background-color: var(--rs-tooltip-bg);
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,qDAAA;AACF;AACE;EACE,mCAAA;EACA,iDAAA;EACA,iDAAA;EACA,0BAAA;EACA,mDAAA;EACA,2BAAA;AACJ;AACI;EACE,kCAAA;EACA,sCAAA;AACN","sourcesContent":[".tooltip {\n  --rs-tooltip-bg: var(--plate-nested-background-color);\n\n  &--light {\n    color: var(--text-color) !important;\n    background-color: var(--rs-tooltip-bg) !important;\n    border: 1px solid var(--divider-color) !important;\n    font-size: 14px !important;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;\n    line-height: 1.2 !important;\n\n    &::after {\n      border-color: var(--divider-color);\n      background-color: var(--rs-tooltip-bg);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip_tooltip__D3VCJ`,
	"tooltip--light": `Tooltip_tooltip--light__-LzS0`
};
export default ___CSS_LOADER_EXPORT___;
