import React, {MouseEvent, useCallback, useState} from 'react';

import {truncate} from 'lodash';

import IIncident from '../../models/IIncident';
import IncidentStatusBadge from '../Status/IncidentStatusBadge';
import IncidentModal from './IncidentModal';

import DateWithTodayYesterdayAccent from '../UI/Date/DateWithTodayYesterdayAccent';

import styles from './Incident/Incident.module.scss';

interface IIncidentRowProps {
    item: IIncident,
    tzString?: string | null,
    hideMonitor: boolean,
}

const IncidentRow: React.FC<IIncidentRowProps> = ({item, tzString, hideMonitor}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => setIsModalOpen(false);

    const onTrClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    return (
        <>
            <tr className="clickable" onClick={onTrClick}>
                <td><IncidentStatusBadge resolved={item.resolved}/></td>
                {
                    !hideMonitor
                    && <td>{item.monitor.name}</td>
                }
                <td>{truncate(item.error, {length: 40})}</td>
                <td>
                    <DateWithTodayYesterdayAccent
                        datetime={item.created_at}
                        tzString={tzString}
                    />
                </td>
                <td className={styles.text_gray}>
                    {item.duration_formatted}
                </td>
            </tr>

            <IncidentModal open={isModalOpen} item={item} handleClose={handleClose}/>
        </>
    );
};

export default IncidentRow;