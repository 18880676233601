import React from 'react';

import IUserOutput from '../../models/IUser';
import UserEditForm from './UserEditForm/UserEditForm';
import MainModal from '../UI/Modal/MainModal';

interface IUserEditModalProps {
    open: boolean,
    item: IUserOutput,
    handleClose: () => void;
}

const UserEditModal: React.FC<IUserEditModalProps> = ({open, item, handleClose}) => {

    return (
        <MainModal
            header={<>Редактирование пользователя &quot;{item.email}&quot;</>}
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <UserEditForm onSuccess={handleClose} item={item}/>
        </MainModal>
    );
};

export default UserEditModal;