import React, {useEffect, useState} from 'react';

import Nouislider from 'nouislider-react';


import 'nouislider/distribute/nouislider.css';

import {InputNumberProps} from 'rsuite/esm/InputNumber/InputNumber';

import {UseFormSetValue} from 'react-hook-form';

import {useIsMount} from '../../../hooks/useIsMount';

import styles from './CheckingIntervalSliderInput.module.scss';

interface NonLinearSliderProps extends InputNumberProps<string | number>{
    name: string,
    setValue: UseFormSetValue<any>,
    minimalValue: number,
    defaultValue: number | undefined,
}


const unitHour = ' час.';
const unitMin = ' мин.';

const convertMinutesInput = (minutes: number) => {
    if(minutes > 60) {
        return String(minutes / 60) + unitHour;
    }

    return String(minutes) + unitMin;
};


const CheckingIntervalSliderInput: React.FC<NonLinearSliderProps> = ({
    name,
    minimalValue,
    defaultValue,
    setValue,
}) => {
    const initValueInMinutes = defaultValue === undefined ? minimalValue : defaultValue;
    const [textValue, setTextValue] = useState(convertMinutesInput(initValueInMinutes));
    const [minutesValue, setMinutesValue]   = useState(initValueInMinutes);
    const isMount = useIsMount();

    useEffect(() => {
        if (!isMount) {
            setValue(name, minutesValue, { shouldDirty: true });
        }
    }, [minutesValue, setValue]);

    const onSlide = (seconds: any) => {
        const min = seconds / 60;
        setMinutesValue(min);
        if(min < 60) {
            setTextValue(String(min) + unitMin);
        } else  {
            setTextValue(String(min / 60) + unitHour);
        }
    };

    const formatPips = {
        to: (seconds: number) => {
            const min = Number(seconds) / 60;
            if(min < 60 && (min === minimalValue || min === 10 || min === 30)) {
                return String(min) + ' м';
            } else  {
                const hour = min / 60;
                if(hour === 1 || hour === 12 || hour === 24) {
                    return String(min / 60) + ' ч';
                }
            }

            return null;
        },
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        from: (seconds: string|number) => {
            return 0;
        },
    };

    return (
        <div className={styles.container}>
            <label>Интервал проверки, {textValue}</label>
            <Nouislider
                className={'slider-styled ' + styles.slider}
                connect={[true, false]}
                start={initValueInMinutes * 60}
                behaviour="tap"
                range={{
                    min: [minimalValue*60, 60],
                    '30%': [60*30, 60*10],
                    '50%': [60*60, 60*60],
                    max: [24*60*60],
                }}
                onSlide={onSlide}
                onSet={onSlide}
                pips={{ mode: 'steps', format: formatPips}}
                clickablePips
            />
            <input type="hidden" name={name} value={minutesValue}/>
        </div>

    );
};

export default CheckingIntervalSliderInput;