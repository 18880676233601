import React from 'react';

import {addMonths, endOfMonth} from 'date-fns';

import {RangeType} from 'rsuite/DatePicker';

import {startOfMonth} from 'rsuite/cjs/utils/dateUtils';

import DateRangePickerControlled, {IDateRangePickerControlledProps} from './DateRangePickerControlled';

import {getMonthName} from '../../../../utils/translate';


const DateRangePickerControlledWithLastTwoMonths: React.FC<IDateRangePickerControlledProps> = (props) => {
    const prevMonth = addMonths(new Date(), -1);
    const prevPrevMonth = addMonths(new Date(), -2);
    const prevMonthName = getMonthName(prevMonth);
    const prevPrevMonthName = getMonthName(prevPrevMonth);

    const predefinedRanges: RangeType<any>[] = [
        {
            label: `За ${prevMonthName}`,
            value: [startOfMonth(prevMonth), endOfMonth(prevMonth)],
            placement: 'bottom',
        },
        {
            label: `За ${prevPrevMonthName}`,
            value: [startOfMonth(prevPrevMonth), endOfMonth(prevPrevMonth)],
            placement: 'bottom',
        },
    ];
    
    return <DateRangePickerControlled ranges={predefinedRanges} {...props}/>;
};

export default DateRangePickerControlledWithLastTwoMonths;