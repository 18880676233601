/* eslint-disable max-len */
import React from 'react';

const Pause: React.FC<object> = () => {

    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM7.05882 17.2939C7.05882 18.2685 7.84891 19.0586 8.82353 19.0586C9.79815 19.0586 10.5882 18.2685 10.5882 17.2939V6.70565C10.5882 5.73103 9.79815 4.94095 8.82353 4.94095C7.84891 4.94095 7.05882 5.73103 7.05882 6.70565V17.2939ZM15.1765 19.0586C14.2019 19.0586 13.4118 18.2685 13.4118 17.2939V6.70565C13.4118 5.73103 14.2019 4.94095 15.1765 4.94095C16.1511 4.94095 16.9412 5.73103 16.9412 6.70565V17.2939C16.9412 18.2685 16.1511 19.0586 15.1765 19.0586Z" fill="white"/>
    </svg>;

};

export default Pause;