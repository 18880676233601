import React from 'react';

import cn from 'classnames';

import Box, {BoxProps} from '../Box';

import styles from './TableBox.module.scss';

const TableBox: React.FC<BoxProps> = ({children, className}) => {
    return (
        <Box className={cn(styles.table_box, className)}>
            {children}
        </Box>
    );
};

export default TableBox;