/* eslint-disable max-len */
import React from 'react';

const Tariffs: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68820)">
                <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H13C14.3807 0.5 15.5 1.61929 15.5 3V13C15.5 14.3807 14.3807 15.5 13 15.5H3C1.61929 15.5 0.5 14.3807 0.5 13V3Z" stroke="currentColor"/>
                <path d="M5.3335 12.6663L5.3335 10.6663M5.3335 3.33301L5.3335 5.33301" stroke="currentColor" strokeLinecap="round"/>
                <path d="M10.6665 12.667V6.66699" stroke="currentColor" strokeLinecap="round"/>
                <path d="M4.66667 7.33301H6C6.36819 7.33301 6.66667 7.63148 6.66667 7.99967C6.66667 8.36786 6.36819 8.66634 6 8.66634H4.66667C4.29848 8.66634 4 8.36786 4 7.99967C4 7.63148 4.29848 7.33301 4.66667 7.33301Z" fill="currentColor" stroke="currentColor" strokeLinecap="round"/>
                <path d="M10.0002 3.33301H11.3335C11.7017 3.33301 12.0002 3.63148 12.0002 3.99967C12.0002 4.36786 11.7017 4.66634 11.3335 4.66634H10.0002C9.63197 4.66634 9.3335 4.36786 9.3335 3.99967C9.3335 3.63148 9.63197 3.33301 10.0002 3.33301Z" fill="currentColor" stroke="currentColor" strokeLinecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68820">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Tariffs;