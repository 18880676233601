// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/Inter-Regular400.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../fonts/Inter-Medium500.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../fonts/Inter-SemiBold600.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../fonts/Inter-Bold700.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
  font-weight: 700;
  font-style: normal;
}
.SliderInput_icon__JYFEt {
  position: absolute;
  left: 13px;
  top: 10px;
  height: 18px;
  z-index: 6;
}
.SliderInput_icon__JYFEt svg {
  height: 18px;
}

.SliderInput_override__gM1Cs .rs-slider-graduator > ol > li::before {
  background-color: transparent;
  border: none;
}

.SliderInput_slider_container__fydYG {
  position: relative;
  padding: 5px;
}
.SliderInput_slider_container__fydYG .rs-slider-toggle-wrapper {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/vendors/fonts.scss","webpack://./src/components/UI/Slider/SliderInput.module.scss","webpack://./src/styles/icon.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,4DAAA;EACA,gBAAA;EACA,kBAAA;ACCF;ADEA;EACE,oBAAA;EACA,kBAAA;EACA,4DAAA;EACA,gBAAA;EACA,kBAAA;ACAF;ADGA;EACE,oBAAA;EACA,kBAAA;EACA,4DAAA;EACA,gBAAA;EACA,kBAAA;ACDF;ADIA;EACE,oBAAA;EACA,kBAAA;EACA,4DAAA;EACA,gBAAA;EACA,kBAAA;ACFF;AC3BA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;AD6BF;AC3BE;EACE,YAAA;AD6BJ;;AAhCE;EACE,6BAAA;EACA,YAAA;AAmCJ;;AA7BE;EACE,kBAAA;EACA,YAAA;AAgCJ;AA/BI;EACE,WAAA;AAiCN","sourcesContent":["@font-face {\n  font-family: \"Inter\";\n  font-display: swap;\n  src: url(\"../../fonts/Inter-Regular400.woff2\") format(\"woff2\");\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-display: swap;\n  src: url(\"../../fonts/Inter-Medium500.woff2\") format(\"woff2\");\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-display: swap;\n  src: url(\"../../fonts/Inter-SemiBold600.woff2\") format(\"woff2\");\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-display: swap;\n  src: url(\"../../fonts/Inter-Bold700.woff2\") format(\"woff2\");\n  font-weight: 700;\n  font-style: normal;\n}","@import \"../../../styles/vendors/mixins\";\n@import \"../../../styles/vendors/fonts\";\n@import \"../../../styles/icon\";\n\n.override {\n  :global(.rs-slider-graduator > ol > li::before) {\n    background-color: transparent;\n    border: none;\n  }\n}\n\n.slider {\n\n  &_container {\n    position: relative;\n    padding: 5px;\n    :global(.rs-slider-toggle-wrapper){\n      width: 100%;\n    }\n  }\n}\n",".icon {\n  position: absolute;\n  left: 13px;\n  top: 10px;\n  height: 18px;\n  z-index: 6;\n\n  svg {\n    height: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `SliderInput_icon__JYFEt`,
	"override": `SliderInput_override__gM1Cs`,
	"slider_container": `SliderInput_slider_container__fydYG`
};
export default ___CSS_LOADER_EXPORT___;
