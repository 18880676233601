import React from 'react';

import cn from 'classnames';

import Box, {BoxProps} from '../Box';

import styles from './BoxTransparent.module.scss';

const BoxTransparent: React.FC<BoxProps> = ({children, className}) => {

    return (
        <Box className={cn(styles.transparent_box, className)}>
            {children}
        </Box>
    );
};

export default BoxTransparent;