import React from 'react';

import styles from './Progress.module.scss';

interface IProgressProps {
    percent: number
}

const Progress: React.FC<IProgressProps> = ({percent}) => {
    const width = `${100-percent}%`;
    return (
        <div className={styles.wrapper}>
            <div className={styles.full_bar} />
            <div className={styles.empty} style={{width}} />
        </div>
    );
};

export default Progress;