import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PlaceholderGraph from 'rsuite/cjs/Placeholder/PlaceholderGraph';

import {useNavigate} from 'react-router-dom';

import Tariff from './Tariff';
import {userSelector} from '../../redux/authSlice';
import {Pages} from '../../constants/pages';

import ITariff from '../../models/ITariff';

import LeftArrow from '../Icons/LeftArrow';

import TariffVariants from './TariffVariants/TariffVariants';

import {tariffVariantAPI} from '../../api/tariffVariantAPI';

import styles from './Tariff.module.scss';

interface ITariffsProps {
    items: ITariff[],
    isLoading: boolean,
}

const Tariffs: React.FC<ITariffsProps> = ({items, isLoading}) => {
    const user = useSelector(userSelector);
    const navigate = useNavigate();
    const { data: variants = [] } = tariffVariantAPI.useGetTariffVariantsQuery(undefined);

    const sortedTariffs = useMemo(() => {
        if (!items || !items.length) return [];
        return [...items].sort((a, b) => {
            if (a.id === user?.tariff.id) return -1;
            if (b.id === user?.tariff.id) return 1;
            if (a.is_archive && !b.is_archive) return -1;
            if (!a.is_archive && b.is_archive) return 1;

            return 0;
        });
    }, [items, user?.tariff.id]);

    return (
        <section>
            <div className={styles.header}>
                <div className={styles.header_content}>
                    <div onClick={() => navigate(-1)}>
                        <LeftArrow />
                    </div>
                    <h3 className={styles.header_title}>{Pages.TARIFFS}</h3>
                </div>
                <TariffVariants variants={variants} />
            </div>
            {
                isLoading
                    ? <PlaceholderGraph height={520} active/>
                    : <div className={styles.tariffs}>
                        {sortedTariffs.map((item) =>
                            <Tariff key={item.id} item={item} user={user} />
                        )}
                    </div>
            }
        </section>
    );
};

export default Tariffs;