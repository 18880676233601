// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorBatchOperations_wrapper__\\+gkyx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  gap: 20px;
  min-height: 58px;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Monitor/MonitorBatchOperations/MonitorBatchOperations.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".wrapper{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 10px 30px;\n    gap: 20px;\n    min-height: 58px;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MonitorBatchOperations_wrapper__+gkyx`
};
export default ___CSS_LOADER_EXPORT___;
