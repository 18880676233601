import React from 'react';

import {useSelector} from 'react-redux';


import {Link} from 'react-router-dom';

import {userSelector} from '../../../../../redux/authSlice';

import Progress from '../../../../UI/Progress/Progress';

import {AppUrls} from '../../../../../constants/urls';

import styles from './TariffBanner.module.scss';

const TariffBanner: React.FC = () => {
    const user = useSelector(userSelector);
    
    if (!user) {
        return <></>;
    }

    return (
        <Link to={AppUrls.TARIFFS} className={styles.link_wrapper}>
            <div className={styles.wrapper}>

                <div className={styles.text}>
                    Тариф: {user.tariff.name}
                </div>
                <div className={styles.text_remain}>
                    {null !== user.tariff_days_left && 'Осталось '}
                    {user.tariff_days_left_human_readable}
                </div>

                <div className={styles.progress}>
                    <Progress percent={user.tariff_days_left_percent} />
                </div>
            </div>
        </Link>
    );
};

export default TariffBanner;