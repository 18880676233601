import {useSelector} from 'react-redux';

import {userSelector} from '../redux/authSlice';
import {monitorAPI} from '../api/monitorAPI';


const useCanCreateMonitor = (): boolean => {
    const user = useSelector(userSelector);

    const {data: summaryMonitors} = monitorAPI.useGetSummaryMonitorsQuery();

    if (!user || !summaryMonitors) {
        return true;
    }

    return user.tariff.monitor_max_count_limit > summaryMonitors.total_active;
};

export default useCanCreateMonitor;