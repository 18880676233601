import React, {MouseEvent, useCallback, useState} from 'react';

import IWarning from '../../models/IWarning';
import {getFormattedDate} from '../../utils/datetime';

import WarningModal from './WarningModal';

import IncidentStatusBadge from '../Status/IncidentStatusBadge';

import {translateWarningCause} from '../../utils/translate';

import WarningMenu from './WarningMenu';

import DateWithTodayYesterdayAccent from '../UI/Date/DateWithTodayYesterdayAccent';

import styles from './Warning/Warning.module.scss';

interface IWarningRowProps {
    item: IWarning,
    tzString?: string | null,
    hideDomain: boolean,
}

const WarningRow: React.FC<IWarningRowProps> = ({item, tzString, hideDomain}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => setIsModalOpen(false);

    const onTrClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const onTdClick = useCallback((event: MouseEvent<HTMLTableDataCellElement>) => {
        event.stopPropagation();
    }, []);

    return (
        <>
            <tr className="clickable" onClick={onTrClick}>
                <td><IncidentStatusBadge resolved={item.resolved}/></td>
                {
                    !hideDomain
                    && <td>{item.domain}</td>
                }
                <td>{translateWarningCause(item.type)}</td>
                <td>
                    <DateWithTodayYesterdayAccent
                        datetime={item.created_at}
                        tzString={tzString}
                    />
                </td>
                <td className={styles.text_gray}>
                    {
                        item.expire_at
                            ? getFormattedDate(item.expire_at, {format: 'DD.MM.YYYY', tzString})
                            : '-'
                    }
                </td>
                <td className={styles.text_gray}>
                    <DateWithTodayYesterdayAccent
                        datetime={item.resolved_at}
                        tzString={tzString}
                    />
                </td>
                <td onClick={onTdClick}>
                    <WarningMenu item={item} onViewClick={onTrClick} />
                </td>
            </tr>

            <WarningModal open={isModalOpen} item={item} handleClose={handleClose}/>
        </>
    );
};

export default WarningRow;