import {combineReducers} from '@reduxjs/toolkit';

import authReducer from './authSlice';
import monitorReducer from './monitorSlice';
import {monitorAPI} from '../api/monitorAPI';
import {incidentAPI} from '../api/incidentAPI';
import {warningAPI} from '../api/warningAPI';
import {tariffAPI} from '../api/tariffAPI';
import {paymentAPI} from '../api/paymentAPI';
import {userAPI} from '../api/userAPI';
import {telegramChatAPI} from '../api/telegramChatAPI';

export default combineReducers({
    authReducer,
    monitorReducer,
    [monitorAPI.reducerPath]: monitorAPI.reducer,
    [incidentAPI.reducerPath]: incidentAPI.reducer,
    [warningAPI.reducerPath]: warningAPI.reducer,
    [tariffAPI.reducerPath]: tariffAPI.reducer,
    [telegramChatAPI.reducerPath]: telegramChatAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
});