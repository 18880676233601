import {ApiUrls} from 'constants/urls';

import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'store';

import createHttpRequest from 'utils/http';

import {backupImpersonatorTokens, setTokens} from 'utils/auth';

import IUserOutput from '../models/IUser';
import {Permission} from '../models/Permission';
import ILoginForm from '../models/forms/ILoginForm';
import IRegistrationForm from '../models/forms/IRegistrationForm';
import SimpleResponse from '../models/SimpleResponse';
import ConfirmEmailData from '../models/ConfirmEmailData';
import NewPasswordData from '../models/NewPasswordData';
import EditUserData from '../models/EditUserData';
import IConfirmEmailChangeResponse from '../models/IConfirmEmailChangeResponse';

interface IInitialState {
    registerUserLoading: boolean,
    registerUserSuccess: boolean,

    loginLoading: boolean,
    isAuthenticated: boolean,
    user: IUserOutput | null,
    isUserLoading: boolean,

    isEmailConfirming: boolean,
    isPasswordResetting: boolean,
    isPasswordSetting: boolean,
    isUserEditing: boolean,
}

const initialState: IInitialState = {
    registerUserLoading: false,
    registerUserSuccess: false,

    loginLoading: false,
    isAuthenticated: false,
    user: null,
    isUserLoading: false,

    isEmailConfirming: false,
    isPasswordResetting: false,
    isPasswordSetting: false,
    isUserEditing: false,
};

export const registerUser = createAsyncThunk<void, IRegistrationForm>(
    'auth/registerUser',
    async(userData) => {
        const tokens = await createHttpRequest({
            method: 'POST',
            path: ApiUrls.USERS(),
            data: userData,
            isAnonymous: true,
            errorMessage: 'При регистрации произошла ошибка! Попробуйте ещё раз.',
        });

        setTokens(tokens.data);
    },
);

export const loginUser = createAsyncThunk<void, ILoginForm>(
    'auth/loginUser',
    async(data) => {
        const response = await createHttpRequest({
            method: 'POST',
            path: ApiUrls.TOKEN,
            data: data,
            isAnonymous: true,
            errorMessage: 'Ошибка авторизации',
        });
        setTokens(response.data);
    }
);

export const impersonate = createAsyncThunk<void, number>(
    'auth/impersonate',
    async(id) => {
        const response = await createHttpRequest({
            method: 'GET',
            path: ApiUrls.IMPERSONATE(id),
            errorMessage: 'Ошибка авторизации',
        });
        backupImpersonatorTokens();
        setTokens(response.data);

    }
);

export const fetchMe = createAsyncThunk<IUserOutput>('auth/fetchMe', async() => {
    const response = await createHttpRequest({
        method: 'GET',
        path: ApiUrls.ME,
        errorMessage: 'Ошибка получения данных пользователя',
    });

    return response.data;
});

export const confirmEmail = createAsyncThunk<SimpleResponse, ConfirmEmailData>(
    'auth/confirmEmail',
    async({userId, token}) => {
        const response = await createHttpRequest({
            method: 'GET',
            path: ApiUrls.EMAIL_CONFIRM(userId, token),
            errorMessage: 'Ошибка при подтверждении Email',
            silent: true,
            isAnonymous: true,
        });

        return response.data;
    },
);

export const confirmChangeEmail = createAsyncThunk<IConfirmEmailChangeResponse, ConfirmEmailData>(
    'auth/confirmChangeEmail',
    async({userId, token}) => {
        const response = await createHttpRequest({
            method: 'POST',
            data: {},
            path: ApiUrls.CHANGE_EMAIL_CONFIRM(userId, token),
            errorMessage: 'Произошла ошибка',
            silent: true,
            isAnonymous: false,
        });

        setTokens(response.data.authTokens);

        return response.data;
    },
);

export const passwordResetRequest =
    createAsyncThunk<SimpleResponse, string>('auth/passwordResetRequest', async(email) => {
        const response = await createHttpRequest({
            method: 'POST',
            path: ApiUrls.PASSWORD_RESET_REQUEST,
            errorMessage: 'Ошибка при запросе на сброс пароля',
            data: {email},
            isAnonymous: true,
        });

        return response.data;
    });

export const emailConfirmationRequest =
    createAsyncThunk<SimpleResponse, void>('auth/emailConfirmationRequest', async() => {
        const response = await createHttpRequest({
            method: 'GET',
            path: ApiUrls.EMAIL_RECONFIRM,
            errorMessage: 'Ошибка при повторной отправке письма с подтверждением Email',
        });

        return response.data;
    });

export const setNewPassword = createAsyncThunk<SimpleResponse, NewPasswordData>(
    'auth/setNewPassword',
    // eslint-disable-next-line camelcase
    async({userId, token, password, password_confirmation}) => {
        const tokens = await createHttpRequest({
            method: 'POST',
            path: ApiUrls.PASSWORD_RESET_CONFIRM(userId, token),
            errorMessage: 'Ошибка при установке нового пароля',
            // eslint-disable-next-line camelcase
            data: {password, password_confirmation},
            isAnonymous: true,
        });
        setTokens(tokens.data);

        return {status: true, message: 'Новый пароль успешно установлен'};
    },
);

export const editUser = createAsyncThunk<IUserOutput, EditUserData>(
    'auth/editUser',
    // eslint-disable-next-line camelcase
    async({id, ...data}) => {
        // @ts-ignore
        const response = await createHttpRequest({
            method: 'PUT',
            path: ApiUrls.USER(id),
            data,
            errorMessage: 'При изменении данных пользователя произошла ошибка!',
        });

        return response.data;
    },
);

export const disableRecurring = createAsyncThunk<{status: boolean, message: string}, void>(
    'auth/disableRecurring',
    // eslint-disable-next-line camelcase
    async() => {

        const response = await createHttpRequest({
            method: 'POST',
            path: ApiUrls.USER_DISABLE_RECURRING,
            data: {},
            errorMessage: 'При отключении периодического платежа произошла ошибка!',
        });

        return response.data;
    },
);

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutAction: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(registerUser.pending, (state) => {
            state.registerUserLoading = true;
        });
        builder.addCase(registerUser.fulfilled, (state) => {
            state.registerUserLoading = false;
            state.registerUserSuccess = true;
        });
        builder.addCase(registerUser.rejected, (state) => {
            state.registerUserLoading = false;
        });

        builder.addCase(editUser.pending, (state) => {
            state.isUserEditing = true;
        });
        builder.addCase(editUser.fulfilled, (state, {payload}) => {
            state.user = payload;
            state.isUserEditing = false;
        });
        builder.addCase(editUser.rejected, (state) => {
            state.isUserEditing = false;
        });

        builder.addCase(loginUser.pending, (state) => {
            state.loginLoading = true;
        });
        builder.addCase(loginUser.fulfilled, (state) => {
            state.loginLoading = false;
            state.isAuthenticated = true;
        });
        builder.addCase(loginUser.rejected, (state) => {
            state.loginLoading = false;
        });

        builder.addCase(impersonate.pending, (state) => {
            state.loginLoading = true;
        });
        builder.addCase(impersonate.fulfilled, (state) => {
            state.loginLoading = false;
            state.isAuthenticated = true;
        });
        builder.addCase(impersonate.rejected, (state) => {
            state.loginLoading = false;
        });

        builder.addCase(confirmEmail.pending, (state) => {
            state.isEmailConfirming = true;
        });
        builder.addCase(confirmEmail.fulfilled, (state) => {
            state.isEmailConfirming = false;
        });
        builder.addCase(confirmEmail.rejected, (state) => {
            state.isEmailConfirming = false;
        });

        builder.addCase(confirmChangeEmail.pending, (state) => {
            state.isEmailConfirming = true;
        });
        builder.addCase(confirmChangeEmail.fulfilled, (state, {payload}) => {
            state.isEmailConfirming = false;
            if (state.user !== null) {
                state.user.email = payload.email;
            }
        });
        builder.addCase(confirmChangeEmail.rejected, (state) => {
            state.isEmailConfirming = false;
        });

        builder.addCase(passwordResetRequest.pending, (state) => {
            state.isPasswordResetting = true;
        });
        builder.addCase(passwordResetRequest.fulfilled, (state) => {
            state.isPasswordResetting = false;
        });
        builder.addCase(passwordResetRequest.rejected, (state) => {
            state.isPasswordResetting = false;
        });

        builder.addCase(setNewPassword.pending, (state) => {
            state.isPasswordSetting = true;
        });
        builder.addCase(setNewPassword.fulfilled, (state) => {
            state.isPasswordSetting = false;
        });
        builder.addCase(setNewPassword.rejected, (state) => {
            state.isPasswordSetting = false;
        });

        builder.addCase(fetchMe.pending, (state) => {
            state.user = null;
            state.isUserLoading = true;
        });
        builder.addCase(fetchMe.fulfilled, (state, {payload}) => {
            state.user = payload || null;
            state.isUserLoading = false;
        });
        builder.addCase(fetchMe.rejected, (state) => {
            state.isUserLoading = false;
        });

        builder.addCase(disableRecurring.pending, (state) => {
            state.isUserLoading = true;
        });
        builder.addCase(disableRecurring.fulfilled, (state) => {
            state.isUserLoading = false;
        });
        builder.addCase(disableRecurring.rejected, (state) => {
            state.isUserLoading = false;
        });
    },
});

export default auth.reducer;
export const {logoutAction} = auth.actions;

//Селекторы
const slice = ({authReducer}: RootState) => authReducer;

export const registerUserLoadingSelector = createSelector(
    slice,
    ({registerUserLoading}) => registerUserLoading,
);

export const registerUserSuccessSelector = createSelector(
    slice,
    ({registerUserSuccess}) => registerUserSuccess,
);

export const authLoadingSelector = createSelector(
    slice,
    ({loginLoading}) => loginLoading,
);

export const isAuthenticatedSelector = createSelector(
    slice,
    ({isAuthenticated, user}) => isAuthenticated || user,
);

export const userSelector = createSelector(
    slice,
    ({user}) => user
);

export const userPermissionsSelector = createSelector(
    userSelector,
    (user) => {
        const permissions: Permission[] = [];
        if (!user) {
            return permissions;
        }

        return user.roles;
    }
);

export const isUserLoadingSelector = createSelector(
    slice,
    ({isUserLoading}) => isUserLoading,
);

export const isEmailConfirmingSelector = createSelector(
    slice,
    ({isEmailConfirming}) => isEmailConfirming,
);

export const isPasswordResettingSelector = createSelector(
    slice,
    ({isPasswordResetting}) => isPasswordResetting,
);

export const isPasswordSettingSelector = createSelector(
    slice,
    ({isPasswordSetting}) => isPasswordSetting,
);

export const isUserEditingSelector = createSelector(
    slice,
    ({isUserEditing}) => isUserEditing,
);