import {createApi} from '@reduxjs/toolkit/query/react';

import {AxiosRequestHeaders} from 'axios';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import IPagination from '../models/IPagination';
import IHydraResponse from '../models/HydraResponse';
import {paginationAdapter} from '../utils/paginationHelper';
import {providesList} from '../rtk-query/RTKQueryHelper';
import IWarning from '../models/IWarning';
import IOrderBy from '../models/IOrderBy';

export const warningAPI = createApi({
    reducerPath: 'warningAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Warning'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getWarnings: build.query<IPagination<IWarning>, {
            monitorId?: string,
            page?: number,
            sort?: IOrderBy,
            limit?: number,
        }>({
            query: ({monitorId, page, sort, limit = 0}) => ({
                path: buildQuery(ApiUrls.WARNINGS(monitorId, page, sort, limit)),
                headers: {Accept: 'application/ld+json'} as unknown as AxiosRequestHeaders,
            }),
            providesTags: (result) => providesList(result?.items, 'Warning'),
            transformResponse(
                rawResult: IHydraResponse<IWarning>
            ): Promise<IPagination<IWarning>> | IPagination<IWarning> {
                return paginationAdapter(rawResult);
            },
        }),
        getWarning: build.query<IWarning, number>({
            query: (id) => ({
                path: ApiUrls.WARNING(id),
            }),
            providesTags: (result, error, id) => [{ type: 'Warning', id }],
        }),
        updateWarning: build.mutation<IWarning, Partial<IWarning>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.WARNING(id ?? 0),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    warningAPI.util.updateQueryData('getWarning', id ?? 0, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'Warning', id: 'LIST' }],
        }),
        deleteWarning: build.mutation<void,  number>({
            query: (id) => ({
                path: ApiUrls.WARNING(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'Warning', id: 'LIST' },
                { type: 'Warning', id },
            ],
        }),
    }),
});