import React, {MouseEvent, ReactNode, useCallback} from 'react';

import {faBan, faEye, faTrash} from '@fortawesome/free-solid-svg-icons';

import {toast} from 'react-toastify';

import MoreActionsItem from '../UI/MoreActions/MoreActionsItem';
import MoreActionsDots from '../UI/MoreActions/MoreActionsDots';
import IWarning from '../../models/IWarning';
import {warningAPI} from '../../api/warningAPI';

interface IWarningMenuProps {
    item: IWarning,
    // eslint-disable-next-line no-unused-vars
    onViewClick: (event: MouseEvent<HTMLTableRowElement>) => void,
}

const WarningMenu: React.FC<IWarningMenuProps> = ({item, onViewClick}) => {
    const [deleteWarning] = warningAPI.useDeleteWarningMutation();
    const [updateWarning] = warningAPI.useUpdateWarningMutation();

    const onClickIgnore = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateWarning({
                id: item.id,
                ignore: true,
            }).unwrap();
            toast.info('Предупреждение проигнорировано');
        }catch (e){

        }

    }, [item.id]);

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteWarning(item.id).unwrap();
            toast.info('Предупреждение удалено');
        }catch (e){

        }
    }, []);

    const getActions = (): ReactNode[] => {
        const actions: ReactNode[] = [];

        actions.push(
            <MoreActionsItem
                key="incidents"
                label="Подробнее"
                icon={faEye}
                onClick={onViewClick}/>
        );

        if (item.resolved) {
            actions.push(
                <MoreActionsItem
                    key="trash"
                    label="Удалить"
                    icon={faTrash}
                    onClick={onClickDelete}
                />
            );
        }else {
            actions.push(
                <MoreActionsItem
                    key="ignore"
                    label="Игнорировать"
                    icon={faBan}
                    onClick={onClickIgnore}
                />
            );
        }

        return actions;
    };

    return (
        <MoreActionsDots>
            {
                getActions().map(el => el)
            }
        </MoreActionsDots>
    );
};

export default React.memo(WarningMenu);