import axios, {AxiosRequestConfig, AxiosRequestHeaders, ResponseType} from 'axios';

import {apiErrorHandler} from 'utils/errorHandler';
import {isBrowser} from 'utils/evironment';

import {ApiUrls, AppUrls} from '../constants/urls';

import Queue from './queue';
import {clearAccessTokens, clearTokens, getAccessToken, getRefreshToken, refreshTokens} from './auth';
import {HttpMethodType} from '../types';


export type HttpRequestType = {
    method?: HttpMethodType,
    path: string,
    baseURL?: string,
    data?: object | string,
    headers?: AxiosRequestHeaders,
    errorMessage?: string,
    isAnonymous?: boolean,
    silent?: boolean,
    responseType?: ResponseType,
};

// eslint-disable-next-line camelcase
interface IRequestOptions extends AxiosRequestConfig{
    errorMessage?: string | null,
    silent?: boolean,
}

let isTokenRefreshing = false;
const failedRequestsQueue = new Queue();

const processFailedRequests = (error: Error | null, token: string|null = null) => {
    failedRequestsQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedRequestsQueue.empty();
};

const createHttpRequest = async({
    method,
    path,
    baseURL,
    data,
    headers,
    errorMessage,
    responseType,
    isAnonymous = false,
    silent = false,
}: HttpRequestType) => {

    const options: IRequestOptions = {};

    if (!isAnonymous && isBrowser()) {
        let token = getAccessToken();
        if (!token) {
            token = await getNewAccessToken();
        }
        if (token) {
            options.headers  = {
                ...http.defaults.headers,
                ...options.headers,
                ...{Authorization: 'Bearer ' + token},
            } as unknown as AxiosRequestHeaders;
        }
    }

    if (baseURL) {
        options.baseURL = baseURL;
    } else if (!baseURL && isBrowser()) {
        options.baseURL = http.defaults.baseURL;
    }

    if (responseType) {
        options.responseType = responseType;
    }

    if (headers) {
        options.headers = {...options['headers'], ...headers};
    }

    options.errorMessage = errorMessage || null;
    options.silent = silent;
    options.withCredentials = !isAnonymous;
    return http({
        method,
        url: path,
        data,
        ...options,
    });
};

function redirectToLogin() {
    if (!window?.location.href.endsWith(AppUrls.LOGIN)){
        return window.location.href = `${AppUrls.LOGIN}?return=${location.pathname}`;
    }
}

function refreshTokenAndRetry(originalRequest: any) {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
        return redirectToLogin();
    }
    if (originalRequest.url === ApiUrls.TOKEN_REFRESH) {
        clearTokens();
        return redirectToLogin();
    }
    if (isTokenRefreshing) {
        return new Promise(function(resolve, reject) {
            failedRequestsQueue.set({resolve, reject});
        }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return http.request(originalRequest);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    originalRequest.retry = true;
    clearAccessTokens();

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        getNewAccessToken()
            .then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(http(originalRequest));
            })
            .catch(error => {
                reject(error);
            });
    });
}

async function getNewAccessToken(): Promise<string|null> {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        redirectToLogin();
        throw Error('Access and Refresh tokens expired');
    }

    if (isTokenRefreshing) {
        await new Promise(r => setTimeout(r, 1000));
        return getNewAccessToken();
    }
    let token = getAccessToken();
    if (token) {
        return token;
    }

    isTokenRefreshing = true;
    try {
        await refreshTokens();
        token = getAccessToken();
        processFailedRequests(null, token);
    }catch(error){
        clearTokens();
        processFailedRequests(error as Error, null);
        throw error;
    }finally{
        isTokenRefreshing = false;
    }

    return token;
}

const http = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: `${process.env.REACT_APP_BASE_HOST}${process.env.REACT_APP_BASE_API_URL}`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

http.interceptors.response.use((response) => {
    return response;
}, error => {
    const originalRequest = error.config;
    if (error?.response?.status === 403) {
        return window.location.href = AppUrls.FORBIDDEN;
    } else if (error?.response?.status === 401 && !originalRequest.retry && originalRequest.withCredentials) {
        return refreshTokenAndRetry(originalRequest);
    }
    return apiErrorHandler(
        error,
        originalRequest?.errorMessage ?? '',
        originalRequest?.silent ?? false,
        error?.response?.status
    );
});

export default createHttpRequest;