import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import MonitorBatchRemoveForm from './MonitorForm/MonitorBatchRemoveForm';

interface IRemoveBatchMonitorModalProps {
    open: boolean,
    handleClose: () => void;
}

const RemoveBatchMonitorModal: React.FC<IRemoveBatchMonitorModalProps> = ({open,  handleClose}) => {
    return <MainModal
        header="Массовое удаление мониторов"
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <MonitorBatchRemoveForm handleClose={handleClose} />
    </MainModal>;
};

export default RemoveBatchMonitorModal;