export const generateFirstName = ():string => {
    return getRandomVariant([
        'Стремительный',
        'Бдительный',
        'Умный',
        'Счастливый',
        'Резвый',
    ]);
};

export const generateLastName = ():string => {
    return getRandomVariant([
        'Тигр',
        'Слоник',
        'Вомбат',
        'Котик',
        'Гепард',
    ]);
};

const getRandomVariant = (variants: string[]):string => {
    return variants[(Math.floor(Math.random() * variants.length))];
};