/* eslint-disable max-len */
import React from 'react';

const Payments: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68821)">
                <path d="M9.18426 15.1801L9.63148 15.4037L9.18426 15.1801C9.08624 15.3762 8.88586 15.5 8.66667 15.5L7.37048 15.5C7.12852 15.5 6.90733 15.3633 6.79912 15.1469C6.19518 13.939 4.47148 13.939 3.86754 15.1469C3.75934 15.3633 3.53814 15.5 3.29618 15.5L2.47214 15.5C1.38296 15.5 0.5 14.617 0.5 13.5279L0.499999 3C0.499999 1.61929 1.61929 0.500001 3 0.500001L4 0.500001L5.33333 0.500001L6.66667 0.500001L9.33333 0.500001L10.6667 0.5L12 0.5L13 0.5C14.3807 0.5 15.5 1.61929 15.5 3L15.5 13C15.5 14.3807 14.3807 15.5 13 15.5L12.6667 15.5C12.4475 15.5 12.2471 15.3762 12.1491 15.1801L12.1325 15.1469C11.5285 13.939 9.80481 13.939 9.20088 15.1469L9.18426 15.1801Z" stroke="currentColor"/>
                <path d="M3.3335 8L12.6668 8" stroke="currentColor" strokeLinecap="round"/>
                <path d="M3.3335 10.667L12.6668 10.667" stroke="currentColor" strokeLinecap="round"/>
                <path d="M3.3335 4.66699H7.3335" stroke="currentColor" strokeLinecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68821">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Payments;