import React from 'react';

import Logo from '../Icons/Logo';

import HintQuestion from '../UI/Hint/HintQuestion';

import ITariff from '../../models/ITariff';

import styles from './Tariff.module.scss';

interface TariffLabelProps {
    isActive: boolean;
    tariff: ITariff;
}

const TariffLabel: React.FC<TariffLabelProps> = ({ isActive, tariff }) => {
    if (tariff.is_archive) {
        return (
            <>
                <div className={styles.label_archive}>
                    <span>Ваш тариф архивный</span>
                </div>
                <HintQuestion className={styles.question}>
                    Архивный тариф пропадет при смене на любой другой тариф, в том числе на базовый бесплатный,
                    если окончится оплаченный период.
                </HintQuestion>
            </>
        );
    }

    if (isActive) {
        return (
            <div className={styles.label_active}>
                <span>Ваш тариф</span>
            </div>
        );
    }

    if (tariff.is_recommended) {
        return (
            <div className={styles.label_recommended}>
                <Logo />
                <span>РЕКОМЕНДУЕТ</span>
            </div>
        );
    }

    return <></>;
};

export default TariffLabel;
