import React, {useState} from 'react';

import LoaderCentered from '../../UI/Loader/LoaderCentered';
import TableBox from '../../UI/Box/TableBox/TableBox';
import {getPollingInterval} from '../../../utils/pollingHelper';
import {IMonitorItem} from '../../../models/Monitor/IMonitor';
import {warningAPI} from '../../../api/warningAPI';
import Warnings from '../../Warning/Warnings';
import IOrderBy from '../../../models/IOrderBy';

interface IMonitorWarningsProps {
    monitor: IMonitorItem,
}

const MonitorWarnings: React.FC<IMonitorWarningsProps> = ({
    monitor,
}) => {
    const [sort, setSort] = useState<IOrderBy>();
    const [page, setPage] = useState(1);
    const {
        data: pagination,
        isLoading,
        isFetching,
    } = warningAPI.useGetWarningsQuery({monitorId: monitor.uuid, page, sort}, {
        pollingInterval: getPollingInterval(),
    });

    return <TableBox className="monitor_incident_container">
        <LoaderCentered active={isFetching}/>
        <h3 className="monitor_incident_title">Проблемы мониторинга &quot;{monitor.name}&quot;</h3>

        {
            pagination
            && <Warnings
                pagination={pagination}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
                setPage={setPage}
            />
        }
    </TableBox>;
};

export default MonitorWarnings;