import React from 'react';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import Login from '../components/Auth/Login';


const LoginPage: React.FC = () => {


    return (
        <BlankLayout pageTitle="Авторизация">
            <Login/>
        </BlankLayout>
    );
};

export default LoginPage;