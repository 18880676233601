import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router';

import {AxiosRequestHeaders} from 'axios/index';

import {Helmet} from 'react-helmet-async';

import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';

import Box from '../components/UI/Box/Box';

import PrimaryButton from '../components/UI/Button/PrimaryButton';
import NotFound from './NotFound';

import createHttpRequest from '../utils/http';
import {ApiUrls} from '../constants/urls';

import styles from './NotificationsUnsbscribePage.module.scss';


const NotificationsUnsubscribePage: React.FC = () => {
    const {id} = useParams();
    if (!id) {
        return <NotFound/>;
    }
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({monitor_url: '', email: ''});
    useEffect(() => {
        createHttpRequest({
            method: 'GET',
            path: ApiUrls.UNSUBSCRIBE_FROM_EMAIL_NOTIFICATIONS_INFO(id),
            isAnonymous: true,
        }).then((response) => {
            setIsLoading(false);
            setData(response.data);
        }, () => {
            setIsError(true);
        }).catch(() => {
            setIsError(true);
        });

    }, [id]);

    if(isError) {
        return <NotFound/>;
    }

    const [unsubscribed, setUnsubscribed] = useState(false);

    const unsubscribeAction = async(mode: number) => {
        setIsLoading(true);
        createHttpRequest({
            method: 'POST',
            data: {},
            headers: {
                'Content-type': 'application/json',
            } as unknown as AxiosRequestHeaders,
            path: ApiUrls.MONITOR_UNSUBSCRIBE_FROM_EMAIL_NOTIFICATIONS_ACTION(id, mode),
            isAnonymous: true,
        }).then(() => {
            setUnsubscribed(true);
            setIsLoading(false);
        });
    };

    const unsubscribeMonitor = async() => {
        if(confirm('Подтверждайте действие?')) {
            unsubscribeAction(1);
        }
    };

    const unsubscribeAll = async() => {
        if(confirm('Подтверждайте действие?')) {
            unsubscribeAction(2);
        }
    };

    if(unsubscribed) {
        return (<BlankLayout isLoading={false}>
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Уведомления отключены</title>
            </Helmet>
            <div className={styles.wrapper}>
                <div className={styles.box_wrapper}>
                    <Box>
                        <div className="row text-center mb-3">
                            <div className="col-12">
                                <p>Уведомления отключены.</p>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </BlankLayout>);
    }

    return (
        <BlankLayout pageTitle="Отписаться от уведомлений" isLoading={isLoading}>
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Отписаться от уведомлений</title>
            </Helmet>

            <div className={styles.wrapper}>
                <div className={styles.box_wrapper}>
                    <Box>
                        <div className="row text-center mb-3">
                            <div className="col-12">
                                <h3>Отключение уведомлений</h3>
                                <p className={styles.text}>
                                    <span>На этой странице вы можете отписаться от уведомлений сервиса overseer для адреса <b>{data.email}</b></span>
                                    <hr/>
                                    <span className={styles.url}><b>Монитор</b>: {data.monitor_url}</span>
                                    <br/>
                                    <PrimaryButton
                                        onClick={unsubscribeMonitor}
                                        title={data.monitor_url}
                                    >
                                        Отписаться от уведомлений по монитору
                                    </PrimaryButton>
                                    <hr/>
                                    <PrimaryButton onClick={unsubscribeAll}>Отписаться от всех уведомлений</PrimaryButton>
                                </p>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </BlankLayout>
    );
};

export default NotificationsUnsubscribePage;