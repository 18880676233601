import React, {useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import TariffModal from './TariffModal';
import TransparentButton from '../../UI/Button/TransparentButton';

const CreateTariffButton: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Добавить тариф<PlusCircleIcon width="20px" height="20px" />
            </TransparentButton>
            <TariffModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default CreateTariffButton;