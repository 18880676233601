import React from 'react';

import {getDiffInDays, getFormattedDate, IDateOptions} from '../../../../utils/datetime';

import styles from './DateWithColorWarnings.module.scss';

interface IDateWithColorWarningsProps {
    dateString?: Date | string | null,
    options?: IDateOptions,
    warningLevel?: number, //Количество дней до даты, ниже которого уровень предупреждения
    dangerLevel?: number, //Количество дней до даты, ниже которого уровень критический
}

const DateWithColorWarnings: React.FC<IDateWithColorWarningsProps> = ({
    dateString,
    options,
    warningLevel= 14,
    dangerLevel= 7,
}) => {
    const content = getFormattedDate(dateString, options);
    if (!dateString) {
        return <>{content}</>;
    }
    const diffDays = getDiffInDays(dateString, new Date());
    let className = '';
    if (diffDays <= warningLevel) {
        className = 'warning';
    }
    if (diffDays <= dangerLevel) {
        className = 'danger';
    }
    if ('' === className){
        return <>{content}</>;
    }

    return (
        <span className={styles[className]}>{content}</span>
    );
};

export default DateWithColorWarnings;