import React from 'react';

import MainModal from '../../UI/Modal/MainModal';
import MonitorBatchOperationsForm from '../MonitorForm/MonitorBatchOperationsForm';


interface ICreateBatchMonitorModalProps {
    open: boolean,
    handleClose: () => void;
    action?: Record<string, any>,
}

const CommonBatchMonitorModal: React.FC<ICreateBatchMonitorModalProps> = ({open,  handleClose, action}) => {
    return <MainModal
        header={`Массовая операция: "${action?.label}"`}
        open={open}
        handleClose={handleClose}
        size="md"
    >
        <MonitorBatchOperationsForm
            action={action}
            handleClose={handleClose}
        />
    </MainModal>;
};

export default CommonBatchMonitorModal;