import React, {MouseEvent, useCallback, useState} from 'react';

import {getFormattedDate} from '../../utils/datetime';
import IUserOutput from '../../models/IUser';
import UserMenu from './UserMenu';
import UserTariffModal from './UserTariffModal';
import UserEditModal from './UserEditModal';
import YesNo from '../UI/YesNo/YesNo';

interface IPaymentRowProps {
    item: IUserOutput,
    tzString?: string | null,
}

const UserRow: React.FC<IPaymentRowProps> = ({item, tzString}) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleCloseEdit = () => setIsEditModalOpen(false);

    const onEditClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsEditModalOpen(true);
    }, []);

    const [isTariffModalOpen, setIsTariffModalOpen] = useState(false);

    const handleCloseTariff = () => setIsTariffModalOpen(false);

    const onTariffClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsTariffModalOpen(true);
    }, []);

    return (
        <>

            <tr className="clickable" onClick={onEditClick}>
                <td>{item.id}</td>
                <td>{getFormattedDate(item.created_at, {tzString, format: 'DD.MM.YYYY HH:mm'})}</td>
                <td>{getFormattedDate(item.last_activity_at, {tzString, format: 'DD.MM.YYYY HH:mm'})}</td>
                <td>{item.first_name}</td>
                <td>{item.last_name}</td>
                <td>
                    <YesNo condition={item.email_confirmed}/>
                    &nbsp;
                    {item.email}
                </td>
                <td>{item.timezone}</td>
                <td>{item.tariff.name}</td>
                <td>{
                    item.active_to
                        ? getFormattedDate(item.active_to, {tzString, format: 'DD.MM.YYYY'})
                        : 'Бессрочно'
                }</td>
                <td><YesNo condition={item.is_recurring}/></td>
                <td>{item.monitors_count}/{item.monitors_with_incidents_count}</td>
                <td>
                    <UserMenu
                        item={item}
                        onEditClick={onEditClick}
                        onTariffClick={onTariffClick}
                    />
                </td>
            </tr>

            <UserTariffModal
                open={isTariffModalOpen}
                item={item}
                handleClose={handleCloseTariff}
            />

            <UserEditModal
                open={isEditModalOpen}
                item={item}
                handleClose={handleCloseEdit}
            />
        </>
    );
};

export default UserRow;