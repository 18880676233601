import React from 'react';

import BlankLayout from '../../components/Layout/BlankLayout/BlankLayout';
import PasswordResetSent from '../../components/Auth/Password/PasswordResetSent';


const PasswordResetSentPage: React.FC = () => {
    return (
        <BlankLayout pageTitle="Сброс пароля">
            <PasswordResetSent/>
        </BlankLayout>
    );
};

export default PasswordResetSentPage;