import React from 'react';

import styles from './Tariff.module.scss';


interface ITariffLimitNumberProps {
    value: string | number;
    text: string;
    className?: string;
    classNameValue?: string;
    classNameText?: string;
}

const TariffLimitNumber: React.FC<ITariffLimitNumberProps> = ({ value, text, className, classNameValue, classNameText }) => (
    <div className={className}>
        <div className={classNameValue}>{value}</div>
        <span className={classNameText}>{text}</span>
    </div>
);

export default TariffLimitNumber;