import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';

import {Permission} from 'models/Permission';
import {userPermissionsSelector, userSelector} from 'redux/authSlice';
import {isGrantedAccess} from 'utils/permissionsHelper';
import NoAuth from 'pages/NoAuth';

import {Navigate} from 'react-router';

import SpinnerWithOverlay from '../UI/SpinnerWithOverlay';
import {AppUrls} from '../../constants/urls';
import {getAccessToken, getRefreshToken} from '../../utils/auth';

interface IProtectedElementProps {
    element: ReactElement,
    auth: Permission[],
}

const ProtectedElement: React.FC<IProtectedElementProps> = ({element, auth}) => {
    const user = useSelector(userSelector);
    const userPermissions = useSelector(userPermissionsSelector);
    const hasTokens = !!getAccessToken() || !!getRefreshToken();

    if (hasTokens && user === null) {
        return <SpinnerWithOverlay />;
    }

    if (!hasTokens && user === null) {
        return <Navigate to={AppUrls.LOGIN} replace />;
    }

    return isGrantedAccess(userPermissions, auth)
        ? element
        : <NoAuth />;
};

export default ProtectedElement;