import React, {useState} from 'react';

import FileExcelIcon from '@rsuite/icons/lib/icons/legacy/FileExcelO';

import TransparentButton from '../../UI/Button/TransparentButton';
import MonitorsExportModal from './MonitorsExportModal';

const MonitorsExportButton: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Экспорт CSV <FileExcelIcon width="20px" height="20px"/>
            </TransparentButton>
            <MonitorsExportModal
                open={isModalOpen}
                handleClose={handleClose}
            />
        </>
    );
};

export default MonitorsExportButton;