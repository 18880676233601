/* eslint-disable max-len */
import React from 'react';

const Monitors: React.FC<object> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2571_68817)">
                <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H13C14.3807 0.5 15.5 1.61929 15.5 3V13C15.5 14.3807 14.3807 15.5 13 15.5H3C1.61929 15.5 0.5 14.3807 0.5 13V3Z" stroke="currentColor"/>
                <path d="M12.0002 8.00033H9.86683L8.26683 13.3337L5.06683 2.66699L3.46683 8.00033H1.3335" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.1318 8.86711C13.4318 8.56706 13.6004 8.16009 13.6004 7.73574C13.6004 7.3114 13.4318 6.90443 13.1318 6.60437C12.8317 6.30431 12.4247 6.13574 12.0004 6.13574C11.576 6.13574 11.1691 6.30431 10.869 6.60437C10.569 6.90443 10.4004 7.3114 10.4004 7.73574C10.4004 8.16009 10.569 8.56706 10.869 8.86711C11.1691 9.16717 11.576 9.33574 12.0004 9.33574C12.4247 9.33574 12.8317 9.16717 13.1318 8.86711Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_2571_68817">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Monitors;