// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorDetails_wrapper__NI\\+CV {
  margin: 0 1em;
}

.MonitorDetails_table__\\+6jyB td {
  text-align: left;
  padding: 3px;
  font-size: 14px;
  font-family: Inter;
}

@media (max-width: 1460px) {
  .MonitorDetails_responsive_container__DET3s {
    width: 99% !important;
  }
}
@media (max-width: 500px) {
  .MonitorDetails_responsive_container__DET3s {
    height: 200px !important;
  }
}

.MonitorDetails_container__5V6c9 {
  margin-top: 10px;
}

.MonitorDetails_subtitle__-DX1Z {
  margin-bottom: 12px;
  font-size: 16px !important;
  color: #242424 !important;
  font-weight: 600 !important;
  font-family: Inter !important;
}

.MonitorDetails_title__QWL0l {
  margin-bottom: 10px;
}

.MonitorDetails_incidents_title__kvaQH {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Monitor/MonitorDetails/MonitorDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAGE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AAAJ;;AAKE;EADF;IAEI,qBAAA;EADF;AACF;AAGE;EALF;IAMI,wBAAA;EAAF;AACF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,mBAAA;EACA,0BAAA;EACA,yBAAA;EACA,2BAAA;EACA,6BAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;AADF","sourcesContent":[".wrapper {\n  margin: 0 1em;\n}\n\n.table {\n  td {\n    text-align: left;\n    padding: 3px;\n    font-size: 14px;\n    font-family: Inter;\n  }\n}\n\n.responsive_container {\n  @media (max-width: 1460px) {\n    width: 99% !important;\n  }\n\n  @media (max-width: 500px) {\n    height: 200px !important;\n  }\n\n}\n\n.container {\n  margin-top: 10px;\n}\n\n.subtitle {\n  margin-bottom: 12px;\n  font-size: 16px !important;\n  color: #242424 !important;\n  font-weight: 600 !important;\n  font-family: Inter !important;\n}\n\n.title {\n  margin-bottom: 10px;\n}\n\n.incidents_title{\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MonitorDetails_wrapper__NI+CV`,
	"table": `MonitorDetails_table__+6jyB`,
	"responsive_container": `MonitorDetails_responsive_container__DET3s`,
	"container": `MonitorDetails_container__5V6c9`,
	"subtitle": `MonitorDetails_subtitle__-DX1Z`,
	"title": `MonitorDetails_title__QWL0l`,
	"incidents_title": `MonitorDetails_incidents_title__kvaQH`
};
export default ___CSS_LOADER_EXPORT___;
