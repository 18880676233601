import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import {providesList} from '../rtk-query/RTKQueryHelper';
import ITariff, {EditTariffType} from '../models/ITariff';
import IOrderBy from '../models/IOrderBy';

export const tariffAPI = createApi({
    reducerPath: 'tariffAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Tariff'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addTariff: build.mutation<ITariff, EditTariffType>({
            query: (data) => ({
                path: ApiUrls.TARIFFS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'Tariff', id: 'LIST' }],
        }),
        getTariffs: build.query<ITariff[], IOrderBy|undefined>({
            query: (sort) => ({
                path: buildQuery(ApiUrls.TARIFFS(sort)),
            }),
            providesTags: (result) => providesList(result, 'Tariff'),
        }),
        getTariff: build.query<ITariff, number>({
            query: (id) => ({
                path: ApiUrls.TARIFF(id),
            }),
            providesTags: (result, error, id) => [{ type: 'Tariff', id }],
        }),
        updateTariff: build.mutation<ITariff, Omit<ITariff, 'is_default'>>({
            query: ({id, ...data}) => ({
                path: ApiUrls.TARIFF(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    tariffAPI.util.updateQueryData('getTariff', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'Tariff', id: 'LIST' }],
        }),
        deleteTariff: build.mutation<void,  number>({
            query: (id) => ({
                path: ApiUrls.TARIFF(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Tariff', id: 'LIST' }, { type: 'Tariff', id }],
        }),
        defaultTariff: build.mutation<{message: string},  number>({
            query: (id) => ({
                path: ApiUrls.TARIFF_DEFAULT(id),
                method: 'POST',
                data: {},
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Tariff', id: 'LIST' }, { type: 'Tariff', id }],
        }),
    }),
});