import React, {MouseEvent} from 'react';

import PrimaryButton from '../UI/Button/PrimaryButton';
import ArrowDownCard from '../Icons/ArrowDownCard';

import styles from './Tariff.module.scss';

interface TariffPriceBlockProps {
    price: number;
    priceWithoutDiscount: number;
    saving: number;
    isActive: boolean;
    isArchive: boolean;
    onPayClick: (event: MouseEvent<HTMLTableRowElement>) => void;
    onExpandClick: () => void;
    isExpanded: boolean;
}

const TariffPrice: React.FC<TariffPriceBlockProps> = ({
    price,
    priceWithoutDiscount,
    saving,
    isActive,
    isArchive,
    onPayClick,
    onExpandClick,
    isExpanded,
}) => (
    <div className={styles.footer_card}>
        <div className={styles.priceBlock}>
            {
                priceWithoutDiscount > price &&
                <div className={styles.basePrice}>Без скидки {priceWithoutDiscount} руб.</div>
            }
            {
                saving > 0 && <div className={styles.saving}>Выгода {saving} руб.</div>
            }
            {
                !saving && <div className={styles.priceLabel}>Стоимость</div>
            }
            <div className={styles.price}>
                {price} <span>руб.</span>
            </div>
        </div>
        <div className={styles.block_button}>
            <div className={styles.button_container}>
                {price === 0 ? (
                    <PrimaryButton onClick={onPayClick} disabled className={styles.button_free}>
                        Бесплатно
                    </PrimaryButton>
                ) : isActive || isArchive ? (
                    <PrimaryButton onClick={onPayClick} className={styles.button_free}>
                        Продлить
                    </PrimaryButton>
                ) : (
                    <PrimaryButton onClick={onPayClick} className={styles.button_default}>
                        Оплатить
                    </PrimaryButton>
                )}
            </div>
            <div className={styles.arrow_icon} onClick={onExpandClick}>
                <ArrowDownCard className={isExpanded ? styles.expanded : ''} />
            </div>
        </div>
    </div>
);

export default TariffPrice;