// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserTariffForm_form__bG0Bx {
  min-width: 400px;
}

.UserTariffForm_text__qk4oV {
  font-size: 16px;
  text-align: left;
  margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/User/UserTariffForm/UserTariffForm.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".form{\n    min-width: 400px;\n}\n\n.text{\n    font-size: 16px;\n    text-align: left;\n    margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `UserTariffForm_form__bG0Bx`,
	"text": `UserTariffForm_text__qk4oV`
};
export default ___CSS_LOADER_EXPORT___;
