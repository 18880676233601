// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInformer_wrapper__tkZ6F {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 10px 15px 0 0;
  gap: 10px;
}

.UserInformer_text__OJ1y9 {
  max-width: 145px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  -webkit-hyphens: auto;
          hyphens: auto;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #616E6E;
}

.UserInformer_icon__1Ajvf {
  cursor: pointer;
  flex-shrink: 0;
}
.UserInformer_icon__1Ajvf svg {
  height: 16px;
  width: 16px;
  color: #AFC1C1;
}
.UserInformer_icon__1Ajvf svg:hover {
  color: #1DB5B6 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UserInformer/UserInformer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,qBAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;UAAA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,cAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;EACA,cAAA;AACR;AACQ;EACI,yBAAA;AACZ","sourcesContent":[".wrapper{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    padding: 10px 15px 0 0;\n    gap: 10px;\n}\n\n.text{\n    max-width: 145px;\n    word-wrap: break-word;\n    overflow-wrap: break-word;\n    white-space: normal;\n    hyphens: auto;\n    font-family: Inter, sans-serif;\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 18px;\n    color: #616E6E;\n}\n\n.icon{\n    cursor: pointer;\n    flex-shrink: 0;\n\n    svg{\n        height: 16px;\n        width: 16px;\n        color: #AFC1C1;\n\n        &:hover{\n            color: #1DB5B6!important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UserInformer_wrapper__tkZ6F`,
	"text": `UserInformer_text__OJ1y9`,
	"icon": `UserInformer_icon__1Ajvf`
};
export default ___CSS_LOADER_EXPORT___;
