import {NavigateFunction} from 'react-router-dom';

import {AppUrls} from '../constants/urls';

export function redirectToReturnUrl(navigate: NavigateFunction, searchParams: URLSearchParams):boolean {
    const returnUrl = searchParams.get('return') ?? null;
    if (null !== returnUrl) {
        navigate(returnUrl.toString());
        return true;
    }
    return false;
}

export function redirectToDashboard(navigate: NavigateFunction):boolean {
    navigate(AppUrls.HOME);
    return true;
}