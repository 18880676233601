// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationToggle_toggle__ihpZd {
  display: none;
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 1001;
}
@media (max-width: 769px) {
  .NavigationToggle_toggle__ihpZd {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/NavigationToggler/NavigationToggle.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;AACJ;AACI;EAPJ;IAQQ,cAAA;EAEN;AACF","sourcesContent":[".toggle{\n    display: none;\n    position: fixed;\n    top: 5px;\n    left: 5px;\n    z-index: 1001;\n\n    @media(max-width: 769px) {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `NavigationToggle_toggle__ihpZd`
};
export default ___CSS_LOADER_EXPORT___;
