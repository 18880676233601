// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailConfirmationBanner_banner__text__pk8nM {
  font-size: 13px;
  line-height: 20px;
  margin: 8px auto;
  word-wrap: break-word;
  font-family: Inter;
}

.EmailConfirmationBanner_banner__btn__YtCyN {
  background-color: #1DB5B6;
  border: none;
  outline: none;
  padding: 0;
  color: #fff;
  text-align: center;
  width: 100%;
  font-family: Inter;
  width: max-content;
  padding: 2px 6px;
  font-size: 13px;
  display: flex;
  border-radius: 4px;
  margin: 0 auto;
}
.EmailConfirmationBanner_banner__btn__YtCyN:hover {
  transform: scale(0.97);
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainLayout/Navigation/EmailConfirmationBanner/EmailConfirmationBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;AACJ;AAAI;EACI,sBAAA;AAER","sourcesContent":[".banner__text {\n    font-size: 13px;\n    line-height: 20px;\n    margin: 8px auto;\n    word-wrap: break-word;\n    font-family: Inter;\n}\n\n.banner__btn {\n    background-color: #1DB5B6;\n    border: none;\n    outline: none;\n    padding: 0;\n    color: #fff;\n    text-align: center;\n    width: 100%;\n    font-family: Inter;\n    width: max-content;\n    padding: 2px 6px;\n    font-size: 13px;\n    display: flex;\n    border-radius: 4px;\n    margin: 0 auto;\n    &:hover{\n        transform: scale(0.97);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner__text": `EmailConfirmationBanner_banner__text__pk8nM`,
	"banner__btn": `EmailConfirmationBanner_banner__btn__YtCyN`
};
export default ___CSS_LOADER_EXPORT___;
