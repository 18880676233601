import React, {useState} from 'react';

import FileExcelIcon from '@rsuite/icons/lib/icons/legacy/FileExcelO';

import TransparentButton from '../../UI/Button/TransparentButton';
import IncidentsExportModal from './IncidentsExportModal';
import IOrderBy from '../../../models/IOrderBy';

interface IIncidentsExportButtonProps {
    monitorId?: string,
    sort?: IOrderBy,
}

const IncidentsExportButton: React.FC<IIncidentsExportButtonProps> = ({monitorId, sort}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Экспорт CSV <FileExcelIcon width="20px" height="20px"/>
            </TransparentButton>
            <IncidentsExportModal
                open={isModalOpen}
                sort={sort}
                handleClose={handleClose}
                monitorId={monitorId}
            />
        </>
    );
};

export default IncidentsExportButton;