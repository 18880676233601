import {createApi} from '@reduxjs/toolkit/query/react';

import {AxiosRequestHeaders} from 'axios';

import httpBaseQuery from '../rtk-query/httpBaseQuery';
import {ApiUrls} from '../constants/urls';

import {buildQuery} from '../utils/query';
import IIncident from '../models/IIncident';
import IPagination from '../models/IPagination';
import IHydraResponse from '../models/HydraResponse';
import {paginationAdapter} from '../utils/paginationHelper';
import {providesList} from '../rtk-query/RTKQueryHelper';
import IOrderBy from '../models/IOrderBy';

export const incidentAPI = createApi({
    reducerPath: 'incidentAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['Incident'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getIncidents: build.query<IPagination<IIncident>, {
            monitorId?: string,
            page?: number,
            sort?: IOrderBy,
            limit?: number,
        }>({
            query: ({monitorId, page, sort, limit = 0}) => ({
                path: buildQuery(ApiUrls.INCIDENTS(monitorId, page, sort, limit)),
                headers: {Accept: 'application/ld+json'} as unknown as AxiosRequestHeaders,
            }),
            providesTags: (result) => providesList(result?.items, 'Incident'),
            transformResponse(
                rawResult: IHydraResponse<IIncident>
            ): Promise<IPagination<IIncident>> | IPagination<IIncident> {
                return paginationAdapter(rawResult);
            },
        }),
    }),
});