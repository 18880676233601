import React, {useState} from 'react';

import cn from 'classnames';

import PauseCircle from '@rsuite/icons/legacy/PauseCircle';

import {pluralize} from '../../../utils/pluralize';

import ISummaryMonitors from '../../../models/ISummaryMonitors';

import BoxTransparent from '../../UI/Box/BoxTransparent/BoxTransparent';

import {AppUrls} from '../../../constants/urls';

import LoaderCentered from '../../UI/Loader/LoaderCentered';

import MonitorStatus from './MonitorStatus/MonitorStatus';

import Corrected from '../../Icons/Corrected';
import Warning from '../../Icons/Warning';
import Problem from '../../Icons/Problem';
import Plus from '../../Icons/Plus';


import GradientButton from '../../UI/Button/GradientButton';

import useCanCreateMonitor from '../../../hooks/useCanCreateMonitor';

import MonitorModal from '../../Monitor/MonitorModal';
import MonitorLimitExceededModal from '../../Monitor/MonitorLimitExceededModal';

import {getPausedText} from '../../../utils/getPausedText';

import Pause from '../../Icons/Pause';

import Like from '../../Icons/Like';

import styles from './SummaryMonitors.module.scss';

interface IMonitorsSummaryProps {
    summary: ISummaryMonitors,
    isFetching: boolean,
}

const SummaryMonitors: React.FC<IMonitorsSummaryProps> = ({summary, isFetching}) => {
    const canCreateMonitor = useCanCreateMonitor();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);

    const availabilityClass = (availability: number): string => {
        switch (true) {
            case availability >= 99:
                return styles.high;
            default:
                return styles.low;
        }
    };

    const upIsZero = Number(summary.up || 0) === 0;
    const downIsZero = Number(summary.down || 0) === 0;
    const warningIsZero = Number(summary.warning || 0) === 0;

    return (
        <BoxTransparent className={cn(
            styles.wrapper,
            {
                [styles.background1]: upIsZero && downIsZero,
                [styles.background2]: upIsZero && warningIsZero,
            }
        )}>
            <LoaderCentered active={isFetching}/>
            <div className="block_header">
                <div className={styles.title}>Мониторинг</div>
            </div>
            <div className={styles.monitors_wrapper}>
                <div className={styles.monitors_container}>
                    {
                        summary.up > 0 && (
                            <div className={styles.monitorItem}>
                                <MonitorStatus
                                    text={pluralize(summary.up, {
                                        one: 'Исправный',
                                        few: 'Исправных',
                                        many: 'Исправных',
                                    })}
                                    counter={summary.up}
                                    className={cn(styles.correctedBig, styles.statusMonitor)}
                                    icon={<Corrected />}
                                    link={AppUrls.MONITORS}
                                    color={styles.color}
                                />
                            </div>
                        )}

                    {
                        summary.warning > 0 && (
                            <div className={styles.monitorItem}>
                                <div className={cn(styles.pulseCircle, styles.colorWarning)}></div>
                                <div className={cn(styles.pulseCircle, styles.colorWarning)}></div>
                                <MonitorStatus
                                    text={pluralize(summary.warning, {
                                        one: 'Предупреждение ',
                                        few: 'Предупреждения ',
                                        many: 'Предупреждений',
                                    })}
                                    counter={summary.warning}
                                    className={cn(
                                        styles.statusMonitor,
                                        upIsZero ? styles.warningBig : styles.warningSmall
                                    )}
                                    icon={<Warning />}
                                    link={AppUrls.WARNINGS}
                                    color={styles.color}
                                />
                            </div>
                        )}

                    {
                        summary.down > 0 && (
                            <div className={styles.monitorItem}>
                                <div className={cn(styles.pulseCircle, styles.colorProblem)}></div>
                                <div className={cn(styles.pulseCircle, styles.colorProblem)}></div>
                                <MonitorStatus
                                    text={pluralize(summary.down, {
                                        one: 'Проблема',
                                        few: 'Проблемы',
                                        many: 'Проблем',
                                    })}
                                    counter={summary.down}
                                    className={cn(
                                        styles.statusMonitor,
                                        downIsZero ? styles.problemBig : styles.problemSmall
                                    )}
                                    icon={<Problem />}
                                    link={AppUrls.INCIDENTS}
                                    color={styles.color}
                                />
                            </div>
                        )}

                    {
                        downIsZero && warningIsZero && (
                            <div className={styles.monitorItem}>
                                <div className={cn(styles.pulseCircle, styles.colorGrey)}></div>
                                <div className={cn(styles.pulseCircle, styles.colorGrey)}></div>
                                <div className={styles.no_problems}>
                                    <div><Like/></div>
                                    Нет проблем
                                </div>
                            </div>
                        )}
                </div>
                <GradientButton
                    onClick={() => setIsModalOpen(true)}
                    href={''}
                    className={styles.button}

                >
                    <span title={'Добавить монитор'} className={styles.circle}><Plus/></span>
                </GradientButton>
            </div>

            <div className={styles.info_wrapper}>
                <div className={styles.availability_wrapper}>
                    <div className={styles.title}>Доступность</div>
                    <div className={styles.availability}>
                        <div className={cn(styles.availability_block, availabilityClass(summary.availability1))}>
                            <span className={cn(styles.value, styles.bigText)}>
                                {summary.availability1}%
                            </span>
                            <div className={styles.period}>24 часа</div>
                        </div>

                        <div className={cn(styles.availability_block, availabilityClass(summary.availability7))}>
                            <span className={styles.value}>
                                {summary.availability7}%
                            </span>
                            <div className={styles.period}>7 дней</div>
                        </div>

                        <div className={cn(styles.availability_block, availabilityClass(summary.availability30))}>
                            <span className={styles.value}>
                                {summary.availability30}%
                            </span>
                            <div className={styles.period}>Месяц</div>
                        </div>
                    </div>
                </div>
                <div className={styles.paused_wrapper}>
                    <div className={styles.title}>{getPausedText(summary.paused)}</div>
                    <div className={styles.paused_informer}>
                        <Pause/>
                        <div className={styles.paused_value}>
                            {summary.paused}
                        </div>
                    </div>
                </div>
            </div>

            {
                canCreateMonitor
                    ? <MonitorModal open={isModalOpen} handleClose={handleClose}/>
                    : <MonitorLimitExceededModal open={isModalOpen} handleClose={handleClose}/>
            }
        </BoxTransparent>
    );
};

export default SummaryMonitors;