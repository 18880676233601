import {OptionType} from '../types';
import ITariff from '../models/ITariff';
import {iri} from './stringHelper';


export const getTariffOption = (item: ITariff): OptionType => {
    return {
        value: iri('tariffs', item?.id),
        label: item.name,
    };
};