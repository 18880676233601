/* eslint-disable max-len */
import React from 'react';

const Check: React.FC<object> = () => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0104 15.0104C13.4163 16.6045 11.2543 17.5 9 17.5C6.74566 17.5 4.58365 16.6045 2.98959 15.0104C1.39553 13.4163 0.5 11.2543 0.5 9C0.5 6.74566 1.39553 4.58365 2.98959 2.98959C4.58365 1.39553 6.74566 0.5 9 0.5C11.2543 0.5 13.4163 1.39553 15.0104 2.98959C16.6045 4.58365 17.5 6.74566 17.5 9C17.5 11.2543 16.6045 13.4163 15.0104 15.0104Z" stroke="#899696"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.828 7.48246C13.0207 7.27575 13.1274 6.99889 13.125 6.71151C13.1225 6.42413 13.0113 6.14923 12.8151 5.94602C12.619 5.74281 12.3536 5.62754 12.0763 5.62504C11.7989 5.62254 11.5316 5.73302 11.3321 5.93267L7.84831 9.54191L6.4804 8.12474C6.28087 7.92509 6.01363 7.81461 5.73624 7.81711C5.45886 7.81961 5.19351 7.93488 4.99736 8.13809C4.80121 8.3413 4.68995 8.6162 4.68754 8.90358C4.68513 9.19095 4.79176 9.46781 4.98448 9.67453L7.10035 11.8666C7.29874 12.0721 7.56779 12.1875 7.84831 12.1875C8.12884 12.1875 8.39788 12.0721 8.59627 11.8666L12.828 7.48246Z" fill="#899696"/>
    </svg>;
};

export default Check;