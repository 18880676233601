// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableBox_table_box__XJY7Y {
  background-color: #f6f6f6;
  padding: 25px 0 0 0;
  width: calc(100vw - 275px);
  max-width: 100%;
}
@media (max-width: 768px) {
  .TableBox_table_box__XJY7Y {
    width: 100vw;
  }
}
.TableBox_table_box__XJY7Y .block_header {
  padding: 0 30px;
  margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Box/TableBox/TableBox.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,0BAAA;EACA,eAAA;AACF;AACE;EANF;IAOI,YAAA;EAEF;AACF;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ","sourcesContent":[".table_box{\n  background-color: #f6f6f6;\n  padding: 25px 0 0 0;\n  width: calc(100vw - 275px);\n  max-width:100%;\n\n  @media (max-width: 768px) {\n    width: 100vw;\n  }\n\n  :global(.block_header){\n    padding: 0 30px;\n    margin-bottom: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_box": `TableBox_table_box__XJY7Y`
};
export default ___CSS_LOADER_EXPORT___;
