import React, {FC, ReactNode} from 'react';
import {Popover, Whisper} from 'rsuite';

import styles from './MoreActions.module.scss';

interface IMoreActionsProps {
    children: ReactNode | null | undefined,
    anchor: JSX.Element,
    arrow?: boolean,
}

const MoreActions: FC<IMoreActionsProps> = (
    {
        children,
        anchor,
        arrow,
    }) => {

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const typePopover = <Popover arrow={arrow ?? true}>
        <div className={styles['dropdown']}>
            {children}
        </div>
    </Popover>;

    return (
        <Whisper placement="autoVertical" trigger="click" speaker={typePopover}>
            <div onClick={handleClick}>
                {anchor}
            </div>
        </Whisper>
    );
};

export default React.memo(MoreActions);