import React, {useCallback, useState} from 'react';

import {Control, useController} from 'react-hook-form';

import cn from 'classnames';

import Input, {InputProps} from './Input';

import {getInputState} from '../../../utils/inputHelper';

import Correct from '../../Icons/Correct';
import Wrong from '../../Icons/Wrong';

import Edit from '../../Icons/Edit';

import styles from './Input.module.scss';

export interface IInputTextProps extends InputProps {
    control: Control<any>;
    name: string,
}

const InputText: React.FC<IInputTextProps> = ({control, className, ...rest}) => {
    const { fieldState } = useController({
        name: rest.name,
        control,
    });

    const [isFocused, setIsFocused] = useState(false);
    const state = getInputState(fieldState, isFocused, !!rest.defaultValue);

    const iconsMap = {
        valid: <Correct/>,
        error: <Wrong/>,
        active: <Edit color="#000000" />,
        default: <Edit />,
    };

    const classMap = {
        valid: styles.input_valid,
        error: styles.input_error,
        active: styles.input_active,
        default: styles.input_default,
    };

    const onInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    return (
        <div className={styles.input_container}>
            <div className={styles.icon}>
                {iconsMap[state]}
            </div>
            <Input
                {...rest}
                type="text"
                className={cn(styles.input, classMap[state], className)}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
            />
        </div>
    );
};

export default InputText;