import React from 'react';
import {Link} from 'react-router-dom';

export interface GradientButtonProps {
    href: string,
    className?: string,
    children: React.ReactNode,
    onClick?: () => void,
    text?: string
}

const GradientButton : React.FC<GradientButtonProps> = props => {
    return (
        <Link
            to={props.href}
            className={props.className}
            onClick={props.onClick}
        >
            {props.children}
            {props.text}
        </Link>
    );
};

export default React.memo(GradientButton);