import React, {useState} from 'react';

import FilterIcon from '@rsuite/icons/lib/icons/legacy/Filter';

import TransparentButton from '../../UI/Button/TransparentButton';
import MonitorFiltersModal from './MonitorFiltersModal';

const MonitorFiltersButton: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Фильтры <FilterIcon width="20px" height="20px"/>
            </TransparentButton>
            <MonitorFiltersModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default MonitorFiltersButton;