/* eslint-disable max-len */
import React from 'react';

const Notification: React.FC<object> = () => {

    return (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 4.22233L8.01333 8.89789C8.30548 9.0928 8.6488 9.19682 9 9.19682C9.3512 9.19682 9.69452 9.0928 9.98667 8.89789L17 4.22233M2.77778 14.0001H15.2222C15.6937 14.0001 16.1459 13.8128 16.4793 13.4794C16.8127 13.146 17 12.6938 17 12.2223V3.33344C17 2.86195 16.8127 2.40976 16.4793 2.07636C16.1459 1.74297 15.6937 1.55566 15.2222 1.55566H2.77778C2.30628 1.55566 1.8541 1.74297 1.5207 2.07636C1.1873 2.40976 1 2.86195 1 3.33344V12.2223C1 12.6938 1.1873 13.146 1.5207 13.4794C1.8541 13.8128 2.30628 14.0001 2.77778 14.0001Z"
                stroke="#1DB5B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Notification;
    