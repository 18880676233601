import React from 'react';

import ITariff from '../../models/ITariff';
import TariffForm from './TariffForm/TariffForm';
import MainModal from '../UI/Modal/MainModal';

interface ITariffModalProps {
    open: boolean,
    item: ITariff,
    handleClose: () => void;
}

const TariffModal: React.FC<ITariffModalProps> = ({open, item, handleClose}) => {

    return (
        <MainModal
            header={`Оплата тарифа - ${item.name}`}
            open={open}
            handleClose={handleClose}
            size="sm"
        >
            <TariffForm onSuccess={handleClose} item={item}/>
        </MainModal>
    );
};

export default TariffModal;